import * as Icons from '../../components/icons'
import { Button, CircularProgress } from '@mui/material'
import { Fade, Menu, MenuItem, useTheme } from '@mui/material'
import { ConfirmMenuItem } from '../shared'
import React, { useState } from 'react'
import _ from 'lodash'
import { asyncForEach } from '../../utils'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'
import { useCurrents } from '../../context/currents'

export default function AllActionButton({ incidents }) {
  const classes = useStyles()
  const theme = useTheme()
  const { user } = useCurrents()
  const acknowledgeMutation = useMutation(ACKNOWLEDGE_INCIDENT)
  const resolveMutation = useMutation(RESOLVE_INCIDENT)
  const snoozeMutation = useMutation(SNOOZE_INCIDENT)
  const configureUserAutoResponder = useMutation(CONFIGURE_USER_AUTO_RESPONDER)
  const disableUserAutoResponder = useMutation(DISABLE_USER_AUTO_RESPONDER)
  const [anchorEl, setAnchorEl] = useState(null)
  const [editingStatus, setEditingStatus] = useState(false)
  const [snoozing, setSnoozing] = useState(false)
  const [autoResponding, setAutoResponding] = useState(false)
  const [loading, setLoading] = useState(false)
  const openIncidents = _.filter(incidents, ['state', 'OPEN'])

  function handleMenu(event) {
    setEditingStatus(true)
    setAnchorEl(event.currentTarget)
  }

  function handleSnoozeAll() {
    setEditingStatus(false)
    setSnoozing(true)
  }

  function handleAutoResponding() {
    setEditingStatus(false)
    setAutoResponding(true)
  }

  function handleClose() {
    setSnoozing(false)
    setEditingStatus(false)
    setAutoResponding(false)
    setAnchorEl(null)
  }

  function statusMutation(status) {
    switch (status) {
      case 'ACKNOWLEDGED':
        return acknowledgeMutation
      case 'RESOLVED':
        return resolveMutation
      default:
        return
    }
  }

  async function setAllStatus(status) {
    handleClose()
    setLoading(true)
    try {
      await Promise.all(
        _.map(openIncidents, (incident) =>
          statusMutation(status)({ incidentId: incident.id })
        )
      )
    } catch (_) {}

    setLoading(false)
  }

  async function snoozeAllWithDuration(duration) {
    handleClose()
    setLoading(true)
    try {
      await Promise.all(
        _.map(openIncidents, (incident) =>
          snoozeMutation({
            incidentId: incident.id,
            durationInMinutes: duration,
          })
        )
      )
    } catch (_) {}

    setLoading(false)
  }

  async function snoozeAllWithTriggerAt(triggerAt) {
    handleClose()
    setLoading(true)
    try {
      await asyncForEach(openIncidents, async (incident) =>
        snoozeMutation({
          incidentId: incident.id,
          triggerAt,
        })
      )
    } catch (_) {}

    setLoading(false)
  }

  async function configureUserAutoResponderWithDuration(durationInMinutes) {
    handleClose()
    setLoading(true)

    try {
      await configureUserAutoResponder({
        userId: user.id,
        until: moment().add(durationInMinutes, 'minutes').unix(),
        action: 'ACKNOWLEDGE',
      })
    } catch (_) {}

    setLoading(false)
  }

  async function disableUserAutoResponderNow() {
    handleClose()
    setLoading(true)

    try {
      await disableUserAutoResponder({
        userId: user.id,
      })
    } catch (_) {}

    setLoading(false)
  }

  const autoRespondOptions = [
    {
      label: 'For 20 minutes',
      onClick: () => configureUserAutoResponderWithDuration(20),
      value: 'for the next 20 minutes',
    },
    {
      label: 'For 1 hour',
      onClick: () => configureUserAutoResponderWithDuration(60),
      value: 'for the next hour',
    },
    {
      label: 'For 6 hours',
      onClick: () => configureUserAutoResponderWithDuration(360),
      value: 'for the next 6 hours',
    },
  ]

  const snoozeOptions = [
    {
      label: 'For 30 minutes',
      onClick: () => snoozeAllWithDuration(30),
      value: 'for 30 minutes',
    },
    {
      label: 'For 1 hour',
      onClick: () => snoozeAllWithDuration(60),
      value: 'for 1 hour',
    },
    {
      label: 'For 6 hours',
      onClick: () => snoozeAllWithDuration(360),
      value: 'for 6 hours',
    },
    {
      label: moment().hour() < 9 ? 'Until 9am' : 'Until tomorrow at 9am',
      onClick: () => {
        const tomorrow = moment('9:00 AM', 'LT')
          .add(moment().hour() < 9 ? 0 : 1, 'day')
          .unix()
        snoozeAllWithTriggerAt(tomorrow)
      },
      value: moment().hour() < 9 ? 'until 9am' : 'until tomorrow at 9am',
    },
    {
      label: moment().isoWeekday() === 1 ? 'Until next Monday' : 'Until Monday',
      onClick: () => {
        const today = moment().isoWeekday()
        const monday = 1
        const nextMonday = moment('9:00 AM', 'LT')
          .add(today < monday ? 0 : 1, 'weeks')
          .isoWeekday(monday)
          .unix()
        snoozeAllWithTriggerAt(nextMonday)
      },
      value: moment().isoWeekday() === 1 ? 'until next Monday' : 'until Monday',
    },
  ]

  const options = [
    {
      label: 'Acknowledge all',
      onClick: async () => setAllStatus('ACKNOWLEDGED'),
      value: 'acknowledge',
    },
    { label: 'Snooze all...', onClick: handleSnoozeAll, value: 'snooze' },
    {
      label: 'Resolve all',
      onClick: async () => setAllStatus('RESOLVED'),
      value: 'resolve',
    },
    {
      label: user.autoResponder
        ? 'Disable auto-responder'
        : 'Enable auto-responder...',
      onClick: user.autoResponder
        ? disableUserAutoResponderNow
        : handleAutoResponding,
      value: user.autoResponder
        ? 'disableAutoResponder'
        : 'configureAutoResponder',
    },
  ]

  return (
    <>
      <Button
        className="mr-sm"
        disabled={loading}
        onClick={handleMenu}
        endIcon={
          editingStatus || snoozing || autoResponding ? (
            <Icons.DropUpArrow
              color={
                loading ? theme.palette.action.disabled : styles.primary.color
              }
              size={15}
            />
          ) : (
            <Icons.DropDownArrow
              color={
                loading ? theme.palette.action.disabled : styles.primary.color
              }
              size={15}
            />
          )
        }
        size="small"
        variant="outlined"
      >
        {loading ? (
          <CircularProgress className="mx-md" color="inherit" size={21} />
        ) : (
          'Actions'
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        className="mt-xs"
        classes={{ paper: classes.paper }}
        id="menu-actionButton"
        keepMounted
        onClose={handleClose}
        open={editingStatus}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        TransitionComponent={Fade}
        transitionDuration={0}
      >
        {options.map((option) =>
          option.value === 'snooze' ? (
            <MenuItem
              classes={{ root: classes.root }}
              key={option.label}
              onClick={option.onClick}
              disabled={!_.some(openIncidents)}
              style={{
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              {option.label}
            </MenuItem>
          ) : option.value === 'configureAutoResponder' ? (
            <MenuItem
              classes={{ root: classes.root }}
              key={option.label}
              onClick={option.onClick}
              style={{
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              {option.label}
            </MenuItem>
          ) : option.value === 'disableAutoResponder' ? (
            <ConfirmMenuItem
              classes={{ root: classes.root }}
              key={option.label}
              confirmContent={`Are you sure you want to turn off automatic responses?`}
              onConfirm={option.onClick}
              title={`Disable auto responder`}
            >
              {option.label}
            </ConfirmMenuItem>
          ) : (
            <ConfirmMenuItem
              classes={{ root: classes.root }}
              disabled={!_.some(openIncidents)}
              key={option.label}
              confirmContent={`Are you sure you want to ${option.value} ${
                openIncidents.length === 1
                  ? 'this 1 incident'
                  : `all ${openIncidents?.length} open incidents`
              }?`}
              onConfirm={option.onClick}
              title={`${_.capitalize(option.value)} All Incidents`}
            >
              {option.label}
            </ConfirmMenuItem>
          )
        )}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        className="mt-xs"
        classes={{ paper: classes.paper }}
        id="menu-snoozeButton"
        keepMounted
        onClose={handleClose}
        open={snoozing}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        TransitionComponent={Fade}
        transitionDuration={0}
      >
        {snoozeOptions.map((option) => (
          <ConfirmMenuItem
            classes={{ root: classes.root }}
            key={option.label}
            confirmContent={`Are you sure you want to snooze ${
              openIncidents?.length === 1
                ? 'this 1 incident'
                : `all ${openIncidents?.length} open incidents`
            } ${option.value}?`}
            onConfirm={option.onClick}
            title={`Snooze ${option.value}`}
          >
            {option.label}
          </ConfirmMenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        className="mt-xs"
        classes={{ paper: classes.paper }}
        id="menu-autoRespondButton"
        keepMounted
        onClose={handleClose}
        open={autoResponding}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        TransitionComponent={Fade}
        transitionDuration={0}
      >
        {autoRespondOptions.map((option) => (
          <ConfirmMenuItem
            classes={{ root: classes.root }}
            key={option.label}
            confirmContent={`Are you sure you want to automatically acknowledge all incidents assigned to you ${option.value}?`}
            onConfirm={option.onClick}
            title={`Auto-respond ${option.value}`}
          >
            {option.label}
          </ConfirmMenuItem>
        ))}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: styles.primary.color,
    borderRadius: 16,
    color: styles.container.backgroundColor,
    width: 198,
  },
  root: {
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 'bold',
    padding: 20,
    paddingBottom: 10,
    paddingTop: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 'bold',
  },
}))

const ACKNOWLEDGE_INCIDENT = gql`
  mutation AcknowledgeIncident($input: AcknowledgeIncidentInput!) {
    acknowledgeIncident(input: $input) {
      incident {
        id
        status
      }
    }
  }
`

const RESOLVE_INCIDENT = gql`
  mutation ResolveIncident($input: ResolveIncidentInput!) {
    resolveIncident(input: $input) {
      incident {
        id
        status
      }
    }
  }
`

const SNOOZE_INCIDENT = gql`
  mutation SnoozeIncident($input: SnoozeIncidentInput!) {
    snoozeIncident(input: $input) {
      incident {
        id
        status
      }
    }
  }
`

const CONFIGURE_USER_AUTO_RESPONDER = gql`
  mutation ConfigureUserAutoResponder(
    $input: ConfigureUserAutoResponderInput!
  ) {
    configureUserAutoResponder(input: $input) {
      autoResponder {
        action
        until
      }
    }
  }
`

const DISABLE_USER_AUTO_RESPONDER = gql`
  mutation DisableUserAutoResponder($input: DisableUserAutoResponderInput!) {
    disableUserAutoResponder(input: $input) {
      autoResponder {
        action
        until
      }
    }
  }
`
