import moment from 'moment-timezone'
import { useCurrents } from '../context/currents'

export default function useWelcome() {
  const { user, account } = useCurrents()
  const now = moment()

  const accountCreatedAt = moment.unix(account.createdAt)
  const accountCreatedAtWeeksAgo = now.diff(accountCreatedAt, 'weeks')
  const accountConditions = accountCreatedAtWeeksAgo < 2

  const userCreatedAt = moment.unix(user.createdAt)
  const userCreatedAtWeeksAgo = now.diff(userCreatedAt, 'weeks')
  const userConditions = userCreatedAtWeeksAgo < 2

  function dismissWelcome() {
    localStorage.setItem(`@dismissedWelcome-${user.id}`, true)
  }

  function dismissWelcomeAlert() {
    localStorage.setItem(`@dismissedWelcomeAlert-${user.id}`, true)
  }

  const showWelcome =
    !localStorage.getItem(`@dismissedWelcome-${user.id}`) &&
    (user.role === 'OWNER'
      ? accountConditions || userConditions
      : userConditions)

  const showWelcomeAlert =
    !(
      localStorage.getItem(`@dismissedWelcomeAlert-${user.id}`) ||
      localStorage.getItem(`@dismissedWelcome-${user.id}`)
    ) &&
    (user.role === 'OWNER'
      ? accountConditions || userConditions
      : userConditions)

  return { dismissWelcome, dismissWelcomeAlert, showWelcome, showWelcomeAlert }
}
