import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import { ScheduleForm } from '../../components/Teams'
import gql from 'graphql-tag'

export default function EditSchedulePage({ scheduleId, teamId }) {
  useBack(`/teams/${teamId}/schedules/${scheduleId}`)
  const { data, errors, loading } = useQuery(QUERY, {
    scheduleId,
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.schedule.viewerCanAdminister) return <NotFoundPage />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Schedule" />
      <R5Header title="Edit Schedule" />
      <ScheduleForm schedule={data.schedule} teamId={teamId} />
    </R5Container>
  )
}

const QUERY = gql`
  query EditSchedule($scheduleId: ID!, $teamId: ID!) {
    schedule(id: $scheduleId, teamId: $teamId) {
      id
      name
      isDefault
      viewerCanAdminister
    }
  }
`
