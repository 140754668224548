import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { R5Error, R5AppBarLoader, R5DrawerLoader } from '.'

import R5Theme from '../../constants/R5Theme'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

export default function LoginError({ errors }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={R5Theme}>
        <CssBaseline />
        <R5AppBarLoader />
        <WrappedLoginError errors={errors} />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

function WrappedLoginError({ errors }) {
  const classes = useStyles()

  return (
    <>
      <nav className={classes.drawer}>
        <R5DrawerLoader />
      </nav>
      <main className={classes.content}>
        <R5Error errors={errors} />
      </main>
    </>
  )
}

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
}))
