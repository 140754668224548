import React, { useEffect, useState } from 'react'

import { Alert } from '@mui/material'
import { useQueryParams } from '../../hooks'

export default function R5Flash() {
  const { error, error_description, info, success, warning, removeQueryParam } =
    useQueryParams()
  const [severity, setSeverity] = useState()
  const [message, setMessage] = useState()

  useEffect(() => {
    const severity = error
      ? 'error'
      : info
      ? 'info'
      : success
      ? 'success'
      : warning
      ? 'warning'
      : undefined

    if (severity) {
      setSeverity(severity)
      setMessage(error || info || success || warning)

      if (error && error_description) {
        const message = error_description.replace(
          'PreSignUp failed with error ',
          ''
        )
        setMessage(message)
      }

      removeQueryParam(severity)
    }
  }, [
    error,
    error_description,
    info,
    removeQueryParam,
    severity,
    success,
    warning,
  ])

  return message ? <Alert severity={severity}>{message}</Alert> : null
}
