import React from 'react'

export default function LogoWithText({ clipKey }) {
  return (
    <svg
      width="155"
      height="26"
      viewBox="0 0 275 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.265 33.6422H33.7791C38.6905 33.6422 43.1748 30.9254 45.4266 26.5901L47.1543 23.2567C47.7755 22.062 46.902 20.6169 45.5431 20.6169H29.2949C28.0719 20.6169 27.1983 21.7923 27.5477 22.9676L30.5178 32.3705C30.7508 33.122 31.4691 33.6422 32.265 33.6422Z"
        fill="white"
      />
      <path
        d="M62.5873 6.87873L64.7809 2.71681C65.4409 1.48365 64.5285 0 63.1308 0H22.8499C21.5881 0 20.6952 1.21389 21.064 2.40852L24.2476 12.4858C24.5 13.2565 25.2183 13.7767 26.0336 13.7767H51.0757C55.9094 13.796 60.3548 11.137 62.5873 6.87873Z"
        fill="white"
      />
      <path
        d="M13.5906 1.46465C13.3188 0.616856 12.5035 0.0195427 11.5911 0.0195427H6.33031H2.09839C0.681283 0.0195427 -0.328166 1.36831 0.0989082 2.71708L11.8823 39.4229C12.1541 40.29 12.9694 40.868 13.8818 40.868H23.3745C24.7916 40.868 25.7816 39.5193 25.374 38.1705L13.5906 1.46465Z"
        fill="white"
      />
      <g clipPath={`url(#clipLogoWithText${clipKey})`}>
        <path
          d="M86.3804 29.6433C86.3804 32.4765 82 32.5979 82 29.7243V11.1869C82 10.0536 82.9006 9.20364 83.965 9.20364H92.7258C101.118 9.20364 102.838 20.2937 95.8371 23.0459L100.217 28.3481C101.937 30.8575 98.4981 33.286 96.5331 30.7766L91.5795 23.734H86.3804V29.6433ZM86.3804 19.9699H92.7667C97.0652 19.9699 97.3109 13.1297 92.7667 13.1297H86.3804V19.9699Z"
          fill="white"
        />
        <path
          d="M124.125 31.7467C123.061 31.1396 122.406 29.9658 123.061 28.6301L132.518 10.3356C133.418 8.59524 135.793 8.55476 136.612 10.3356L145.904 28.6301C147.255 31.18 143.284 33.2038 142.097 30.6539L140.664 27.7802H128.506L127.073 30.6539C126.541 31.7467 125.313 31.9895 124.125 31.7467ZM138.29 23.4494L134.565 15.395L130.512 23.4494H138.29Z"
          fill="white"
        />
        <path
          d="M151.186 31.7467C150.08 31.7467 148.893 31.1396 148.893 29.723V11.2261C148.893 10.0523 150.08 9.20236 151.186 9.20236H158.759C173.824 9.20236 173.497 31.7872 159.046 31.7872H151.186V31.7467ZM153.273 13.1689V27.7802H158.759C167.684 27.7802 168.052 13.1689 158.759 13.1689H153.273Z"
          fill="white"
        />
        <path
          d="M172.432 12.3189C170.836 10.2142 174.397 7.58337 176.117 10.1333L181.889 18.6329L187.743 10.1333C189.504 7.74527 193.147 10.1333 191.428 12.3189L183.977 22.9637V29.723C183.977 32.5967 179.638 32.5157 179.638 29.6825V22.9637L172.432 12.3189Z"
          fill="white"
        />
        <path
          d="M208.786 13.4117V18.5925H217.178C218.365 18.5925 219.552 19.7662 219.552 20.8995C219.552 21.9923 218.365 22.8423 217.178 22.8423H208.786V29.6825C208.786 30.8158 207.967 31.7062 206.82 31.7062C205.388 31.7062 204.487 30.8158 204.487 29.6825V11.2261C204.487 10.0523 205.388 9.20236 206.82 9.20236H218.406C219.839 9.20236 220.699 10.0523 220.699 11.2261C220.699 12.2784 219.839 13.4522 218.406 13.4522H208.786V13.4117Z"
          fill="white"
        />
        <path
          d="M224.547 11.1452C224.547 8.47384 228.886 8.39289 228.886 11.1452V29.7635C228.886 32.4753 224.547 32.5157 224.547 29.7635V11.1452Z"
          fill="white"
        />
        <path
          d="M241.25 30.2896L232.53 12.2379C231.261 9.64757 235.15 7.5429 236.583 10.2547L239.53 16.8116L243.337 25.4327L247.063 16.8116L250.01 10.2547C251.32 7.78574 255.169 9.32378 254.022 12.0356L245.63 30.2491C244.852 32.3538 242.519 32.8395 241.25 30.2896Z"
          fill="white"
        />
        <path
          d="M118.722 27.4968H108.651V22.5994V18.3091V13.4117H118.148C121.137 13.4117 121.178 9.16185 118.148 9.16185H106.317C105.171 9.16185 104.27 10.0118 104.27 11.1856V29.7229C104.27 30.8562 105.171 31.7467 106.317 31.7467H118.722C121.956 31.7467 121.71 27.4968 118.722 27.4968Z"
          fill="white"
        />
        <path
          d="M115.119 22.9232C113.768 22.9232 112.663 21.8304 112.663 20.4948C112.663 19.1591 113.768 18.0663 115.119 18.0663C116.47 18.0663 117.575 19.1591 117.575 20.4948C117.575 21.8304 116.47 22.9232 115.119 22.9232Z"
          fill="white"
        />
        <path
          d="M272.035 27.4968H261.964V22.5994V18.3091V13.4117H271.462C274.45 13.4117 274.491 9.16185 271.462 9.16185H259.631C258.484 9.16185 257.584 10.0118 257.584 11.1856V29.7229C257.584 30.8562 258.484 31.7467 259.631 31.7467H272.035C275.269 31.7467 275.064 27.4968 272.035 27.4968Z"
          fill="white"
        />
        <path
          d="M268.432 22.9232C267.081 22.9232 265.976 21.8304 265.976 20.4948C265.976 19.1591 267.081 18.0663 268.432 18.0663C269.783 18.0663 270.889 19.1591 270.889 20.4948C270.889 21.8304 269.783 22.9232 268.432 22.9232Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={`clipLogoWithText${clipKey}`}>
          <rect
            width="192.368"
            height="23.03"
            fill="white"
            transform="translate(82 9)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
