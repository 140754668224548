import * as Icons from '../icons'

import {
  Avatar,
  CircularProgress,
  Fade,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function ActionButton({ incident, setShowEditIncidentModal }) {
  const classes = useStyles()
  const acknowledgeMutation = useMutation(ACKNOWLEDGE_INCIDENT)
  const resolveMutation = useMutation(RESOLVE_INCIDENT)
  const snoozeMutation = useMutation(SNOOZE_INCIDENT)
  const [anchorEl, setAnchorEl] = useState(null)
  const [editingStatus, setEditingStatus] = useState(false)
  const [snoozing, setSnoozing] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [incident])

  function handleMenu(event) {
    setEditingStatus(true)
    setAnchorEl(event.currentTarget)
  }

  function handleSnooze() {
    setEditingStatus(false)
    setSnoozing(true)
  }

  function handleClose() {
    setSnoozing(false)
    setEditingStatus(false)
    setAnchorEl(null)
  }

  function statusMutation(status) {
    switch (status) {
      case 'ACKNOWLEDGED':
        return acknowledgeMutation
      case 'SNOOZED':
        return snoozeMutation
      case 'RESOLVED':
        return resolveMutation
      default:
        return
    }
  }

  async function setStatus(status) {
    handleClose()
    setLoading(true)
    await statusMutation(status)({ incidentId: incident.id })
  }

  async function snoozeWithDuration(duration) {
    handleClose()
    setLoading(true)
    await snoozeMutation({
      incidentId: incident.id,
      durationInMinutes: duration,
    })
  }

  async function snoozeWithTriggerAt(triggerAt) {
    handleClose()
    setLoading(true)
    await snoozeMutation({
      incidentId: incident.id,
      triggerAt,
    })
  }

  function showEditModal() {
    handleClose()
    setShowEditIncidentModal(true)
  }

  const snoozeOptions = [
    {
      label: 'For 30 minutes',
      onClick: () => snoozeWithDuration(30),
    },
    {
      label: 'For 1 hour',
      onClick: () => snoozeWithDuration(60),
    },
    {
      label: 'For 6 hours',
      onClick: () => snoozeWithDuration(360),
    },
    {
      label: moment().hour() < 9 ? 'Until 9am' : 'Until tomorrow at 9am',
      onClick: () => {
        const tomorrow = moment('9:00 AM', 'LT')
          .add(moment().hour() < 9 ? 0 : 1, 'day')
          .unix()
        snoozeWithTriggerAt(tomorrow)
      },
    },
    {
      label: moment().isoWeekday() === 1 ? 'Until next Monday' : 'Until Monday',
      onClick: () => {
        const today = moment().isoWeekday()
        const monday = 1
        const nextMonday = moment('9:00 AM', 'LT')
          .add(today < monday ? 0 : 1, 'weeks')
          .isoWeekday(monday)
          .unix()
        snoozeWithTriggerAt(nextMonday)
      },
    },
  ]

  let options = []
  switch (incident.status) {
    case 'ACKNOWLEDGED':
    case 'SNOOZED':
      options = [
        { label: 'Snooze...', onClick: handleSnooze },
        { label: 'Reassign...', onClick: showEditModal },
        {
          label: 'Resolve',
          onClick: async () => setStatus('RESOLVED'),
        },
      ]
      break
    case 'RESOLVED':
      options = [{ label: 'Redact...', onClick: showEditModal }]
      break
    case 'TRIGGERED':
      options = [
        {
          label: 'Acknowledge',
          onClick: async () => setStatus('ACKNOWLEDGED'),
        },
        { label: 'Snooze...', onClick: handleSnooze },
        { label: 'Reassign...', onClick: showEditModal },
        {
          label: 'Resolve',
          onClick: async () => setStatus('RESOLVED'),
        },
      ]
      break
    case 'REDACTED':
    default:
      options = []
  }

  return (
    <>
      <IconButton disabled={loading} onClick={handleMenu} size="small">
        <Avatar
          className={
            editingStatus || snoozing ? classes.editingAvatar : classes.avatar
          }
        >
          {loading ? (
            <CircularProgress size={18} />
          ) : (
            <Icons.Dots
              color={editingStatus || snoozing ? '#fff' : styles.primary.color}
              size={20}
            />
          )}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.paper }}
        id="menu-actionButton"
        keepMounted
        onClose={handleClose}
        open={editingStatus}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
        transitionDuration={0}
      >
        {options.map((option) => (
          <MenuItem
            classes={{ root: classes.root }}
            key={option.label}
            onClick={option.onClick}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.paper }}
        id="menu-snoozeButton"
        keepMounted
        onClose={handleClose}
        open={snoozing}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
        transitionDuration={0}
      >
        {snoozeOptions.map((option) => (
          <MenuItem
            classes={{ root: classes.root }}
            key={option.label}
            onClick={option.onClick}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#fff',
    borderColor: '#e5e7eb',
    borderStyle: 'solid',
    borderWidth: 1,
    height: 32,
    width: 32,
  },
  editingAvatar: {
    backgroundColor: styles.primary.color,
    borderWidth: 0,
    height: 32,
    width: 32,
  },
  paper: {
    backgroundColor: styles.primary.color,
    borderRadius: 16,
    color: styles.container.backgroundColor,
    width: 170,
  },
  root: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: 20,
    paddingBottom: 10,
    paddingTop: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

const ACKNOWLEDGE_INCIDENT = gql`
  mutation AcknowledgeIncident($input: AcknowledgeIncidentInput!) {
    acknowledgeIncident(input: $input) {
      incident {
        id
        status
      }
    }
  }
`

const RESOLVE_INCIDENT = gql`
  mutation ResolveIncident($input: ResolveIncidentInput!) {
    resolveIncident(input: $input) {
      incident {
        id
        status
      }
    }
  }
`

const SNOOZE_INCIDENT = gql`
  mutation SnoozeIncident($input: SnoozeIncidentInput!) {
    snoozeIncident(input: $input) {
      incident {
        id
        status
      }
    }
  }
`
