import React from 'react'

export default function ArrowDown({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94962 13.554L8.94962 2L10.7117 2L10.7117 13.7002L14.754 9.65785L16 10.9038L9.90372 17.0001L3.80745 10.9038L5.05343 9.65785L8.94962 13.554Z"
        fill={color}
      />
    </svg>
  )
}
