import { AccountItem, CoverageItem } from '../Users'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import * as Icons from '../../components/icons'
import { R5Error, Loader } from '../../components/shared'
import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import InvitationItem from './InvitationItem'
import MembershipItem from '../Users/MembershipItem'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'
import RssModal from '../../components/Profile/RssModal'
import { styles } from '../../constants/styles'
import moment from 'moment-timezone'

export default function Details() {
  const { data, errors, loading, refetch } = useQuery(QUERY)
  const [showRssModal, setShowRssModal] = useState(false)

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <DetailsLoaded
      viewerAccounts={data.viewerAccounts.nodes}
      refetch={refetch}
      setShowRssModal={setShowRssModal}
      showRssModal={showRssModal}
      viewer={data.viewer}
      viewerInvitations={data.viewerInvitations}
    />
  )
}

function DetailsLoaded({
  refetch,
  setShowRssModal,
  showRssModal,
  viewer,
  viewerAccounts,
  viewerInvitations,
}) {
  const classes = useStyles()
  const { accountId, subscribe } = useCurrents()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  const [subscribeAnchorEl, setSubscribeAnchorEl] = useState(null)
  const [subscribeMenuOpen, setSubscribeMenuOpen] = useState(false)

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: USER_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_CREATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_DELETE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  function handleSubscribeMenu(event) {
    setSubscribeMenuOpen(true)
    setSubscribeAnchorEl(event.currentTarget)
  }

  function handleSubscribeMenuClose(_event) {
    setSubscribeMenuOpen(false)
    setSubscribeAnchorEl(null)
  }

  function renderEmpty(text) {
    return (
      <ListItem className={classes.minHeight}>
        <Typography className={classes.cardEmpty} color="textSecondary">
          {text}
        </Typography>
      </ListItem>
    )
  }

  function renderInvitations() {
    const hasInvitations = viewerInvitations.nodes.length > 0
    const invitations = viewerInvitations.nodes.map((invitation, index) => (
      <InvitationItem
        invitation={invitation}
        key={invitation.id}
        onInvitationMutation={refetchMemo}
      />
    ))

    return (
      hasInvitations && (
        <Card className={`${classes.card} mt-xl`} variant="outlined">
          <CardHeader
            title="Invitations"
            titleTypographyProps={{
              className: classes.cardTitle,
              color: 'textSecondary',
            }}
          />
          <Divider />
          <CardContent className={classes.content}>
            <List disablePadding>{invitations}</List>
          </CardContent>
        </Card>
      )
    )
  }

  function renderCoverage(coverage, emptyText) {
    return (
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.content}>
          {coverage.length === 0 ? (
            renderEmpty(emptyText)
          ) : (
            <List disablePadding>
              {coverage.map((item, index) => (
                <CoverageItem coverage={item} key={index} />
              ))}
            </List>
          )}
        </CardContent>
      </Card>
    )
  }

  function renderCurrent() {
    _.forEach(
      viewer.currentCoverage.onCall,
      (i) => (i.coverageType = 'ON_CALL')
    )
    _.forEach(
      viewer.currentCoverage.onDuty,
      (i) => (i.coverageType = 'ON_DUTY')
    )
    const sortedCoverage = _.sortBy(
      _.concat(viewer.currentCoverage.onCall, viewer.currentCoverage.onDuty),
      'startsAt'
    )

    return (
      <>
        <Box className="mt-5">
          <Box className="flex items-center justify-between">
            <Box className="flex items-center">
              <Typography className={classes.header} color="textSecondary">
                Current Coverage
              </Typography>
              {sortedCoverage.length > 0 && (
                <Typography
                  className="ml-xs"
                  sx={{
                    color: styles.label,
                    fontStyle: 'italic',
                    fontSize: 12,
                  }}
                >
                  All times {moment.tz(moment.tz.guess(true)).format('z')}
                </Typography>
              )}
            </Box>
            <>
              <Button
                onClick={handleSubscribeMenu}
                endIcon={
                  subscribeMenuOpen ? (
                    <Icons.DropUpArrow color={styles.primary.color} size={15} />
                  ) : (
                    <Icons.DropDownArrow
                      color={styles.primary.color}
                      size={15}
                    />
                  )
                }
                size="small"
                variant="outlined"
              >
                Subscribe to calendar
              </Button>
              <Menu
                anchorEl={subscribeAnchorEl}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
                className="mt-xs"
                classes={{ paper: classes.menuPaper }}
                keepMounted
                onClose={handleSubscribeMenuClose}
                open={subscribeMenuOpen}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
                TransitionComponent={Fade}
                transitionDuration={0}
              >
                <MenuItem
                  classes={{ root: classes.menuRoot }}
                  onClick={() => {
                    handleSubscribeMenuClose()
                    setShowRssModal('on-call')
                  }}
                  style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  On-call calendar feed
                </MenuItem>
                <MenuItem
                  classes={{ root: classes.menuRoot }}
                  onClick={() => {
                    handleSubscribeMenuClose()
                    setShowRssModal('on-duty')
                  }}
                  style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  On-duty calendar feed
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Box>
        {renderCoverage(
          sortedCoverage,
          "You're not currently on call or on duty."
        )}
      </>
    )
  }

  function renderUpcoming() {
    _.forEach(
      viewer.upcomingCoverage.onCall,
      (i) => (i.coverageType = 'ON_CALL')
    )
    _.forEach(
      viewer.upcomingCoverage.onDuty,
      (i) => (i.coverageType = 'ON_DUTY')
    )
    const sortedCoverage = _.sortBy(
      _.concat(viewer.upcomingCoverage.onCall, viewer.upcomingCoverage.onDuty),
      'startsAt'
    )

    return (
      <>
        <Box className="mt-5">
          <Box className="flex items-center justify-between">
            <Box className="flex items-center">
              <Typography className={classes.header} color="textSecondary">
                Upcoming Coverage
              </Typography>
              {sortedCoverage.length > 0 && (
                <Typography
                  className="ml-xs"
                  sx={{
                    color: styles.label,
                    fontStyle: 'italic',
                    fontSize: 12,
                  }}
                >
                  All times {moment.tz(moment.tz.guess(true)).format('z')}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        {renderCoverage(
          sortedCoverage,
          "You don't have any upcoming on call or on duty coverage."
        )}
      </>
    )
  }

  function renderTeams() {
    const hasTeams = viewer.memberships.nodes.length > 0
    const teams = viewer.memberships.nodes.map((membership) => (
      <MembershipItem key={membership.id} membership={membership} />
    ))

    return (
      <Card className={classes.card} variant="outlined">
        <CardHeader
          title="Teams"
          titleTypographyProps={{
            className: classes.cardTitle,
            color: 'textSecondary',
          }}
        />
        <Divider />
        <CardContent className={classes.content}>
          <List disablePadding>
            {hasTeams ? teams : renderEmpty("You're not on any teams")}
          </List>
        </CardContent>
      </Card>
    )
  }

  function renderAccounts() {
    return (
      <Card className={classes.card} variant="outlined">
        <CardHeader
          title="Accounts"
          titleTypographyProps={{
            className: classes.cardTitle,
            color: 'textSecondary',
          }}
        />
        <Divider />
        <CardContent className={`${classes.content} p-0`}>
          <List disablePadding>
            {viewerAccounts.map((viewerAccount) => (
              <AccountItem
                viewerAccount={viewerAccount}
                key={viewerAccount.accountId}
              />
            ))}
          </List>
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      {renderInvitations()}
      {renderCurrent()}
      {renderUpcoming()}
      {renderTeams()}
      {renderAccounts()}
      {showRssModal !== false && (
        <RssModal
          setShowRssModal={setShowRssModal}
          showRssModal={showRssModal}
          viewer={viewer}
        />
      )}
    </>
  )
}

export const useStyles = makeStyles((theme) => ({
  action: {
    marginRight: 0,
    marginTop: 0,
  },
  card: {
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  cardEmpty: {
    fontSize: 14,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
  header: {
    fontSize: 14,
    fontWeight: 900,
    letterSpacing: 1,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textTransform: 'uppercase',
  },
  minHeight: {
    height: 48,
  },
  shifts: {
    fontWeight: '600',
    marginTop: theme.spacing(1),
  },
  menuPaper: {
    backgroundColor: styles.primary.color,
    borderRadius: 16,
    color: styles.container.backgroundColor,
    width: 198,
  },
  menuRoot: {
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 'bold',
    padding: 20,
    paddingBottom: 10,
    paddingTop: 10,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  menuText: {
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 'bold',
  },
}))

const USER_UPDATE_SUBSCRIPTION = gql`
  subscription onUserUpdated($accountId: ID!) {
    userUpdated(accountId: $accountId) {
      id
      name
      initials
      accountId
      currentCoverage {
        onCall {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
        onDuty {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
      }
      upcomingCoverage {
        onCall {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
        onDuty {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
      }
    }
  }
`

const MEMBERSHIP_CREATE_SUBSCRIPTION = gql`
  subscription onMembershipCreated($accountId: ID!) {
    membershipCreated(accountId: $accountId) {
      id
    }
  }
`

const MEMBERSHIP_UPDATE_SUBSCRIPTION = gql`
  subscription onMembershipUpdated($accountId: ID!) {
    membershipUpdated(accountId: $accountId) {
      id
      accountId
      userId
      teamId
      role
      createdAt
    }
  }
`

const MEMBERSHIP_DELETE_SUBSCRIPTION = gql`
  subscription onMembershipDeleted($accountId: ID!) {
    membershipDeleted(accountId: $accountId) {
      id
    }
  }
`

const QUERY = gql`
  query Details {
    viewerAccounts(first: 30) {
      nodes {
        accountId
        accountName
        role
        userId
        userName
      }
    }
    viewerInvitations(first: 30) {
      nodes {
        id
        accountId
        accountName
        invitedBy {
          id
          name
          initials
        }
      }
    }
    viewer {
      id
      name
      initials
      accountId
      calendars {
        onCall {
          url
        }
        onDuty {
          url
        }
      }
      currentCoverage {
        onCall {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
        onDuty {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
      }
      upcomingCoverage {
        onCall {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
        onDuty {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
      }
      memberships(first: 30) {
        nodes {
          id
          role
          teamId
          viewerCanAdminister
          team {
            id
            name
            accentColor
          }
          user {
            id
            name
            role
          }
        }
      }
    }
  }
`
