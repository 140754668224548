import * as Icons from '../../components/icons'

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { R5Error, Loader, R5Container, R5Title } from '../../components/shared'
import { MobileScreenShare, PhonelinkSetup } from '@mui/icons-material'
import React, { useCallback, useEffect } from 'react'
import {
  applePushNotificationService,
  configStorageKey,
} from '../../constants/app'
import { useBack, useMutation, useQuery } from '../../hooks'
import AppStoreBadge from '../../components/illustrations/AppStoreBadge.svg'
import GooglePlayBadge from '../../components/illustrations/GooglePlayBadge.png'
import QRCode from 'qrcode.react'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import { enterprise } from '../../utils'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useJunkDrawer } from '../../context/junkDrawer'

export default function InstallAppPage() {
  const classes = useStyles()
  const { junkDrawer, updateJunkDrawer } = useJunkDrawer()
  const { userId, user, accountId, subscribe } = useCurrents()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const testMutation = useMutation(TEST_CONTACT_METHOD)
  const { data, errors, loading, refetch, refetching } =
    useQuery(CONTACT_METHOD_QUERY)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])
  const config = JSON.parse(localStorage.getItem(configStorageKey))

  useBack(user.role === 'OWNER' ? '/welcome' : null)

  useEffect(() => {
    if (_.isUndefined(junkDrawer.welcome)) updateJunkDrawer('welcome', {})
  }, [junkDrawer, updateJunkDrawer])

  useEffect(() => {
    if (loading || refetching) return

    updateJunkDrawer('welcome', {
      ...junkDrawer.welcome,
      pushContactMethod: _.find(data.viewer.contactMethods.nodes, {
        type: 'PUSH',
      }),
    })
  }, [
    data,
    junkDrawer.welcome,
    loading,
    refetchMemo,
    refetching,
    updateJunkDrawer,
  ])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: CONTACT_METHOD_CREATE_SUBSCRIPTION,
      variables: {
        accountId,
        userId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, userId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: CONTACT_METHOD_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        userId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, userId, refetchMemo, subscribe])

  function handleTest() {
    try {
      testMutation({
        contactMethodId: junkDrawer.welcome.pushContactMethod.id,
        userId: junkDrawer.welcome.pushContactMethod.userId,
      })
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  if (_.isUndefined(junkDrawer.welcome)) return <Loader />
  if (errors) return <R5Error errors={errors} />

  const shortName = user.name.split(' ').slice(0, -1).join(' ')

  return (
    <R5Container maxWidth={mobileView ? 'xs' : 'sm'}>
      <R5Title title="Welcome" />
      <Box className="flex flex-col items-center justify-center text-center mt-1/10">
        <Typography className={classes.title}>
          {user.role === 'OWNER' ? 'Here we go!' : `Hi there, ${shortName}!`}
        </Typography>
        <Typography className={classes.subTitle} color="textSecondary">
          {user.role !== 'OWNER' && "We're so glad you're here. "}The most
          important part of setting up your account is configuring your alerts,
          so let's do that now.
        </Typography>

        <List>
          <ListItem className={`mt-xl ${classes.listItem}`}>
            <ListItemAvatar>
              <Avatar
                style={{
                  backgroundColor:
                    junkDrawer.welcome.getIt ||
                    junkDrawer.welcome.pushContactMethod
                      ? styles.success.color
                      : null,
                }}
              >
                {junkDrawer.welcome.getIt ||
                junkDrawer.welcome.pushContactMethod ? (
                  <Icons.Check color="#f6f7fb" size={20} />
                ) : (
                  <MobileScreenShare />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="First, install the Ready Five mobile app on your phone."
              secondary={
                <Box className="flex flex-row">
                  <Link
                    className="mr-sm"
                    href="https://www.readyfive.io/mobile"
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="hover"
                  >
                    <QRCode size={75} value="https://www.readyfive.io/mobile" />
                  </Link>
                  <Link
                    className="mr-sm"
                    href="https://apps.apple.com/us/app/id1450092852"
                    onClick={() =>
                      updateJunkDrawer('welcome', {
                        ...junkDrawer.welcome,
                        getIt: true,
                      })
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="hover"
                  >
                    <img alt="Download on the App Store" src={AppStoreBadge} />
                  </Link>
                  <Link
                    href="https://play.google.com/store/apps/details?id=io.readyfive.readyfive"
                    onClick={() =>
                      updateJunkDrawer('welcome', {
                        ...junkDrawer.welcome,
                        getIt: true,
                      })
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="hover"
                  >
                    <img
                      alt="Get it on Google Play"
                      src={GooglePlayBadge}
                      width="133"
                    />
                  </Link>
                </Box>
              }
              secondaryTypographyProps={{
                component: 'div',
                className: 'mt-sm',
              }}
            />
          </ListItem>
          <ListItem className={`mt-xl ${classes.listItem}`}>
            <ListItemAvatar>
              <Avatar
                style={{
                  backgroundColor: junkDrawer.welcome.pushContactMethod
                    ? styles.success.color
                    : null,
                }}
              >
                {junkDrawer.welcome.pushContactMethod ? (
                  <Icons.Check color="#f6f7fb" size={20} />
                ) : (
                  <PhonelinkSetup />
                )}
              </Avatar>
            </ListItemAvatar>
            <Box className="flex flex-col">
              <ListItemText
                primary={
                  enterprise()
                    ? 'Open the app, enter your installation ID, and login.'
                    : 'Open the app and login.'
                }
              />
              {enterprise() && (
                <Typography color="textSecondary" variant="body2">
                  Your installation ID is{' '}
                  <strong>{config.publicIdentifier}</strong>.
                </Typography>
              )}
              <Typography color="textSecondary" variant="body2">
                You'll be prompted to allow push notifications (and critical
                alerts on iOS). Don't worry, we won't send you anything
                unexpected.
              </Typography>
            </Box>
          </ListItem>
          <ListItem className={`mt-xl ${classes.listItem}`}>
            <ListItemAvatar>
              <Avatar
                style={{
                  backgroundColor: junkDrawer.welcome.pushContactMethod
                    ? styles.success.color
                    : null,
                }}
              >
                {junkDrawer.welcome.pushContactMethod ? (
                  <Icons.Check color="#f6f7fb" size={20} />
                ) : (
                  <CircularProgress color="inherit" size={20} />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                junkDrawer.welcome.pushContactMethod
                  ? `Perfect. Now let’s configure your ${
                      junkDrawer.welcome.pushContactMethod.pushProvider ===
                      applePushNotificationService
                        ? junkDrawer.welcome.pushContactMethod.description
                        : junkDrawer.welcome.pushContactMethod.to
                    } alerts.`
                  : 'Waiting for you to allow push notifications from the app...'
              }
            />
          </ListItem>
        </List>
        <Box className="mt-xl items-center">
          <Grid className="flex justify-center" container spacing={1}>
            {user.role === 'OWNER' && (
              <Grid item>
                <Button
                  component={RouterLink}
                  size="small"
                  to="/welcome"
                  variant="outlined"
                >
                  Back: Account setup
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                component={RouterLink}
                disabled={!junkDrawer.welcome.pushContactMethod}
                onClick={handleTest}
                size="small"
                to="/welcome/notifications"
                variant="outlined"
              >
                Next: Send test notification and configure timing
              </Button>
            </Grid>
          </Grid>
          {junkDrawer.welcome.pushContactMethod &&
            junkDrawer.welcome.pushContactMethod.pushProvider ===
              applePushNotificationService && (
              <Box className="mt-lg ml-xl mr-xl text-left">
                <Typography color="textSecondary" variant="body2">
                  We recommend disabling the{' '}
                  <Typography
                    className="font-bold"
                    component="span"
                    variant="body2"
                  >
                    Offload Unused Apps
                  </Typography>{' '}
                  feature on your Apple device so the app isn't deleted after a
                  period of inactivity.{' '}
                  <Link
                    href="https://www.readyfive.io/docs/users/notification-settings#offload-unused-apps-ios"
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="hover"
                  >
                    Learn More
                  </Link>
                </Typography>
              </Box>
            )}
        </Box>
      </Box>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    backgroundImage: `url(${GooglePlayBadge})`,
  },
  listItem: {
    '&::before': {
      content: 'no-open-quote',
      position: 'absolute',
      left: '2.2rem',
      backgroundColor: styles.border.color,
      height: '200%',
      width: 1,
      zIndex: -1,
    },
    '&:first-child:before': {
      top: theme.spacing(8),
    },
    '&:last-child:before': {
      height: 0,
    },
  },
  maxWidth: {
    // width: 100,
  },
  subTitle: {
    fontSize: 24,
    fontWeight: 600,
  },
  title: {
    fontSize: 40,
    fontWeight: 900,
  },
}))

const CONTACT_METHOD_QUERY = gql`
  query WelcomeStepOne {
    viewer {
      id
      contactMethods(first: 30) {
        nodes {
          description
          pushProvider
          id
          to
          type
          userId
        }
      }
    }
  }
`

const TEST_CONTACT_METHOD = gql`
  mutation TestContactMethod($input: TestContactMethodInput!) {
    testContactMethod(input: $input) {
      contactMethod {
        id
      }
    }
  }
`

const CONTACT_METHOD_CREATE_SUBSCRIPTION = gql`
  subscription onContactMethodCreated($accountId: ID!, $userId: ID!) {
    contactMethodCreated(accountId: $accountId, userId: $userId) {
      id
    }
  }
`

const CONTACT_METHOD_DELETE_SUBSCRIPTION = gql`
  subscription onContactMethodDeleted($accountId: ID!, $userId: ID!) {
    contactMethodDeleted(accountId: $accountId, userId: $userId) {
      id
    }
  }
`
