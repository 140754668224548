import { useEffect, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

import { GraphQLAPI } from '@aws-amplify/api-graphql'
import { Helmet } from 'react-helmet'
import { globalHistory } from '@reach/router'
import gql from 'graphql-tag'
import { useCurrents } from '../context/currents'
import useMounted from '../hooks/useMounted'

export default function IntercomProvider({ children }) {
  const mounted = useMounted()
  const theme = useTheme()
  const [intercomBooted, setIntercomBooted] = useState(false)
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const { userId } = useCurrents()

  useEffect(() => {
    if (!userId || !mounted) return
    let tries = 0
    const interval = setInterval(async () => {
      if (window.Intercom) {
        clearInterval(interval)
      } else {
        tries < 20 ? tries++ : clearInterval(interval)
        return
      }

      const variables = { platform: 'WEB' }
      const response = await GraphQLAPI.graphql({
        query: INTERCOM_QUERY,
        variables,
      })
      const i = response.data._viewerIntercomUserData

      window.Intercom('boot', {
        api_base: i.apiBase,
        app_id: i.appId,
        company: {
          company_id: i.company.companyId,
          name: i.company.name,
        },
        created_at: i.createdAt,
        custom_launcher_selector: '#create_support_ticket',
        email: i.email,
        hide_default_launcher: mobileView,
        name: i.name,
        user_hash: i.userHash,
        user_id: i.userId,
      })

      setIntercomBooted(true)
    }, 1000)

    return () => window.Intercom && window.Intercom('shutdown')
  }, [userId, mobileView, mounted])

  useEffect(() => {
    if (!intercomBooted) return

    const historyUnsubscribe = globalHistory.listen(({ action }) => {
      if (action === 'PUSH')
        window.Intercom('update', {
          last_request_at: parseInt(new Date().getTime() / 1000),
        })
    })

    return historyUnsubscribe
  }, [intercomBooted])

  return (
    <>
      <Helmet>
        <script>
          {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
        </script>
      </Helmet>

      {children}
    </>
  )
}

const INTERCOM_QUERY = gql`
  query IntercomUserData($platform: PrivateIntercomUserDataPlatform!) {
    _viewerIntercomUserData(platform: $platform) {
      apiBase
      appId
      userId
      name
      email
      createdAt
      userHash
      company {
        companyId
        name
      }
    }
  }
`
