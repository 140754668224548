import * as Icons from '../../components/icons'

import {
  ActivityIndicator,
  AlertDialog,
  R5Error,
  Loader,
  R5ButtonGroup,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { currentDomain, enterprise } from '../../utils'
import { useBack, useMutation, useQuery } from '../../hooks'
import { Elements } from '@stripe/react-stripe-js'
import { GraphQLAPI } from '@aws-amplify/api-graphql'
import { Launch } from '@mui/icons-material'
import { StripeForm } from '../../components/Account'
import gql from 'graphql-tag'
import { loadStripe } from '@stripe/stripe-js/pure'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function BillingPage() {
  useBack(null)
  const { data, errors, loading, refetch } = useQuery(QUERY)

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <BillingCards
      card={data.billingPaymentCard}
      refetch={refetch}
      subscription={data.billingSubscriptions.nodes[0]}
    />
  )
}

function BillingCards({ card, refetch, subscription }) {
  const { accountId, account, user, load, subscribe } = useCurrents()

  const classes = useStyles()
  const theme = useTheme()

  const createBillingSubscriptionMutation = useMutation(
    CREATE_BILLING_SUBSCRIPTION
  )
  const updateBillingSubscriptionMutation = useMutation(
    UPDATE_BILLING_SUBSCRIPTION
  )
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const [recurringInterval, setRecurringInterval] = useState(
    subscription ? subscription.recurringInterval : 'MONTH'
  )
  const [editingCard, setEditingCard] = useState(false)
  const [cardUpdating, setCardUpdating] = useState(false)
  const [editingSubscription, setEditingSubscription] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showSubscribeDialog, setShowSubscribeDialog] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])
  const [stripePromise, setStripePromise] = useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: BILLING_SUBSCRIPTION_CREATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => {
        refetchMemo()
        load()
      },
    })
    return unsubscribe
  }, [accountId, refetchMemo, load, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: BILLING_SUBSCRIPTION_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => {
        refetchMemo()
        load()
      },
    })
    return unsubscribe
  }, [accountId, refetchMemo, load, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: BILLING_SUBSCRIPTION_DELETE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => {
        refetchMemo()
        load()
      },
    })
    return unsubscribe
  }, [accountId, refetchMemo, load, subscribe])

  useEffect(() => {
    async function loadStripePromise() {
      const sp = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
      setStripePromise(sp)
    }
    loadStripePromise()
  }, [])

  useEffect(() => {
    if (cardUpdating) {
      const interval = setInterval(refetchMemo, 1000)
      return () => clearInterval(interval)
    }
  }, [cardUpdating, refetchMemo])

  useEffect(() => setCardUpdating(false), [card])

  async function handleBillingSubscriptionSubmit() {
    try {
      const billingSubscriptionMutation = subscription
        ? updateBillingSubscriptionMutation
        : createBillingSubscriptionMutation

      await billingSubscriptionMutation({
        productName: 'Team',
        recurringInterval,
        cancelsAtPeriodEnd: subscription ? false : undefined,
      })

      setEditingSubscription(false)
    } catch (error) {
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleBillingSubscriptionCancel() {
    try {
      await updateBillingSubscriptionMutation({
        productName: 'Team',
        cancelsAtPeriodEnd: true,
      })

      setEditingSubscription(false)
    } catch (error) {
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleGetStripeCustomerPortalSession() {
    const variables = { returnUrl: `${currentDomain()}/account/billing` }
    const response = await GraphQLAPI.graphql({
      query: STRIPE_PORTAL_QUERY,
      variables,
    })
    const { url } = response.data._stripeCustomerPortalSession

    window.location = url
  }

  function renderAlert() {
    switch (subscription?.status) {
      case 'ACTIVE':
        if (subscription.cancelsAtPeriodEnd) {
          return (
            <Alert
              className={classes.alert}
              color="info"
              icon={<Icons.ExclamationCircle size={30} color="#fff" />}
              variant="filled"
            >
              Your account will be canceled at the end of your current billing
              cycle on{' '}
              {moment
                .unix(subscription.currentPeriodEndsAt)
                .format('MMMM D, YYYY')}
              .
            </Alert>
          )
        }
        break
      case 'TRIALING':
        return (
          <Alert
            className={classes.alert}
            color="info"
            icon={<Icons.ExclamationCircle size={30} color="#fff" />}
            variant="filled"
          >
            At the end of your free trial, you will{' '}
            {subscription.cancelsAtPeriodEnd ? 'not' : ''} be charged.
          </Alert>
        )
      case 'PAST_DUE':
        return (
          <Alert
            className={classes.alertCanceled}
            color="info"
            icon={<Icons.ExclamationCircle size={30} color="#fff" />}
            variant="filled"
          >
            <AlertTitle>Account Past Due</AlertTitle>
            <strong>Your subscription is past due</strong>. We were unable to
            charge the card on file. Please update it below or contact your bank
            to authorize the charge to avoid cancellation of your subscription.
          </Alert>
        )
      case undefined:
        return (
          <Alert
            className={classes.alertCanceled}
            color="info"
            icon={<Icons.ExclamationCircle size={30} color="#fff" />}
            variant="filled"
          >
            <AlertTitle>Account Canceled</AlertTitle>
            Your account subscription is <strong>inactive</strong> and your
            trial period has ended. We'll keep your data for 90 days and you can
            resubscribe at any time and be instantly back up and running.
          </Alert>
        )
      default:
        return null
    }
  }

  function renderSubscriptionSecondary() {
    return (
      <>
        {subscription && !subscription.cancelsAtPeriodEnd && (
          <Button
            className={`mt-sm ${classes.cancelSubscriptionButton}`}
            onClick={() => setShowCancelDialog(true)}
          >
            Cancel Subscription
          </Button>
        )}
        {subscription?.status === 'TRIALING' && (
          <Typography className={classes.subText}>
            Trial Period: {moment.unix(subscription.trialEndsAt).fromNow(true)}{' '}
            left
          </Typography>
        )}
      </>
    )
  }

  function renderCardSecondary() {
    if (card) {
      if (subscription && !subscription.cancelsAtPeriodEnd) {
        return (
          <Typography className={classes.subtitle}>
            Future charges will be billed to the card above.
          </Typography>
        )
      } else {
        return (
          <Typography className={classes.subtitle}>
            Your card is on file, but will not be billed unless you subscribe.
          </Typography>
        )
      }
    } else {
      return (
        <Typography className={classes.subtitle}>
          There's no credit card on file for this account.
        </Typography>
      )
    }
  }

  function renderEditSubscription() {
    return (
      editingSubscription && (
        <form
          className="flex flex-1 flex-col mt-lg"
          onSubmit={(event) => {
            event.preventDefault() // Block native form submission
            setShowSubscribeDialog(true)
          }}
        >
          <R5ButtonGroup
            buttons={[
              { label: 'Monthly', value: 'MONTH' },
              { label: 'Yearly', value: 'YEAR' },
            ]}
            onChange={setRecurringInterval}
            selectedValue={recurringInterval}
          />
          <Button
            classes={{ root: classes.changeSubscriptionButton }}
            className="mt-lg"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
          <Button
            className={`mt-sm ${classes.cancelSubscriptionButton}`}
            onClick={() => {
              setRecurringInterval(
                subscription ? subscription.recurringInterval : 'MONTH'
              )
              setEditingSubscription(false)
            }}
          >
            Never mind
          </Button>
        </form>
      )
    )
  }

  function renderSubscribeText() {
    return subscription && !subscription.cancelsAtPeriodEnd
      ? 'Change Subscription'
      : subscription?.status === 'TRIALING'
      ? 'Subscribe'
      : 'Resubscribe'
  }

  function subscriptionContent() {
    return subscription &&
      !subscription.cancelsAtPeriodEnd &&
      subscription?.status !== 'TRIALING'
      ? 'Are you sure you want to change your subscription? You will be charged immediately.'
      : subscription?.status === 'TRIALING'
      ? 'Are you sure you want to subscribe? You will be charged at the end of your trial.'
      : 'Are you sure you want to resubscribe? You will be charged immediately.'
  }

  function renderDialogs() {
    return (
      <>
        <AlertDialog
          cancelText="Never mind"
          confirmText="Yes, cancel my subscription"
          content={`Are you sure you want to cancel your subscription? You will have access until your ${
            subscription?.status === 'TRIALING' ? 'trial' : 'billing cycle'
          } ends.`}
          handleClose={() => setShowCancelDialog(false)}
          handleConfirm={() => handleBillingSubscriptionCancel()}
          open={showCancelDialog}
          title="Cancel Subscription"
        />
        <AlertDialog
          content={subscriptionContent()}
          handleClose={() => setShowSubscribeDialog(false)}
          handleConfirm={() => handleBillingSubscriptionSubmit()}
          open={showSubscribeDialog}
          title={renderSubscribeText()}
        />
      </>
    )
  }

  function renderLeftCard() {
    const [price, per, interval] = subscription
      ? subscription.cost.split(' ')
      : []

    return (
      <Card
        className={mobileView ? classes.leftCardMobile : classes.leftCard}
        variant="outlined"
      >
        <CardContent
          className={`${classes.content} ${mobileView ? '' : 'mt-16'}`}
        >
          <Box className="flex flex-1 flex-col justify-center items-center text-center">
            {editingSubscription ? (
              <>
                <Typography className={classes.cost}>
                  {recurringInterval === 'MONTH' ? '$18.00' : '$180.00'}
                </Typography>
                <Typography className={classes.costSubtitle}>
                  per user, per {recurringInterval.toLowerCase()}
                </Typography>
                {recurringInterval === 'YEAR' && (
                  <Typography className={classes.costSavings}>
                    17% savings!
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography className={classes.cost}>
                  {subscription ? price : '$18.00'}
                </Typography>
                <Typography className={classes.costSubtitle}>
                  {subscription
                    ? `${per} ${interval}`
                    : `per user, per ${recurringInterval.toLowerCase()}`}
                </Typography>
                {recurringInterval === 'YEAR' && (
                  <Typography className={classes.costSavings}>
                    17% savings!
                  </Typography>
                )}
              </>
            )}
            {!editingSubscription && (
              <Box className="flex flex-1 flex-col">
                <Button
                  classes={{
                    disabled: classes.changeSubscriptionButtonDisabled,
                    root: classes.changeSubscriptionButton,
                  }}
                  className="mt-lg"
                  disabled={!card}
                  onClick={() => setEditingSubscription(true)}
                  variant="contained"
                >
                  {renderSubscribeText()}
                </Button>
                {renderSubscriptionSecondary()}
              </Box>
            )}
            {validationErrors && (
              <FormHelperText error>{validationErrors}</FormHelperText>
            )}
            {renderEditSubscription()}
            {renderDialogs()}
          </Box>
        </CardContent>
      </Card>
    )
  }

  function renderRightCard() {
    return (
      <Card
        className={mobileView ? classes.cardMobile : classes.card}
        variant="outlined"
      >
        {subscription && (
          <>
            <CardContent className={classes.content}>
              <List dense disablePadding>
                <ListItem disableGutters>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.secondary,
                    }}
                    primary="Package Type"
                    secondary={subscription?.productName || 'Team'}
                  />
                </ListItem>
              </List>
            </CardContent>
            <Divider />
          </>
        )}
        <CardContent className={classes.content}>
          <List dense disablePadding>
            <ListItem disableGutters>
              <ListItemText
                classes={{
                  primary: classes.primary,
                  secondary: classes.secondary,
                }}
                primary="Active Users"
                secondary={subscription?.quantity || account.activeUsersCount}
              />
            </ListItem>
          </List>
        </CardContent>
        <Divider />
        <CardContent className={classes.content}>
          <List dense disablePadding>
            <ListItem disableGutters>
              <ListItemText
                classes={{
                  primary: editingCard
                    ? classes.primaryEditing
                    : classes.primary,
                  secondary: classes.secondary,
                }}
                primary="Card Details"
                secondary={
                  cardUpdating ? (
                    <ActivityIndicator size={15} />
                  ) : editingCard ? null : card ? (
                    `•••• •••• •••• ${card.last4}`
                  ) : (
                    'n/a'
                  )
                }
                secondaryTypographyProps={{ component: 'span' }}
              />
            </ListItem>
            {!editingCard && renderCardSecondary()}
            {editingCard && stripePromise && (
              <Elements stripe={stripePromise}>
                <StripeForm
                  card={card}
                  setCardUpdating={setCardUpdating}
                  setEditingCard={setEditingCard}
                  setShowSubscribeDialog={setShowSubscribeDialog}
                  subscription={subscription}
                />
              </Elements>
            )}
          </List>
        </CardContent>
        {account.viewerCanManageBilling && !editingCard && (
          <CardActions className={classes.cardActions}>
            {!enterprise() && (
              <Button
                className="ml-auto"
                endIcon={<Launch />}
                onClick={handleGetStripeCustomerPortalSession}
                size="small"
                type="submit"
              >
                Invoices
              </Button>
            )}

            <Button
              className={enterprise() ? 'ml-auto' : undefined}
              onClick={() => setEditingCard(true)}
              size="small"
              variant="outlined"
            >
              {card
                ? 'Edit Card'
                : subscription?.status === 'TRIALING'
                ? 'Add Card & Subscribe'
                : 'Add Card'}
            </Button>
          </CardActions>
        )}
      </Card>
    )
  }

  return (
    <R5Container>
      <R5Title title={account.name} />
      <R5Header
        editUrl={user.account.viewerCanAdminister ? '/account/edit' : null}
        title={account.name}
      />
      {renderAlert()}
      <Grid className={classes.marginTop} container spacing={1}>
        <Grid item xs={12} sm={5}>
          {renderLeftCard()}
        </Grid>
        <Grid item xs={12} sm={7}>
          {renderRightCard()}
        </Grid>
      </Grid>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  alert: {
    alignItems: 'center',
    borderRadius: theme.spacing(1),
  },
  alertCanceled: {
    borderRadius: theme.spacing(1),
  },
  cancelSubscriptionButton: {
    color: '#ffffff99',
    fontSize: 14,
    fontWeight: 600,
  },
  card: {
    borderRadius: theme.spacing(1),
    minHeight: 378,
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
  },
  cardMobile: {
    borderRadius: theme.spacing(1),
  },
  changeSubscriptionButton: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: styles.primary.color,
    fontSize: 12,
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  changeSubscriptionButtonDisabled: {
    backgroundColor: '#ffffff60 !important',
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  cost: {
    fontSize: 40,
    fontWeight: 900,
    color: theme.palette.common.white,
  },
  costSavings: {
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 900,
    color: theme.palette.common.white,
  },
  costSubtitle: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  leftCard: {
    backgroundColor: styles.primary.color,
    borderRadius: theme.spacing(1),
    minHeight: 378,
  },
  leftCardMobile: {
    backgroundColor: styles.primary.color,
    borderRadius: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(3.5),
  },
  primary: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  primaryEditing: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 900,
    paddingTop: theme.spacing(0.25),
    textTransform: 'uppercase',
  },
  secondary: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 800,
    marginTop: theme.spacing(1),
  },
  subtitle: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 600,
  },
  subText: {
    color: '#ffffff99',
    fontSize: 14,
    fontWeight: 600,
    paddingTop: theme.spacing(2),
  },
}))

const QUERY = gql`
  query BillingPage {
    billingPaymentCard {
      brand
      country
      expiresAtMonth
      expiresAtYear
      last4
    }
    billingSubscriptions(first: 30) {
      nodes {
        id
        quantity
        cancelsAtPeriodEnd
        cancelsAt
        cost
        currentPeriodStartsAt
        currentPeriodEndsAt
        productName
        recurringInterval
        recurringIntervalCount
        startsAt
        status
        trialStartsAt
        trialEndsAt
        unitAmount
      }
    }
  }
`

const STRIPE_PORTAL_QUERY = gql`
  query StripeCustomerPortalSession($returnUrl: String!) {
    _stripeCustomerPortalSession(returnUrl: $returnUrl) {
      url
    }
  }
`

const CREATE_BILLING_SUBSCRIPTION = gql`
  mutation CreateBillingSubscription($input: CreateBillingSubscriptionInput!) {
    createBillingSubscription(input: $input) {
      billingSubscription {
        id
      }
    }
  }
`

const UPDATE_BILLING_SUBSCRIPTION = gql`
  mutation UpdateBillingSubscription($input: UpdateBillingSubscriptionInput!) {
    updateBillingSubscription(input: $input) {
      billingSubscription {
        id
      }
    }
  }
`

const BILLING_SUBSCRIPTION_CREATE_SUBSCRIPTION = gql`
  subscription onBillingSubscriptionCreated($accountId: ID!) {
    billingSubscriptionCreated(accountId: $accountId) {
      id
    }
  }
`

const BILLING_SUBSCRIPTION_UPDATE_SUBSCRIPTION = gql`
  subscription onBillingSubscriptionUpdated($accountId: ID!) {
    billingSubscriptionUpdated(accountId: $accountId) {
      id
      quantity
      cancelsAtPeriodEnd
      cancelsAt
      cost
      currentPeriodStartsAt
      currentPeriodEndsAt
      productName
      recurringInterval
      recurringIntervalCount
      startsAt
      status
      trialStartsAt
      trialEndsAt
      unitAmount
    }
  }
`

const BILLING_SUBSCRIPTION_DELETE_SUBSCRIPTION = gql`
  subscription onBillingSubscriptionDeleted($accountId: ID!) {
    billingSubscriptionDeleted(accountId: $accountId) {
      id
    }
  }
`
