import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { Loader, R5AppBarLoader, R5DrawerLoader } from './'
import React from 'react'

import R5Theme from '../../constants/R5Theme'
import makeStyles from '@mui/styles/makeStyles'

export default function SplashScreen() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={R5Theme}>
        <SplashBody />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

function SplashBody() {
  const classes = useStyles()
  return (
    <>
      <CssBaseline />
      <R5AppBarLoader />
      <nav className={classes.drawer}>
        <R5DrawerLoader />
      </nav>
      <main className={classes.content}>
        <Loader />
      </main>
    </>
  )
}

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
}))
