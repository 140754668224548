import * as Icons from '../icons'

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import RouteToSelect from './RouteToSelect'
import TeamOptions from '../../constants/TeamOptions'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function DefaultRouting({ integration }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [assignTo, setAssignTo] = useState(integration.assignTo)
  const [isEditing, setIsEditing] = useState(false)
  const updateIntegration = useMutation(UPDATE_INTEGRATION)
  const [validationErrors, setValidationErrors] = useState({})

  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)
    try {
      await updateIntegration({
        assignToId: assignTo.id,
        integrationId: integration.id,
        teamId: integration.teamId,
      })
      setLoading(false)
      setIsEditing(false)
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  return (
    <Card
      className={isEditing ? classes.editingCard : classes.card}
      variant="outlined"
    >
      <CardContent className={classes.content}>
        <form onSubmit={handleSubmit}>
          <Box className="flex flex-row justify-between items-center">
            <Box className="flex flex-row items-center">
              {!isEditing && (
                <>
                  <Typography className={classes.separator}>
                    Route all alerts to
                  </Typography>
                  <Chip
                    className="ml-sm"
                    color="primary"
                    label={integration.assignTo.name}
                  />
                </>
              )}
            </Box>
            <Box>
              {isEditing ? (
                <>
                  <Button
                    className={classes.button}
                    disabled={loading}
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.button}
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </Button>
                </>
              ) : (
                integration.viewerCanAdminister && (
                  <IconButton
                    onClick={() => {
                      setIsEditing(true)
                      if (
                        !TeamOptions.transformableProviders.includes(
                          integration.provider
                        )
                      )
                        setTimeout(() =>
                          window.scrollTo(0, document.body.scrollHeight)
                        )
                    }}
                    size="small"
                  >
                    <Icons.EditBox color={styles.primary.color} size={22} />
                  </IconButton>
                )
              )}
            </Box>
          </Box>
          {isEditing && (
            <RouteToSelect
              assignTo={assignTo}
              className="mt-sm"
              multiple={false}
              setAssignTo={setAssignTo}
              team={integration.team}
              validationErrors={validationErrors}
            />
          )}
        </form>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(0.5),
  },
  button: {
    color: theme.palette.common.white,
  },
  card: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  editingCard: {
    backgroundColor: styles.r5Purple.color,
    borderRadius: 16,
    marginTop: theme.spacing(1),
  },
  separator: {
    color: styles.darkLabel.color,
    fontSize: 15,
    fontWeight: 'bold',
  },
  label: {
    fontSize: 16,
    fontWeight: 900,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.4),
    textTransform: 'uppercase',
  },
}))

const UPDATE_INTEGRATION = gql`
  mutation UpdateDefaultEventRule($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      integration {
        id
        name
      }
    }
  }
`
