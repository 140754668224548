import * as Icons from '../../components/icons'

import { ActivityIndicator, R5Error, R5Search } from '../shared'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import React, { useEffect, useMemo, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useQuery } from '../../hooks'

export default function ParticipantsSelect({
  participants,
  setParticipants,
  teamId,
  validationErrors,
}) {
  const theme = useTheme()
  const classes = useStyles()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const [isDragging, setIsDragging] = useState(false)
  const [availableParticipants, setAvailableParticipants] = useState([])

  function removeParticipant(index) {
    const newParticipants = [...participants]
    newParticipants.splice(index, 1)

    setParticipants(newParticipants)
  }

  function renderEmpty() {
    return <div>No Participants Selected</div>
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function clone(source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    return destClone
  }

  function onBeforeCapture() {
    setIsDragging(true)
  }

  function onDragEnd({ source, destination }) {
    setIsDragging(false)

    // dropped outside the list
    if (!destination) return
    if (destination.droppableId === 'availableParticipants') return

    let newParticipants = []

    if (source.droppableId === destination.droppableId) {
      newParticipants = reorder(participants, source.index, destination.index)
    } else {
      newParticipants = clone(
        availableParticipants,
        participants,
        source,
        destination
      )
    }
    setParticipants(newParticipants)
  }

  function renderAdded() {
    return (
      <>
        <Typography className={classes.addedLabel} color="textSecondary">
          Added
        </Typography>
        <Droppable droppableId="selectedParticipants">
          {(listProvided, listSnapShot) => (
            <List
              {...listProvided.droppableProps}
              dense
              className={classes.addedList}
              ref={listProvided.innerRef}
              style={{
                background: listSnapShot.isDraggingOver ? 'whitesmoke' : '',
                borderRadius: 8,
              }}
            >
              {participants.length === 0 && renderEmpty()}
              {participants.map((participant, index) => (
                <Draggable
                  key={index}
                  draggableId={`${participant.id}-${index}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      button
                      className={classes.li}
                      divider={index === participants.length - 1 ? false : true}
                      key={index}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDragging
                          ? 'rgba(82, 89, 255, 0.5)'
                          : '',
                        userSelect: 'none',
                        ...provided.draggableProps.style,
                      }}
                    >
                      <ListItemAvatar className={classes.avatarRoot}>
                        <Avatar className={classes.avatarNumber}>
                          {index + 1}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText>{participant.label}</ListItemText>
                      {!isDragging && (
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => removeParticipant(index)}
                            size="small"
                          >
                            <Icons.MinusCircleOutline
                              color={styles.error.color}
                              size={25}
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {listProvided.placeholder}
            </List>
          )}
        </Droppable>
      </>
    )
  }

  return (
    <FormControl className={classes.margin}>
      <InputLabel
        className={classes.label}
        error={_.some(validationErrors['participantIds'])}
        htmlFor="participants"
        required
      >
        Participants
      </InputLabel>
      <Card
        className={
          validationErrors['participantIds'] ? classes.errorCard : classes.card
        }
        variant="outlined"
      >
        <CardContent className={classes.content}>
          <DragDropContext
            onBeforeCapture={onBeforeCapture}
            onDragEnd={onDragEnd}
          >
            <Grid container spacing={3}>
              <Grid
                className={mobileView ? 'border-b' : 'border-r'}
                item
                xs={12}
                sm={6}
              >
                {renderAdded()}
              </Grid>
              <Grid item xs={12} sm={6}>
                <AddParticipants
                  setAvailableParticipants={setAvailableParticipants}
                  teamId={teamId}
                />
              </Grid>
            </Grid>
          </DragDropContext>
        </CardContent>
      </Card>
      {validationErrors['participantIds'] && (
        <FormHelperText error>
          {validationErrors['participantIds']}
        </FormHelperText>
      )}
    </FormControl>
  )
}

function AddParticipants({ setAvailableParticipants, teamId }) {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const { data, errors, fetchMore, fetchingMore, loading } = useQuery(QUERY, {
    teamId,
  })
  const handleNextDebounced = useMemo(
    () => _.debounce(handleNext, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.team?.memberships?.pageInfo?.hasNextPage, fetchingMore]
  )

  useEffect(() => {
    if (!data.team) return

    setAvailableParticipants([
      { value: 'nobody', label: 'Nobody' },
      ...data.team.memberships.nodes.map((membership) => ({
        label: membership.user.name,
        value: membership.user.id,
      })),
    ])
  }, [data.team, setAvailableParticipants])

  useEffect(() => {
    if (!data.team) return
    if (search.length > 0) handleNextDebounced()
  }, [data.team, handleNextDebounced, search.length])

  if (loading) return <ActivityIndicator className="m-md" size={30} />
  if (errors) return <R5Error errors={errors} />

  function handleNext() {
    if (fetchingMore) return

    if (data.team.memberships.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data.team.memberships.pageInfo.endCursor,
          teamId: data.team.id,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.team.memberships.nodes.length
            ? {
                team: {
                  ...previousResult.team,
                  memberships: {
                    __typename: previousResult.team.memberships.__typename,
                    nodes: [
                      ...previousResult.team.memberships.nodes,
                      ...fetchMoreResult.team.memberships.nodes,
                    ],
                    pageInfo: fetchMoreResult.team.memberships.pageInfo,
                  },
                },
              }
            : previousResult
        },
      })
    }
  }

  const selectedUsers = [
    { user: { id: 'nobody', name: 'Nobody', initials: 'NB' } },
    ...data.team.memberships.nodes,
  ]
    .map((membership) => membership.user)
    .filter((user) => user.name.toLowerCase().includes(search.toLowerCase()))

  return (
    <Box className="flex flex-col">
      <Typography className={classes.subLabel} color="textSecondary">
        Available
      </Typography>
      <R5Search fullWidth onChange={setSearch} value={search} />
      <InfiniteScroll
        dataLength={selectedUsers.length}
        hasChildren={_.some(selectedUsers)}
        hasMore={data.team.memberships.pageInfo.hasNextPage}
        height={255}
        loader={<ActivityIndicator className="ml-md" />}
        next={handleNext}
      >
        <Droppable droppableId="availableParticipants" isDropDisabled={true}>
          {(listProvided, listSnapShot) => (
            <List
              {...listProvided.droppableProps}
              className={classes.availableList}
              dense
              ref={listProvided.innerRef}
            >
              {selectedUsers.map((user, index) => (
                <Draggable key={user.id} draggableId={user.id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      button
                      className={classes.li}
                      divider={
                        index === selectedUsers.length - 1 ? false : true
                      }
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDragging
                          ? 'rgba(82, 89, 255, 0.5)'
                          : '',
                        userSelect: 'none',
                        ...provided.draggableProps.style,
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.avatar} src={user.avatarUrl}>
                          {user.initials}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText>{user.name}</ListItemText>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {listProvided.placeholder}
            </List>
          )}
        </Droppable>
      </InfiniteScroll>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  addedLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    paddingBottom: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
  addedList: {
    overflow: 'auto',
    height: 305,
    marginTop: 0,
  },
  availableList: {
    overflow: 'auto',
    height: 255,
  },
  avatar: {
    backgroundColor: styles.primary.color,
    fontSize: 12,
    fontWeight: 800,
    height: 32,
    width: 32,
  },
  avatarNumber: {
    backgroundColor: styles.container.backgroundColor,
    borderColor: styles.primary.color,
    borderStyle: 'solid',
    borderWidth: 1,
    color: styles.primary.color,
    fontSize: 12,
    fontWeight: 800,
    height: 32,
    width: 32,
  },
  avatarRoot: {
    minWidth: 44,
  },
  card: {
    borderRadius: theme.spacing(1.5),
    'label + &': {
      marginTop: theme.spacing(3.5),
    },
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  errorCard: {
    borderColor: styles.error.color,
    borderRadius: theme.spacing(1.5),
    'label + &': {
      marginTop: theme.spacing(3.5),
    },
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  img: {
    width: 273,
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    paddingBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  li: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(82, 89, 255, 0.1)',
      borderRadius: 8,
    },
  },
  margin: {
    marginTop: theme.spacing(3.5),
  },
  subLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    paddingBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
}))

const QUERY = gql`
  query ParticipantsScreen($cursor: String, $teamId: ID!) {
    team(id: $teamId) {
      id
      name
      memberships(after: $cursor, first: 30) {
        nodes {
          id
          user {
            id
            name
            initials
            avatarUrl
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`
