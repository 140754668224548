import { Box, Button, Typography } from '@mui/material'
import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useCallback, useEffect } from 'react'
import { useBack, useQuery } from '../../hooks'
import { ReactComponent as EmptyEscalationPolicySteps } from '../../components/illustrations/EmptyEscalationPolicySteps.svg'
import { EscalationPolicyStep } from '../../components/Teams'
import { Link as RouterLink } from '@reach/router'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function EscalationPolicyPage({ escalationPolicyId, teamId }) {
  useBack(`/teams/${teamId}/coverage`)
  const classes = useStyles()
  const { accountId, subscribe } = useCurrents()
  const { data, errors, loading, refetch } = useQuery(QUERY, {
    escalationPolicyId,
    teamId,
    startsAt: moment().unix(),
    endsAt: moment().add(1, 'day').unix(),
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: ESCALATION_POLICY_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
        id: escalationPolicyId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, escalationPolicyId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: ESCALATION_POLICY_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
        id: escalationPolicyId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, escalationPolicyId])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  function renderEmpty() {
    return (
      <Box className="flex flex-col items-center justify-center mt-1/10">
        <EmptyEscalationPolicySteps />
        <Typography variant="h5" gutterBottom>
          There are no steps in this escalation policy
        </Typography>
        {data.escalationPolicy.viewerCanAdminister && (
          <Button component={RouterLink} variant="contained" to="steps/new">
            + Add a step
          </Button>
        )}
      </Box>
    )
  }

  return (
    <R5Container>
      <R5Title title={`Escalation Policy - ${data.escalationPolicy.name}`} />
      <R5Header
        editUrl={data.escalationPolicy.viewerCanAdminister ? 'edit' : null}
        title={`${data.escalationPolicy.name} ${
          (data.escalationPolicy.isDefault &&
            (data.escalationPolicy.name !== 'Default'
              ? '(default)'
              : 'Policy')) ||
          ''
        }`}
      >
        <Typography className={classes.subtitle}>
          Execute the following actions until an assigned incident is
          acknowledged, snoozed, or resolved.
        </Typography>
      </R5Header>

      {data.escalationPolicy.steps.length > 0 ? (
        <>
          {data.escalationPolicy.steps.map((step) => (
            <EscalationPolicyStep
              escalationPolicy={data.escalationPolicy}
              key={step.id}
              step={step}
              teamId={teamId}
            />
          ))}
          <Button component={RouterLink} variant="outlined" to="steps/new">
            + Add a step
          </Button>
        </>
      ) : (
        renderEmpty()
      )}
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: styles.ruleTitle.color,
    fontSize: 15,
    fontWeight: 'bold',
  },
}))

const QUERY = gql`
  query EscalationPolicy($escalationPolicyId: ID!, $teamId: ID!) {
    escalationPolicy(id: $escalationPolicyId, teamId: $teamId) {
      id
      name
      isDefault
      viewerCanAdminister
      steps {
        id
        condition
        delayInMinutes
        escalations {
          action
          target {
            id
            name
          }
        }
      }
    }
  }
`

const ESCALATION_POLICY_UPDATE_SUBSCRIPTION = gql`
  subscription onEscalationPolicyUpdated(
    $accountId: ID!
    $teamId: ID!
    $id: ID
  ) {
    escalationPolicyUpdated(accountId: $accountId, teamId: $teamId, id: $id) {
      id
      updatedAt
    }
  }
`

const ESCALATION_POLICY_DELETE_SUBSCRIPTION = gql`
  subscription onEscalationPolicyDeleted(
    $accountId: ID!
    $teamId: ID!
    $id: ID
  ) {
    escalationPolicyDeleted(accountId: $accountId, teamId: $teamId, id: $id) {
      id
    }
  }
`
