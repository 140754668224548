import React from 'react'

export default function Envelope({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 16.2632V3H19V16.2632H1ZM3.36842 5.36842H16.6316V5.90003L9.99996 8.69549L3.36842 5.90007V5.36842ZM3.36842 8.47031V13.8947H16.6316V8.47028L9.99996 11.2657L3.36842 8.47031Z"
        fill={color}
      />
    </svg>
  )
}
