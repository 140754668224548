/** @jsxImportSource @emotion/react */

import * as Icons from '../../components/icons'

import { ActivityIndicator, R5Error } from '../../components/shared'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { useCallback, useEffect } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'
import { css } from '@emotion/react'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useQuery } from '../../hooks'

export default function EscalationPolicyList({
  handleSelect,
  search,
  selectedValues,
  setFetching,
}) {
  const classes = useStyles()
  const { data, errors, fetchMore, fetchingMore, loading } = useQuery(
    ESCALATION_POLICIES_QUERY
  )

  function handleNext() {
    if (fetchingMore) return
    if (!data.teams.pageInfo.hasNextPage) return

    fetchMore({
      variables: { cursor: data.teams.pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult.teams.nodes.length
          ? {
              teams: {
                __typename: previousResult.teams.__typename,
                nodes: [
                  ...previousResult.teams.nodes,
                  ...fetchMoreResult.teams.nodes,
                ],
                pageInfo: fetchMoreResult.teams.pageInfo,
              },
            }
          : previousResult
      },
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNextDebounced = useCallback(_.debounce(handleNext, 500), [
    data.teams?.pageInfo?.hasNextPage,
    fetchingMore,
  ])

  useEffect(() => {
    setFetching(fetchingMore)
  }, [fetchingMore, setFetching])

  useEffect(() => {
    if (!data.teams) return
    if (search.length > 0) handleNextDebounced()
  }, [data.teams, handleNextDebounced, search.length])

  if (loading) return <ActivityIndicator className="m-md" size={30} />
  if (errors) return <R5Error errors={errors} />

  const _escalationPolicies = _.flatMap(data.teams.nodes, (team) =>
    team.escalationPolicies.nodes.map((escalationPolicy) => ({
      ...escalationPolicy,
      team: { accentColor: team.accentColor, name: team.name },
    }))
  )

  const selectedEscalationPolicies = _escalationPolicies.filter(
    (escalationPolicy) =>
      escalationPolicy.name.toLowerCase().includes(search.toLowerCase()) ||
      escalationPolicy.team.name.toLowerCase().includes(search.toLowerCase()) ||
      selectedValues.find((value) => value.id === escalationPolicy.id)
  )

  return (
    <div className="flex" css={stylesheet}>
      <InfiniteScroll
        className="scroll-shadows"
        dataLength={selectedEscalationPolicies.length}
        hasChildren={_.some(selectedEscalationPolicies)}
        hasMore={data.teams.pageInfo.hasNextPage}
        height={300}
        next={handleNextDebounced}
      >
        <List dense className={classes.list}>
          {_.some(selectedEscalationPolicies)
            ? selectedEscalationPolicies.map((escalationPolicy, index) => (
                <ListItem
                  button
                  className={classes.li}
                  divider={
                    index === selectedEscalationPolicies.length - 1
                      ? false
                      : true
                  }
                  key={escalationPolicy.id}
                  onClick={() =>
                    handleSelect({
                      id: escalationPolicy.id,
                      name: `${escalationPolicy.team.name}: ${escalationPolicy.name}`,
                    })
                  }
                >
                  <ListItemIcon className={classes.icon}>
                    <Box
                      className={classes.avatar}
                      style={{
                        backgroundColor: escalationPolicy.team.accentColor,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography component="span" variant="body2">
                      {escalationPolicy.team.name}:{' '}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      component="span"
                      variant="body2"
                    >
                      {escalationPolicy.name}
                    </Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    {selectedValues.find(
                      (value) => value.id === escalationPolicy.id
                    ) ? (
                      <Icons.CheckCircle color="#6dd76b" size={32} />
                    ) : null}
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            : !data.teams.pageInfo.hasNextPage && (
                <ListItem button className={classes.li}>
                  <ListItemText>
                    No policies or teams exist with that name
                  </ListItemText>
                </ListItem>
              )}
        </List>
      </InfiniteScroll>
    </div>
  )
}

const stylesheet = css`
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
  .scroll-shadows {
    background:
      /* Shadow Cover TOP */ linear-gradient(
          hsla(200 1% 98% / 1) 30%,
          rgba(255, 255, 255, 0)
        )
        center top,
      /* Shadow Cover BOTTOM */
        linear-gradient(rgba(255, 255, 255, 0), hsla(200 1% 98% / 1) 70%) center
        bottom,
      /* Shadow TOP */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0)
        )
        center top,
      /* Shadow BOTTOM */
        radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0)
        )
        center bottom;

    background-repeat: no-repeat;
    background-size: 100% 20px, 100% 20px, 100% 8px, 100% 8px;
    background-attachment: local, local, scroll, scroll;
  }
`

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 12,
    height: 12,
    width: 12,
  },
  icon: {
    marginBottom: theme.spacing(1.25),
    marginTop: theme.spacing(1.25),
    minWidth: theme.spacing(3),
  },
  li: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(82, 89, 255, 0.1)',
      borderRadius: 8,
    },
  },
  list: {
    height: 300,
  },
}))

const ESCALATION_POLICIES_QUERY = gql`
  query EscalationPolicyList($cursor: String) {
    teams(after: $cursor, first: 30) {
      nodes {
        id
        name
        accentColor
        escalationPolicies(first: 30) {
          nodes {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
