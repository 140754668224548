import React from 'react'

export default function DropUpArrow({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8327 8.03071C10.4324 7.51021 9.64761 7.51021 9.24731 8.03071L6.31792 11.8397C5.81215 12.4973 6.28098 13.4493 7.1106 13.4493L12.9694 13.4493C13.799 13.4493 14.2678 12.4974 13.7621 11.8397L10.8327 8.03071Z"
        fill={color}
      />
    </svg>
  )
}
