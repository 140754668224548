import { ActivityIndicator, R5Error, Loader } from '../../components/shared'
import { Box, Button, List, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { ReactComponent as EmptyTeams } from '../../components/illustrations/EmptyTeams.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as NotFound } from '../../components/illustrations/NotFound.svg'
import { Link as RouterLink } from '@reach/router'
import TeamItem from './TeamItem'
import _ from 'lodash'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'

export default function AllTeams({ search }) {
  const { user, accountId, subscribe } = useCurrents()
  const { data, errors, fetchMore, fetchingMore, loading, refetch } =
    useQuery(ALL_TEAMS_QUERY)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  const handleNextDebounced = useMemo(
    () => _.debounce(handleNext, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.teams?.pageInfo?.hasNextPage, fetchingMore]
  )

  useEffect(() => {
    if (!data.teams) return
    if (search.length > 0) handleNextDebounced()
  }, [data.teams, handleNextDebounced, search.length])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: TEAM_CREATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, subscribe, refetchMemo])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: TEAM_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, subscribe, refetchMemo])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: TEAM_DELETE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, subscribe, refetchMemo])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  function handleNext() {
    if (fetchingMore) return

    if (data.teams.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data.teams.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.teams.nodes.length
            ? {
                teams: {
                  __typename: previousResult.teams.__typename,
                  nodes: [
                    ...previousResult.teams.nodes,
                    ...fetchMoreResult.teams.nodes,
                  ],
                  pageInfo: fetchMoreResult.teams.pageInfo,
                },
              }
            : previousResult
        },
      })
    }
  }

  const selectedTeams = _.filter(data.teams.nodes, (team) =>
    team.name.toLowerCase().includes(search.toLowerCase())
  )

  function renderEmpty() {
    return (
      <Box className="flex flex-col items-center justify-center mt-1/10">
        <EmptyTeams />
        <Typography variant="h5" gutterBottom>
          There are no teams
        </Typography>
        {user.account.viewerCanCreateTeams && (
          <Button component={RouterLink} to="/teams/new" variant="outlined">
            + Add Team
          </Button>
        )}
      </Box>
    )
  }

  function renderNoResults() {
    if (fetchingMore) return null

    return (
      <Box className="flex flex-col items-center justify-center">
        <NotFound height={150} width={150} />
        <Typography variant="h5" gutterBottom>
          No teams match your search
        </Typography>
        {user.account.viewerCanCreateTeams && (
          <Button
            component={RouterLink}
            to="/teams/new"
            state={{ name: search }}
            variant="outlined"
          >
            + Add {search}
          </Button>
        )}
      </Box>
    )
  }

  return data.teams.nodes.length > 0 ? (
    selectedTeams.length > 0 ? (
      <InfiniteScroll
        dataLength={selectedTeams.length}
        hasChildren={_.some(selectedTeams)}
        hasMore={data.teams.pageInfo.hasNextPage}
        loader={<ActivityIndicator />}
        next={handleNext}
      >
        <List>
          {selectedTeams.map((team) => (
            <TeamItem key={team.id} team={team} />
          ))}
        </List>
      </InfiniteScroll>
    ) : (
      renderNoResults()
    )
  ) : (
    renderEmpty()
  )
}

const ALL_TEAMS_QUERY = gql`
  query AllTeams($cursor: String) {
    teams(after: $cursor, first: 30) {
      nodes {
        id
        name
        accentColor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const TEAM_CREATE_SUBSCRIPTION = gql`
  subscription onTeamCreated($accountId: ID!) {
    teamCreated(accountId: $accountId) {
      id
    }
  }
`

const TEAM_UPDATE_SUBSCRIPTION = gql`
  subscription onTeamUpdated($accountId: ID!) {
    teamUpdated(accountId: $accountId) {
      id
      accountId
      name
      accentColor
      createdAt
    }
  }
`

const TEAM_DELETE_SUBSCRIPTION = gql`
  subscription onTeamDeleted($accountId: ID!) {
    teamDeleted(accountId: $accountId) {
      id
    }
  }
`
