import * as Icons from '../icons'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '../../hooks'

import { R5Error } from '../../components/shared'
import { MobileDateTimePicker } from '@mui/lab'
import { R5Input } from '../shared'
import TimeOffUserSelect from '../../components/Profile/TimeOffUserSelect'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function TimeOffModal({
  setShowTimeOffAlert,
  setShowTimeOffModal,
  setTimeOffShifts,
  showTimeOffModal,
}) {
  const { user } = useCurrents()
  const { data, errors, loading } = useQuery(QUERY, {})
  const classes = useStyles()
  const overrideMutation = useMutation(CREATE_OVERRIDE)
  const [submitting, setSubmitting] = useState(false)

  const [shifts, setShifts] = useState([])
  const [toUser, setToUser] = useState({
    id: 'nobody',
    initials: 'NB',
    name: 'Nobody',
  })
  const [endsAt, setEndsAt] = useState(
    moment().add(1, 'day').startOf('day').toDate()
  )
  const [startsAt, setStartsAt] = useState(moment().startOf('day').toDate())

  useEffect(() => {
    if (startsAt > endsAt) setEndsAt(moment(startsAt).add(1, 'day').toDate())
  }, [endsAt, startsAt])

  useEffect(() => {
    if (data.viewer) {
      setShifts(
        _.flatMap(data.viewer.memberships.nodes, (membership) =>
          _.flatMap(
            membership.team.schedules.nodes,
            (schedule) => schedule.shifts.nodes
          )
        ).filter((shift) =>
          _.map(shift.participants.nodes, 'id').includes(user.id)
        )
      )
    }
  }, [user, data])

  if (errors) return <R5Error errors={errors} />

  async function handleSubmit() {
    setSubmitting(true)

    await Promise.all(
      shifts.map((shift) => {
        const template = `${shift.schedule.name} (${shift.name}) in ${shift.team.name}`
        return overrideMutation({
          endsAt: moment(endsAt).unix(),
          fromUserId: user.id,
          scheduleId: shift.schedule.id,
          shiftIds: [shift.id],
          startsAt: moment(startsAt).unix(),
          teamId: shift.team.id,
          toUserId: toUser.id,
        })
          .then(() =>
            setTimeOffShifts((prevTimeOffShifts) => ({
              ...prevTimeOffShifts,
              success: [...prevTimeOffShifts.success, template],
            }))
          )
          .catch(() =>
            setTimeOffShifts((prevTimeOffShifts) => ({
              ...prevTimeOffShifts,
              failed: [...prevTimeOffShifts.failed, template],
            }))
          )
      })
    )
    setShowTimeOffAlert(true)
    handleClose()
  }

  function handleClose() {
    setShowTimeOffModal(false)
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row my-sm justify-end">
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            onClick={handleClose}
            size="large"
          >
            Cancel
          </Button>
          <Button
            disabled={_.isEmpty(shifts) || submitting}
            onClick={handleSubmit}
            size="large"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Box>
    )
  }

  function renderStartsAt() {
    return (
      <FormControl fullWidth>
        <InputLabel className={classes.label} htmlFor="starts-at">
          Starts
        </InputLabel>
        <MobileDateTimePicker
          disablePast
          inputFormat="MMMM D hh:mm a"
          minutesStep={15}
          onChange={setStartsAt}
          OpenPickerButtonProps={{ color: 'primary' }}
          renderInput={(props) => <R5Input {...props} />}
          value={startsAt}
        />
      </FormControl>
    )
  }

  function renderEndsAt() {
    return (
      <FormControl className="mt-md" fullWidth>
        <InputLabel className={classes.label} htmlFor="starts-at">
          Ends
        </InputLabel>
        <MobileDateTimePicker
          inputFormat="MMMM D hh:mm a"
          minDate={moment(startsAt)}
          minutesStep={15}
          onChange={setEndsAt}
          OpenPickerButtonProps={{ color: 'primary' }}
          renderInput={(props) => <R5Input {...props} />}
          value={endsAt}
        />
      </FormControl>
    )
  }

  return (
    <Dialog
      aria-labelledby="add-integration-dialog-title"
      aria-describedby="add-integration-dialog-description"
      fullWidth
      onClose={handleClose}
      open={showTimeOffModal}
      maxWidth="xs"
    >
      <DialogTitle id="add-integration-dialog-title">
        <Box className="flex flex-row justify-between items-center">
          <Typography style={styles.headerTitleStyle} variant="h4">
            Time Off
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Icons.Close color={styles.primary.color} size={20} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className="flex flex-col mb-md">
        <Box className="mt-3">
          <Typography className={classes.subtitle} color="textSecondary">
            This will create overrides for all your shifts in the selected time
            frame.
          </Typography>
          <Box className="mt-xs">
            {loading ? (
              <CircularProgress className="ml-xs" size={10} />
            ) : _.some(shifts) ? (
              shifts.map((shift) => (
                <Typography
                  className={classes.shifts}
                  color="textSecondary"
                  key={shift.id}
                >
                  {shift.schedule.name} ({shift.name}) in {shift.team.name}
                </Typography>
              ))
            ) : (
              'You have no shifts to override'
            )}
          </Box>
        </Box>
        <Box className="flex flex-col mt-md">
          <Box style={{ minHeight: 300 }}>
            <TimeOffUserSelect setToUser={setToUser} toUser={toUser} />
            {renderStartsAt()}
            {renderEndsAt()}
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actions}>
        {renderFooter()}
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  adornment: {
    backgroundColor: 'blue',
  },
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  divider: {
    backgroundColor: styles.primary.color,
    height: 2,
    marginTop: theme.spacing(1.5),
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  shifts: {
    fontSize: 12,
    fontWeight: '600',
  },
  subtitle: {
    fontSize: 12,
  },
}))

const QUERY = gql`
  query TimeOff {
    viewer {
      id
      memberships(first: 30) {
        nodes {
          id
          team {
            id
            schedules(first: 30) {
              nodes {
                id
                shifts(first: 30) {
                  nodes {
                    id
                    name
                    participants(first: 30) {
                      nodes {
                        id
                        name
                      }
                    }
                    schedule {
                      id
                      name
                    }
                    team {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          user {
            id
            name
            role
          }
        }
      }
    }
  }
`

const CREATE_OVERRIDE = gql`
  mutation CreateOverride($input: CreateOverrideInput!) {
    createOverride(input: $input) {
      override {
        id
      }
    }
  }
`
