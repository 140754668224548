import React from 'react'

export default function DropDownArrow({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8327 11.9693C10.4324 12.4898 9.64761 12.4898 9.24731 11.9693L6.31792 8.16028C5.81215 7.50265 6.28098 6.55065 7.1106 6.55065L12.9694 6.55065C13.799 6.55065 14.2678 7.50265 13.7621 8.16028L10.8327 11.9693Z"
        fill={color}
      />
    </svg>
  )
}
