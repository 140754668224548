import * as Icons from '../icons'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export function SlackIntegrationModal({
  integration,
  setIntegration,
  setShowAddIntegrationModal,
  showAddIntegrationModal,
  teamId,
}) {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const requestSlackMutation = useMutation(
    REQUEST_SLACK_INTEGRATION_AUTHORIZATION
  )

  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)

    try {
      const response = await requestSlackMutation({
        teamId,
      })
      if (response.data.requestSlackIntegrationAuthorization) {
        window.location.href =
          response.data.requestSlackIntegrationAuthorization.slackAuthorization.url
      }
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function handleClose() {
    setShowAddIntegrationModal(false)
    setIntegration({})
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row my-sm justify-end">
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            onClick={handleClose}
            size="large"
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            Add
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Dialog
      aria-labelledby="add-integration-dialog-title"
      aria-describedby="add-integration-dialog-description"
      fullWidth
      onClose={handleClose}
      open={showAddIntegrationModal}
      maxWidth="xs"
    >
      <DialogTitle id="add-integration-dialog-title">
        <Box className="flex flex-row justify-between items-center">
          <Typography style={styles.headerTitleStyle} variant="h4">
            Create {integration.name} Integration
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Icons.Close color={styles.primary.color} size={20} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider className={classes.divider} />
      <form onSubmit={handleSubmit}>
        <DialogContent className="flex flex-col p-xl mb-md">
          <Typography>{integration.helpText}</Typography>
        </DialogContent>
        <Divider />
        <DialogActions>{renderFooter()}</DialogActions>
      </form>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  card: {
    borderRadius: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  description: {
    fontSize: 12,
    marginTop: theme.spacing(1),
  },
  header: {
    height: 181,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}))

const REQUEST_SLACK_INTEGRATION_AUTHORIZATION = gql`
  mutation RequestSlackIntegrationAuthorization(
    $input: RequestSlackIntegrationAuthorizationInput!
  ) {
    requestSlackIntegrationAuthorization(input: $input) {
      slackAuthorization {
        url
      }
    }
  }
`
