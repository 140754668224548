import React from 'react'

export default function PersonCircle({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM11.8001 7.89474C11.8001 8.94117 10.9942 9.78947 10.0001 9.78947C9.00596 9.78947 8.20007 8.94117 8.20007 7.89474C8.20007 6.8483 9.00596 6 10.0001 6C10.9942 6 11.8001 6.8483 11.8001 7.89474ZM7 11.7182L10 10.6316L13 11.7182V14H7V11.7182Z"
        fill={color}
      />
    </svg>
  )
}
