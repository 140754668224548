import {
  CreateEscalationPolicyPage,
  CreateEscalationPolicyStepPage,
  EditEscalationPolicyPage,
  EditEscalationPolicyStepPage,
  EscalationPolicyPage,
} from '../../../pages/Teams'

import React from 'react'
import { Router } from '@reach/router'

export default function EscalationPoliciesStack() {
  return (
    <Router>
      <CreateEscalationPolicyPage path="new" />
      <EscalationPolicyPage path=":escalationPolicyId" />
      <EditEscalationPolicyPage path=":escalationPolicyId/edit" />

      <CreateEscalationPolicyStepPage path=":escalationPolicyId/steps/new" />
      <EditEscalationPolicyStepPage path=":escalationPolicyId/steps/:stepId/edit" />
    </Router>
  )
}
