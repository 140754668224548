import React from 'react'

export default function ClockCircle({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM10.5385 10.5385V6.76923H9.46155V11.6154H12.9615V10.5385H10.5385Z"
        fill={color}
      />
    </svg>
  )
}
