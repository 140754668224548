import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Hidden,
  Typography,
} from '@mui/material'
import { ConfirmButton, R5TextField } from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'

import { EscalationPolicy } from '../../components/illustrations'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation } from '../../hooks'

export default function EscalationPolicyForm({ escalationPolicy, teamId }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const escalationPolicyMutation = useMutation(
    escalationPolicy ? UPDATE_ESCALATION_POLICY : CREATE_ESCALATION_POLICY
  )
  const deleteMutation = useMutation(DELETE_ESCALATION_POLICY)
  const [name, setName] = useState(
    escalationPolicy ? escalationPolicy.name : ''
  )
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission
    setLoading(true)

    try {
      const response = await escalationPolicyMutation({
        teamId,
        escalationPolicyId: escalationPolicy?.id,
        name,
      })

      setLoading(false)
      if (response.data.createEscalationPolicy) {
        navigate(
          `/teams/${teamId}/escalationPolicies/${response.data.createEscalationPolicy.escalationPolicy.id}`
        )
      } else {
        navigate(`/teams/${teamId}/escalationPolicies/${escalationPolicy.id}`)
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)

    try {
      await deleteMutation({ teamId, escalationPolicyId: escalationPolicy.id })
      setLoading(false)
      navigate(`/teams/${teamId}/coverage`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-sm ${
          escalationPolicy && !escalationPolicy.isDefault
            ? 'justify-between'
            : 'justify-end'
        }`}
      >
        {escalationPolicy && !escalationPolicy.isDefault && (
          <ConfirmButton
            confirmContent="Are you sure you want to delete this escalation policy and all
          its steps? Doing so will immediately remove this policy as a
          target for any integrations or open incidents."
            onConfirm={handleDelete}
            error
            title="Delete Escalation Policy"
          />
        )}
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/teams/${teamId}${
              escalationPolicy
                ? `/escalationPolicies/${escalationPolicy.id}`
                : ``
            }`}
          >
            Cancel
          </Button>

          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {escalationPolicy ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Card variant="outlined">
      <form onSubmit={handleSubmit}>
        <CardContent className={classes.content}>
          <Box className="flex flex-row items-center">
            <Box className="flex flex-col mr-10">
              <R5TextField
                error={_.some(validationErrors['name'])}
                label="Name"
                onChange={setName}
                placeholder="Alternate"
                required
                value={name}
              />
              <Typography className={classes.subtitle}>
                {escalationPolicy
                  ? escalationPolicy.isDefault
                    ? 'This is the default policy for this team. You can edit its name, but it cannot be deleted.'
                    : 'This is a secondary policy on this team, which can be configured with its own steps and timings and can be targeted by by integrations and incident creators.'
                  : 'Additional escalation policies can be configured with their own steps and timings and can be targeted by by integrations and incident creators.'}
              </Typography>
            </Box>
            <Hidden implementation="css" smDown>
              <CardMedia
                alt="Escalation Policies"
                component="img"
                image={EscalationPolicy}
                sx={{ width: 160 }}
              />
            </Hidden>
          </Box>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>{renderFooter()}</CardActions>
      </form>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}))

const CREATE_ESCALATION_POLICY = gql`
  mutation CreateEscalationPolicy($input: CreateEscalationPolicyInput!) {
    createEscalationPolicy(input: $input) {
      escalationPolicy {
        id
        name
      }
    }
  }
`

const UPDATE_ESCALATION_POLICY = gql`
  mutation UpdateEscalationPolicy($input: UpdateEscalationPolicyInput!) {
    updateEscalationPolicy(input: $input) {
      escalationPolicy {
        id
        name
      }
    }
  }
`

const DELETE_ESCALATION_POLICY = gql`
  mutation DeleteEscalationPolicy($input: DeleteEscalationPolicyInput!) {
    deleteEscalationPolicy(input: $input) {
      escalationPolicyId
    }
  }
`
