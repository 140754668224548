import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Hidden,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  R5Container,
  R5Header,
  R5TextField,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'
import { useBack, useMutation } from '../../hooks'

import { Link as RouterLink } from '@reach/router'
import { SupportTicket } from '../../components/illustrations'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'

export default function CreateSupportTicketPage({ navigate }) {
  useBack('/help')
  const classes = useStyles()
  const theme = useTheme()
  const openSupportTicket = useMutation(OPEN_SUPPORT_TICKET)

  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSubmit = async (event) => {
    event.preventDefault() // Block native form submission

    setLoading(true)

    try {
      await openSupportTicket({
        subject,
        message,
      })
      navigate('/help')
    } catch (error) {
      if (error.errors) setValidationErrors(error.errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Support Ticket" />
      <R5Header title="Create Support Ticket" />
      <form onSubmit={handleSubmit}>
        <Card className="mt-xxl" variant="outlined">
          <CardContent className={classes.content}>
            <Grid className="items-center" container spacing={3}>
              <Grid
                className="flex flex-1 flex-col justify-center"
                item
                xs={12}
              >
                <Grid className="items-center" container spacing={1}>
                  <Grid className="flex flex-1 flex-col justify-center" item>
                    <R5TextField
                      className="mt-0"
                      error={_.some(validationErrors['subject'])}
                      label="Subject"
                      onChange={setSubject}
                      placeholder="Bug, feature request?"
                      required
                      value={subject}
                    />
                  </Grid>
                  <Grid className="flex flex-1 justify-center" item>
                    <Hidden implementation="css" smDown>
                      <CardMedia
                        alt="Support"
                        component="img"
                        image={SupportTicket}
                        sx={{ width: 160 }}
                      />
                    </Hidden>
                  </Grid>
                </Grid>

                <R5TextField
                  className={mobileView ? null : 'mt-0'}
                  error={_.some(validationErrors['message'])}
                  inputComponent={TextareaAutosize}
                  inputProps={{ minRows: mobileView ? 10 : 5 }}
                  label="Message"
                  onChange={setMessage}
                  placeholder="Describe the problem you're having."
                  required
                  value={message}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className="justify-end">
            <Button component={RouterLink} size="large" to="/help">
              Cancel
            </Button>
            <Button
              disabled={loading}
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </form>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(5),
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  title: {
    fontSize: 24,
    fontWeight: 900,
  },
}))

const OPEN_SUPPORT_TICKET = gql`
  mutation OpenSupportTicket($input: OpenSupportTicketInput!) {
    openSupportTicket(input: $input) {
      supportTicket {
        id
      }
    }
  }
`
