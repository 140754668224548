import { Box, Button } from '@mui/material'
import {
  Loader,
  R5Container,
  R5Header,
  R5TextField,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'
import { useBack, useMutation } from '../../hooks'

import { AssignToSelect } from '../../components/shared'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import gql from 'graphql-tag'

export default function CreateIncidentPage({ navigate }) {
  useBack('/incidents')
  const createIncident = useMutation(CREATE_INCIDENT)
  const [title, setTitle] = useState('')
  const [details, setDetails] = useState('')
  const [assignTo, setAssignTo] = useState([])
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    setLoading(true)
    try {
      await createIncident({
        title,
        details,
        assignToIds: assignTo.map((item) => item.id),
      })
      setLoading(false)
      navigate('/incidents')
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  if (loading) return <Loader />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Incident" />
      <R5Header title="Create Incident" />

      <form
        autoComplete="off"
        className="flex flex-col"
        onSubmit={handleSubmit}
      >
        <R5TextField
          label="Title"
          onChange={setTitle}
          placeholder="Something is wrong!"
          error={_.some(validationErrors['title'])}
          value={title}
          required
        />
        <R5TextField
          label="Details"
          onChange={setDetails}
          placeholder="Optional"
          value={details}
        />
        <AssignToSelect
          assignTo={assignTo}
          setAssignTo={setAssignTo}
          validationErrors={validationErrors}
        />
        <Box className="flex flex-1 flex-row justify-between my-5">
          <Button component={RouterLink} size="large" to="/incidents">
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            Create
          </Button>
        </Box>
      </form>
    </R5Container>
  )
}

const CREATE_INCIDENT = gql`
  mutation CreateIncident($input: CreateIncidentInput!) {
    createIncident(input: $input) {
      incident {
        id
        number
      }
    }
  }
`
