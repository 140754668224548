import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Hidden,
  Typography,
} from '@mui/material'
import { R5Container, R5Header, R5Title } from '../../components/shared'
import { R5Select, R5TextField } from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'
import { useBack, useMutation } from '../../hooks'

import { ContactMethod, SlackButtonIcon } from '../../components/illustrations'
import ProfileOptions from '../../constants/ProfileOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'

export default function CreateContactMethodPage() {
  useBack('/profile/notifications')
  const classes = useStyles()
  const navigate = useNavigate()
  const { user, accountId } = useCurrents()
  const createMutation = useMutation(CREATE_CONTACT_METHOD)

  const [description, setDescription] = useState('')
  const [type, setType] = useState('EMAIL')
  const [countryCode, setCountryCode] = useState(1)
  const [to, setTo] = useState('')
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()

    if (type === 'SLACK') {
      return
    }

    setLoading(true)

    try {
      const response = await createMutation({
        userId: user.id,
        description: description,
        type,
        to: type === 'EMAIL' ? to : `${countryCode}${to}`,
      })

      setLoading(false)

      if (response.data.createContactMethod) {
        navigate(
          `/profile/contactMethods/${response.data.createContactMethod.contactMethod.id}/verify`
        )
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row justify-end my-sm">
        <Button
          classes={{ root: classes.marginRight }}
          component={RouterLink}
          size="large"
          to="/profile/notifications"
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          size="large"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </Box>
    )
  }

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Contact Method" />
      <R5Header title="Create Contact Method" />
      <Card variant="outlined">
        <form onSubmit={handleSubmit}>
          <CardContent className={classes.content}>
            <Box className="flex flex-row items-center">
              <Box className="flex flex-1 flex-col mr-xl">
                <R5Select
                  error={_.some(validationErrors['type'])}
                  label="Type"
                  onChange={setType}
                  options={ProfileOptions.types}
                  value={type}
                />
                {(type === 'SMS' || type === 'VOICE') && (
                  <R5TextField
                    error={_.some(validationErrors['countryCode'])}
                    inputProps={{ type: 'tel' }}
                    label="Country Code"
                    onChange={setCountryCode}
                    required
                    value={countryCode}
                  />
                )}
                {(type === 'EMAIL' || type === 'SMS' || type === 'VOICE') && (
                  <>
                    <R5TextField
                      error={validationErrors['to']}
                      inputProps={{ type: type === 'EMAIL' ? 'email' : 'tel' }}
                      label={type === 'EMAIL' ? 'Address' : 'Number'}
                      onChange={setTo}
                      placeholder={
                        type === 'EMAIL' ? 'name@example.com' : '1112223333'
                      }
                      required
                      value={to}
                    />
                    <R5TextField
                      error={_.some(validationErrors['description'])}
                      label="Description"
                      onChange={setDescription}
                      placeholder="Optional"
                      value={description}
                    />
                  </>
                )}
                {type === 'SLACK' && (
                  <Box className="flex flex-1 flex-col gap-4 mt-4">
                    <Button
                      component={RouterLink}
                      style={{ backgroundColor: '#4A154B' }}
                      size="large"
                      type="button"
                      variant="contained"
                      to={`/profile/slack/authorize?accountId=${accountId}`}
                    >
                      <img
                        src={SlackButtonIcon}
                        alt="Slack Icon"
                        style={{
                          height: 24,
                          marginRight: 10,
                        }}
                      />
                      Connect to Slack
                    </Button>
                    <Typography color="textSecondary" style={{ fontSize: 14 }}>
                      You'll be sent to Slack to authorize this connection. If
                      you use multiple Slack workspaces, be sure to choose the
                      one you want to connect.
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className="flex flex-1 justify-end">
                <Hidden implementation="css" smDown>
                  <CardMedia
                    alt="Contact Method"
                    component="img"
                    image={ContactMethod}
                    sx={{ width: 160 }}
                  />
                </Hidden>
              </Box>
            </Box>
          </CardContent>
          {(type === 'EMAIL' || type === 'SMS' || type === 'VOICE') && (
            <>
              <Divider />
              <CardActions className={classes.actions}>
                {renderFooter()}
              </CardActions>
            </>
          )}
        </form>
      </Card>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  margin: {
    marginTop: 0,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 900,
  },
}))

const CREATE_CONTACT_METHOD = gql`
  mutation CreateContactMethod($input: CreateContactMethodInput!) {
    createContactMethod(input: $input) {
      contactMethod {
        id
      }
    }
  }
`
