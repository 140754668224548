import { Avatar, IconButton } from '@mui/material'
import React, { useRef, useState } from 'react'

import R5Tooltip from './R5Tooltip'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function R5Infotip({ placement, learnMore, title }) {
  const classes = useStyles()
  const tooltipRef = useRef()
  const [open, setOpen] = useState(false)

  return (
    <R5Tooltip
      learnMore={learnMore}
      open={open}
      placement={placement}
      ref={tooltipRef}
      setOpen={setOpen}
      title={title}
    >
      <IconButton onClick={() => setOpen((prevOpen) => !prevOpen)} size="small">
        <Avatar className={classes.avatar}>i</Avatar>
      </IconButton>
    </R5Tooltip>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: styles.label.color,
    height: 14,
    fontSize: 10,
    width: 14,
  },
}))
