import React from 'react'

export default function ChevronLeft({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 16.2929C13.0976 15.9024 13.0976 15.2692 12.7071 14.8787L7.75736 9.92895L12.7071 4.9792C13.0976 4.58868 13.0976 3.95551 12.7071 3.56499C12.3166 3.17446 11.6834 3.17446 11.2929 3.56499L4.92893 9.92895L11.2929 16.2929C11.6834 16.6834 12.3166 16.6834 12.7071 16.2929Z"
        fill={color}
      />
    </svg>
  )
}
