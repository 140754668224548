import { Box, Button } from '@mui/material'
import { Link as RouterLink, useLocation } from '@reach/router'

import { Alert } from '@mui/material'
import R5Container from './R5Container'
import React from 'react'
import { enterprise } from '../../utils'
import moment from 'moment'
import { useCurrents } from '../../context/currents'

export default function TrialEndingAlert({ withPadding = false }) {
  const { account } = useCurrents()
  const now = moment()
  const location = useLocation()
  const trialEndsAt = moment.unix(account.billingSubscriptionTrialEndsAt)
  const trialEndsDays = trialEndsAt.diff(now, 'days')

  const Container = withPadding ? R5Container : Box

  return (
    account.viewerCanManageBilling &&
    !account.billingPaymentCardAdded &&
    account.billingSubscriptionStatus === 'TRIALING' &&
    trialEndsDays < 10 &&
    !location.pathname.match(/billing/) && (
      <Container>
        <Alert
          action={
            !enterprise() && (
              <Button
                component={RouterLink}
                color="inherit"
                size="small"
                to="/account/billing"
              >
                SUBSCRIBE
              </Button>
            )
          }
          severity="info"
          variant="filled"
        >
          Your trial ends in {trialEndsAt.fromNow(true)}
        </Alert>
      </Container>
    )
  )
}
