import { InstallPage, NotificationsPage, OwnerPage } from '../../pages/Welcome'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import { Router } from '@reach/router'
import { useCurrents } from '../../context/currents'

export default function WelcomeStack() {
  const { user } = useCurrents()

  return (
    <Router>
      {user.role === 'OWNER' ? (
        <>
          <OwnerPage path="/" />
          <InstallPage path="/install" />
        </>
      ) : (
        <InstallPage path="/" />
      )}
      <NotificationsPage path="notifications" />

      <NotFoundPage default />
    </Router>
  )
}
