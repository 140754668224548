import { ActivityIndicator, R5Error, Loader, R5AddButton } from '../shared'
import { Box, Button, List, Typography } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { ReactComponent as EmptyIntegrations } from '../illustrations/EmptyIntegrations.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import { IntegrationItem } from '../Teams'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'
import useQuery from '../../hooks/useQuery'

export default function Integrations({ teamId }) {
  const { accountId, subscribe } = useCurrents()
  const { data, errors, fetchMore, fetchingMore, loading, refetch } = useQuery(
    INTEGRATIONS_QUERY,
    { teamId }
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INTEGRATION_CREATE_SUBSCRIPTION,
      variables: {
        accountId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INTEGRATION_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INTEGRATION_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  function handleNext() {
    if (fetchingMore) return

    if (data.team.integrations.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data.team.integrations.pageInfo.endCursor,
          teamId: data.team.id,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.team.integrations.nodes.length
            ? {
                team: {
                  ...previousResult.team,
                  integrations: {
                    __typename: previousResult.team.integrations.__typename,
                    nodes: [
                      ...previousResult.team.integrations.nodes,
                      ...fetchMoreResult.team.integrations.nodes,
                    ],
                    pageInfo: fetchMoreResult.team.integrations.pageInfo,
                  },
                },
              }
            : previousResult
        },
      })
    }
  }

  function renderEmpty() {
    return (
      <Box className="flex flex-1 flex-col items-center justify-center mt-1/10">
        <EmptyIntegrations />
        <Typography variant="h5" gutterBottom>
          This team has no integrations.
        </Typography>
        {data.team.viewerCanCreateIntegrations && (
          <Button
            component={RouterLink}
            variant="contained"
            to={`/teams/${data.team.id}/integrations/new`}
          >
            + Add an integration
          </Button>
        )}
      </Box>
    )
  }

  return (
    <Box>
      {data.team.integrations.nodes.length > 0 ? (
        <InfiniteScroll
          dataLength={data.team.integrations.nodes.length}
          hasChildren={_.some(data.team.integrations.nodes)}
          hasMore={data.team.integrations.pageInfo.hasNextPage}
          loader={<ActivityIndicator />}
          next={handleNext}
        >
          <List>
            {data.team.integrations.nodes.map((integration) => (
              <IntegrationItem key={integration.id} integration={integration} />
            ))}
          </List>
        </InfiniteScroll>
      ) : (
        renderEmpty()
      )}

      {data.team.viewerCanCreateIntegrations && (
        <R5AddButton to={`/teams/${data.team.id}/integrations/new`} />
      )}
    </Box>
  )
}

const INTEGRATIONS_QUERY = gql`
  query Integrations($cursor: String, $teamId: ID!) {
    team(id: $teamId) {
      id
      viewerCanCreateIntegrations
      integrations(after: $cursor, first: 30) {
        nodes {
          id
          isDisabled
          description
          name
          provider
          providerImageUrl
          teamId
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

const INTEGRATION_CREATE_SUBSCRIPTION = gql`
  subscription onIntegrationCreated($accountId: ID!) {
    integrationCreated(accountId: $accountId) {
      id
    }
  }
`

const INTEGRATION_UPDATE_SUBSCRIPTION = gql`
  subscription onIntegrationUpdated($accountId: ID!) {
    integrationUpdated(accountId: $accountId) {
      id
      updatedAt
    }
  }
`

const INTEGRATION_DELETE_SUBSCRIPTION = gql`
  subscription onIntegrationDeleted($accountId: ID!) {
    integrationDeleted(accountId: $accountId) {
      id
    }
  }
`
