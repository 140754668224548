import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material'
import { OverridePeriod, ShiftPeriod } from './'

import React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useNavigate } from '@reach/router'
import useResizeObserver from 'use-resize-observer'

export default function ShiftSegment({
  emptyText,
  index,
  item,
  rightIcon,
  periodEnd,
  periodStart,
  refetching,
  schedule,
  selectedValue,
  type,
}) {
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    ref: periodsContainerRef,
    width: periodsContainerWidth,
    height: periodsContainerHeight,
  } = useResizeObserver()

  let coverageTypeText = 'On call'
  if (item.shift.coverageType === 'ON_DUTY') {
    coverageTypeText = 'On duty'
  }

  function spaces() {
    let spaces = 0
    switch (selectedValue) {
      case 0:
        spaces = 24
        break
      case 1:
        spaces = 7
        break
      case 2:
        spaces = 14
        break
      case 3:
        spaces = moment(periodStart).daysInMonth()
        break
      default:
    }
    return spaces
  }

  function renderBars() {
    return [...Array(spaces())].map((_e, i) => {
      const periodDay =
        selectedValue === 0
          ? moment(periodStart).add(i, 'hour').day()
          : moment(periodStart).add(i, 'day').day()
      const isWeekend = periodDay === 0 || periodDay === 6

      return (
        <div
          className="flex flex-1 flex-row items-end"
          key={i}
          style={{
            backgroundColor: isWeekend
              ? styles.well.backgroundColor
              : undefined,
            borderColor: styles.border.color,
            borderLeftStyle: 'solid',
            borderLeftWidth: 1,
            borderRightStyle: 'solid',
            borderRightWidth: i === spaces() - 1 ? 1 : 0,
          }}
        >
          <div
            key={i}
            style={{
              fontWeight: 'bold',
              fontSize: 8,
              color: styles.lightLabel.color,
            }}
          >
            {selectedValue === 0
              ? i % 6 === 0
                ? moment(periodStart).add(i, 'hour').format('h a')
                : ''
              : selectedValue === 3
              ? i % 6 === 0
                ? moment(periodStart).add(i, 'day').format('D')
                : ''
              : moment(periodStart).add(i, 'day').format('M/D')}
          </div>
        </div>
      )
    })
  }

  function renderCurrentTime() {
    const totalWidth = periodEnd.unix() - periodStart.unix()
    const periodPositionStart = moment().unix() - periodStart.unix()
    const leftPercentageStart = periodPositionStart / totalWidth
    const currentTimePosition = leftPercentageStart * 100

    return (
      currentTimePosition < 100 &&
      currentTimePosition > 0 && (
        <div
          style={{
            top: -10,
            height: '100%',
            position: 'absolute',
            left: `${currentTimePosition}%`,
          }}
        >
          <div
            style={{
              borderColor: styles.pending.color,
              borderRadius: 20,
              borderWidth: 6,
              marginLeft: -4,
              position: 'absolute',
            }}
          />
          <div
            style={{
              backgroundColor: styles.pending.color,
              borderRadius: 20,
              height: '100%',
              opacity: 0.9,
              width: 4,
            }}
          />
        </div>
      )
    )
  }

  return (
    <Box>
      <Box
        className={`flex flex-row justify-between items-center pb-md ${
          index > 0 ? 'mt-md' : ''
        } ${classes.height}`}
      >
        <Typography className={classes.header}>
          {item.shift.name}
          <Chip
            component="span"
            size="small"
            variant="outlined"
            color={
              item.shift.coverageType === 'ON_CALL' ? 'primary' : 'warning'
            }
            label={coverageTypeText}
            className="ml-sm"
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: 11,
              height: 'inherit',
              verticalAlign: 'middle',
            }}
          />
        </Typography>
        {refetching ? (
          <IconButton className={classes.loadingeButton} size="large">
            <CircularProgress size={17} />
          </IconButton>
        ) : (
          rightIcon
        )}
      </Box>
      {_.some(item.rows, (row) => row.periods.length) ? (
        <Box style={{ position: 'relative' }} ref={periodsContainerRef}>
          <Box
            className="flex flex-row"
            style={{ height: item.rows.length * 35 + 25 }}
          >
            {renderBars()}
          </Box>
          {renderCurrentTime()}
          {item.rows.map((row, i) =>
            row.periods.map((period, j) => {
              const PeriodType =
                type === 'shift'
                  ? ShiftPeriod
                  : type === 'override'
                  ? OverridePeriod
                  : period.override
                  ? OverridePeriod
                  : ShiftPeriod
              return (
                <PeriodType
                  key={`${i}-${j}-${period.startsAt}`}
                  final={type === 'final'}
                  index={i}
                  nagivate={navigate}
                  period={period}
                  periodEnd={periodEnd}
                  periodStart={periodStart}
                  schedule={schedule}
                  shift={item.shift}
                  containerDimensions={{
                    width: periodsContainerWidth,
                    height: periodsContainerHeight,
                  }}
                />
              )
            })
          )}
        </Box>
      ) : (
        <Typography className={classes.subtitle}>{emptyText}</Typography>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  height: {
    height: 36,
  },
  loadingeButton: {
    backgroundColor: styles.container.backgroundColor,
    color: styles.primary.color,
    padding: 6,
    marginRight: theme.spacing(0.5),
  },
  subtitle: {
    color: styles.lightLabel.color,
    fontSize: 14,
  },
}))
