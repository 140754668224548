import { AlertDialog, R5TextField } from '../shared'
import { Box, Button, CardContent } from '@mui/material'
import { useEffect, useState } from 'react'
import { useStyles } from './EmailForm'
import { useCurrents } from '../../context/currents'
import gql from 'graphql-tag'
import { useMutation } from '../../hooks'

export default function EmailUpdateForm({
  email,
  emailDirty,
  setCodeSent,
  setEmail,
  setShowEmailUpdatedDialog,
  showEmailUpdatedDialog,
}) {
  const classes = useStyles()
  const { load, user } = useCurrents()
  const changeUserEmailAddressMutation = useMutation(CHANGE_USER_EMAIL_ADDRESS)
  const [isSendingCode, setIsSendingCode] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})
  const [buttonDisabled, setButtonDisabled] = useState(true)

  async function handleUpdateEmail(event) {
    event.preventDefault()

    setIsSendingCode(true)
    setValidationErrors({})

    try {
      await changeUserEmailAddressMutation({
        userId: user.id,
        newEmailAddress: email,
      })
      setCodeSent(true)
      setIsSendingCode(false)
    } catch (error) {
      setIsSendingCode(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  useEffect(() => {
    setButtonDisabled(isSendingCode || !emailDirty)
  }, [emailDirty, isSendingCode])

  return (
    <CardContent className={classes.content}>
      <form className="flex flex-1 flex-col" onSubmit={handleUpdateEmail}>
        <R5TextField
          error={validationErrors['newEmailAddress']}
          label="Email"
          onChange={setEmail}
          type="email"
          value={email}
        />

        <Box className="flex flex-1 flex-row justify-end mt-md">
          <Button disabled={buttonDisabled} type="submit" variant="outlined">
            Change sign in email address
          </Button>
        </Box>
      </form>
      <AlertDialog
        cancelText="Ok"
        content="Your sign in email address was updated successfully. For all future sign ins, you must use this new address."
        handleClose={() => {
          setShowEmailUpdatedDialog(false)
          load()
        }}
        open={showEmailUpdatedDialog}
        title="Email address updated"
      />
    </CardContent>
  )
}

const CHANGE_USER_EMAIL_ADDRESS = gql`
  mutation ChangeUserEmailAddress($input: ChangeUserEmailAddressInput!) {
    changeUserEmailAddress(input: $input) {
      userEmailAddressChange {
        newEmailAddress
        completedAt
        expiresAt
        verificationRequired
      }
    }
  }
`
