import { R5Error, Loader } from '../../components/shared'
import { R5Container, R5Header, R5Title } from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { IncidentNotificationRuleForm } from '../../components/Users'
import React from 'react'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'

export default function EditIncidetNotificationRulePage({
  incidentNotificationRuleId,
}) {
  useBack(`/profile/rules/${incidentNotificationRuleId}`)
  const { user } = useCurrents()
  const { data, errors, loading } = useQuery(QUERY, {
    incidentNotificationRuleId,
    userId: user.id,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Notification Rule" />
      <R5Header title={data.incidentNotificationRule.name} />
      <IncidentNotificationRuleForm
        incidentNotificationRule={data.incidentNotificationRule}
        userId={user.id}
      />
    </R5Container>
  )
}

const QUERY = gql`
  query EditIncidentNotificationRule(
    $incidentNotificationRuleId: ID!
    $userId: ID!
  ) {
    incidentNotificationRule(id: $incidentNotificationRuleId, userId: $userId) {
      id
      userId
      name
      isDefault
      repeatAfterInMinutes
      timePeriods {
        monday {
          fromTime
          toTime
        }
        tuesday {
          fromTime
          toTime
        }
        wednesday {
          fromTime
          toTime
        }
        thursday {
          fromTime
          toTime
        }
        friday {
          fromTime
          toTime
        }
        saturday {
          fromTime
          toTime
        }
        sunday {
          fromTime
          toTime
        }
      }
    }
  }
`
