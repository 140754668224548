import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { EscalationPolicyForm } from '../../components/Teams'
import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import gql from 'graphql-tag'

export default function CreateEscalationPolicyPage({ teamId }) {
  useBack(`/teams/${teamId}/coverage`)
  const { data, errors, loading } = useQuery(QUERY, {
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.team.viewerCanCreateEscalationPolicies) return <NotFoundPage />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Escalation Policy" />
      <R5Header title="Create Escalation Policy" />
      <EscalationPolicyForm teamId={teamId} />
    </R5Container>
  )
}

const QUERY = gql`
  query CreateEscalationPolicy($teamId: ID!) {
    team(id: $teamId) {
      id
      name
      viewerCanCreateEscalationPolicies
    }
  }
`
