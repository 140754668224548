import * as Icons from '../icons'

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment, useState } from 'react'
import { Report } from '@mui/icons-material'
import EventRuleOptions from '../../constants/EventRuleOptions'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import { styles } from '../../constants/styles'

export default function EventRuleItem({
  eventRule,
  eventRuleIndex,
  integration,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  function renderCondition(index) {
    return (
      <Box
        className={`flex flex-0 flex-row ${classes.conditionItem} p-3 justify-between mb-4 mr-2 mt-2`}
        key={index}
      >
        <Typography className={classes.conditionType}>
          {eventRule.criteria.match === 'ANY' ? 'or' : 'and'}
        </Typography>
        <Typography className={classes.conditionText}>
          {conditionText(eventRule.criteria.conditions[index])}
        </Typography>
      </Box>
    )
  }

  function renderOpen() {
    return (
      <>
        <Box className="flex flex-row items-center justify-between">
          <Typography className={classes.subtitle}>More Conditions</Typography>
          <IconButton
            className={open ? classes.openIconButton : classes.iconButton}
            onClick={() =>
              !eventRule.isDefault ? setOpen((prevOpen) => !prevOpen) : null
            }
            size="large"
          >
            <Icons.Dots color="#fff" size={20} />
          </IconButton>
        </Box>
        <Box className="flex flex-col items-end">
          {eventRule.criteria.conditions.map(
            (_condition, index) =>
              index !== 0 && index !== 1 && renderCondition(index)
          )}
        </Box>
      </>
    )
  }

  function renderClosed() {
    return (
      <Box className="flex flex-1 flex-row justify-between items-center">
        <Box className="flex flex-1 flex-row items-center">
          <Avatar className={`${classes.conditionChip} mr-2`}>
            {`+${eventRule.criteria.conditions.length - 2}`}
          </Avatar>
          <Typography className={classes.subtitle}>
            More{' '}
            {eventRule.criteria.conditions.length - 2 === 1
              ? 'Condition'
              : 'Conditions'}
          </Typography>
        </Box>
        <IconButton
          className={open ? classes.openIconButton : classes.iconButton}
          onClick={() =>
            !eventRule.isDefault ? setOpen((prevOpen) => !prevOpen) : null
          }
          size="large"
        >
          <Icons.Dots color="#fff" size={20} />
        </IconButton>
      </Box>
    )
  }

  function conditionText(condition) {
    const inverse = condition.negateOperation
      ? EventRuleOptions.isOperations.includes(condition.operation)
        ? ' is not '
        : ' does not '
      : ' '
    const operation = condition.negateOperation
      ? EventRuleOptions.criteriaOperations
          .find((option) => option.value === condition.operation)
          .label.toLowerCase()
      : EventRuleOptions.criteriaOperationLabels
          .find((option) => option.value === condition.operation)
          .label.toLowerCase()

    return `${condition.field}${inverse}${operation} "${condition.expectedValue}"`
  }

  function operationText() {
    let operationText = _.find(EventRuleOptions.actionOptions, {
      value: eventRule.action,
    }).label

    if (eventRule.action !== 'SUPPRESS_ALERT') {
      const valueOptions =
        eventRule.action === 'SET_ALERT_ACTION'
          ? EventRuleOptions.alertActions
          : eventRule.action === 'SET_ALERT_SEVERITY'
          ? EventRuleOptions.severityOptions
          : []

      const value = _.get(
        _.find(valueOptions, { value: eventRule.value }),
        'label'
      )

      operationText = `${operationText} to ${value}`
    }

    return operationText
  }

  const ActionComponent = integration.viewerCanAdminister
    ? CardActionArea
    : Fragment

  return (
    <Card className={classes.card} key={integration.teamId} variant="outlined">
      <ActionComponent>
        <ListItem
          className="mb-2 mt-2"
          component={integration.viewerCanAdminister ? RouterLink : null}
          to={`/teams/${integration.teamId}/integrations/${integration.id}/eventRules/${eventRuleIndex}/edit`}
        >
          <Box className="flex flex-1 flex-col">
            <Box className="flex flex-1 flex-row items-center">
              <ListItemIcon className={classes.icon}>
                <Icons.ArrowDown color={styles.primaryLight.color} size={20} />
              </ListItemIcon>
              <Box className="flex flex-1 flex-row items-center">
                <Chip
                  className={classes.chip}
                  label={
                    eventRuleIndex === 0
                      ? eventRule.criteria.match === 'NONE'
                        ? 'unless'
                        : 'if'
                      : eventRule.criteria.match === 'NONE'
                      ? 'else unless'
                      : 'else if'
                  }
                />
                <Typography className={classes.title}>
                  {eventRule.criteria.conditions.length > 0 &&
                    conditionText(eventRule.criteria.conditions[0])}
                </Typography>
              </Box>

              {integration.viewerCanAdminister && (
                <Icons.ChevronRight color="#fff" size={20} />
              )}
            </Box>
            {eventRule.criteria.conditions.length > 1 && (
              <Box className="flex justify-end mr-4">{renderCondition(1)}</Box>
            )}
          </Box>
        </ListItem>
      </ActionComponent>
      {eventRule.criteria.conditions.length - 2 > 0 && (
        <>
          <Divider className={classes.topDivider} />
          <CardContent
            className={open ? classes.openContent : classes.closedContent}
          >
            {open ? renderOpen() : renderClosed()}
          </CardContent>
        </>
      )}
      <Divider className={classes.divider} />
      <CardContent className={classes.content}>
        <ListItem className={classes.listItem} disableGutters>
          <ListItemText
            classes={{
              root: classes.listItem,
              primary: classes.listItemPrimary,
              secondary: classes.listItemSecondary,
            }}
            primary="then"
            secondary={operationText()}
          />
          {eventRule.stopEvaluatingOnMatch && (
            <ListItemSecondaryAction>
              <Box className="flex flex-row items-center">
                <Report color="error" />
                <ListItemText
                  classes={{ root: classes.listItem }}
                  primary="stop on match"
                  primaryTypographyProps={{ variant: 'body2' }}
                />
              </Box>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: styles.r5Purple.color,
    borderRadius: 16,
    color: '#fff',
    marginBottom: theme.spacing(3),
  },
  chip: {
    backgroundColor: '#fff',
    color: styles.r5Purple.color,
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  conditionChip: {
    backgroundColor: styles.error.color,
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    height: 30,
    marginRight: theme.spacing(1),
    width: 30,
  },
  conditionItem: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: 10,
  },
  conditionType: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    marginRight: theme.spacing(5),
  },
  conditionText: {
    fontSize: 14,
    fontWeight: 600,
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  closedContent: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  openContent: {
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  icon: {
    minWidth: theme.spacing(3),
  },
  iconButton: {
    '&:hover': {
      backgroundColor: styles.primaryLight.color,
    },
  },
  listItem: {
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  listItemPrimary: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
  },
  listItemSecondary: {
    color: '#fff',
    fontSize: 14,
  },
  openIconButton: {
    transform: 'rotate(90deg)',
    '&:hover': {
      backgroundColor: styles.primaryLight.color,
    },
  },
  openSubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  topDivider: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))
