import React from 'react'

export default function ChevronRight({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 16.2929C6.90237 15.9024 6.90237 15.2692 7.29289 14.8787L12.2426 9.92895L7.29289 4.9792C6.90237 4.58868 6.90237 3.95551 7.29289 3.56499C7.68342 3.17446 8.31658 3.17446 8.70711 3.56499L15.0711 9.92895L8.70711 16.2929C8.31658 16.6834 7.68342 16.6834 7.29289 16.2929Z"
        fill={color}
      />
    </svg>
  )
}
