import { Box, Fade } from '@mui/material'

import ActivityIndicator from './ActivityIndicator'
import React from 'react'

export default function Loader() {
  return (
    <Box className="flex items-center justify-center mt-1/10">
      <Fade in={true} timeout={3000}>
        <ActivityIndicator />
      </Fade>
    </Box>
  )
}
