/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */

import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { MobileTimePicker } from '@mui/lab'
import { R5Input } from '../../components/shared'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'

export default function TimePeriodSettings({
  helpText,
  setTimePeriods,
  timePeriods,
  validationErrors,
}) {
  const classes = useStyles()
  const [withTimePeriods, setWithTimePeriods] = useState(
    !_.isEmpty(_.reject(timePeriods, (day) => _.isEmpty(day)))
  )
  const [withMonday, setWithMonday] = useState(!_.isEmpty(timePeriods.monday))
  const [withTuesday, setWithTuesday] = useState(
    !_.isEmpty(timePeriods.tuesday)
  )
  const [withWednesday, setWithWednesday] = useState(
    !_.isEmpty(timePeriods.wednesday)
  )
  const [withThursday, setWithThursday] = useState(
    !_.isEmpty(timePeriods.thursday)
  )
  const [withFriday, setWithFriday] = useState(!_.isEmpty(timePeriods.friday))
  const [withSaturday, setWithSaturday] = useState(
    !_.isEmpty(timePeriods.saturday)
  )
  const [withSunday, setWithSunday] = useState(!_.isEmpty(timePeriods.sunday))

  const eightAm = moment('08:00', 'HH:mm').toDate()
  const fivePm = moment('17:00', 'HH:mm').toDate()

  const timeStringToDate = ({ fromTime, toTime }) => ({
    fromTime: moment(fromTime, 'hh:mm').toDate(),
    toTime: moment(toTime, 'hh:mm').toDate(),
  })

  const [monday, setMonday] = useState(timePeriods.monday.map(timeStringToDate))
  const [tuesday, setTuesday] = useState(
    timePeriods.tuesday.map(timeStringToDate)
  )
  const [wednesday, setWednesday] = useState(
    timePeriods.wednesday.map(timeStringToDate)
  )
  const [thursday, setThursday] = useState(
    timePeriods.thursday.map(timeStringToDate)
  )
  const [friday, setFriday] = useState(timePeriods.friday.map(timeStringToDate))
  const [saturday, setSaturday] = useState(
    timePeriods.saturday.map(timeStringToDate)
  )
  const [sunday, setSunday] = useState(timePeriods.sunday.map(timeStringToDate))

  const didMountRef = useRef(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setTimePeriodsMemo = useCallback(setTimePeriods, [])

  const dateToTimeString = ({ fromTime, toTime }) => ({
    fromTime: moment(fromTime).format('HH:mm'),
    toTime: moment(toTime).format('HH:mm'),
  })

  useEffect(() => {
    if (didMountRef.current) {
      setTimePeriodsMemo(
        withTimePeriods
          ? {
              monday: withMonday ? monday.map(dateToTimeString) : [],
              tuesday: withTuesday ? tuesday.map(dateToTimeString) : [],
              wednesday: withWednesday ? wednesday.map(dateToTimeString) : [],
              thursday: withThursday ? thursday.map(dateToTimeString) : [],
              friday: withFriday ? friday.map(dateToTimeString) : [],
              saturday: withSaturday ? saturday.map(dateToTimeString) : [],
              sunday: withSunday ? sunday.map(dateToTimeString) : [],
            }
          : {
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
              sunday: [],
            }
      )
    } else didMountRef.current = true
  }, [
    withMonday,
    withTuesday,
    withWednesday,
    withThursday,
    withFriday,
    withSaturday,
    withSunday,
    withTimePeriods,
    setTimePeriodsMemo,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  ])

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  function renderDay(dayString) {
    const day = eval(`${dayString.toLowerCase()}`)
    const previousDay = eval(
      `${days[
        dayString !== 'Sunday' ? days.indexOf(dayString) - 1 : 0
      ].toLowerCase()}`
    )
    const withDay = eval(`with${dayString}`)
    const setDay = eval(`set${dayString}`)
    const setWithDay = eval(`setWith${dayString}`)

    return (
      <Box className="flex flex-1 flex-col" key={dayString}>
        <Divider />
        <FormControlLabel
          classes={{
            label: classes.checkLabel,
            labelPlacementStart: classes.checkLabelStart,
          }}
          className="flex flex-1 justify-between p-sm px-md"
          control={
            <Checkbox
              checked={withDay}
              color="primary"
              name={`with-${dayString}`}
              onChange={({ target: { checked } }) => {
                if (dayString !== 'Sunday') {
                  setWithDay(checked)
                  setDay([
                    {
                      fromTime: _.last(previousDay)?.fromTime || eightAm,
                      toTime: _.last(previousDay)?.toTime || fivePm,
                    },
                  ])
                } else {
                  setWithDay(checked)
                  setDay([{ fromTime: eightAm, toTime: fivePm }])
                }
              }}
            />
          }
          label={dayString}
          labelPlacement="start"
        />
        {validationErrors[`timePeriods.${dayString.toLowerCase()}`] && (
          <FormHelperText error>
            {validationErrors[`timePeriods.${dayString.toLowerCase()}`]}
          </FormHelperText>
        )}
        {withDay && (
          <>
            <Divider />
            <Paper elevation={0} className={classes.paper} square>
              {day.map(({ fromTime, toTime }, index) => (
                <Paper
                  className="flex flex-row mb-md p-md justify-between items-start border"
                  elevation={0}
                  key={index}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <FromTime
                        dayString={dayString}
                        fromTime={fromTime}
                        toTime={toTime}
                        index={index}
                        setDay={setDay}
                        validationErrors={validationErrors}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ToTime
                        dayString={dayString}
                        fromTime={fromTime}
                        toTime={toTime}
                        index={index}
                        setDay={setDay}
                        validationErrors={validationErrors}
                      />
                    </Grid>
                  </Grid>
                  {day.length > 1 && (
                    <IconButton
                      onClick={() =>
                        setDay((prevDay) => {
                          const newDay = [...prevDay]
                          newDay.splice(index, 1)
                          return newDay
                        })
                      }
                      size="small"
                    >
                      <Icons.MinusCircleOutline
                        color={styles.error.color}
                        size={20}
                      />
                    </IconButton>
                  )}
                </Paper>
              ))}
              <Button
                onClick={() =>
                  setDay((prevDay) => {
                    const newDay = [...prevDay]
                    newDay.push({ fromTime: eightAm, toTime: fivePm })
                    return newDay
                  })
                }
                variant="outlined"
              >
                + Add Time
              </Button>
            </Paper>
          </>
        )}
      </Box>
    )
  }

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        title={
          <FormControl classes={{ root: classes.root }}>
            <FormControlLabel
              classes={{
                label: classes.checkLabel,
                labelPlacementStart: classes.checkLabelStart,
              }}
              className="flex flex-1 justify-between"
              control={
                <Checkbox
                  checked={withTimePeriods}
                  color="primary"
                  onChange={({ target: { checked } }) => {
                    setWithTimePeriods(checked)
                  }}
                  name="with-time-periods"
                />
              }
              label="Time periods"
              labelPlacement="start"
            />
          </FormControl>
        }
      />
      {helpText && (
        <Typography className={classes.subTitle}>{helpText}</Typography>
      )}
      <CardContent className={classes.content}>
        {withTimePeriods && days.map(renderDay)}
      </CardContent>
    </Card>
  )
}

function FromTime({
  dayString,
  fromTime,
  toTime,
  index,
  setDay,
  validationErrors,
}) {
  const classes = useStyles()
  return (
    <FormControl>
      <InputLabel
        className={classes.label}
        error={_.some(
          validationErrors[`timePeriods.${dayString.toLowerCase()}`]
        )}
      >
        From
      </InputLabel>
      <MobileTimePicker
        minutesStep={15}
        onChange={(date) =>
          setDay((prevDay) => {
            const newDay = [...prevDay]
            newDay[index] = { fromTime: date, toTime }
            return newDay
          })
        }
        OpenPickerButtonProps={{ color: 'primary' }}
        renderInput={({ inputProps, ...restProps }) => {
          const date = moment(inputProps.value, 'HH:mm A')
          return (
            <R5Input
              inputProps={{
                ...inputProps,
                value:
                  date.hour() === 0 && date.minute() === 0
                    ? 'Start of day'
                    : date.format('LT'),
              }}
              {...restProps}
            />
          )
        }}
        value={fromTime}
      />
    </FormControl>
  )
}

function ToTime({
  dayString,
  fromTime,
  toTime,
  index,
  setDay,
  validationErrors,
}) {
  const classes = useStyles()
  return (
    <FormControl>
      <InputLabel
        className={classes.label}
        error={_.some(
          validationErrors[`timePeriods.${dayString.toLowerCase()}`]
        )}
      >
        To
      </InputLabel>
      <MobileTimePicker
        minutesStep={15}
        onChange={(date) =>
          setDay((prevDay) => {
            const newDay = [...prevDay]
            newDay[index] = { fromTime, toTime: date }
            return newDay
          })
        }
        OpenPickerButtonProps={{ color: 'primary' }}
        renderInput={({ inputProps, ...restProps }) => {
          const date = moment(inputProps.value, 'HH:mm A')
          return (
            <R5Input
              inputProps={{
                ...inputProps,
                value:
                  date.hour() === 0 && date.minute() === 0
                    ? 'End of day'
                    : `${date.format('LT')} ${
                        toTime < fromTime &&
                        !(date.hour() === 0 && date.minute() === 0)
                          ? '(next day)'
                          : ''
                      }`,
              }}
              {...restProps}
            />
          )
        }}
        value={toTime}
      />
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    marginTop: theme.spacing(3),
  },
  checkLabel: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  checkLabelStart: {
    marginLeft: 0,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    paddingBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  paper: {
    backgroundColor: styles.well.backgroundColor,
    padding: theme.spacing(3),
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 12,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(10),
    marginBottom: theme.spacing(2),
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  root: {
    display: 'flex',
    flex: 1,
  },
}))
