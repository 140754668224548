import React from 'react'

export default function Pencil({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3731 2L17.5632 7.19016L7.19016 17.5632H2L2 12.3731L12.3731 2ZM12.3731 5.27455L14.2887 7.19016L6.23107 15.2478H4.31546V13.3322L12.3731 5.27455Z"
        fill={color}
      />
    </svg>
  )
}
