import {
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Link,
  Typography,
} from '@mui/material'
import { R5Input, R5Title } from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, navigate } from '@reach/router'

import { Auth } from 'aws-amplify'
import { Logo, SignInFront, SignInBack } from '../../components/illustrations'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useJunkDrawer } from '../../context/junkDrawer'

export default function SignUpConfirmPage({ location }) {
  const classes = useStyles()
  const [code, setCode] = useState('')
  const urlParams = new URLSearchParams(location.search)
  const email = urlParams.get('email')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const { signIn } = useCurrents()
  const { junkDrawer, updateJunkDrawer } = useJunkDrawer()

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission

    setLoading(true)

    try {
      const tempPassword = junkDrawer.tempPassword
      updateJunkDrawer('tempPassword', null)

      await Auth.confirmSignUp(email, code, {})
      if (tempPassword) await signIn(email, tempPassword)

      navigate('/welcome')
    } catch (err) {
      setLoading(false)
      setError(err.message)
    }
  }

  async function handleResend() {
    setResendLoading(true)

    try {
      await Auth.resendSignUp(email)
      setResendLoading(false)
      setError(`Verification code resent to ${email}`)
    } catch (err) {
      setResendLoading(false)
      setError(err.message)
    }
  }

  return (
    <Container className="flex w-full items-center h-full justify-between p-0 max-w-[100%] min-h-[100vh]">
      <R5Title title="Confirm your account" />
      <Box className={classes.imgCol}>
        <img
          className={classes.frontImg}
          src={SignInFront}
          alt="Illustration of a phone with the Ready Five UI on the screen."
        />
        <img
          className={classes.backImg}
          src={SignInBack}
          alt="Background illustration of a futuristic city."
        />
      </Box>
      <Box style={{ margin: '0 auto', maxWidth: '400px' }}>
        <CardContent className={classes.content}>
          <Box>
            <Logo
              className={classes.logo}
              color={styles.primary.color}
              size={65}
            />
            <Typography className={classes.title}>Check your email</Typography>
            {error && (
              <Typography className={classes.error}>{error}</Typography>
            )}
          </Box>
          <form onSubmit={handleSubmit}>
            <Typography className={classes.subtitle} paragraph>
              We sent an email with a 6-digit code to <b>{email}</b>. Enter the
              code here to verify that address.
            </Typography>
            <R5Input
              autoFocus
              onChange={setCode}
              placeholder="Confirmation code"
              required
              value={code}
              fullWidth
            />
            <Button
              className={classes.link}
              disabled={resendLoading}
              onClick={handleResend}
            >
              {resendLoading ? (
                <CircularProgress color="inherit" size={21} />
              ) : (
                'Resend Confirmation Code'
              )}
            </Button>
            <Button
              classes={{
                disabled: classes.disabled,
                root: classes.button,
              }}
              disabled={loading || _.isEmpty(code)}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {loading ? (
                <CircularProgress color="inherit" size={21} />
              ) : (
                'Confirm Code'
              )}
            </Button>
          </form>
          <CardActions className="flex flex-1 justify-center">
            <Link
              className={classes.link}
              color="primary"
              component={RouterLink}
              to="/login"
              underline="hover"
            >
              Back to sign in
            </Link>
          </CardActions>
        </CardContent>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 800,
    marginTop: theme.spacing(3),
    textTransform: 'uppercase',
  },
  card: {
    borderRadius: theme.spacing(1),
    minHeight: 556,
  },
  disabled: {
    backgroundColor: `${styles.primaryLight.color} !important`,
    color: 'white !important',
  },
  error: {
    backgroundColor: styles.error.backgroundColor,
    borderColor: styles.error.color,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    color: 'white',
    marginTop: 20,
    padding: 12,
    width: '100%',
  },
  link: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: 50,
    fontWeight: 600,
    lineHeight: '107.9%',
    textAlign: 'left',
  },
  imgCol: {
    height: '100vh',
    width: '50%',
    backgroundColor: '#4147EB',
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
    '@media (max-width: 925px)': {
      display: 'none',
    },
  },
  backImg: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: '0',
  },
  frontImg: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '-350px',
    top: '50%',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: '60%',
    minWidth: '580px',
    display: 'block',
  },
}))
