import { AllUsers, DeactivatedUsers, Invited } from '../../components/Users'
import {
  R5AddButton,
  R5ButtonGroup,
  R5Container,
  R5Header,
  R5Search,
  R5Title,
} from '../../components/shared'
import React, { useEffect, useState } from 'react'
import { useBack, useQueryParams } from '../../hooks'

import { Typography } from '@mui/material'
import _ from 'lodash'
import { useCurrents } from '../../context/currents'

export default function UsersPage() {
  useBack(null)
  const { account, user } = useCurrents()
  let { scope: queryScope } = useQueryParams()
  const [scope, setScope] = useState()
  const [search, setSearch] = useState('')

  useEffect(() => {
    let viewableScopes = ['all']
    let currentScope = queryScope

    if (account.viewerCanInviteUsers)
      viewableScopes = _.concat(viewableScopes, ['invited', 'deactivated'])

    if (!viewableScopes.includes(queryScope)) currentScope = 'all'

    localStorage.setItem('@filterState/users', `?q=scope:${currentScope}`)
    setScope(currentScope)
  }, [account.viewerCanInviteUsers, user.role, queryScope])

  function userButtons() {
    let buttons = [
      {
        label: 'Invited',
        to: '?q=scope:invited',
        value: 'invited',
      },
      {
        label: 'All',
        to: '?q=scope:all',
        value: 'all',
      },
    ]

    if (scope === 'deactivated')
      buttons.push({
        label: 'Deactivated',
        to: '?q=scope:deactivated',
        value: 'deactivated',
      })

    return buttons
  }

  return (
    <R5Container>
      <R5Title title="Users" />
      <R5Header
        addText="Invite User"
        addUrl={account.viewerCanInviteUsers ? '/users/invite' : null}
        helpText={
          <Typography paragraph>
            You can invite one user at a time by entering their email address
            and specifying their account level role. We'll send them an email
            they can use to accept and setup their account. Once they accept,
            you can begin adding them to teams, scheduling their shifts, and
            they can configure their contact methods and notification rules.
          </Typography>
        }
        helpUrl="https://www.readyfive.io/docs/users/adding-and-removing"
        title="Users"
      >
        {account.viewerCanInviteUsers && (
          <R5ButtonGroup
            buttons={userButtons()}
            saveState="@filterState/users"
            selectedValue={scope}
          />
        )}

        <R5Search onChange={setSearch} value={search} />
      </R5Header>

      {scope === 'deactivated' ? (
        <DeactivatedUsers search={search} />
      ) : scope === 'invited' ? (
        <Invited search={search} />
      ) : (
        <AllUsers search={search} />
      )}

      {account.viewerCanInviteUsers && <R5AddButton to="/users/invite" />}
    </R5Container>
  )
}
