import * as Icons from '../icons'

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import {
  ReassignIncidentModal,
  RedactIncidentModal,
} from '../../components/Incidents'

import ActionButton from './ActionButton'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'

export default function IncidentItem({ incident }) {
  const classes = useStyles()
  const [showEditIncidentModal, setShowEditIncidentModal] = useState(false)

  function color() {
    switch (incident.status) {
      case 'ACKNOWLEDGED':
        return '#FFAB2D'
      case 'SNOOZED':
        return '#9BAFC6'
      case 'TRIGGERED':
        return '#EB5757'
      case 'REDACTED':
      case 'RESOLVED':
      default:
        return '#6ED76C'
    }
  }

  function renderFooterText() {
    switch (incident.status) {
      case 'ACKNOWLEDGED':
      case 'SNOOZED':
        return `Triggers again ${moment
          .unix(incident.triggerAt)
          .format('M/D/YY LT')}`
      case 'REDACTED':
      case 'RESOLVED':
        return `${_.capitalize(incident.status)} ${moment
          .unix(incident.updatedAt)
          .format('M/D/YY LT')}`
      case 'TRIGGERED':
        return `Unacknowleged for ${moment
          .unix(incident.lastTriggeredAt)
          .fromNow(true)}`
      default:
        return null
    }
  }

  function IconFromStatus() {
    switch (incident.status) {
      case 'ACKNOWLEDGED':
        return Icons.ExclamationCircle
      case 'REDACTED':
        return Icons.RedactedCircle
      case 'SNOOZED':
        return Icons.ClockCircle
      case 'TRIGGERED':
        return Icons.BellCircle
      case 'RESOLVED':
      default:
        return Icons.CheckCircle
    }
  }

  const StatusIcon = IconFromStatus()

  const canPerformActions =
    (incident.viewerCanAcknowledge ||
      incident.viewerCanSnooze ||
      incident.viewerCanResolve ||
      incident.viewerCanReassign ||
      incident.viewerCanRedact) &&
    incident.status !== 'REDACTED'

  return (
    <Card className={classes.root} variant="outlined">
      <CardActionArea component={RouterLink} to={`/incidents/${incident.id}`}>
        <CardContent className={classes.cardContent}>
          <Box className="flex flex-1 flex-row justify-between">
            <Box className="flex flex-1 flex-row">
              <Box className="flex mr-2">
                <StatusIcon color={color()} size={32} />
              </Box>
              <Box className="flex flex-1 flex-col mr-sm">
                <Typography className={classes.title}>
                  {incident.title}
                </Typography>
                <Typography
                  className={classes.subtitle}
                  color="textSecondary"
                >{`${moment.unix(incident.createdAt).format('M/D/YY LT')} by ${
                  incident.createdBy.name
                }`}</Typography>
              </Box>
            </Box>

            <Box>
              <Chip
                color="primary"
                label={`#${incident.number}`}
                style={{
                  backgroundColor: color(),
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
              />
            </Box>
          </Box>
          <Box className="flex flex-1 flex-row items-center mt-3">
            <Typography
              className={`${classes.subtitle} pr-1`}
              color="textSecondary"
            >
              Assigned to:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                '& > *': { margin: 0.5 },
              }}
            >
              {incident.assignedTo.map((item) => (
                <Chip
                  classes={{
                    label: classes.chipLabel,
                    root: classes.chipRoot,
                  }}
                  key={item.id}
                  label={
                    <ListItem component="span" disableGutters dense>
                      <ListItemIcon className={classes.icon}>
                        <Box
                          className={classes.avatar}
                          style={{ backgroundColor: item.accentColor }}
                        />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.primary }}>
                        {item.name}
                      </ListItemText>
                    </ListItem>
                  }
                />
              ))}
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
      <Divider className={classes.divider} />
      <CardActions className={classes.cardActions}>
        <Box className="flex flex-1 flex-row items-center justify-between">
          <Typography className={classes.footer} color="textSecondary">
            {renderFooterText()}
          </Typography>
          {canPerformActions && (
            <ActionButton
              incident={incident}
              setShowEditIncidentModal={setShowEditIncidentModal}
            />
          )}
        </Box>
      </CardActions>
      {incident.status === 'RESOLVED' ? (
        <RedactIncidentModal
          incident={incident}
          setShowEditIncidentModal={setShowEditIncidentModal}
          showEditIncidentModal={showEditIncidentModal}
        />
      ) : (
        <ReassignIncidentModal
          incident={incident}
          setShowEditIncidentModal={setShowEditIncidentModal}
          showEditIncidentModal={showEditIncidentModal}
        />
      )}
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 10,
    height: 10,
    width: 10,
  },
  cardContent: {
    paddingBottom: theme.spacing(1),
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: 0,
  },
  chipContainerRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chipRoot: {
    backgroundColor: styles.well.backgroundColor,
    cursor: 'pointer',
  },
  chipLabel: {
    color: styles.label.color,
    fontWeight: 'bold',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  footer: {
    fontSize: 14,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25),
  },
  icon: {
    minWidth: theme.spacing(2),
  },
  primary: {
    fontSize: 14,
    fontWeight: 600,
  },
  root: {
    borderRadius: 15,
    boxShadow: '0px 10px 12px rgba(0, 0, 0, 0.05)',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontWeight: 'bold',
    maxWidth: 1000,
  },
}))
