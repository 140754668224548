import React from 'react'
import { Redirect } from '@reach/router'
import { useCurrents } from '../../context/currents'

const PublicOnlyRoute = ({ component: Component, location, ...rest }) => {
  const { userId } = useCurrents()

  if (userId) return <Redirect to="/" noThrow />
  return <Component location={location} {...rest} />
}

export default PublicOnlyRoute
