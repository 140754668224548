import * as Icons from '../../components/icons'

import { AlertDialog, R5TextField } from '../../components/shared'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function EditIncidentModal({
  incident,
  setShowEditIncidentModal,
  showEditIncidentModal,
}) {
  const classes = useStyles()
  const redactMutation = useMutation(REDACT_INCIDENT)
  const [confirmRedact, setConfirmRedact] = useState(false)
  const [reason, setReason] = useState('')

  const handleSubmit = async () => {
    try {
      await redactMutation({
        incidentId: incident.id,
        reason,
      })
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function handleClose() {
    setShowEditIncidentModal(false)
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row my-sm justify-end">
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            onClick={handleClose}
            size="large"
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              setConfirmRedact(true)
              handleClose()
            }}
            size="large"
            variant="contained"
          >
            Redact
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Dialog
        aria-labelledby="add-integration-dialog-title"
        aria-describedby="add-integration-dialog-description"
        fullWidth
        onClose={handleClose}
        open={showEditIncidentModal}
        maxWidth="xs"
      >
        <DialogTitle id="add-integration-dialog-title">
          <Box className="flex flex-row justify-between items-center">
            <Typography style={styles.headerTitleStyle} variant="h4">
              Redact Incident
            </Typography>
            <IconButton onClick={handleClose} size="small">
              <Icons.Close color={styles.primary.color} size={20} />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent className="flex flex-col p-xl mb-md">
          <R5TextField
            label="Reason"
            onChange={setReason}
            placeholder="Optional"
            value={incident.reason}
          />
        </DialogContent>
        <Divider />
        <DialogActions className={classes.actions}>
          {renderFooter()}
        </DialogActions>
      </Dialog>
      <AlertDialog
        content="Are you sure you want to redact this incident? This cannot be undone. This will wipe potentially sensitive information from user visibility and from our servers."
        handleClose={() => setConfirmRedact(false)}
        handleConfirm={() => {
          setConfirmRedact(false)
          handleSubmit()
        }}
        open={confirmRedact}
        title="Redact Incident"
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}))

const REDACT_INCIDENT = gql`
  mutation RedactIncident($input: RedactIncidentInput!) {
    redactIncident(input: $input) {
      incident {
        id
        status
      }
    }
  }
`
