import * as Icons from '../icons'

import {
  Avatar,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import React, { useState } from 'react'
import { Link as RouterLink } from '@reach/router'
import { styles } from '../../constants/styles'
import GraphQLAPI from '@aws-amplify/api-graphql'
import gql from 'graphql-tag'
import { PhotoCamera } from '@mui/icons-material'
import { useCurrents } from '../../context/currents'

export default function UserHeader({ user, subtitle }) {
  const classes = useStyles()
  const { userId } = useCurrents()
  const [uploadingAvatar, setUploadingAvatar] = useState(false)

  async function uploadAvatar(file) {
    setUploadingAvatar(true)
    try {
      const createUploadResp = await GraphQLAPI.graphql(
        {
          query: FILE_UPLOAD_QUERY,
          variables: {},
        },
        { 'x-ready-five-account-id': user.accountId }
      )
      const fileUploadId = createUploadResp.data.createFileUpload.fileUpload.id
      const presignedUrl =
        createUploadResp.data.createFileUpload.fileUpload.presignedUrl
      if (process.env.NODE_ENV === 'development') {
        console.log('File upload ID', fileUploadId)
        console.log('Presigned URL', presignedUrl)
        console.log('File to upload', file)
      }

      const resp = await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'content-disposition': `attachment; filename="${file.name}"`,
        },
        body: file,
      })
      if (process.env.NODE_ENV === 'development') {
        console.log('S3 response', resp)
      }

      const setAvatarResp = await GraphQLAPI.graphql(
        {
          query: SET_USER_AVATAR,
          variables: {
            input: {
              userId: user.id,
              fileUploadId,
            },
          },
        },
        { 'x-ready-five-account-id': user.accountId }
      )
      if (process.env.NODE_ENV === 'development') {
        console.log(setAvatarResp)
      }
    } catch (_) {}
    setUploadingAvatar(false)
  }

  return (
    <Paper className={classes.paper} elevation={0} square>
      <List disablePadding>
        <ListItem disableGutters>
          <ListItemAvatar>
            <div className="relative">
              <Avatar className={classes.avatar} src={user.avatarUrl}>
                {user.initials}
              </Avatar>
              {user.id === userId && ( // TODO: use user.viewerCanSetAvatar permission
                <div
                  style={{
                    position: 'absolute',
                    backgroundColor: '#fff',
                    borderRadius: '100px',
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.4)',
                    bottom: '-12px',
                    right: '20px',
                    margin: '0 auto',
                    maxWidth: '40px',
                  }}
                >
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => uploadAvatar(e.target.files[0])}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload photo"
                      component="span"
                      disabled={uploadingAvatar}
                    >
                      {uploadingAvatar ? (
                        <CircularProgress size={18} />
                      ) : (
                        <PhotoCamera />
                      )}
                    </IconButton>
                  </label>
                </div>
              )}
            </div>
          </ListItemAvatar>
          <ListItemText
            classes={{ primary: classes.primary, secondary: classes.secondary }}
            primary={user.name}
            secondary={subtitle}
          />
          {(user.viewerCanAdminister ||
            user.viewerCanChangeRole ||
            user.viewerCanDeactivate ||
            user.viewerCanReactivate) && (
            <IconButton
              component={RouterLink}
              to={
                user.viewerCanAdminister
                  ? '/profile/edit'
                  : `/users/${user.id}/edit`
              }
              size="large"
            >
              <Icons.EditBox className={classes.svg} color="#fff" />
            </IconButton>
          )}
        </ListItem>
      </List>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.common.white,
    color: styles.r5Purple.color,
    fontSize: 24,
    fontWeight: 800,
    height: 94,
    marginRight: theme.spacing(3),
    width: 94,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      height: 50,
      width: 50,
    },
  },
  paper: {
    backgroundColor: styles.r5Purple.color,
    padding: theme.spacing(4),
  },
  primary: {
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: 500,
  },
  secondary: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 500,
  },
  svg: {
    height: 35,
    width: 35,
    [theme.breakpoints.down('sm')]: {
      height: 25,
      width: 25,
    },
  },
}))

const FILE_UPLOAD_QUERY = gql`
  mutation {
    createFileUpload(input: {}) {
      fileUpload {
        id
        presignedUrl
      }
    }
  }
`

const SET_USER_AVATAR = gql`
  mutation SetUserAvatar($input: SetUserAvatarInput!) {
    setUserAvatar(input: $input) {
      file {
        id
        url
      }
    }
  }
`
