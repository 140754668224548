import React, { forwardRef } from 'react'

import { CircularProgress } from '@mui/material'

function ActivityIndicator(
  { className, color = 'primary', size = undefined, ...restProps },
  ref
) {
  return (
    <div ref={ref} {...restProps}>
      <CircularProgress className={className} color={color} size={size} />
    </div>
  )
}

export default forwardRef(ActivityIndicator)
