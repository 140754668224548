import {
  AddMembershipPage,
  CreateTeamPage,
  EditMembershipPage,
  EditTeamPage,
  TeamPage,
  TeamsPage,
} from '../../pages/Teams'
import {
  EscalationPoliciesStack,
  IntegrationsStack,
  SchedulesStack,
} from './Teams'
import { Redirect, Router } from '@reach/router'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import _ from 'lodash'
import { useCurrents } from '../../context/currents'

export default function TeamsStack({ location, ...restProps }) {
  const { account } = useCurrents()
  const path = restProps['*']
  const storedFilter = localStorage.getItem('@filterState/teams')
  const shouldRedirect = _.some(storedFilter) && _.isEmpty(location.search)

  if (!account.viewerCanCreateTeams && path.match(/^new$/))
    return <NotFoundPage />

  return (
    <Router>
      <Redirect from=":teamId" to="/teams/:teamId/coverage" noThrow />
      {shouldRedirect && (
        <Redirect from="/" to={`/teams${storedFilter}`} noThrow />
      )}
      <TeamsPage path="/" />
      <CreateTeamPage path="/new" />
      <EditTeamPage path=":teamId/edit" />
      <TeamPage path=":teamId/coverage" />
      <TeamPage path=":teamId/members" tab="members" />
      <TeamPage path=":teamId/integrations" tab="integrations" />
      <AddMembershipPage path=":teamId/members/new" />
      <EditMembershipPage path=":teamId/members/:membershipId/edit" />

      <SchedulesStack path=":teamId/schedules/*" />
      <EscalationPoliciesStack path=":teamId/escalationPolicies/*" />
      <IntegrationsStack path=":teamId/integrations/*" />

      <NotFoundPage default />
    </Router>
  )
}
