import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Hidden,
  Typography,
} from '@mui/material'
import { ConfirmButton, R5TextField } from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'

import { Schedule } from '../../components/illustrations'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation } from '../../hooks'

export default function ScheduleForm({ schedule, teamId }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const scheduleMutation = useMutation(
    schedule ? UPDATE_SCHEDULE : CREATE_SCHEDULE
  )
  const deleteMutation = useMutation(DELETE_SCHEDULE)
  const [name, setName] = useState(schedule ? schedule.name : '')
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission
    setLoading(true)

    try {
      const response = await scheduleMutation({
        teamId,
        scheduleId: schedule?.id,
        name,
      })
      setLoading(false)
      if (response.data.createSchedule) {
        navigate(
          `/teams/${teamId}/schedules/${response.data.createSchedule.schedule.id}`
        )
      } else {
        navigate(`/teams/${teamId}/schedules/${schedule.id}`)
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)
    try {
      await deleteMutation({ teamId, scheduleId: schedule.id })
      setLoading(false)
      navigate(`/teams/${teamId}/coverage`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-sm ${
          schedule && !schedule.isDefault ? 'justify-between' : 'justify-end'
        }`}
      >
        {schedule && !schedule.isDefault && (
          <ConfirmButton
            confirmContent="Are you sure you want to delete this schedule? Doing so will
          immediately remove this schedule as a target in any escalation
          policies."
            disabled={loading}
            error
            onConfirm={handleDelete}
            title="Delete Schedule"
          />
        )}
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/teams/${teamId}${
              schedule ? `/schedules/${schedule.id}` : ``
            }`}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {schedule ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Card variant="outlined">
      <form onSubmit={handleSubmit}>
        <CardContent className={classes.content}>
          <Box className="flex flex-row items-center">
            <Box className="flex flex-col mr-10">
              <R5TextField
                error={_.some(validationErrors['name'])}
                label="Schedule"
                onChange={setName}
                placeholder="Alternate"
                required
                value={name}
              />
              <Typography className={classes.subtitle}>
                A schedule is used to assign teams to shifts and determine who
                is on call and who is on duty
              </Typography>
            </Box>
            <Hidden implementation="css" smDown>
              <CardMedia
                alt="Schedules"
                component="img"
                image={Schedule}
                sx={{ width: 160 }}
              />
            </Hidden>
          </Box>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>{renderFooter()}</CardActions>
      </form>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}))

const CREATE_SCHEDULE = gql`
  mutation CreateSchedule($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      schedule {
        id
        name
      }
    }
  }
`

const UPDATE_SCHEDULE = gql`
  mutation UpdateSchedule($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      schedule {
        id
        name
      }
    }
  }
`

const DELETE_SCHEDULE = gql`
  mutation DeleteSchedule($input: DeleteScheduleInput!) {
    deleteSchedule(input: $input) {
      scheduleId
    }
  }
`
