import * as Icons from '../../components/icons'

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import ProfileOptions from '../../constants/ProfileOptions'
import { R5Tooltip } from '../../components/shared'
import { Link as RouterLink } from '@reach/router'
import { Warning } from '@mui/icons-material'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function IncidentNotificationRuleItem({
  incidentNotificationRule,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(incidentNotificationRule.isDefault)

  function stepColor() {
    return [
      styles.primary.color,
      '#FFC23F',
      '#FFA73F',
      '#FF903F',
      '#FF6D3F',
      '#FF3F62',
    ]
  }

  function colorForStep(step) {
    return stepNumber(step) > stepColor().length
      ? _.last(stepColor())
      : stepColor()[stepNumber(step) - 1]
  }

  function stepNumber(step) {
    return (
      _.findIndex(incidentNotificationRule.steps, {
        id: step.id,
      }) + 1
    )
  }

  function renderClosed() {
    return (
      <Box className="flex flex-1 flex-row justify-between items-center">
        {incidentNotificationRule.steps.length === 0 ? (
          <Typography className={classes.emptySubtitle}>
            There are no steps in this rule
          </Typography>
        ) : (
          <>
            <Box className="flex flex-1 flex-row items-center">
              <Avatar className={`${classes.chip} mr-2`}>
                {incidentNotificationRule.steps.length}
              </Avatar>
              <Typography className={classes.subtitle}>
                {incidentNotificationRule.steps.length === 1 ? 'Step' : 'Steps'}
              </Typography>
            </Box>
            <IconButton
              className={open ? classes.iconButton : ''}
              onClick={() =>
                !incidentNotificationRule.isDefault
                  ? setOpen((prevOpen) => !prevOpen)
                  : null
              }
              size="large"
            >
              <Icons.Dots color={styles.primary.color} size={20} />
            </IconButton>
          </>
        )}
      </Box>
    )
  }

  function renderOpen() {
    return incidentNotificationRule.steps.length === 0 ? (
      <Typography className={classes.emptyOpenSubtitle}>
        There are no steps in this rule
      </Typography>
    ) : (
      <>
        {!incidentNotificationRule.isDefault && (
          <Box className="flex flex-row items-center justify-between">
            <Typography className={classes.subtitle}>Steps</Typography>
            <IconButton
              className={open ? classes.iconButton : ''}
              onClick={() =>
                !incidentNotificationRule.isDefault
                  ? setOpen((prevOpen) => !prevOpen)
                  : null
              }
              size="large"
            >
              <Icons.Dots color={styles.primary.color} size={20} />
            </IconButton>
          </Box>
        )}
        {incidentNotificationRule.steps.map((step, index) => (
          <Box
            className={
              incidentNotificationRule.isDefault && index === 0 ? 'mt-sm' : null
            }
            key={step.id}
          >
            <Box className="flex flex-1 justify-between">
              <Box className="flex flex-1 items-center">
                <Box
                  className={classes.accentColor}
                  style={{ backgroundColor: colorForStep(step) }}
                />
                <Typography className={classes.title}>
                  {parseInt(step.delayInMinutes) === 0
                    ? 'Immediately'
                    : _.find(ProfileOptions.delayInMinutes, {
                        value: step.delayInMinutes,
                      }).label}
                </Typography>
              </Box>
            </Box>
            {step.contactMethods.nodes.map((contactMethod, i) => (
              <Box
                className="mt-sm ml-lg"
                key={`${step.id}-${contactMethod.id}`}
              >
                {i !== 0 && (
                  <Typography
                    className={`${classes.subtitle} mb-sm`}
                    color="textSecondary"
                  >
                    AND
                  </Typography>
                )}
                <Box className="flex flex-row justify-between items-center">
                  <Box>
                    <Typography
                      className={classes.subtitle}
                      color="textSecondary"
                    >
                      {_.find(ProfileOptions.actions, {
                        value: contactMethod.type,
                      })?.label || contactMethod.type}
                    </Typography>
                    <Typography className={classes.title}>
                      {contactMethod.to}
                    </Typography>
                  </Box>
                  <CriticalAlertIconWithTooltip
                    target={_.find(step.targets, { id: contactMethod.id })}
                  />
                </Box>
              </Box>
            ))}
            {index !== incidentNotificationRule.steps.length - 1 && (
              <Divider className={classes.stepDivider} />
            )}
          </Box>
        ))}
      </>
    )
  }

  const isrestrictedToTimePeriods = !_.isEmpty(
    _.reject(incidentNotificationRule.timePeriods, _.isEmpty)
  )

  return (
    <Card
      className={classes.card}
      key={incidentNotificationRule.id}
      variant="outlined"
    >
      <CardActionArea tabIndex={-1}>
        <ListItem
          className="my-sm"
          component={RouterLink}
          to={`/profile/rules/${incidentNotificationRule.id}`}
        >
          <ListItemText
            classes={{
              primary: classes.primary,
              secondary: classes.secondary,
            }}
            primary={`${incidentNotificationRule.name} ${
              incidentNotificationRule.isDefault &&
              incidentNotificationRule.name !== 'Default'
                ? '(default)'
                : ''
            }`}
            secondary={
              incidentNotificationRule.repeatAfterInMinutes > 0
                ? `Repeats every ${
                    incidentNotificationRule.repeatAfterInMinutes
                  } ${
                    incidentNotificationRule.repeatAfterInMinutes === 1
                      ? 'minute'
                      : 'minutes'
                  } until acknowledgement`
                : null
            }
          />
          <Box className="flex items-center">
            {isrestrictedToTimePeriods && (
              <Box className="mr-md">
                <Icons.Clock color={styles.lightSubtitle.color} size={30} />
              </Box>
            )}
            <Icons.ChevronRight color={styles.lightLabel.color} size={20} />
          </Box>
        </ListItem>
      </CardActionArea>
      <Divider className={classes.divider} />
      <CardContent className={open ? classes.openContent : classes.content}>
        {open ? renderOpen() : renderClosed()}
      </CardContent>
    </Card>
  )
}

function CriticalAlertIconWithTooltip({ target }) {
  const [showTooltip, setShowTooltip] = useState(false)

  if (!target.sendCriticalAlert) {
    return null
  }

  return (
    <R5Tooltip
      open={showTooltip}
      placement="left"
      setOpen={setShowTooltip}
      title={`Sends a critical alert (${target.criticalAlertVolume}% volume)`}
    >
      <IconButton onClick={() => setShowTooltip(!showTooltip)} size="small">
        <Warning style={{ color: styles.error.color }} size={25} />
      </IconButton>
    </R5Tooltip>
  )
}

const useStyles = makeStyles((theme) => ({
  accentColor: {
    borderRadius: 14,
    height: 14,
    marginRight: theme.spacing(1),
    width: 14,
  },
  chip: {
    backgroundColor: styles.primary.color,
    height: 30,
    fontSize: 14,
    fontWeight: 800,
    width: 30,
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(3),
  },
  content: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  openContent: {
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  iconButton: {
    transform: 'rotate(90deg)',
  },
  openSubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  stepDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
  },
  emptySubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  emptyOpenSubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
    marginTop: theme.spacing(1),
  },
  primary: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  secondary: {
    fontWeight: 'bold',
  },
}))
