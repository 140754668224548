import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import {
  ConfirmButton,
  R5Input,
  R5TextField,
  TimePeriodSettings,
} from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'

import ProfileOptions from '../../constants/ProfileOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function IncidentNotificationRuleForm({
  incidentNotificationRule,
  userId,
}) {
  const classes = useStyles()
  const navigate = useNavigate()
  const incidentNotificationRuleMutation = useMutation(
    incidentNotificationRule
      ? UPDATE_INCIDENT_NOTIFICATION_RULE
      : CREATE_INCIDENT_NOTIFICATION_RULE
  )
  const deleteMutation = useMutation(DELETE_INCIDENT_NOTIFICATION_RULE)
  const [name, setName] = useState(
    incidentNotificationRule ? incidentNotificationRule.name : ''
  )
  const [repeatAfterInMinutes, setRepeatAfterInMinutes] = useState(
    incidentNotificationRule ? incidentNotificationRule.repeatAfterInMinutes : 1
  )
  const extractTimes = ({ fromTime, toTime }) => ({
    fromTime,
    toTime,
  })
  const [timePeriods, setTimePeriods] = useState({
    monday:
      incidentNotificationRule?.timePeriods.monday.length > 0
        ? incidentNotificationRule.timePeriods.monday.map(extractTimes)
        : [],
    tuesday:
      incidentNotificationRule?.timePeriods.tuesday.length > 0
        ? incidentNotificationRule.timePeriods.tuesday.map(extractTimes)
        : [],
    wednesday:
      incidentNotificationRule?.timePeriods.wednesday.length > 0
        ? incidentNotificationRule.timePeriods.wednesday.map(extractTimes)
        : [],
    thursday:
      incidentNotificationRule?.timePeriods.thursday.length > 0
        ? incidentNotificationRule.timePeriods.thursday.map(extractTimes)
        : [],
    friday:
      incidentNotificationRule?.timePeriods.friday.length > 0
        ? incidentNotificationRule.timePeriods.friday.map(extractTimes)
        : [],
    saturday:
      incidentNotificationRule?.timePeriods.saturday.length > 0
        ? incidentNotificationRule.timePeriods.saturday.map(extractTimes)
        : [],
    sunday:
      incidentNotificationRule?.timePeriods.sunday.length > 0
        ? incidentNotificationRule.timePeriods.sunday.map(extractTimes)
        : [],
  })
  const [validationErrors, setValidationErrors] = useState([])
  const [withRepeat, setWithRepeat] = useState(
    incidentNotificationRule
      ? incidentNotificationRule.repeatAfterInMinutes > 0
      : false
  )
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission

    setLoading(true)

    try {
      const response = await incidentNotificationRuleMutation({
        userId,
        incidentNotificationRuleId: incidentNotificationRule?.id,
        name,
        repeatAfterInMinutes: withRepeat ? repeatAfterInMinutes : 0,
        timePeriods,
      })

      setLoading(false)
      if (response.data.createIncidentNotificationRule) {
        navigate(
          `/profile/rules/${response.data.createIncidentNotificationRule.incidentNotificationRule.id}`
        )
      } else {
        navigate(`/profile/rules/${incidentNotificationRule.id}`)
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)

    try {
      await deleteMutation({
        userId,
        incidentNotificationRuleId: incidentNotificationRule.id,
      })
      setLoading(false)
      navigate('/profile/notifications')
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-xxl ${
          incidentNotificationRule && !incidentNotificationRule.isDefault
            ? 'justify-between'
            : 'justify-end'
        }`}
      >
        {incidentNotificationRule && !incidentNotificationRule.isDefault && (
          <ConfirmButton
            confirmContent="Are you sure you want to remove this Notification Rule?"
            error
            onConfirm={handleDelete}
            title="Delete Notification Rule"
          />
        )}

        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={
              incidentNotificationRule
                ? `/profile/rules/${incidentNotificationRule.id}`
                : `/profile/notifications`
            }
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {incidentNotificationRule ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      <R5TextField
        error={_.some(validationErrors['name'])}
        label="Name"
        onChange={setName}
        placeholder="Alternate"
        required
        value={name}
      />
      <Card className={classes.card} variant="outlined">
        <CardHeader
          title={
            <FormControl classes={{ root: classes.root }}>
              <FormControlLabel
                classes={{
                  label: classes.label,
                  labelPlacementStart: classes.checkLabelStart,
                }}
                className="flex flex-1 justify-between items-center"
                control={
                  <Checkbox
                    checked={withRepeat}
                    color="primary"
                    onChange={({ target: { checked } }) => {
                      setWithRepeat(checked)
                      if (checked && repeatAfterInMinutes === 0)
                        setRepeatAfterInMinutes(1)
                    }}
                    name="with-repeat"
                  />
                }
                label="Repeat"
                labelPlacement="start"
              />
            </FormControl>
          }
        />

        {withRepeat && (
          <>
            <Divider />
            <CardContent className={classes.cardContent}>
              <Box className="flex flex-row justify-between">
                <ListItemText
                  classes={{
                    primary: classes.label,
                    secondary: classes.secondary,
                  }}
                  primary="After"
                  secondary="We’ll repeat all steps up to 10 times"
                />
                <Select
                  id="repeat-after-in-minutes"
                  input={<R5Input />}
                  onChange={setRepeatAfterInMinutes}
                  value={repeatAfterInMinutes}
                >
                  {ProfileOptions.repeatAfterInMinutes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </CardContent>
          </>
        )}
      </Card>

      {incidentNotificationRule && !incidentNotificationRule?.isDefault && (
        <TimePeriodSettings
          helpText="Configure time periods on a notification rule to be notified using its steps during specific days of the week and times of day. Rules with time periods take precedence over your default rule."
          setTimePeriods={setTimePeriods}
          timePeriods={timePeriods}
          validationErrors={validationErrors}
        />
      )}
      {renderFooter()}
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  checkLabelStart: {
    marginLeft: 0,
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  label: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  primary: {
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  secondary: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  root: {
    display: 'flex',
    flex: 1,
  },
}))

const CREATE_INCIDENT_NOTIFICATION_RULE = gql`
  mutation CreateIncidentNotificationRule(
    $input: CreateIncidentNotificationRuleInput!
  ) {
    createIncidentNotificationRule(input: $input) {
      incidentNotificationRule {
        id
        name
      }
    }
  }
`

const UPDATE_INCIDENT_NOTIFICATION_RULE = gql`
  mutation UpdateIncidentNotificationRule(
    $input: UpdateIncidentNotificationRuleInput!
  ) {
    updateIncidentNotificationRule(input: $input) {
      incidentNotificationRule {
        id
        name
        repeatAfterInMinutes
        timePeriods {
          monday {
            fromTime
            toTime
          }
          tuesday {
            fromTime
            toTime
          }
          wednesday {
            fromTime
            toTime
          }
          thursday {
            fromTime
            toTime
          }
          friday {
            fromTime
            toTime
          }
          saturday {
            fromTime
            toTime
          }
          sunday {
            fromTime
            toTime
          }
        }
        # steps {
        #   id
        #   delayInMinutes
        #   contactMethodIds
        # }
      }
    }
  }
`
const DELETE_INCIDENT_NOTIFICATION_RULE = gql`
  mutation DeleteIncidentNotificationRule(
    $input: DeleteIncidentNotificationRuleInput!
  ) {
    deleteIncidentNotificationRule(input: $input) {
      incidentNotificationRuleId
    }
  }
`
