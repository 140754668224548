import * as Icons from '../icons'

import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material'
import { FileCopy, GetApp, RssFeed } from '@mui/icons-material'
import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'

import { copyToClipboard } from '../../utils'
import { styles } from '../../constants/styles'
import { R5Infotip } from '../shared'

export default function RssModal({ setShowRssModal, showRssModal, viewer }) {
  const classes = useStyles()
  const [copied, setCopied] = useState(false)
  const [showICalLink, setShowICalLink] = useState(false)
  const [showWebCalLink, setShowWebCalLink] = useState(false)

  function handleClose() {
    setShowRssModal(false)
  }

  let url = viewer.calendars.onCall.url
  if (showRssModal === 'on-duty') {
    url = viewer.calendars.onDuty.url
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row my-sm justify-end">
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            color="primary"
            onClick={handleClose}
            size="large"
          >
            Close
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Dialog
      aria-labelledby="add-integration-dialog-title"
      aria-describedby="add-integration-dialog-description"
      fullWidth
      onClose={handleClose}
      open={showRssModal !== false}
      maxWidth="xs"
    >
      <DialogTitle id="add-integration-dialog-title">
        <Box className="flex flex-row justify-between items-center">
          <Typography style={styles.headerTitleStyle} variant="h4">
            Subscribe to {showRssModal} calendar feed
            <R5Infotip
              learnMore="https://www.readyfive.io/docs/users/calendar-feed"
              title={
                <>
                  <Typography paragraph>
                    You can subscribe to your on call or on duty coverage in
                    your favorite calendar application.
                  </Typography>
                </>
              }
            />
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Icons.Close color={styles.primary.color} size={20} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className="flex flex-col mb-md">
        <Box className="mt-3">
          <Typography variant="h6">WebCal Feed</Typography>
          <Typography className="mt-3" paragraph>
            The WebCal protocol allows most modern calendar applications to get
            live updates from this schedule without needing to click this link
            again.
          </Typography>
          <Box className="flex flex-row">
            <Button
              color="primary"
              component="a"
              href={url.replace('https', 'webcal')}
              startIcon={<RssFeed />}
              variant="outlined"
            >
              Open WebCal
            </Button>
            <Button
              color="primary"
              className="ml-sm"
              onClick={() =>
                setShowWebCalLink((prevShowWebCalLink) => !prevShowWebCalLink)
              }
              variant="text"
            >
              Show link
            </Button>
          </Box>

          <Collapse in={showWebCalLink}>
            <Alert
              action={
                <IconButton
                  onClick={() => {
                    copyToClipboard(url.replace('https', 'webcal'))
                    setCopied(true)
                  }}
                  size="small"
                >
                  <FileCopy style={{ fontSize: 20 }} />
                </IconButton>
              }
              className="mt-sm"
              classes={{ message: classes.message }}
              icon={false}
              severity="info"
            >
              {url.replace('https', 'webcal')}
            </Alert>
          </Collapse>
        </Box>
        <Box className="mt-3">
          <Typography variant="h6">iCalendar File</Typography>
          <Typography className="mt-3" paragraph>
            Copy this link for Google Calendar or if you want a one time export
            of this schedule click the iCalendar File button below.
          </Typography>
          <Button
            color="primary"
            component="a"
            href={url}
            startIcon={<GetApp />}
            variant="outlined"
          >
            iCalendar File
          </Button>
          <Button
            color="primary"
            className="ml-sm"
            onClick={() =>
              setShowICalLink((prevShowICalLink) => !prevShowICalLink)
            }
            variant="text"
          >
            Show link
          </Button>
          <Collapse in={showICalLink}>
            <Alert
              action={
                <IconButton
                  onClick={() => {
                    copyToClipboard(url)
                    setCopied(true)
                  }}
                  size="small"
                >
                  <FileCopy />
                </IconButton>
              }
              className="mt-sm"
              classes={{ message: classes.message }}
              icon={false}
              severity="info"
            >
              {url}
            </Alert>
          </Collapse>
          <Typography className="mt-sm" variant="body2">
            <strong>
              You will need to download this file again if you make changes to
              this schedule.
            </strong>
          </Typography>
          <Snackbar
            autoHideDuration={3000}
            onClose={() => setCopied(false)}
            open={copied}
          >
            <Alert severity="info">URL copied to clipboard.</Alert>
          </Snackbar>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actions}>
        {renderFooter()}
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  divider: {
    backgroundColor: styles.primary.color,
    height: 2,
    marginTop: theme.spacing(1.5),
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  message: {
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  secondary: {
    fontSize: 12,
    fontWeight: '600',
  },
  subtitle: {
    fontSize: 12,
  },
}))
