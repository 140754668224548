import * as Icons from '../icons'

import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material'

import { Logo } from '../../components/illustrations'
import { ProfileMenu } from '.'
import R5BackButton from './R5BackButton'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { rootScreensRegex } from '../../constants/app'
import { styles } from '../../constants/styles'
import { useJunkDrawer } from '../../context/junkDrawer'
import { useLocation } from '@reach/router'

const drawerWidth = 250

export default function R5AppBar() {
  const classes = useStyles()
  const location = useLocation()
  const { updateJunkDrawer } = useJunkDrawer()

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar className="flex justify-between">
        <Box className="flex flex-row">
          {location.pathname.match(rootScreensRegex) ? (
            <IconButton
              aria-label="menu"
              edge="start"
              onClick={() => updateJunkDrawer('mobileOpen', true)}
              className={classes.menuButton}
              size="small"
            >
              <Icons.Menu color={styles.primary.color} size={25} />
            </IconButton>
          ) : (
            <R5BackButton />
          )}
        </Box>
        <Hidden smUp implementation="css">
          <Logo color={styles.primary.color} size={35} />
        </Hidden>
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: styles.container.backgroundColor,
    borderBottomColor: '#e5e7eb',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))
