import * as Icons from '../../components/icons'

import { Alert, AlertTitle } from '@mui/material'
import { Box, Button, Hidden, IconButton, Typography } from '@mui/material'
import React, { Children, cloneElement, isValidElement, useState } from 'react'
import { Link, Link as RouterLink, useLocation } from '@reach/router'

import R5Flash from './R5Flash'
import R5Infotip from './R5Infotip'
import TrialEndingAlert from './TrialEndingAlert'
import { enterprise } from '../../utils'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useJunkDrawer } from '../../context/junkDrawer'
import { useWelcome } from '../../hooks'

export default function R5Header({
  action,
  addText,
  addUrl,
  children,
  editUrl,
  helpText,
  helpUrl,
  title,
  withTrialAlert = true,
}) {
  const classes = useStyles()
  const location = useLocation()
  const { account } = useCurrents()
  const { junkDrawer } = useJunkDrawer()
  const { showWelcomeAlert, dismissWelcomeAlert } = useWelcome()
  const [welcomeAlertVisible, setWelcomeAlertVisible] =
    useState(showWelcomeAlert)

  const childrenArray = Children.toArray(children)
  const childrenWithProps = Children.map(childrenArray, (child) =>
    isValidElement(child) ? (
      <Box className={`${child.props.className} mt-5`}>
        {cloneElement(child)}
      </Box>
    ) : null
  )

  function renderInactiveAlert() {
    const now = moment()
    const accountCreatedAt = moment.unix(account.createdAt)
    const accountCreatedAtMinutesAgo = now.diff(accountCreatedAt, 'minutes')

    return (
      !enterprise() &&
      accountCreatedAtMinutesAgo > 5 &&
      account.billingSubscriptionStatus === 'NONE' &&
      !location.pathname.match(/billing/) && (
        <Alert
          action={
            account.viewerCanManageBilling ? (
              <Button
                component={RouterLink}
                color="inherit"
                size="small"
                to="/account/billing"
              >
                RESUBSCRIBE
              </Button>
            ) : null
          }
          severity="error"
          variant="filled"
        >
          Your account is inactive. Contact your account owner to login and
          update the subscription.
        </Alert>
      )
    )
  }

  function renderDisconnectedAlert() {
    return (
      junkDrawer.disconnected && (
        <Alert
          action={
            account.viewerCanManageBilling ? (
              <Button
                color="inherit"
                onClick={() => window.location.reload()}
                size="small"
              >
                Reload
              </Button>
            ) : null
          }
          severity="warning"
          variant="filled"
        >
          No internet connection. Please reconnect and reload
        </Alert>
      )
    )
  }

  function renderWelcomeAlert() {
    return (
      welcomeAlertVisible && (
        <Alert
          action={
            <>
              <Button
                color="inherit"
                onClick={() => {
                  dismissWelcomeAlert()
                  setWelcomeAlertVisible(false)
                }}
                size="small"
              >
                Dismiss this
              </Button>
            </>
          }
          onClose={() => {}}
          severity="info"
        >
          <AlertTitle>Need help getting things setup?</AlertTitle>
          Our Welcome screen is available anytime to guide you at the bottom
          left of the sidebar.{' '}
          <Link className={classes.link} to={'/welcome'} underline="hover">
            Go there
          </Link>
        </Alert>
      )
    )
  }

  return (
    <>
      {renderInactiveAlert()}
      {withTrialAlert && <TrialEndingAlert />}
      {renderDisconnectedAlert()}
      {renderWelcomeAlert()}
      <R5Flash />
      <Box className="mt-3">
        <Box className="flex flex-row items-center justify-between flex-wrap">
          <Box className="flex items-center">
            <Typography
              noWrap
              style={{
                color: styles.headerTitleStyle.color,
                fontSize: 28,
                fontWeight: 'bold',
              }}
              variant="h4"
            >
              {title}
              {helpUrl && <R5Infotip learnMore={helpUrl} title={helpText} />}
            </Typography>
            {editUrl && (
              <IconButton
                classes={{ root: classes.marginLeft }}
                component={RouterLink}
                size="small"
                to={editUrl}
              >
                <Icons.EditBox color={styles.primary.color} size={30} />
              </IconButton>
            )}
          </Box>

          <Box className="flex flex-row">
            {action}
            {addUrl && (
              <Hidden smDown implementation="css">
                <Button
                  component={RouterLink}
                  startIcon={<Icons.Add color="#fff" size={10} />}
                  to={addUrl}
                  size="small"
                  variant="contained"
                >
                  {addText}
                </Button>
              </Hidden>
            )}
          </Box>
        </Box>
        <Box
          className={
            childrenWithProps.length === 1
              ? 'flex flex-0 mb-5'
              : 'flex flex-1 justify-between flex-wrap mb-5'
          }
        >
          {childrenWithProps}
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'underline',
  },
  marginLeft: {
    padding: 2,
    marginLeft: theme.spacing(1),
  },
}))
