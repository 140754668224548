import * as Icons from '../icons'

import { Fab, Hidden, useTheme } from '@mui/material'

import React from 'react'
import { Link as RouterLink } from '@reach/router'

export default function R5AddButton({ to }) {
  const theme = useTheme()

  return (
    <Hidden smUp implementation="css">
      <Fab
        color="primary"
        component={RouterLink}
        to={to}
        size="large"
        style={{
          bottom: theme.spacing(4),
          position: 'fixed',
          right: theme.spacing(3),
        }}
      >
        <Icons.Add color="#fff" size={15} />
      </Fab>
    </Hidden>
  )
}
