import queryString from 'query-string'
import { useLocation, useNavigate } from '@reach/router'

export default function useQueryParams() {
  const navigate = useNavigate()
  const location = useLocation()
  const query = queryString.parse(location.search)

  function removeQueryParam(param) {
    navigate(
      decodeURIComponent(queryString.exclude(location.href, [param])).replace(
        ' ',
        '+'
      ),
      true
    )
  }

  let q

  try {
    q = query?.q?.split(' ').reduce((acc, param) => {
      const [key, value] = param.split(':')
      acc[key] = value
      return acc
    }, {})
  } catch (err) {
    q = {}
  }

  return {
    ...query,
    ...q,
    removeQueryParam,
  }
}
