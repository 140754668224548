import * as Icons from '../../components/icons'

import { Box, Button, List, Typography } from '@mui/material'
import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useCallback, useEffect } from 'react'
import { useBack, useQuery } from '../../hooks'
import { ReactComponent as EmptyIncidentNotificationRuleSteps } from '../../components/illustrations/EmptyIncidentNotificationRuleSteps.svg'
import { IncidentNotificationRuleStep } from '../../components/Users'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function IncidentNotificationRulePage({
  incidentNotificationRuleId,
}) {
  useBack('/profile/notifications')
  const classes = useStyles()

  const { accountId, subscribe, userId } = useCurrents()
  const { data, errors, loading, refetch } = useQuery(QUERY, {
    incidentNotificationRuleId,
    userId,
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INCIDENT_NOTIFICATION_RULE_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        userId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, userId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INCIDENT_NOTIFICATION_RULE_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        userId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, userId, refetchMemo, subscribe])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  function renderEmpty() {
    return (
      <Box className="flex flex-col items-center justify-center mt-1/10">
        <EmptyIncidentNotificationRuleSteps />
        <Typography variant="h5" gutterBottom>
          There are no steps in this rule
        </Typography>
        <Button component={RouterLink} variant="contained" to="steps/new">
          + Add a step
        </Button>
      </Box>
    )
  }

  return (
    <R5Container>
      <R5Title
        title={`Notification Rule - ${data.incidentNotificationRule.name}`}
      />
      <R5Header
        editUrl="edit"
        title={`${data.incidentNotificationRule.name} ${
          data.incidentNotificationRule.isDefault &&
          data.incidentNotificationRule.name !== 'Default'
            ? '(default)'
            : ''
        }`}
      />
      <Box className="flex flex-row items-center justify-between">
        <Typography className={classes.subtitle}>
          Execute the following actions{' '}
          {data.incidentNotificationRule.repeatAfterInMinutes > 0
            ? `every ${data.incidentNotificationRule.repeatAfterInMinutes} ${
                data.incidentNotificationRule.repeatAfterInMinutes === 1
                  ? 'minute'
                  : 'minutes'
              } `
            : ''}
          until an assigned incident is acknowledged, snoozed, or resolved.
        </Typography>
        {!_.isEmpty(
          _.reject(
            data.incidentNotificationRule.timePeriods,
            (day) =>
              _.isNull(day) || (_.isString(day) && day.includes('TimePeriods'))
          )
        ) ? (
          <Icons.Clock color={styles.primary.color} size={30} />
        ) : null}
      </Box>

      {_.some(data.incidentNotificationRule.steps) ? (
        <>
          <List>
            {data.incidentNotificationRule.steps.map((step) => (
              <IncidentNotificationRuleStep
                incidentNotificationRule={data.incidentNotificationRule}
                key={step.id}
                step={step}
                userId={userId}
              />
            ))}
          </List>

          <Button
            component={RouterLink}
            to="steps/new"
            size="large"
            variant="outlined"
          >
            + Add a step
          </Button>
        </>
      ) : (
        renderEmpty()
      )}
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.ruleSubtitle.color,
  },
}))

const QUERY = gql`
  query IncidentNotificationRule(
    $incidentNotificationRuleId: ID!
    $userId: ID!
  ) {
    viewer {
      id
      accountId
      contactMethods(first: 30) {
        nodes {
          id
          type
          description
          to
          verifiedAt
          user {
            id
          }
        }
      }
    }
    incidentNotificationRule(id: $incidentNotificationRuleId, userId: $userId) {
      id
      name
      isDefault
      repeatAfterInMinutes
      timePeriods {
        monday {
          fromTime
          toTime
        }
        tuesday {
          fromTime
          toTime
        }
        wednesday {
          fromTime
          toTime
        }
        thursday {
          fromTime
          toTime
        }
        friday {
          fromTime
          toTime
        }
        saturday {
          fromTime
          toTime
        }
        sunday {
          fromTime
          toTime
        }
      }
      steps {
        id
        delayInMinutes
        contactMethods(first: 30) {
          nodes {
            id
            type
            description
            to
            verifiedAt
            user {
              id
            }
          }
        }
        targets {
          id
          sendCriticalAlert
          criticalAlertVolume
        }
      }
      userId
    }
  }
`

const INCIDENT_NOTIFICATION_RULE_UPDATE_SUBSCRIPTION = gql`
  subscription onIncidentNotificationRuleUpdated(
    $accountId: ID!
    $userId: ID!
  ) {
    incidentNotificationRuleUpdated(accountId: $accountId, userId: $userId) {
      id
      updatedAt
    }
  }
`

const INCIDENT_NOTIFICATION_RULE_DELETE_SUBSCRIPTION = gql`
  subscription onIncidentNotificationRuleDeleted(
    $accountId: ID!
    $userId: ID!
  ) {
    incidentNotificationRuleDeleted(accountId: $accountId, userId: $userId) {
      id
    }
  }
`
