import {
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'

import React from 'react'
import { Link as RouterLink } from '@reach/router'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import {
  coverageDateFormat,
  coverageDateFormatRange,
} from '../../utils/dateFormat'
import { Loop } from '@mui/icons-material'

export default function CoverageItem({ coverage }) {
  const classes = useStyles()
  let coverageTypeText = 'On call'
  if (coverage.coverageType === 'ON_DUTY') {
    coverageTypeText = 'On duty'
  }

  let teamWithSchedule = `${coverage.team.name} · ${coverage.schedule.name}`
  if (coverage.schedule.name === 'Default') {
    teamWithSchedule = coverage.team.name
  }

  return (
    <ListItem
      button
      component={RouterLink}
      to={`/teams/${coverage.team.id}/schedules/${coverage.schedule.id}?timelineStartsAt=${coverage.startsAt}`}
    >
      <ListItemAvatar classes={{ root: classes.avatarRoot }}>
        <div
          className={classes.avatar}
          style={{
            backgroundColor: coverage.team.accentColor,
          }}
        ></div>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <Typography className={classes.primary}>
            {teamWithSchedule}
            <Chip
              component="span"
              size="small"
              variant="outlined"
              color={
                coverage.coverageType === 'ON_CALL' ? 'primary' : 'warning'
              }
              label={coverageTypeText}
              className="ml-sm"
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                fontSize: 11,
                height: 'inherit',
                verticalAlign: 'middle',
              }}
            />
          </Typography>
        }
        secondary={
          <>
            <Typography className={classes.secondary}>
              {coverage.shift.name}:{' '}
              {coverageDateFormatRange(
                moment.unix(coverage.startsAt),
                moment.unix(coverage.endsAt)
              )}
            </Typography>
            {coverage.rotationEndsAt && (
              <>
                <Typography className={classes.secondary}>
                  <Loop
                    style={{
                      fontSize: 14,
                      verticalAlign: 'middle',
                      display: 'inline-flex',
                    }}
                  />{' '}
                  Rotation ends{' '}
                  {coverageDateFormat(moment.unix(coverage.rotationEndsAt))}
                </Typography>
              </>
            )}
            {coverage.override && (
              <Typography className={classes.secondary}>
                Covering for{' '}
                {coverage.override.fromUser
                  ? coverage.override.fromUser.name
                  : 'everyone'}
              </Typography>
            )}
          </>
        }
      />
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 14,
    height: 14,
    width: 14,
  },
  avatarRoot: {
    minWidth: theme.spacing(5),
  },
  primary: {
    fontWeight: 'bold',
  },
  secondary: {
    color: styles.lightLabel.color,
    fontWeight: 'bold',
    fontSize: 14,
  },
}))
