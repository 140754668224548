import React from 'react'

export default function ExclamationCircle({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.3 12.1L10.7 12.1L10.7 13.5L9.3 13.5L9.3 12.1ZM9.3 6.5L10.7 6.5L10.7 10.7L9.3 10.7L9.3 6.5ZM10 17C13.85 17 17 13.85 17 10C17 6.15 13.85 3 10 3C6.15 3 3 6.15 3 10C3 13.85 6.15 17 10 17Z"
        fill={color}
      />
    </svg>
  )
}
