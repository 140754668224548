import { useState } from 'react'
import EmailConfirmationForm from './EmailConfirmationForm'
import EmailUpdateForm from './EmailUpdateForm'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'
import { validEmailRegex } from '../../constants/app'

export const emailRegex = new RegExp(validEmailRegex)

export default function EmailForm() {
  const { user } = useCurrents()
  const [codeSent, setCodeSent] = useState(false)
  const [email, setEmail] = useState(user.signInEmailAddress)
  const [showEmailUpdatedDialog, setShowEmailUpdatedDialog] = useState(false)
  const emailDirty = email !== user.signInEmailAddress

  return codeSent ? (
    <EmailConfirmationForm
      email={email}
      setCodeSent={setCodeSent}
      setShowEmailUpdatedDialog={setShowEmailUpdatedDialog}
    />
  ) : (
    <EmailUpdateForm
      email={email}
      emailDirty={emailDirty}
      setCodeSent={setCodeSent}
      setEmail={setEmail}
      setShowEmailUpdatedDialog={setShowEmailUpdatedDialog}
      showEmailUpdatedDialog={showEmailUpdatedDialog}
    />
  )
}

export const useStyles = makeStyles((theme) => ({
  content: { '&:last-child': { paddingBottom: theme.spacing(2) } },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}))
