import { R5Container, R5Header, R5Title } from '../../components/shared'

import { IncidentNotificationRuleForm } from '../../components/Users'
import React from 'react'
import { useBack } from '../../hooks'
import { useCurrents } from '../../context/currents'

export default function CreateIncidetNotificationRulePage() {
  useBack('/profile/notifications')
  const { user } = useCurrents()

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Notification Rule" />
      <R5Header title="Create Notification Rule" />
      <IncidentNotificationRuleForm userId={user.id} />
    </R5Container>
  )
}
