import * as Icons from '../../components/icons'

import {
  ActionButton,
  Details,
  ReassignIncidentModal,
  RedactIncidentModal,
  Timeline,
} from '../../components/Incidents'
import {
  Avatar,
  Box,
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import {
  R5Error,
  Loader,
  R5ButtonGroup,
  R5Container,
  R5Header,
  R5Title,
  TrialEndingAlert,
} from '../../components/shared'
import { useCallback, useEffect, useState } from 'react'
import { useBack, useQuery } from '../../hooks'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function IncidentPage({ incidentId, tab = 'details' }) {
  useBack('/incidents')
  const classes = useStyles()
  const { accountId, subscribe } = useCurrents()
  const [showEditIncidentModal, setShowEditIncidentModal] = useState(false)

  const { data, errors, fetchMore, fetchingMore, loading, refetch } = useQuery(
    QUERY,
    {
      incidentId,
    }
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INCIDENT_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  if (errors) return <R5Error errors={errors} />

  function handleNext() {
    if (fetchingMore) return

    if (data.incident.events.pageInfo.hasPreviousPage) {
      fetchMore({
        variables: {
          cursor: data.incident.events.pageInfo.endCursor,
          incidentId: data.incident.id,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.incident.events.nodes.length
            ? {
                incident: {
                  ...previousResult.incident,
                  events: {
                    __typename: previousResult.incident.events.__typename,
                    nodes: [
                      ...previousResult.incident.events.nodes,
                      ...fetchMoreResult.incident.events.nodes,
                    ],
                    pageInfo: fetchMoreResult.incident.events.pageInfo,
                  },
                },
              }
            : previousResult
        },
      })
    }
  }

  function color() {
    switch (data.incident.status) {
      case 'ACKNOWLEDGED':
        return '#FFAB2D'
      case 'SNOOZED':
        return '#9BAFC6'
      case 'TRIGGERED':
        return '#EB5757'
      case 'REDACTED':
      case 'RESOLVED':
      default:
        return '#6ED76C'
    }
  }

  function renderFooterText() {
    switch (data.incident.status) {
      case 'ACKNOWLEDGED':
      case 'SNOOZED':
        return `Triggers again ${moment
          .unix(data.incident.triggerAt)
          .format('M/D/YY LT')}`
      case 'REDACTED':
      case 'RESOLVED':
        return `${_.capitalize(data.incident.status)} ${moment
          .unix(data.incident.updatedAt)
          .format('M/D/YY LT')}`
      case 'TRIGGERED':
        return `Unacknowleged for ${moment
          .unix(data.incident.lastTriggeredAt)
          .fromNow(true)}`
      default:
        return null
    }
  }

  function IconFromStatus() {
    switch (data.incident?.status) {
      case 'ACKNOWLEDGED':
        return Icons.ExclamationCircle
      case 'REDACTED':
        return Icons.RedactedCircle
      case 'SNOOZED':
        return Icons.ClockCircle
      case 'TRIGGERED':
        return Icons.BellCircle
      case 'RESOLVED':
      default:
        return Icons.CheckCircle
    }
  }

  const StatusIcon = IconFromStatus()

  const canPerformActions =
    data.incident &&
    (data.incident.viewerCanAcknowledge ||
      data.incident.viewerCanSnooze ||
      data.incident.viewerCanResolve ||
      data.incident.viewerCanReassign ||
      data.incident.viewerCanRedact) &&
    data.incident.status !== 'REDACTED'

  return (
    <Box>
      <R5Title title={`Incident #${data.incident?.number}`} />
      <TrialEndingAlert withPadding />
      {loading ? (
        <Paper className={classes.paperEmpty} elevation={0} square />
      ) : (
        <Paper className={classes.paper} elevation={0} square>
          <Box className="flex flex-1 flex-row justify-between pl-8 pr-8 pt-8">
            <Box className="flex flex-1 flex-row">
              <Box className="flex mr-2">
                <Avatar className={classes.status}>
                  <StatusIcon color={color()} size="100%" />
                </Avatar>
              </Box>
              <Box className="flex flex-1 flex-col">
                <Typography className={classes.title}>
                  {data.incident.title}
                </Typography>
                <Typography className={classes.subtitle}>{`${moment
                  .unix(data.incident.createdAt)
                  .format('M/D/YY LT')} by ${
                  data.incident.createdBy.name
                }`}</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="flex flex-1 flex-row items-center mt-3 mb-5 pl-8 pr-8">
            <Typography className={`${classes.footer} pr-1`}>
              Assigned to:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                '& > *': { margin: 0.5 },
              }}
            >
              {data.incident.assignedTo.map((item) => (
                <Chip
                  classes={{
                    label: classes.chipLabel,
                    root: classes.chipRoot,
                  }}
                  key={item.id}
                  label={
                    <ListItem component="span" disableGutters dense>
                      <ListItemIcon className={classes.icon}>
                        <Box
                          className={classes.avatar}
                          style={{ backgroundColor: item.accentColor }}
                        />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.primary }}>
                        {item.name}
                      </ListItemText>
                    </ListItem>
                  }
                />
              ))}
            </Box>
          </Box>
          <Divider />
          <Box className="flex flex-1 flex-row items-center justify-between mt-3 pb-3 pl-8 pr-8">
            <Typography className={`${classes.footer} py-sm`}>
              {renderFooterText()}
            </Typography>
            {canPerformActions && (
              <ActionButton
                incident={data.incident}
                setShowEditIncidentModal={setShowEditIncidentModal}
              />
            )}
            {data.incident.status === 'RESOLVED' ? (
              <RedactIncidentModal
                incident={data.incident}
                setShowEditIncidentModal={setShowEditIncidentModal}
                showEditIncidentModal={showEditIncidentModal}
              />
            ) : (
              <ReassignIncidentModal
                incident={data.incident}
                setShowEditIncidentModal={setShowEditIncidentModal}
                showEditIncidentModal={showEditIncidentModal}
              />
            )}
          </Box>
        </Paper>
      )}
      {loading ? (
        <Loader />
      ) : (
        <R5Container>
          <R5Header withTrialAlert={false}>
            <R5ButtonGroup
              buttons={[
                {
                  label: 'Details',
                  to: `/incidents/${data.incident.id}/details`,
                  value: 'details',
                },
                {
                  label: 'Timeline',
                  to: `/incidents/${data.incident.id}/timeline`,
                  value: 'timeline',
                },
              ]}
              selectedValue={tab}
            />
            <Chip
              color="primary"
              label={`#${data.incident.number}`}
              style={{
                backgroundColor: color(),
                fontSize: 24,
                fontWeight: 'bold',
              }}
            />
          </R5Header>
          {tab === 'details' ? (
            <Details incident={data.incident} />
          ) : (
            <Timeline handleNext={handleNext} incident={data.incident} />
          )}
        </R5Container>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 10,
    height: 10,
    width: 10,
  },
  chipRoot: {
    backgroundColor: '#fff', //styles.well.backgroundColor,
  },
  chipLabel: {
    color: styles.primary.color,
    fontWeight: 'bold',
  },
  footer: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  headerRoot: {
    borderRadius: 0,
    boxShadow: 'none',
    margin: 0,
  },
  icon: {
    minWidth: theme.spacing(2),
  },
  incidentStatus: {
    color: styles.headerTitleStyle.color,
    fontSize: 28,
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  primary: {
    fontSize: 14,
    fontWeight: 600,
  },
  paper: {
    background: 'linear-gradient(90deg, #535FFB 0%, #7784FC 100%);',
  },
  paperEmpty: {
    background: 'linear-gradient(90deg, #535FFB 0%, #7784FC 100%);',
    minHeight: 229,
  },
  root: {
    borderRadius: 15,
    boxShadow: '0px 10px 12px rgba(0, 0, 0, 0.05)',
  },
  status: {
    backgroundColor: '#fff',
  },
  subtitle: {
    color: '#ffffff99',
    fontSize: 16,
    fontWeight: 'bold',
  },
  title: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    maxWidth: 1000,
  },
}))

const INCIDENT_UPDATE_SUBSCRIPTION = gql`
  subscription onIncidentUpdated($accountId: ID!) {
    incidentUpdated(accountId: $accountId) {
      id
      updatedAt
    }
  }
`

const QUERY = gql`
  query IncidentPage($incidentId: ID!, $cursor: String) {
    incident(id: $incidentId) {
      id
      number
      source
      state
      status
      title
      details
      detailsType
      metadata
      createdAt
      updatedAt
      triggerAt
      lastTriggeredAt
      viewerCanAcknowledge
      viewerCanSnooze
      viewerCanResolve
      viewerCanReassign
      viewerCanRedact
      createdBy {
        id
        name
        initials
        accentColor
      }
      updatedBy {
        id
        name
        initials
        accentColor
      }
      assignedTo {
        id
        name
        initials
        accentColor
      }
      events(before: $cursor, last: 30, reverse: true) {
        nodes {
          id
          type
          actor {
            id
            name
            initials
            accentColor
          }
          targets {
            id
            name
            initials
            accentColor
          }
          triggerAt
          createdAt
        }
        pageInfo {
          endCursor
          hasPreviousPage
        }
      }
    }
  }
`
