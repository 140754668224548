import * as Icons from '../icons'

import {
  Box,
  Card,
  CardActionArea,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'

import React from 'react'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function PersonalAccessTokenItem({ personalAccessToken }) {
  const classes = useStyles()

  const scopes = _.compact(
    _.map(personalAccessToken.scopes, (value, key) => (value ? key : null))
  )

  const scopeLabels = _.reduce(
    scopes,
    (accu, scope) => {
      const readOrWrite = _.last(_.snakeCase(scope).split('_'))
      const scopeTitle = _.snakeCase(scope).split('_').slice(0, -1).join(' ')

      accu[scopeTitle] = accu[scopeTitle]
        ? `${accu[scopeTitle]}/${readOrWrite}`
        : readOrWrite
      return accu
    },
    {}
  )

  return (
    <Card
      className={classes.card}
      key={personalAccessToken.id}
      variant="outlined"
    >
      <CardActionArea tabIndex={-1}>
        <ListItem
          className="my-sm"
          component={RouterLink}
          to={`/profile/tokens/${personalAccessToken.id}/edit`}
        >
          <ListItemText
            classes={{
              primary: classes.primary,
              secondary: classes.secondary,
            }}
            primary={personalAccessToken.description}
            secondary={
              <Grid container spacing={1}>
                {_.some(scopeLabels) ? (
                  _.map(scopeLabels, (value, scope, index) => (
                    <Grid item key={scope}>
                      <Typography className={classes.secondary}>
                        {scope}:{' '}
                        <span className={classes.tertiary}>{value}</span>
                      </Typography>
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <Typography className={classes.secondary}>
                      no access
                    </Typography>
                  </Grid>
                )}
              </Grid>
            }
            secondaryTypographyProps={{ component: 'div' }}
          />
          <Box className="flex items-center">
            <Icons.ChevronRight color={styles.lightLabel.color} size={20} />
          </Box>
        </ListItem>
      </CardActionArea>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  accentColor: {
    borderRadius: 14,
    height: 14,
    marginRight: theme.spacing(1),
    width: 14,
  },
  chip: {
    backgroundColor: styles.primary.color,
    height: 30,
    fontSize: 14,
    fontWeight: 800,
    width: 30,
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(3),
  },
  content: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  openContent: {
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  iconButton: {
    transform: 'rotate(90deg)',
  },
  openSubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  stepDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
  },
  emptySubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  emptyOpenSubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
    marginTop: theme.spacing(1),
  },
  primary: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  secondary: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  tertiary: {
    fontSize: 12,
    fontWeight: 'normal',
  },
}))
