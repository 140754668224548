import { R5Container, R5Header, R5Title } from '../../components/shared'

import { PersonalAccessTokenForm } from '../../components/Profile'
import React from 'react'
import { useBack } from '../../hooks'
import { useCurrents } from '../../context/currents'

export default function CreatePersonalAccessTokenPage() {
  useBack('/profile/security')
  const { user } = useCurrents()

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Token" />
      <R5Header title="Create Token" />
      <PersonalAccessTokenForm userId={user.id} />
    </R5Container>
  )
}
