import React, { useEffect } from 'react'

import { Redirect } from '@reach/router'
import { SplashScreen } from '../../components/shared'
import { useBack } from '../../hooks'
import { useCurrents } from '../../context/currents'

export default function SignOutPage() {
  useBack(null)
  const { user, signOut } = useCurrents()

  useEffect(() => {
    if (user) signOut()
  }, [user, signOut])

  return user ? <SplashScreen /> : <Redirect to="/login" noThrow />
}
