import * as Icons from '../../components/icons'

import {
  Avatar,
  Fade,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import { Link as RouterLink } from '@reach/router'
import { enterprise } from '../../utils'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function ProfileMenu() {
  const { account, user, setCurrentAccountId } = useCurrents()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenu = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <IconButton edge="end" onClick={handleMenu} size="small">
        <Avatar className={classes.avatar} src={user.avatarUrl}>
          {user.initials}
        </Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ list: classes.list, paper: classes.paper }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        transitionDuration={0}
      >
        <MenuItem
          component={RouterLink}
          divider
          onClick={handleClose}
          to="/profile"
        >
          <ListItem className="py-xs px-0">
            <ListItemAvatar className="min-w-0 mr-md">
              <Avatar className={classes.avatar} src={user.avatarUrl}>
                {user.initials}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
              primary={user.name}
              secondary={user.signInEmailAddress}
            />
          </ListItem>
        </MenuItem>
        {user.viewerAccounts.map(({ accountId, accountName }, index) => (
          <MenuItem
            className={
              account.id === accountId
                ? 'hover:bg-transparent cursor-auto'
                : null
            }
            classes={{ root: classes.accountItemRoot }}
            key={accountId}
            onClick={
              account.id === accountId
                ? undefined
                : () => setCurrentAccountId(accountId)
            }
            divider={user.viewerAccounts.length - 1 === index}
          >
            <Typography classes={{ root: classes.root }}>
              {accountName}
            </Typography>
            {account.id === accountId && (
              <ListItemIcon classes={{ root: classes.accountItemIconRoot }}>
                <Icons.CheckCircle color={styles.success.color} size={20} />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
        {user.account.viewerCanManageBilling && (
          <MenuItem
            classes={{ root: classes.root }}
            component={RouterLink}
            onClick={handleClose}
            to="/account"
          >
            {enterprise() ? 'Account' : 'Account / Billing'}
          </MenuItem>
        )}
        <MenuItem
          classes={{ root: classes.root }}
          component={RouterLink}
          onClick={handleClose}
          to="/help"
        >
          Help
        </MenuItem>
        <MenuItem
          classes={{ root: classes.root }}
          component={RouterLink}
          onClick={handleClose}
          to="/logout"
        >
          Sign out
        </MenuItem>
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: styles.primary.color,
    color: '#fff',
    fontSize: 14,
    height: 32,
    width: 32,
  },
  accountItemIconRoot: {
    minWidth: '0 !important',
  },
  accountItemRoot: {
    fontSize: 12,
    fontWeight: 400,
    justifyContent: 'space-between',
  },
  list: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  paper: {
    backgroundColor: styles.container.backgroundColor,
    borderRadius: 10,
    color: styles.darkText.backgroundColor,
  },
  primary: {
    fontSize: 14,
  },
  root: {
    fontSize: 12,
  },
  secondary: {
    fontSize: 12,
  },
}))
