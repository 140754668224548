import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { PersonalAccessTokenForm } from '../../components/Profile'
import React from 'react'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'

export default function EditPersonalAccessTokenPage({ personalAccessTokenId }) {
  useBack('/profile/security')
  const { user } = useCurrents()
  const { data, errors, loading } = useQuery(QUERY, {
    personalAccessTokenId,
    userId: user.id,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Token" />
      <R5Header title="Edit Token" />
      <PersonalAccessTokenForm
        personalAccessToken={data.personalAccessToken}
        userId={user.id}
      />
    </R5Container>
  )
}

const QUERY = gql`
  query EditPersonalAccessToken($personalAccessTokenId: ID!, $userId: ID!) {
    personalAccessToken(id: $personalAccessTokenId, userId: $userId) {
      id
      accountId
      userId
      description
      accessToken
      createdAt
      scopes {
        accountRead
        escalationPoliciesRead
        escalationPoliciesWrite
        fileUploadsWrite
        incidentsRead
        incidentsWrite
        incidentEventsRead
        integrationsRead
        integrationsWrite
        integrationAlertsRead
        invitationsRead
        invitationsWrite
        membershipsRead
        membershipsWrite
        overridesRead
        overridesWrite
        schedulesRead
        schedulesWrite
        shiftsRead
        shiftsWrite
        teamsRead
        teamsWrite
        usersRead
        usersWrite
        viewerUserRead
        viewerUserWrite
        viewerContactMethodsRead
        viewerContactMethodsWrite
        viewerIncidentNotificationRulesRead
        viewerIncidentNotificationRulesWrite
        viewerInvitationsRead
      }
      viewerCanAdminister
    }
  }
`
