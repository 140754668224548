import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import { R5Input, R5Title } from '../../components/shared'
import React, { useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { Auth } from 'aws-amplify'
import { GoogleIcon, Logo } from '../../components/illustrations'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { navigate } from '@reach/router'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useJunkDrawer } from '../../context/junkDrawer'
import { useQueryParams } from '../../hooks'

const missingNameError = 'Name cannot be empty'
const missingUsernameError = 'Username cannot be empty'
const invalidUsernameError =
  'PreSignUp failed with error The provided email address is invalid.'
const missingPasswordError = 'Password cannot be empty'
const ambiguousSignupError =
  'You must supply accountName or accountId, invitationId, and invitationToken upon signup'
const accountNameTooLongError =
  'custom:accountName: String must be no longer than 64 characters'
const tokenMissingError =
  'Attribute value for custom:invitationToken must not be null'
const shortPasswordError = 'Member must have length greater'
const passwordSpacesError = 'Member must satisfy regular expression'
const passwordLowerCaseError = 'Password must have lowercase characters'
const passwordUpperCaseError = 'Password must have uppercase characters'
const passwordNumericError = 'Password must have numeric characters'

export default function InvitationPage() {
  const classes = useStyles()
  const { user, signOut } = useCurrents()
  const {
    accountId,
    invitationId,
    invitationToken,
    emailAddress,
    domain,
    provider,
  } = useQueryParams()

  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const { updateJunkDrawer } = useJunkDrawer()

  const ssoRequired = !_.isEmpty(provider) && !_.isEmpty(domain)

  useEffect(() => {
    if (user) signOut()
  }, [user, signOut])

  async function handleSubmit(event) {
    event.preventDefault()

    setError(null)
    setLoading(true)

    try {
      await Auth.signUp({
        username: emailAddress,
        password,
        attributes: {
          name,
          'custom:accountId': accountId,
          'custom:invitationId': invitationId,
          'custom:invitationToken': invitationToken,
          'custom:timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      updateJunkDrawer('tempPassword', password)
      navigate(`/signup/confirm?email=${encodeURIComponent(emailAddress)}`)
    } catch (err) {
      setLoading(false)
      if (err.message.includes(ambiguousSignupError)) {
        setError('Please specify an account name')
      } else if (err.message.includes(missingNameError)) {
        setError('Please specify your name')
      } else if (err.message.includes(missingUsernameError)) {
        setError('Please specify your email address')
      } else if (err.message.includes(invalidUsernameError)) {
        setError('Invalid email address')
      } else if (err.message.includes(missingPasswordError)) {
        setError('Please specify a password')
      } else if (err.message.includes(accountNameTooLongError)) {
        setError('Account name cannot exceed 64 characters')
      } else if (err.message.includes(tokenMissingError)) {
        setError('Invitation link invalid')
      } else if (err.message.includes(shortPasswordError)) {
        setError('Password is too short')
      } else if (err.message.includes(passwordSpacesError)) {
        setError('Password cannot contain spaces')
      } else if (
        err.message.includes(passwordLowerCaseError) ||
        err.message.includes(passwordUpperCaseError) ||
        err.message.includes(passwordNumericError)
      ) {
        setError(
          'Password must have at least one uppercase, lowercase, and numeric character'
        )
      } else {
        setError(err.message)
      }
    }
  }

  function handlePasswordCompare() {
    if (_.isEqual(password, passwordConfirm)) {
      if (error === 'Passwords do not match') setError(null)
    } else {
      setError('Passwords do not match')
    }
  }

  return (
    <Container maxWidth="xs">
      <R5Title title="Sign Up" />
      <Box className="mt-1/4">
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.content}>
            <Box className="flex flex-1 flex-col justify-center items-center text-center">
              <Logo
                className={classes.logo}
                color={styles.primary.color}
                size={65}
              />
              <Typography className={classes.title}>Almost there.</Typography>
              {ssoRequired && (
                <>
                  <Typography className={classes.subtitle}>
                    You'll need to sign in with <strong>{provider}</strong>{' '}
                    using your <strong>{domain}</strong> email address to join.
                  </Typography>
                  <Button
                    className={classes.googleButton}
                    style={{ marginTop: 36 }}
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      Auth.federatedSignIn({
                        provider,
                        customState: JSON.stringify({}),
                      })
                    }}
                  >
                    {provider === 'Google' && (
                      <img
                        src={GoogleIcon}
                        alt="Google Icon"
                        style={{
                          maxWidth: '18px',
                          marginRight: 10,
                        }}
                      />
                    )}
                    Sign in with {provider}
                  </Button>
                </>
              )}
              {!ssoRequired && (
                <>
                  <Typography className={classes.subtitle}>
                    We just need a few more details.
                  </Typography>
                  {error && (
                    <Typography className={classes.error}>{error}</Typography>
                  )}
                  <form className="mx-xxl mt-xl" onSubmit={handleSubmit}>
                    <R5Input
                      autoComplete="username"
                      error={error?.includes('email')}
                      fullWidth
                      name="email"
                      placeholder="Email address"
                      readOnly
                      startAdornment={
                        <Icons.Envelope
                          size={25}
                          color={styles.lightLabel.color}
                        />
                      }
                      type="email"
                      value={emailAddress}
                    />
                    <R5Input
                      autoComplete="name"
                      className="mt-lg"
                      error={error?.includes('your name')}
                      fullWidth
                      name="name"
                      onChange={setName}
                      placeholder="Name"
                      startAdornment={
                        <Icons.Person
                          size={25}
                          color={styles.lightLabel.color}
                        />
                      }
                      value={name}
                    />
                    <R5Input
                      autoComplete="new-password"
                      className="mt-lg"
                      error={error?.includes('Password')}
                      fullWidth
                      name="password"
                      onChange={setPassword}
                      placeholder="Password"
                      startAdornment={
                        <Icons.Key size={25} color={styles.lightLabel.color} />
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPassword(
                                (prevShowPassword) => !prevShowPassword
                              )
                            }
                            size="small"
                            tabIndex={-1}
                            title={
                              showPassword ? 'Hide Password' : 'Show Password'
                            }
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                    />
                    <R5Input
                      autoComplete="new-password"
                      className="mt-lg"
                      error={error?.includes('Password')}
                      fullWidth
                      name="password-comfirm"
                      onBlur={handlePasswordCompare}
                      onChange={setPasswordConfirm}
                      placeholder="Confirm Password"
                      startAdornment={
                        <Icons.Key size={25} color={styles.lightLabel.color} />
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPasswordConfirm(
                                (prevShowPasswordConfirm) =>
                                  !prevShowPasswordConfirm
                              )
                            }
                            size="small"
                            tabIndex={-1}
                            title={
                              showPasswordConfirm
                                ? 'Hide Password'
                                : 'Show Password'
                            }
                          >
                            {showPasswordConfirm ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      type={showPasswordConfirm ? 'text' : 'password'}
                      value={passwordConfirm}
                    />
                    <Button
                      classes={{
                        disabled: classes.disabled,
                        root: classes.button,
                      }}
                      disabled={
                        loading ||
                        _.isEmpty(name) ||
                        _.isEmpty(password) ||
                        !_.isEqual(password, passwordConfirm)
                      }
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {loading ? (
                        <CircularProgress color="inherit" size={21} />
                      ) : (
                        'Sign Up'
                      )}
                    </Button>
                  </form>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 800,
    marginTop: theme.spacing(5),
    textTransform: 'uppercase',
  },
  card: {
    borderRadius: theme.spacing(1),
    minHeight: 556,
  },
  disabled: {
    backgroundColor: `${styles.primaryLight.color} !important`,
    color: 'white !important',
  },
  error: {
    backgroundColor: styles.error.backgroundColor,
    borderColor: styles.error.color,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    color: 'white',
    marginTop: 20,
    padding: 12,
    width: '80%',
  },
  googleButton: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: styles.primary.color,
    color: styles.primary.color,
    marginBottom: 0,
    marginTop: 0,
    borderRadius: 8,
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    lineHeight: '107.9%',
    marginTop: theme.spacing(2),
  },
}))
