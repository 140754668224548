export default class ValidationError extends Error {
  constructor(message, errorInfo) {
    super(message)
    this.name = 'ValidationError'
    this.errors = errorInfo.reduce((accu, error) => {
      accu[error.source] = error.detail
      return accu
    }, {})
  }
}
