import { Box, Card, CardContent, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { R5Container, R5Title } from '../../components/shared'
import React, { useEffect } from 'react'
import { useBack, useMutation, useQueryParams } from '../../hooks'

import { ReactComponent as SlackRGB } from '../../components/illustrations/SlackRGB.svg'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'

export default function AuthorizePage() {
  useBack(null)
  const classes = useStyles()
  const { user, account } = useCurrents()
  const { accountId } = useQueryParams()
  const requestSlackMutation = useMutation(
    REQUEST_SLACK_USER_IDENTITY_AUTHORIZATION
  )

  useEffect(() => {
    async function getSlackAuthorizationUrl() {
      try {
        const response = await requestSlackMutation({
          userId: user.id,
        })
        if (response.data.requestSlackUserIdentityAuthorization) {
          window.location.replace(
            response.data.requestSlackUserIdentityAuthorization
              .slackAuthorization.url
          )
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }
    if (accountId === account.id) getSlackAuthorizationUrl()
  }, [accountId, account, user, requestSlackMutation])

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Authorize" />
      <Card className={`${classes.card} mt-1/4`} variant="outlined">
        <CardContent className={classes.content}>
          <Box className="flex flex-col items-center justify-center">
            {accountId === account.id ? (
              <Typography component="span" className={classes.title}>
                Redirecting to...
              </Typography>
            ) : (
              <Typography component="span" className={classes.title}>
                It looks like you're trying to authorize an account that you're
                not logged into.
              </Typography>
            )}
            <Box
              style={{
                backgroundColor: 'white',
                borderRadius: 20,
              }}
            >
              <SlackRGB width={400} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: 24,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center',
  },
}))

const REQUEST_SLACK_USER_IDENTITY_AUTHORIZATION = gql`
  mutation RequestSlackUserIdentityAuthorization(
    $input: RequestSlackUserIdentityAuthorizationInput!
  ) {
    requestSlackUserIdentityAuthorization(input: $input) {
      slackAuthorization {
        url
      }
    }
  }
`
