import * as Icons from '../icons'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@mui/material'
import { ConfirmButton, R5Input } from '../../components/shared'
import React, { Fragment, useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'
import {
  applePushNotificationService,
  firebaseCloudMessaging,
} from '../../constants/app'

import ProfileOptions from '../../constants/ProfileOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function IncidentNotificationRuleStepForm({
  incidentNotificationRule,
  step,
  viewer,
}) {
  const classes = useStyles()
  const navigate = useNavigate()
  const updateMutation = useMutation(UPDATE_INCIDENT_NOTIFICATION_RULE)
  const [targets, setTargets] = useState(
    step
      ? step.targets.map((target) => ({
          ...target,
          ..._.find(viewer.contactMethods.nodes, { id: target.id }),
        }))
      : [
          {
            ...viewer.contactMethods.nodes.filter(
              (contactMethod) => contactMethod.verifiedAt
            )[0],
            sendCriticalAlert: false,
          },
        ]
  )
  const [delayInMinutes, setDelayInMinutes] = useState(
    step ? step.delayInMinutes : 0
  )
  const [loading, setLoading] = useState(false)

  function newSteps() {
    return _.concat(incidentNotificationRule.steps, {
      delayInMinutes,
      targets,
    }).map(({ delayInMinutes, targets }) => ({
      delayInMinutes,
      targets: _.map(
        targets,
        ({ id, sendCriticalAlert, criticalAlertVolume }) => ({
          id,
          sendCriticalAlert,
          criticalAlertVolume,
        })
      ),
    }))
  }

  function updatedSteps() {
    return incidentNotificationRule.steps.map(
      ({ id, delayInMinutes: prevDelayInMinutes, targets: prevTargets }) =>
        id === step.id
          ? {
              delayInMinutes: delayInMinutes,
              targets: _.map(
                targets,
                ({ id, sendCriticalAlert, criticalAlertVolume }) => ({
                  id,
                  sendCriticalAlert,
                  criticalAlertVolume,
                })
              ),
            }
          : {
              delayInMinutes: prevDelayInMinutes,
              targets: _.map(
                prevTargets,
                ({ id, sendCriticalAlert, criticalAlertVolume }) => ({
                  id,
                  sendCriticalAlert,
                  criticalAlertVolume,
                })
              ),
            }
    )
  }

  function deletedSteps() {
    return _.reject(incidentNotificationRule.steps, {
      id: step.id,
    }).map(({ delayInMinutes, targets }) => ({
      delayInMinutes,
      targets: _.map(
        targets,
        ({ id, sendCriticalAlert, criticalAlertVolume }) => ({
          id,
          sendCriticalAlert,
          criticalAlertVolume,
        })
      ),
    }))
  }

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission

    setLoading(true)
    try {
      await updateMutation({
        incidentNotificationRuleId: incidentNotificationRule.id,
        steps: step ? updatedSteps() : newSteps(),
        userId: incidentNotificationRule.userId,
      })
      setLoading(false)
      navigate(`/profile/rules/${incidentNotificationRule.id}`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  async function handleDelete() {
    setLoading(true)
    try {
      await updateMutation({
        incidentNotificationRuleId: incidentNotificationRule.id,
        steps: deletedSteps(),
        userId: incidentNotificationRule.userId,
      })
      setLoading(false)
      navigate(`/profile/rules/${incidentNotificationRule.id}`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-xxl ${
          step ? 'justify-between' : 'justify-end'
        }`}
      >
        {step && (
          <ConfirmButton
            confirmContent="Are you sure you want to delete this notification rule step?"
            error
            onConfirm={handleDelete}
            title="Delete Step"
          />
        )}

        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/profile/rules/${incidentNotificationRule.id}`}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {step ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card className={classes.card} variant="outlined">
        <CardHeader
          title={
            <Select
              id="delay-in-minutes"
              input={<R5Input />}
              onChange={setDelayInMinutes}
              value={delayInMinutes}
            >
              {ProfileOptions.delayInMinutes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          }
        />
        <CardContent className={classes.content}>
          <List disablePadding>
            {targets.map((target, index) => {
              let criticalAlertVolumeName
              switch (target.criticalAlertVolume) {
                case 100:
                  criticalAlertVolumeName = 'Max'
                  break
                case 70:
                  criticalAlertVolumeName = 'High'
                  break
                case 40:
                  criticalAlertVolumeName = 'Medium'
                  break
                case 10:
                  criticalAlertVolumeName = 'Low'
                  break
                default:
                  criticalAlertVolumeName = 'Custom'
                  break
              }

              return (
                <Fragment key={index}>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      className="py-xs"
                      classes={{ primary: classes.subtitle }}
                      primary={index === 0 ? 'Notify me at' : 'And'}
                    />

                    {targets.length > 1 && (
                      <IconButton
                        onClick={() => {
                          setTargets((prevTargets) =>
                            _.filter(prevTargets, (_target, j) => index !== j)
                          )
                        }}
                        size="small"
                      >
                        <Icons.MinusCircleOutline
                          color={styles.error.color}
                          size={30}
                        />
                      </IconButton>
                    )}
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      classes={{
                        primary: classes.label,
                        secondary: classes.secondary,
                      }}
                      primary="Where"
                      secondary={
                        _.find(ProfileOptions.actions, {
                          value: target.type,
                        })?.label || target.type
                      }
                    />
                    <Select
                      input={<R5Input />}
                      onChange={(value) =>
                        setTargets((prevTargets) => {
                          let newTargets = [...prevTargets]
                          newTargets[index] = {
                            ..._.find(viewer.contactMethods.nodes, {
                              id: value,
                            }),
                            sendCriticalAlert: false,
                          }
                          return newTargets
                        })
                      }
                      value={target.id}
                    >
                      {viewer.contactMethods.nodes
                        .filter((contactMethod) => contactMethod.verifiedAt)
                        .map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {`${option.to} (${option.type.toLowerCase()})`}
                          </MenuItem>
                        ))}
                    </Select>
                  </ListItem>
                  {[
                    applePushNotificationService,
                    firebaseCloudMessaging,
                  ].includes(target.pushProvider) && (
                    <>
                      <FormControlLabel
                        classes={{
                          label: classes.checkLabel,
                          labelPlacementStart: classes.checkLabelStart,
                        }}
                        className="flex flex-1 justify-between p-sm px-md"
                        control={
                          <Checkbox
                            checked={target.sendCriticalAlert}
                            color="primary"
                            onChange={({ target: { checked } }) =>
                              setTargets((prevTargets) => {
                                let newTargets = [...prevTargets]
                                newTargets[index] = {
                                  ...target,
                                  sendCriticalAlert: checked,
                                  criticalAlertVolume: checked ? 100 : 0,
                                }
                                return newTargets
                              })
                            }
                            name="send-critical-alerts"
                          />
                        }
                        label="Send critical alert"
                        labelPlacement="start"
                      />
                      {target.sendCriticalAlert && (
                        <>
                          <ListItem>
                            <ListItemText
                              className={'flex-1'}
                              classes={{
                                primary: classes.label,
                                secondary: classes.secondary,
                              }}
                              primary={`Alert volume: ${criticalAlertVolumeName}`}
                            />
                            <Slider
                              className={'flex-1'}
                              step={30}
                              min={10}
                              max={100}
                              value={target.criticalAlertVolume}
                              valueLabelDisplay="auto"
                              valueLabelFormat={`${target.criticalAlertVolume}%`}
                              onChange={({ target: { value } }) =>
                                setTargets((prevTargets) => {
                                  let newTargets = [...prevTargets]
                                  newTargets[index] = {
                                    ...target,
                                    criticalAlertVolume: value,
                                  }
                                  return newTargets
                                })
                              }
                            />
                          </ListItem>
                          {target.pushProvider ===
                            applePushNotificationService && (
                            <Typography className={classes.helpText}>
                              Critical alerts must be enabled on the selected
                              device, otherwise a standard push notification
                              will be sent.
                            </Typography>
                          )}
                          {target.pushProvider === firebaseCloudMessaging && (
                            <Typography className={classes.helpText}>
                              Critical alerts are sent as alarm notifications.
                              This ensures your device always makes a sound as
                              long as as alarms are configured to bypass Do Not
                              Disturb on your device.
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Fragment>
              )
            })}
          </List>
        </CardContent>
        <Divider />
        <CardActions className="m-sm">
          <Button
            onClick={() => {
              setTargets((prevTargets) => [
                ...prevTargets,
                {
                  ...viewer.contactMethods.nodes.filter(
                    (contactMethod) => contactMethod.verifiedAt
                  )[0],
                  sendCriticalAlert: false,
                },
              ])
            }}
            variant="outlined"
          >
            + Add Notification Method
          </Button>
        </CardActions>
      </Card>

      {renderFooter()}
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
  },
  checkLabel: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  checkLabelStart: {
    marginLeft: 0,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  helpText: {
    fontWeight: 600,
    fontSize: 12,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  label: {
    color: styles.lightLabel.color,
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  secondary: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}))

const UPDATE_INCIDENT_NOTIFICATION_RULE = gql`
  mutation UpdateIncidentNotificationRule(
    $input: UpdateIncidentNotificationRuleInput!
  ) {
    updateIncidentNotificationRule(input: $input) {
      incidentNotificationRule {
        id
        name
      }
    }
  }
`
