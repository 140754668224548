import {
  CreateIncidentPage,
  IncidentPage,
  IncidentsPage,
} from '../../pages/Incidents'
import { Redirect, Router } from '@reach/router'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import _ from 'lodash'
import { useCurrents } from '../../context/currents'

export default function IncidentsStack({ location }) {
  const { user } = useCurrents()
  const storedFilter = localStorage.getItem('@filterState/incidents')
  const shouldRedirect = _.some(storedFilter) && _.isEmpty(location.search)

  return (
    <Router>
      <Redirect
        from=":incidentId"
        to="/incidents/:incidentId/details"
        noThrow
      />
      {shouldRedirect && (
        <Redirect from="/" to={`/incidents${storedFilter}`} noThrow />
      )}
      <IncidentsPage path="/" />
      <IncidentPage path=":incidentId/details" />
      <IncidentPage path=":incidentId/timeline" tab="timeline" />
      {user.account.viewerCanCreateIncidents && (
        <CreateIncidentPage path="/new" />
      )}

      <NotFoundPage default />
    </Router>
  )
}
