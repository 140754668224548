import React from 'react'

export default function People({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1786 9.78571C6.77629 9.78571 8.07146 8.49054 8.07146 6.89286C8.07146 5.29518 6.77629 4 5.1786 4C3.58092 4 2.28575 5.29518 2.28575 6.89286C2.28575 8.49054 3.58092 9.78571 5.1786 9.78571ZM5.17857 11.0714L1 12.7304V16.2143H9.35714V12.7304L5.17857 11.0714ZM10.6428 12.7304L14.8213 11.0714L18.9999 12.7304V16.2143H10.6428V12.7304ZM14.8214 9.78571C16.419 9.78571 17.7142 8.49054 17.7142 6.89286C17.7142 5.29518 16.419 4 14.8214 4C13.2237 4 11.9285 5.29518 11.9285 6.89286C11.9285 8.49054 13.2237 9.78571 14.8214 9.78571Z"
        fill={color}
      />
    </svg>
  )
}
