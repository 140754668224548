import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { NotFoundPage } from '../../pages/utils'
import { OverrideForm } from '../../components/Teams'
import React from 'react'
import gql from 'graphql-tag'

export default function CreateOverridePage({ location, scheduleId, teamId }) {
  useBack(`/teams/${teamId}/schedules/${scheduleId}`)
  const { data, errors, loading } = useQuery(QUERY, {
    scheduleId,
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.schedule.viewerCanCreateOverrides) return <NotFoundPage />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Override" />
      <R5Header title="Create Override" />
      <OverrideForm
        defaultShifts={data.schedule.shifts.nodes.filter(
          (shift) => location.state?.shift?.id === shift.id
        )}
        period={location.state?.period}
        schedule={data.schedule}
      />
    </R5Container>
  )
}

const QUERY = gql`
  query CreateOverride($teamId: ID!, $scheduleId: ID!) {
    schedule(id: $scheduleId, teamId: $teamId) {
      id
      name
      viewerCanCreateOverrides
      shifts(first: 30) {
        nodes {
          id
          name
        }
      }
      teamId
    }
  }
`
