import * as Icons from '../../components/icons'

import {
  ActivityIndicator,
  R5Infotip,
  R5Input,
  R5TextField,
} from '../../components/shared'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material'
import React, { createRef, useEffect, useRef, useState } from 'react'

import { Alert } from '@mui/material'
import ReactJson from 'react-json-view'
import _ from 'lodash'
import gql from 'graphql-tag'
import { isValidJSON } from '../../utils'
import { jsonTheme } from '../../constants/app'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function Transforms({ integration }) {
  const [saved, setSaved] = useState(false)
  const [transforms, setTransforms] = useState(integration.transforms)
  const [currentField, setCurrentField] = useState(transforms[0]?.targetField)
  const didMountRef = useRef(false)
  const elementsRef = useRef(
    transforms.reduce((accu, transform) => {
      accu[transform.targetField] = createRef()
      return accu
    }, {})
  )

  useEffect(() => {
    if (didMountRef.current) {
      if (currentField && elementsRef.current[currentField].current)
        elementsRef.current[currentField].current.focus()
    } else {
      didMountRef.current = true
    }
  }, [currentField])

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <LeftCard
            currentField={currentField}
            elementsRef={elementsRef}
            integration={integration}
            setTransforms={setTransforms}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RightCard
            currentField={currentField}
            elementsRef={elementsRef}
            integration={integration}
            setCurrentField={setCurrentField}
            transforms={transforms}
            setSaved={setSaved}
            setTransforms={setTransforms}
          />
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={3000}
        onClose={() => setSaved(false)}
        open={saved}
      >
        <Alert severity="success">Integration saved!</Alert>
      </Snackbar>
    </Box>
  )
}

function LeftCard({ currentField, elementsRef, integration, setTransforms }) {
  const classes = useStyles()

  function isArrayKey(key) {
    return parseInt(key).toString() === key
  }

  function handleAddFieldToTemplate(key, value) {
    let template = key

    if (value) {
      const namespace = value.namespace.reduce((accu, key) => {
        accu += isArrayKey(key) ? `[${key}]` : `.${key}`
        return accu
      }, '')
      const name = isArrayKey(value.name) ? `[${value.name}]` : `.${value.name}`

      template = `${key}${namespace}${name}`
    }

    if (currentField) {
      elementsRef.current[currentField].current.focus()
      setTransforms((prevTransforms) => [
        ..._.reject(prevTransforms, { targetField: currentField }),
        {
          targetField: currentField,
          template: `${
            _.find(prevTransforms, { targetField: currentField }).template
          }{{ ${template} }}`,
        },
      ])
    }
  }

  const firstAlert = integration.alerts.nodes[0]
  const alertPayload = firstAlert?.payload

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent
        className={`${classes.content} ${
          alertPayload ? '' : 'flex flex-1 justify-between'
        }`}
      >
        {alertPayload ? (
          <Box className="flex flex-1 flex-col">
            <Box className="flex flex-row items-center">
              <Typography className={classes.subHeader}>
                Most recent request
              </Typography>
              <R5Infotip
                title="Click on a value in the JSON below to add its key to the
                selected field transform on the right."
              />
            </Box>
            <Typography className={classes.date} color="textSecondary">
              {moment
                .unix(firstAlert.createdAt)
                .format('ddd, MMM D h:mm:ssa z')}
            </Typography>
            <Box className=" mt-md overflow-scroll">
              <Box className="mb-sm">
                <Typography
                  className={`${classes.jsonLabel} mb-sm cursor-pointer`}
                  color="textSecondary"
                  onClick={() => handleAddFieldToTemplate('_body')}
                >
                  body
                </Typography>
                {isValidJSON(alertPayload.body) ? (
                  <ReactJson
                    displayDataTypes={false}
                    displayObjectSize={false}
                    enableClipboard={false}
                    name={null}
                    onSelect={(select) =>
                      handleAddFieldToTemplate('_body', select)
                    }
                    src={JSON.parse(alertPayload.body)}
                    theme={jsonTheme}
                  />
                ) : (
                  <pre
                    className="cursor-pointer whitespace-normal"
                    onClick={() => handleAddFieldToTemplate('_body')}
                    style={{ fontSize: 11, color: 'rgb(203, 75, 22)' }}
                  >
                    {_.isEmpty(alertPayload.body)
                      ? '""'
                      : `"${alertPayload.body}"`}
                  </pre>
                )}
              </Box>
              <Box className="my-sm">
                <Typography
                  className={`${classes.jsonLabel} mb-sm cursor-pointer`}
                  color="textSecondary"
                  onClick={() => handleAddFieldToTemplate('_headers')}
                >
                  headers
                </Typography>
                <ReactJson
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  name={null}
                  onSelect={(select) =>
                    handleAddFieldToTemplate('_headers', select)
                  }
                  src={JSON.parse(alertPayload.headers)}
                  theme={jsonTheme}
                />
              </Box>
              <Box className="my-sm">
                <Typography
                  className={`${classes.jsonLabel} mb-sm cursor-pointer`}
                  color="textSecondary"
                  onClick={() => handleAddFieldToTemplate('_query')}
                >
                  query
                </Typography>
                <ReactJson
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  name={null}
                  onSelect={(select) =>
                    handleAddFieldToTemplate('_query', select)
                  }
                  src={JSON.parse(alertPayload.queryStringParameters)}
                  theme={jsonTheme}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className="flex flex-1 flex-col ">
            <Box className="flex flex-row items-center mb-sm">
              <Typography className={classes.subHeader}>
                Waiting for an event
              </Typography>
              <R5Infotip
                title="Send a request to the address above to preview its available
                fields."
              />
            </Box>
            <Box
              className={`flex flex-1 flex-col items-center ${classes.margin}`}
            >
              <ActivityIndicator />
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

function RightCard({
  elementsRef,
  integration,
  setCurrentField,
  setSaved,
  setTransforms,
  transforms,
}) {
  const classes = useStyles()
  const didMountRef = useRef(false)
  const integrationMutation = useMutation(UPDATE_INTEGRATION)

  const [loading, setLoading] = useState(false)
  const [targetField, setTargetField] = useState('')
  const [addingField, setAddingField] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  useEffect(() => {
    if (didMountRef.current) {
      if (addingField === false && _.some(targetField)) {
        setCurrentField(targetField)
        elementsRef.current[targetField] = createRef()
        setTargetField('')
      }
    } else {
      didMountRef.current = true
    }
  }, [elementsRef, addingField, setCurrentField, targetField])

  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)

    try {
      await integrationMutation({
        teamId: integration.teamId,
        integrationId: integration.id,
        transforms,
      })

      setLoading(false)
      setSaved(true)
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  function handleAddTransform() {
    setTransforms((prevTransforms) => [
      ...prevTransforms,
      {
        targetField,
        template: '',
      },
    ])
    setAddingField(false)
  }

  function handleChangeTransform(targetField, template) {
    setTransforms((prevTransforms) => [
      ..._.reject(prevTransforms, { targetField }),
      { targetField, template },
    ])
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row justify-end">
        <Box className="flex flex-row items-center">
          <Button
            disabled={loading || addingField}
            size="large"
            type={addingField ? null : 'submit'}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Box>
    )
  }

  const FormComponent = addingField ? 'div' : 'form'

  return (
    <Card className={classes.card} variant="outlined">
      <FormComponent onSubmit={addingField ? null : handleSubmit}>
        <CardContent className={classes.content}>
          <Box className="flex flex-row items-center mb-sm">
            <Typography className={classes.subHeader}>
              Transformed Fields
            </Typography>
            <R5Infotip
              learnMore={`https://www.readyfive.io/docs/integrations/${
                integration.type === 'EMAIL' ? 'email' : 'https'
              }/#transform-incoming-events`}
              title={
                <Typography paragraph>
                  Add or modify any fields in the request body based on static
                  values or values from existing values in the request headers,
                  body, or query parameters.
                </Typography>
              }
            />
          </Box>
          {validationErrors['transforms'] && (
            <FormHelperText error>
              {validationErrors['transforms']}
            </FormHelperText>
          )}
          <Box>
            {_.sortBy(transforms, 'targetField').map((transform) => (
              <R5TextField
                className="flex flex-1 mt-lg"
                endAdornment={
                  <IconButton
                    onClick={() => {
                      setTransforms((prevTransforms) =>
                        _.filter(
                          prevTransforms,
                          (ct) => ct.targetField !== transform.targetField
                        )
                      )
                    }}
                    size="small"
                  >
                    <Icons.MinusCircleOutline
                      color={styles.error.color}
                      size={20}
                    />
                  </IconButton>
                }
                key={transform.targetField}
                label={transform.targetField}
                labelProps={{ className: classes.label }}
                onChange={(value) =>
                  handleChangeTransform(transform.targetField, value)
                }
                onFocus={() => setCurrentField(transform.targetField)}
                placeholder="Enter value / template variables"
                ref={elementsRef.current[transform.targetField]}
                value={transform.template}
              />
            ))}

            {addingField ? (
              <form onSubmit={handleAddTransform}>
                <Box className="flex flex-1 flex-row flex-wrap items-center mb-md justify-between">
                  <R5Input
                    autoFocus
                    className="mt-md"
                    onChange={setTargetField}
                    placeholder="Target field name"
                    value={targetField}
                    variant="outlined"
                  />
                  <Box className="mt-md">
                    <Button
                      className="mx-xs"
                      disabled={_.isEmpty(targetField)}
                      type="submit"
                      variant="outlined"
                    >
                      Continue
                    </Button>
                    <Button onClick={() => setAddingField(false)}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </form>
            ) : (
              <Button
                className="my-md"
                onClick={() => setAddingField(true)}
                variant="outlined"
              >
                {transforms.length > 0
                  ? 'Add Another Field'
                  : 'Add Field Transform'}
              </Button>
            )}
          </Box>
          <Divider />
          <CardActions className={classes.actions}>
            {renderFooter()}
          </CardActions>
        </CardContent>
      </FormComponent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: 0,
    marginTop: theme.spacing(2),
  },
  card: {
    borderRadius: theme.spacing(1),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  date: {
    fontSize: 14,
  },
  jsonLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    whiteSpace: 'nowrap',
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    whiteSpace: 'nowrap',
  },
  margin: {
    margin: theme.spacing(7.158),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  subHeader: {
    fontSize: 16,
    fontWeight: 600,
  },
  waiting: {
    fontSize: 18,
    fontWeight: 600,
  },
}))

const UPDATE_INTEGRATION = gql`
  mutation UpdateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      integration {
        id
        name
      }
    }
  }
`
