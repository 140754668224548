import React, { createContext, useContext, useState } from 'react'

import _ from 'lodash'

const JunkDrawerContext = createContext()

const JunkDrawerProvider = (props) => {
  const [junkDrawer, setJunkDrawer] = useState({})

  function updateJunkDrawer(key, data) {
    if (_.isEqual(junkDrawer[key], data)) return
    setJunkDrawer((prevJunkDrawer) => {
      let newJunkDrawer = { ...prevJunkDrawer }
      newJunkDrawer[key] = data
      return newJunkDrawer
    })
  }

  return (
    <JunkDrawerContext.Provider
      value={{ junkDrawer, setJunkDrawer, updateJunkDrawer }}
      {...props}
    />
  )
}

function useJunkDrawer() {
  return useContext(JunkDrawerContext)
}

export { JunkDrawerProvider, useJunkDrawer }
