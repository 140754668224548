import { FormControl, FormHelperText, InputLabel } from '@mui/material'
import React, { forwardRef } from 'react'

import R5Input from './R5Input'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

function R5TextField(
  {
    autoFocus,
    className,
    disabled,
    endAdornment,
    error,
    fullWidth,
    inputComponent,
    inputProps,
    label,
    labelProps,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required,
    type,
    value,
  },
  ref
) {
  const classes = useStyles()

  return (
    <FormControl
      className={className ? className : label ? classes.margin : null}
    >
      {label && (
        <InputLabel
          className={classes.label}
          disabled={disabled}
          error={!!error}
          htmlFor={_.kebabCase(label)}
          required={required}
          ref={ref}
          {...labelProps}
        >
          {label}
        </InputLabel>
      )}
      <R5Input
        autoFocus={autoFocus}
        disabled={disabled}
        endAdornment={endAdornment}
        error={!!error}
        fullWidth={fullWidth}
        id={_.kebabCase(label)}
        inputComponent={inputComponent}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        value={value}
        required={required}
        type={type}
        inputProps={inputProps}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default forwardRef(R5TextField)

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  margin: {
    marginTop: theme.spacing(3.5),
  },
}))
