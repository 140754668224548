import * as Icons from '../../components/icons'

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import { Link as RouterLink } from '@reach/router'
import TeamOptions from '../../constants/TeamOptions'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function EscalationPolicyItem({ escalationPolicy, team }) {
  const classes = useStyles()
  const [open, setOpen] = useState(escalationPolicy.isDefault)

  function stepColor() {
    return [
      styles.primary.color,
      '#FFC23F',
      '#FFA73F',
      '#FF903F',
      '#FF6D3F',
      '#FF3F62',
    ]
  }

  function colorForStep(index) {
    return index > stepColor().length ? _.last(stepColor()) : stepColor()[index]
  }

  function renderOpen() {
    return escalationPolicy.steps.length === 0 ? (
      <Typography className={classes.emptyOpenSubtitle}>
        This escalation policy has no steps.
      </Typography>
    ) : (
      <>
        {!escalationPolicy.isDefault && (
          <Box className="flex flex-row items-center justify-between">
            <Typography className={classes.subtitle}>Steps</Typography>
            <IconButton
              className={open ? classes.iconButton : ''}
              onClick={() =>
                !escalationPolicy.isDefault
                  ? setOpen((prevOpen) => !prevOpen)
                  : null
              }
              size="large"
            >
              <Icons.Dots color={styles.primary.color} size={20} />
            </IconButton>
          </Box>
        )}
        {escalationPolicy.steps.map((step, index) => (
          <Box className="flex flex-col" key={step.id}>
            <Box className="flex flex-1 justify-between">
              <Box className="flex flex-1 items-center">
                <Box
                  className={classes.accentColor}
                  style={{ backgroundColor: colorForStep(index) }}
                />
                <Typography className={classes.title}>
                  {parseInt(step.delayInMinutes) === 0
                    ? 'Immediately'
                    : _.find(TeamOptions.delayAfterInMinutesOptions, {
                        value: step.delayInMinutes,
                      }).label}
                </Typography>
              </Box>
            </Box>
            {step.escalations.map((escalation, i) => (
              <Box
                className="mt-sm ml-lg"
                key={`${step.id}-${escalation.targetId}-${i}`}
              >
                {i !== 0 && (
                  <Typography
                    className={`${classes.subtitle} mb-sm`}
                    color="textSecondary"
                  >
                    AND
                  </Typography>
                )}
                <Typography className={classes.subtitle} color="textSecondary">
                  {
                    _.find(TeamOptions.actionOptions, {
                      value: escalation.action,
                    }).label
                  }
                </Typography>
                <Typography className={classes.title}>
                  {escalation.target.name}
                </Typography>
              </Box>
            ))}
            {index !== escalationPolicy.steps.length - 1 && (
              <Divider className={classes.stepDivider} />
            )}
          </Box>
        ))}
      </>
    )
  }

  function renderClosed() {
    return (
      <Box className="flex flex-1 flex-row justify-between items-center">
        {escalationPolicy.steps.length === 0 ? (
          <Typography className={classes.emptySubtitle}>
            This escalation policy has no steps.
          </Typography>
        ) : (
          <>
            <Box className="flex flex-1 flex-row items-center">
              <Avatar className={`${classes.chip} mr-2`}>
                {escalationPolicy.steps.length}
              </Avatar>
              <Typography className={classes.subtitle}>
                {escalationPolicy.steps.length === 1 ? 'Step' : 'Steps'}
              </Typography>
            </Box>
            <IconButton
              className={open ? classes.iconButton : ''}
              onClick={() =>
                !escalationPolicy.isDefault
                  ? setOpen((prevOpen) => !prevOpen)
                  : null
              }
              size="large"
            >
              <Icons.Dots color={styles.primary.color} size={20} />
            </IconButton>
          </>
        )}
      </Box>
    )
  }

  return (
    <Card className={classes.card} key={team.id} variant="outlined">
      <CardActionArea tabIndex={-1}>
        <ListItem
          className="my-2"
          component={RouterLink}
          to={`/teams/${team.id}/escalationPolicies/${escalationPolicy.id}`}
        >
          <ListItemText
            classes={{ primary: classes.title }}
            primary={`${escalationPolicy.name} ${
              escalationPolicy.isDefault && escalationPolicy.name !== 'Default'
                ? '(default)'
                : ''
            }`}
          />
          <Icons.ChevronRight color={styles.lightLabel.color} size={20} />
        </ListItem>
      </CardActionArea>
      <Divider className={classes.divider} />
      <CardContent className={open ? classes.openContent : classes.content}>
        {open ? renderOpen() : renderClosed()}
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  accentColor: {
    borderRadius: 14,
    height: 14,
    marginRight: theme.spacing(1),
    width: 14,
  },
  chip: {
    backgroundColor: styles.primary.color,
    height: 30,
    fontSize: 14,
    fontWeight: 800,
    width: 30,
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(3),
  },
  content: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  openContent: {
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  iconButton: {
    transform: 'rotate(90deg)',
  },
  openSubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  stepDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
  },
  emptySubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  emptyOpenSubtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}))
