import { useCallback, useEffect, useMemo, useReducer } from 'react'

import { GraphQLAPI } from '@aws-amplify/api-graphql'
import { NullError } from '../components/shared'
import _ from 'lodash'
import { timeoutPromise } from '../utils'
import { useCurrents } from '../context/currents'
import { useJunkDrawer } from '../context/junkDrawer'
import { useQueryCache } from '../context/queryCache'

const validNullKeys = ['billingPaymentCard']

export default function useQuery(query, variables) {
  const [state, dispatch] = useReducer(queryDataReducer, {
    data: {},
    errors: null,
    loading: true,
    refetching: false,
    fetchingMore: false,
  })
  const { accountId } = useCurrents()

  const { queryCache, updateQueryCache } = useQueryCache()
  const { updateJunkDrawer } = useJunkDrawer()
  const storageKeyMemo = useMemo(() => {
    const queryName = _.find(query.definitions, {
      kind: 'OperationDefinition',
    }).name.value
    const variableString = _.some(variables)
      ? `-${Object.values(variables).sort().join('-')}`
      : ''
    return `${queryName}${variableString}`
  }, [query, variables])

  const runQuery = useCallback(
    async (refetching = false) => {
      try {
        if (
          !_.isUndefined(queryCache[storageKeyMemo]) &&
          !storageKeyMemo.match(/Create|Edit/) &&
          !refetching
        ) {
          dispatch({
            type: 'QUERY_SUCCESS',
            payload: queryCache[storageKeyMemo],
          })
        }

        const response = await GraphQLAPI.graphql(
          { query, variables },
          { 'x-ready-five-account-id': accountId }
        )

        if (
          _.some(
            Object.entries(response.data),
            ([key, value]) => !validNullKeys.includes(key) && _.isNull(value)
          )
        )
          throw new NullError()

        dispatch({ type: 'QUERY_SUCCESS', payload: response.data })
        updateQueryCache(storageKeyMemo, response.data, true)
      } catch (err) {
        // if (err.data) setData(err.data) // Not sure why we wanted to dump data on errors
        if (process.env.NODE_ENV === 'development')
          console.log('QUERY ERROR', err)

        if (err.errors) {
          // These come back from gqlErrors
          let graphQLErrors = {}
          if (
            err.errors.graphQLErrors &&
            err.errors.graphQLErrors[0]?.message
          ) {
            err.errors.graphQLErrors.forEach((error) => {
              graphQLErrors[error.path.join(', ')] = error.message
            })

            dispatch({ type: 'QUERY_FAILURE', payload: graphQLErrors })
          }

          // These come back from malformed queries and other errors
          if (err.errors.length && err.errors[0]?.message) {
            if (err.errors.length === 1) {
              if (
                err.errors[0]?.message === 'timeout of 0ms exceeded' ||
                err.errors[0]?.message === 'Network Error'
              ) {
                updateJunkDrawer('disconnected', true)
                dispatch({ type: 'QUERY_FAILURE', payload: 'Network Error' })
              } else {
                dispatch({
                  type: 'QUERY_FAILURE',
                  payload: err.errors[0]?.message,
                })
              }
            } else {
              let otherErrors = {}
              err.errors.forEach((error, index) => {
                otherErrors[error?.path?.join(', ') || `error ${index}`] =
                  error.message
              })
              dispatch({ type: 'QUERY_FAILURE', payload: otherErrors })
            }
          }
        } else {
          dispatch({ type: 'QUERY_FAILURE', payload: err })
        }
      }
    },
    [
      accountId,
      query,
      queryCache,
      storageKeyMemo,
      updateJunkDrawer,
      updateQueryCache,
      variables,
    ]
  )

  const graphQuery = useCallback(async () => {
    try {
      await timeoutPromise(10000, runQuery())
    } catch (error) {
      dispatch({ type: 'QUERY_FAILURE', payload: 'Network Error' })
    }
  }, [runQuery])

  const refetch = useCallback(async () => {
    dispatch({ type: 'QUERY_REFETCH' })

    try {
      await timeoutPromise(10000, runQuery(true))
    } catch (error) {
      dispatch({ type: 'QUERY_FAILURE', payload: 'Network Error' })
    }
  }, [runQuery])

  const fetch = useCallback(() => {
    dispatch({ type: 'QUERY_INIT' })
    graphQuery()
  }, [graphQuery])

  const fetchMore = async ({ variables, updateQuery }) => {
    dispatch({ type: 'QUERY_FETCH_MORE' })

    try {
      const response = await GraphQLAPI.graphql(
        { query, variables },
        { 'x-ready-five-account-id': accountId }
      )

      dispatch({
        type: 'QUERY_FETCH_MORE_SUCCESS',
        payload: ({ data }) =>
          updateQuery(data, { fetchMoreResult: response.data }),
      })
      updateQueryCache(storageKeyMemo, response.data)
    } catch (err) {
      if (process.env.NODE_ENV === 'development') console.warn(err)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetch, [])

  const { data, errors, fetchingMore, loading, refetching } = state

  return {
    data,
    errors,
    fetch,
    fetchMore,
    fetchingMore,
    loading,
    refetch,
    refetching,
  }
}

function queryDataReducer(state, action) {
  switch (action.type) {
    case 'QUERY_INIT':
      return {
        ...state,
        data: {},
        errors: null,
        loading: true,
        refetching: false,
        fetchingMore: false,
      }
    case 'QUERY_SUCCESS':
      return {
        ...state,
        data: action.payload,
        errors: null,
        loading: false,
        refetching: false,
        fetchingMore: false,
      }
    case 'QUERY_FAILURE':
      return {
        ...state,
        data: {},
        errors: action.payload,
        loading: false,
        refetching: false,
        fetchingMore: false,
      }
    case 'QUERY_REFETCH':
      return {
        ...state,
        refetching: true,
      }
    case 'QUERY_FETCH_MORE':
      return {
        ...state,
        fetchingMore: true,
      }
    case 'QUERY_FETCH_MORE_SUCCESS':
      return {
        ...state,
        data: action.payload(state),
        errors: null,
        loading: false,
        refetching: false,
        fetchingMore: false,
      }
    default:
      throw new Error()
  }
}
