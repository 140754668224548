import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import {
  ConfirmButton,
  R5RadioGroup,
  R5TextField,
} from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from '@reach/router'

import TeamOptions from '../../constants/TeamOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import tinycolor from 'tinycolor2'
import { useCurrents } from '../../context/currents'
import { useMutation } from '../../hooks'

const COLORS = [
  '#ff6C01',
  '#0185ff',
  '#8f01ff',
  '#ff4Ded',
  '#ff0101',
  '#15db94',
  '#ffc701',
  '#33d30b',
  '#5c5c5c',
  '#ad2d7a',
  '#46a6ff',
  '#5a4bf8',
]

export default function TeamForm({ team }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useCurrents()

  const teamMutation = useMutation(team ? UPDATE_TEAM : CREATE_TEAM)
  const membershipMutation = useMutation(CREATE_MEMBERSHIP)
  const shiftMutation = useMutation(CREATE_SHIFT)
  const escalationPolicyMutation = useMutation(UPDATE_ESCALATION_POLICY)
  const deleteMutation = useMutation(DELETE_TEAM)

  const [name, setName] = useState(team?.name || location.state?.name || '')
  const [accentColor, setAccentColor] = useState(team?.accentColor || '#33d30b')
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [initializeWith, setInitializeWith] = useState('NO_SETUP')

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)
    try {
      const response = await teamMutation({
        teamId: team?.id,
        name: name,
        accentColor: tinycolor(accentColor).toHexString(),
      })

      if (response.data.createTeam) {
        if (initializeWith === 'FULL_SETUP') {
          await membershipMutation({
            userId: user.id,
            teamId: response.data.createTeam.team.id,
            role: 'ADMINISTRATOR',
          })

          await shiftMutation({
            teamId: response.data.createTeam.team.id,
            scheduleId: response.data.createTeam.team.schedules.nodes[0].id,
            name: '24/7 On call',
            timeZone: user.timeZone,
            coverageType: 'ON_CALL',
            rotationType: 'NONE',
            rotationLength: 0,
            startsAt: moment
              .tz(moment().format('Y-MM-DDTHH:mm:ss'), user.timeZone)
              .unix(),
            participantIds: [user.id],
          })

          await escalationPolicyMutation({
            teamId: response.data.createTeam.team.id,
            escalationPolicyId:
              response.data.createTeam.team.escalationPolicies.nodes[0].id,
            steps: [
              {
                condition: 'NOT_ACKNOWLEDGED',
                delayInMinutes: 0,
                escalations: [
                  {
                    action: 'NOTIFY_ON_DUTY_AND_ON_CALL_USERS_IN_SCHEDULE',
                    targetId:
                      response.data.createTeam.team.schedules.nodes[0].id,
                  },
                ],
              },
            ],
          })
        }

        if (initializeWith === 'QUICK_SETUP') {
          await membershipMutation({
            userId: user.id,
            teamId: response.data.createTeam.team.id,
            role: 'ADMINISTRATOR',
          })

          await escalationPolicyMutation({
            teamId: response.data.createTeam.team.id,
            escalationPolicyId:
              response.data.createTeam.team.escalationPolicies.nodes[0].id,
            steps: [
              {
                condition: 'NOT_ACKNOWLEDGED',
                delayInMinutes: 0,
                escalations: [{ action: 'NOTIFY_USER', targetId: user.id }],
              },
            ],
          })
        }

        navigate(`/teams/${response.data.createTeam.team.id}`)
      } else {
        navigate(`/teams/${team.id}`)
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)
    try {
      await deleteMutation({ teamId: team.id })
      setTimeout(() => {
        setLoading(false)
        navigate('/teams/?q=scope:all')
      }, 1000)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-xxl ${
          team ? 'justify-between' : 'justify-end'
        }`}
      >
        {team && (
          <ConfirmButton
            confirmContent="Are you sure you want to delete this team? Doing so will
          permanently delete all escalation policies, schedules, and
          routing rules within the team as well. This action cannot be
          undone."
            disabled={loading}
            error
            onConfirm={handleDelete}
            size="large"
            title="Delete Team"
          />
        )}
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/teams${team ? `/${team.id}` : ''}`}
          >
            Cancel
          </Button>

          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {team ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <form
        autoComplete="off"
        className="flex flex-col"
        onSubmit={handleSubmit}
      >
        <R5TextField
          error={_.some(validationErrors['name'])}
          label="Name"
          onChange={setName}
          placeholder="What's the team name?"
          required
          value={name}
        />
        <R5TextField
          endAdornment={
            <InputAdornment position="end">
              <div
                style={{
                  backgroundColor: tinycolor(accentColor),
                  height: 20,
                  width: 24,
                  borderRadius: 5,
                }}
              />
            </InputAdornment>
          }
          error={_.some(validationErrors['accentColor'])}
          label="Color"
          onChange={setAccentColor}
          placeholder="Pick an accent color"
          required
          value={accentColor}
        />
        <Card className={`${classes.card} mt-8`} variant="outlined">
          <CardContent className={classes.content}>
            <Grid className="flex flex-1 justify-center" container spacing={3}>
              {COLORS.map((color) => (
                <Grid item key={color}>
                  <IconButton
                    style={{
                      backgroundColor: color,
                      borderRadius: 8,
                      width: 85,
                      height: 69,
                    }}
                    onClick={() => setAccentColor(color)}
                    size="large"
                  >
                    {accentColor === color ? (
                      <Icons.Check color="#fff" size={30} />
                    ) : null}
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>

        {!team && (
          <Card className={`${classes.card} mt-8`} variant="outlined">
            <CardContent className={classes.content}>
              <R5RadioGroup
                buttons={TeamOptions.initializers}
                className={classes.radio}
                label="After creating this team..."
                onChange={setInitializeWith}
                value={initializeWith}
              />
              <Typography color="textSecondary">
                You can always change your team settings later.
              </Typography>
            </CardContent>
          </Card>
        )}

        {renderFooter()}
      </form>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: { borderRadius: 8 },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  radio: {
    marginTop: theme.spacing(1),
  },
}))

const UPDATE_TEAM = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        id
        name
      }
    }
  }
`

const CREATE_TEAM = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        id
        name
        schedules(first: 30) {
          nodes {
            id
            name
            isDefault
          }
        }
        escalationPolicies(first: 30) {
          nodes {
            id
            name
            isDefault
          }
        }
      }
    }
  }
`

const DELETE_TEAM = gql`
  mutation DeleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input) {
      teamId
    }
  }
`

const CREATE_MEMBERSHIP = gql`
  mutation CreateMembership($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      membership {
        id
      }
    }
  }
`

const CREATE_SHIFT = gql`
  mutation CreateShift($input: CreateShiftInput!) {
    createShift(input: $input) {
      shift {
        id
      }
    }
  }
`

const UPDATE_ESCALATION_POLICY = gql`
  mutation UpdateEscalationPolicy($input: UpdateEscalationPolicyInput!) {
    updateEscalationPolicy(input: $input) {
      escalationPolicy {
        id
      }
    }
  }
`
