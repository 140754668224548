import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  Typography,
} from '@mui/material'
import { R5ButtonGroup, R5Infotip, R5Input } from '../../components/shared'
import React, { useEffect, useState } from 'react'

import { Alert } from '@mui/material'
import AvailableIntegrations from '../../components/Teams/AvailableIntegrations'
import DefaultRouting from './DefaultRouting'
import EventRules from './EventRules'
import { FileCopy } from '@mui/icons-material'
import TeamOptions from '../../constants/TeamOptions'
import Transforms from './Transforms'
import { copyToClipboard } from '../../utils'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation } from '../../hooks'

export default function IntegrationSetup({ integration, refetchMemo }) {
  const provider = AvailableIntegrations({ index: false }).find(
    (i) => i.provider === integration.provider
  )
  const [loading, setLoading] = useState(false)
  const enableMutation = useMutation(ENABLE_INTEGRATION)
  const disableMutation = useMutation(DISABLE_INTEGRATION)

  useEffect(() => setLoading(false), [integration])

  async function handleIntegrationToggle(enabled) {
    const integrationMutation = enabled ? enableMutation : disableMutation
    setLoading(true)

    try {
      await integrationMutation({
        integrationId: integration.id,
        teamId: integration.teamId,
      })
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  return (
    <>
      <Typography>
        {provider?.description} For help setting up this integration,{' '}
        <Link href={provider?.docsUrl} target="_blank" underline="hover">
          take a look at the docs
        </Link>
      </Typography>
      <Box className="flex flex-row items-center mt-xl">
        <R5ButtonGroup
          buttons={[
            { label: 'Enabled', value: true },
            { label: 'Disabled', value: false },
          ]}
          className="mr-xs"
          onChange={handleIntegrationToggle}
          selectedValue={!integration.isDisabled}
        />
        {loading ? (
          <CircularProgress size={18} />
        ) : (
          <R5Infotip
            learnMore="https://www.readyfive.io/docs/integration-basics#disable-an-integration"
            title={
              <>
                <Typography paragraph>
                  <strong>Enabled:</strong> Alerts can open and close incidents.
                </Typography>
                <Typography paragraph>
                  <strong>Disabled</strong>: All alerts will be ignored.
                </Typography>
              </>
            }
          />
        )}
      </Box>
      {!integration.isOutbound && (
        <InboundSetup integration={integration} refetchMemo={refetchMemo} />
      )}
    </>
  )
}

function InboundSetup({ integration, refetchMemo }) {
  const classes = useStyles()
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (TeamOptions.transformableProviders.includes(integration.provider)) {
      const interval = setInterval(refetchMemo, 5000)
      return () => clearInterval(interval)
    }
  }, [integration.provider, refetchMemo])

  return (
    <>
      <Typography className={classes.header} color="textSecondary">
        Address
      </Typography>
      <R5Input
        className={`${classes.paddingVertical} flex flex-1`}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              color="primary"
              onClick={() => {
                copyToClipboard(integration.address)
                setCopied(true)
              }}
              size="small"
            >
              <FileCopy />
            </IconButton>
          </InputAdornment>
        }
        value={integration.address}
      />
      <Typography className={classes.header} color="textSecondary">
        Routing
      </Typography>
      <DefaultRouting integration={integration} />
      {TeamOptions.transformableProviders.includes(integration.provider) && (
        <>
          <EventRules integration={integration} />
          <Typography className={classes.header} color="textSecondary">
            Transform incoming events
          </Typography>

          <Transforms integration={integration} />
        </>
      )}
      <Snackbar
        autoHideDuration={3000}
        onClose={() => setCopied(false)}
        open={copied}
      >
        <Alert severity="info">Address copied to clipboard.</Alert>
      </Snackbar>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 14,
    fontWeight: 900,
    letterSpacing: 1,
    marginBottom: theme.spacing(1.25),
    marginTop: theme.spacing(5),
    textTransform: 'uppercase',
  },
  paddingVertical: {
    paddingBottom: theme.spacing(1.462),
    paddingTop: theme.spacing(1.462),
  },
}))

const ENABLE_INTEGRATION = gql`
  mutation EnableIntegration($input: EnableIntegrationInput!) {
    enableIntegration(input: $input) {
      integration {
        id
        isDisabled
      }
    }
  }
`

const DISABLE_INTEGRATION = gql`
  mutation DisableIntegration($input: DisableIntegrationInput!) {
    disableIntegration(input: $input) {
      integration {
        id
        isDisabled
      }
    }
  }
`
