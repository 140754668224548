import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Coverage, Integrations, Members } from '../../components/Teams'
import {
  R5Error,
  Loader,
  R5ButtonGroup,
  R5Container,
  R5Header,
  R5Search,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'
import { useBack, useQuery } from '../../hooks/'

import { NotFoundPage } from '../../pages/utils'
import gql from 'graphql-tag'
import { styles } from '../../constants/styles'

export default function TeamPage({ location, tab = 'coverage', teamId }) {
  useBack('/teams')
  if (location.state?.team) {
    return <TeamPageLoaded tab={tab} team={location.state.team} />
  } else {
    return <TeamPageWithLoader tab={tab} teamId={teamId} />
  }
}

function TeamPageWithLoader({ tab, teamId }) {
  const { data, errors, loading } = useQuery(QUERY, { teamId })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.team.viewerCanAdminister && tab === 'integrations')
    return <NotFoundPage />

  return <TeamPageLoaded tab={tab} team={data.team} />
}

function TeamPageLoaded({ tab, team }) {
  const classes = useStyles()
  const [search, setSearch] = useState('')

  return (
    <R5Container>
      <R5Title title={`Team - ${team.name}`} />
      <R5Header
        addText={tab === 'members' ? 'Add Team Member' : 'Add Integration'}
        addUrl={
          tab === 'members' && team.viewerCanCreateMemberships
            ? `/teams/${team.id}/members/new`
            : tab === 'integrations' && team.viewerCanCreateIntegrations
            ? `/teams/${team.id}/integrations/new`
            : null
        }
        editUrl={team.viewerCanAdminister ? `/teams/${team.id}/edit` : null}
        title={
          <Box className="flex flex-1 items-center">
            <Box
              className={classes.avatar}
              style={{ backgroundColor: team.accentColor }}
            />
            {team.name}
          </Box>
        }
      >
        <R5ButtonGroup
          buttons={
            team.viewerCanAdminister
              ? [
                  {
                    label: 'Coverage',
                    to: `/teams/${team.id}/coverage`,
                    value: 'coverage',
                  },
                  {
                    label: 'Members',
                    to: `/teams/${team.id}/members`,
                    value: 'members',
                  },

                  {
                    label: 'Integrations',
                    to: `/teams/${team.id}/integrations`,
                    value: 'integrations',
                  },
                ]
              : [
                  {
                    label: 'Coverage',
                    to: `/teams/${team.id}/coverage`,
                    value: 'coverage',
                  },
                  {
                    label: 'Members',
                    to: `/teams/${team.id}/members`,
                    value: 'members',
                  },
                ]
          }
          saveState="@filterState/team"
          selectedValue={tab}
        />
        {tab === 'members' && <R5Search onChange={setSearch} value={search} />}
      </R5Header>

      {tab === 'coverage' ? (
        <Coverage teamId={team.id} />
      ) : tab === 'members' ? (
        <Members search={search} teamId={team.id} />
      ) : (
        <Integrations teamId={team.id} />
      )}
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: styles.primary.color,
    borderRadius: 12,
    height: 12,
    marginRight: theme.spacing(1),
    width: 12,
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  icon: {
    minWidth: theme.spacing(3),
  },
}))

const QUERY = gql`
  query TeamPage($teamId: ID!) {
    team(id: $teamId) {
      id
      name
      accentColor
      viewerCanAdminister
      viewerCanCreateIntegrations
      viewerCanCreateMemberships
    }
  }
`
