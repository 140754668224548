import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { EscalationPolicyForm } from '../../components/Teams'
import React from 'react'
import { Redirect } from '@reach/router'
import gql from 'graphql-tag'

export default function EditEscalationPolicyPage({
  escalationPolicyId,
  teamId,
}) {
  useBack(`/teams/${teamId}/escalationPolicies/${escalationPolicyId}`)
  const { data, errors, loading } = useQuery(QUERY, {
    escalationPolicyId,
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  if (!data.escalationPolicy.viewerCanAdminister) {
    return (
      <Redirect
        to={`/teams/${teamId}/escalationPolicies/${escalationPolicyId}`}
        noThrow
      />
    )
  }

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Escalation Policy" />
      <R5Header title="Edit Escalation Policy" />
      <EscalationPolicyForm
        escalationPolicy={data.escalationPolicy}
        teamId={teamId}
      />
    </R5Container>
  )
}

const QUERY = gql`
  query EditEscalationPolicy($escalationPolicyId: ID!, $teamId: ID!) {
    escalationPolicy(id: $escalationPolicyId, teamId: $teamId) {
      id
      name
      isDefault
      viewerCanAdminister
    }
  }
`
