import {
  ActivityIndicator,
  R5Error,
  Loader,
  R5AddButton,
} from '../../components/shared'
import { Box, Button, List, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { ReactComponent as EmptyTeams } from '../../components/illustrations/EmptyTeams.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import MembershipItem from './MembershipItem'
import { ReactComponent as NotFound } from '../../components/illustrations/NotFound.svg'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'
import useQuery from '../../hooks/useQuery'

export default function Members({ search, teamId }) {
  const { accountId, subscribe } = useCurrents()
  const { data, errors, fetchMore, fetchingMore, loading, refetch } = useQuery(
    MEMBERS_QUERY,
    { teamId }
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])
  const handleNextDebounced = useMemo(
    () => _.debounce(handleNext, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.team?.memberships?.pageInfo?.hasNextPage, fetchingMore]
  )

  useEffect(() => {
    if (!data.team) return
    if (search.length > 0) handleNextDebounced()
  }, [data.team, handleNextDebounced, search.length])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_CREATE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  function handleNext() {
    if (fetchingMore) return

    if (data.team.memberships.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data.team.memberships.pageInfo.endCursor,
          teamId: data.team.id,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.team.memberships.nodes.length
            ? {
                team: {
                  ...previousResult.team,
                  memberships: {
                    __typename: previousResult.team.memberships.__typename,
                    nodes: [
                      ...previousResult.team.memberships.nodes,
                      ...fetchMoreResult.team.memberships.nodes,
                    ],
                    pageInfo: fetchMoreResult.team.memberships.pageInfo,
                  },
                },
              }
            : previousResult
        },
      })
    }
  }

  function renderEmpty() {
    return (
      <Box className="flex flex-1 flex-col items-center justify-center mt-1/10">
        <EmptyTeams />
        <Typography variant="h5" gutterBottom>
          This team has no members.
        </Typography>
        {data.team.viewerCanCreateMemberships && (
          <Button
            component={RouterLink}
            to={`/teams/${data.team.id}/members/new`}
            variant="outlined"
          >
            + Add a team member
          </Button>
        )}
      </Box>
    )
  }

  function renderNoResults() {
    if (fetchingMore) return null

    return (
      <Box className="flex flex-1 flex-col items-center justify-center">
        <NotFound height={150} width={150} />
        <Typography variant="h5" gutterBottom>
          No members match your search
        </Typography>
        {data.team.viewerCanCreateMemberships && (
          <Button
            component={RouterLink}
            to={`/teams/${data.team.id}/members/new`}
            variant="outlined"
          >
            + Add {search}
          </Button>
        )}
      </Box>
    )
  }

  const selectedMemberships = data.team.memberships.nodes.filter(({ user }) =>
    user.name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <Box>
      {data.team.memberships.nodes.length > 0 ? (
        selectedMemberships.length > 0 ? (
          <InfiniteScroll
            dataLength={selectedMemberships.length}
            hasChildren={_.some(selectedMemberships)}
            hasMore={data.team.memberships.pageInfo.hasNextPage}
            loader={<ActivityIndicator />}
            next={handleNext}
          >
            <List>
              {selectedMemberships.map((membership) => (
                <MembershipItem key={membership.id} membership={membership} />
              ))}
            </List>
          </InfiniteScroll>
        ) : (
          renderNoResults()
        )
      ) : (
        renderEmpty()
      )}

      {data.team.viewerCanCreateMemberships && (
        <R5AddButton to={`/teams/${data.team.id}/members/new`} />
      )}
    </Box>
  )
}

const MEMBERS_QUERY = gql`
  query Members($cursor: String, $teamId: ID!) {
    team(id: $teamId) {
      id
      viewerCanCreateMemberships
      memberships(after: $cursor, first: 30) {
        nodes {
          id
          role
          teamId
          viewerCanAdminister
          user {
            id
            name
            role
            initials
            avatarUrl
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

const MEMBERSHIP_CREATE_SUBSCRIPTION = gql`
  subscription onMembershipCreated($accountId: ID!, $teamId: ID!) {
    membershipCreated(accountId: $accountId, teamId: $teamId) {
      id
    }
  }
`

const MEMBERSHIP_UPDATE_SUBSCRIPTION = gql`
  subscription onMembershipUpdated($accountId: ID!, $teamId: ID!) {
    membershipUpdated(accountId: $accountId, teamId: $teamId) {
      id
      accountId
      userId
      teamId
      role
      createdAt
    }
  }
`

const MEMBERSHIP_DELETE_SUBSCRIPTION = gql`
  subscription onMembershipDeleted($accountId: ID!, $teamId: ID!) {
    membershipDeleted(accountId: $accountId, teamId: $teamId) {
      id
    }
  }
`
