import React, { useState } from 'react'

import AlertDialog from './AlertDialog'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function ConfirmButton({
  cancelText,
  children,
  confirmContent,
  confirmText,
  component,
  error,
  onConfirm,
  title,
  ...restProps
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const ButtonComponent = component || Button

  return (
    <>
      <ButtonComponent
        className={error ? classes.errorRoot : null}
        onClick={() => setOpen(true)}
        {...restProps}
      >
        {children ? children : 'Delete'}
      </ButtonComponent>
      <AlertDialog
        cancelText={cancelText}
        confirmText={confirmText}
        content={confirmContent}
        handleClose={() => setOpen(false)}
        handleConfirm={() => {
          setOpen(false)
          onConfirm()
        }}
        open={open}
        title={title}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  errorRoot: {
    borderColor: styles.error.color,
    color: styles.error.color,
  },
}))
