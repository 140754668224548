import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Search,
  R5Title,
} from '../../components/shared'
import React, { useEffect, useState } from 'react'
import { useBack, useQuery } from '../../hooks'

import AvailableIntegrations from '../../components/Teams/AvailableIntegrations'
import CreateIntegrationModal from '../../components/Teams/CreateIntegrationModal'
import { NotFoundPage } from '../utils'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'

export default function CreateIntegrationPage({ teamId }) {
  useBack(`/teams/${teamId}/integrations`)
  const { data, errors, loading } = useQuery(QUERY, {
    teamId,
  })

  const [search, setSearch] = useState('')

  const selectedIntegrations = AvailableIntegrations({
    index: true,
  }).filter((integration) =>
    integration.name.toLowerCase().includes(search.toLowerCase())
  )

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.team.viewerCanCreateIntegrations) return <NotFoundPage />

  return (
    <R5Container>
      <R5Title title="Add Integration" />
      <R5Header title="Add Integration">
        <R5Search onChange={setSearch} value={search} />
      </R5Header>
      <IntegrationForm integrations={selectedIntegrations} teamId={teamId} />
    </R5Container>
  )
}

function IntegrationForm({ integrations, teamId }) {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const [integration, setIntegration] = useState({})
  const [showAddIntegrationModal, setShowAddIntegrationModal] = useState(false)

  useEffect(() => {
    if (_.isEmpty(integration)) return
    setShowAddIntegrationModal(true)
  }, [integration])

  return (
    <>
      <Grid
        className={mobileView ? 'justify-center' : null}
        container
        spacing={3}
      >
        {integrations.map((integration) => (
          <Grid item key={integration.provider} xs={12} sm={6} md={4}>
            <Card className={classes.card} variant="outlined">
              <CardHeader
                className={classes.header}
                title={
                  <Box className="flex flex-1 flex-col items-center justify-center text-center">
                    {integration.icon}
                    <Typography className={classes.name}>
                      {integration.name}
                    </Typography>
                    <Typography className={classes.description}>
                      {integration.description}
                    </Typography>
                  </Box>
                }
              />
              <Divider />
              <CardActions className="justify-center">
                <Button
                  className="m-sm"
                  fullWidth
                  onClick={() => setIntegration(integration)}
                  variant="outlined"
                >
                  Add
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <CreateIntegrationModal
        integration={integration}
        setIntegration={setIntegration}
        setShowAddIntegrationModal={setShowAddIntegrationModal}
        showAddIntegrationModal={showAddIntegrationModal}
        teamId={teamId}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  card: {
    borderRadius: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  description: {
    fontSize: 12,
    marginTop: theme.spacing(1),
  },
  header: {
    height: 181,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}))

const QUERY = gql`
  query CreateIntegration($teamId: ID!) {
    team(id: $teamId) {
      id
      name
      viewerCanCreateIntegrations
    }
  }
`
