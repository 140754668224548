import { Container } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

export default function R5Container({ children, maxWidth = 'md' }) {
  const classes = useStyles()
  return (
    <Container className={classes.content} maxWidth={maxWidth}>
      {children}
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(1),
  },
}))
