import { FormControl, TextField } from '@mui/material'
import React, { useEffect } from 'react'

import { Autocomplete } from '@mui/material'
import { R5Error } from '../shared'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useQuery } from '../../hooks'

export default function TargetEscalationPolicySelect({
  escalationPolicy,
  setTarget,
  target,
}) {
  const classes = useStyles()
  const { data, errors, fetchMore, fetchingMore, loading } = useQuery(QUERY)

  useEffect(() => {
    if (!loading && !fetchingMore && data.teams.pageInfo.hasNextPage) {
      fetchMore({
        variables: { cursor: data.teams.pageInfo.endCursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.teams.nodes.length
            ? {
                teams: {
                  __typename: previousResult.teams.__typename,
                  nodes: [
                    ...previousResult.teams.nodes,
                    ...fetchMoreResult.teams.nodes,
                  ],
                  pageInfo: fetchMoreResult.teams.pageInfo,
                },
              }
            : previousResult
        },
      })
    }
  }, [data, fetchMore, fetchingMore, loading])

  if (errors) return <R5Error errors={errors} />

  return (
    <FormControl fullWidth className={classes.margin}>
      <Autocomplete
        blurOnSelect
        classes={{
          clearIndicator: classes.clearIndicator,
          inputRoot: classes.inputRoot,
          popupIndicator: classes.popupIndicator,
          root: classes.root,
        }}
        disableClearable
        fullWidth
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : option.team
            ? `${option.team.name}: ${option.name}`
            : option.name
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id="target"
        options={
          data.teams?.nodes
            ? _.flatMap(data.teams.nodes, (team) =>
                _.compact(
                  team.escalationPolicies.nodes.map((ep) =>
                    escalationPolicy.id !== ep.id
                      ? { ...ep, team: { name: team.name } }
                      : null
                  )
                )
              )
            : []
        }
        onChange={(_event, option) => setTarget(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={target ? null : 'Search...'}
            required
          />
        )}
        size="small"
        value={loading ? 'Loading...' : target}
      />
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  clearIndicator: {
    color: theme.palette.common.white,
  },
  inputRoot: {
    backgroundColor: '#ffffff33',
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
  margin: {
    marginTop: theme.spacing(3.5),
    minWidth: 200,
  },
  popupIndicator: {
    color: theme.palette.common.white,
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.common.white,
      },
    },
  },
}))

const QUERY = gql`
  query AssignToEscalationPolicy($cursor: String) {
    teams(after: $cursor, first: 30) {
      nodes {
        id
        name
        escalationPolicies(first: 30) {
          nodes {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
