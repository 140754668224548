import * as Icons from '../../components/icons'

import {
  AlertDialog,
  R5Error,
  Loader,
  R5ButtonGroup,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { Box, Button, Typography } from '@mui/material'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useBack, useQuery, useQueryParams } from '../../hooks'
import { ReactComponent as EmptyShifts } from '../../components/illustrations/EmptyShifts.svg'
import { IconButton } from '@mui/material'
import { Link as RouterLink } from '@reach/router'
import { ShiftSegment } from '../../components/Teams'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import useMounted from '../../hooks/useMounted'

const SELECTED_VALUE = {
  0: { timelineInterval: 'DAY', timelineIntervalCount: '1' },
  1: { timelineInterval: 'WEEK', timelineIntervalCount: '1' },
  2: { timelineInterval: 'WEEK', timelineIntervalCount: '2' },
  3: { timelineInterval: 'MONTH', timelineIntervalCount: '1' },
}

export default function SchedulePage({ navigate, scheduleId, teamId }) {
  useBack(`/teams/${teamId}/coverage`)

  const {
    timelineStartsAt = moment().startOf('DAY').unix(),
    timelineInterval = 'WEEK',
    timelineIntervalCount = '2',
  } = useQueryParams()

  const classes = useStyles()
  const theme = useTheme()
  const { accountId, subscribe } = useCurrents()
  const mounted = useMounted()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const [showNoShiftDialog, setShowNoShiftDialog] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedValue = useCallback(
    parseInt(
      _.findKey(SELECTED_VALUE, {
        timelineInterval,
        timelineIntervalCount,
      })
    ),
    [timelineInterval, timelineIntervalCount]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const periodStart = useCallback(
    moment.unix(timelineStartsAt).startOf(timelineInterval),
    [timelineInterval, timelineStartsAt]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const periodEnd = useCallback(
    moment
      .unix(timelineStartsAt)
      .startOf(timelineInterval)
      .add(parseInt(timelineIntervalCount - 1), timelineInterval)
      .endOf(timelineInterval),
    [timelineInterval, timelineIntervalCount, timelineStartsAt]
  )

  const { data, errors, loading, refetch, refetching } = useQuery(QUERY, {
    scheduleId: scheduleId,
    teamId: teamId,
    startsAt: periodStart.unix(),
    endsAt: periodEnd.unix(),
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [periodStart, periodEnd])

  useEffect(() => {
    if (mounted) refetchMemo()
  }, [mounted, refetchMemo])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: SCHEDULE_UPDATE_SUBSCRIPTION,
      variables: { accountId, teamId, id: scheduleId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, scheduleId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: SHIFT_CREATE_SUBSCRIPTION,
      variables: { accountId, teamId, scheduleId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, scheduleId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: SHIFT_UPDATE_SUBSCRIPTION,
      variables: { accountId, teamId, scheduleId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, scheduleId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: SHIFT_DELETE_SUBSCRIPTION,
      variables: { accountId, teamId, scheduleId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, scheduleId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: OVERRIDE_CREATE_SUBSCRIPTION,
      variables: { accountId, teamId, scheduleId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, scheduleId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: OVERRIDE_UPDATE_SUBSCRIPTION,
      variables: { accountId, teamId, scheduleId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, scheduleId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: OVERRIDE_DELETE_SUBSCRIPTION,
      variables: { accountId, teamId, scheduleId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, scheduleId])

  function handleNextPeriod() {
    const params = {
      timelineInterval,
      timelineIntervalCount,
      timelineStartsAt: moment
        .unix(timelineStartsAt)
        .add(timelineIntervalCount, timelineInterval)
        .unix(),
    }

    navigate(
      `/teams/${teamId}/schedules/${scheduleId}?${new URLSearchParams(
        params
      ).toString()}`
    )
  }

  function handlePreviousPeriod() {
    const params = {
      timelineInterval,
      timelineIntervalCount,
      timelineStartsAt: moment
        .unix(timelineStartsAt)
        .subtract(timelineIntervalCount, timelineInterval)
        .unix(),
    }

    navigate(
      `/teams/${teamId}/schedules/${scheduleId}?${new URLSearchParams(
        params
      ).toString()}`
    )
  }

  function handlePressToday() {
    const params = {
      timelineInterval,
      timelineIntervalCount,
      timelineStartsAt: moment().startOf('DAY').unix(),
    }

    navigate(
      `/teams/${teamId}/schedules/${scheduleId}?${new URLSearchParams(
        params
      ).toString()}`
    )
  }

  if (loading && !data.schedule) return <Loader />
  if (errors) return <R5Error errors={errors} />

  function formattedPeriod() {
    return timelineInterval === 'DAY'
      ? moment(periodStart).format('ddd, MMM DD')
      : timelineInterval === 'MONTH'
      ? moment(periodStart).format('MMMM YYYY')
      : `${moment(periodStart).format('MMM DD')} - ${moment(periodEnd).format(
          'MMM DD'
        )}`
  }

  function emptyText(type) {
    return `No ${type} ${
      timelineInterval === 'DAY'
        ? 'on'
        : timelineInterval === 'MONTH'
        ? 'in'
        : 'between'
    } ${formattedPeriod()}`
  }

  function renderEmpty() {
    return (
      <Box className="flex flex-col items-center justify-center text-center mt-1/10">
        <EmptyShifts />
        <Typography variant="h5" gutterBottom>
          There are no shifts in this schedule
        </Typography>
        {data.schedule.viewerCanCreateShifts && (
          <Button
            component={RouterLink}
            variant="contained"
            to={`/teams/${teamId}/schedules/${scheduleId}/shifts/new`}
          >
            + Add a shift
          </Button>
        )}
      </Box>
    )
  }

  function renderShiftLayer() {
    return (
      <Card className={classes.card} variant="outlined">
        <CardHeader
          className={classes.cardHeader}
          title={
            <Box className="flex items-center">
              <ListItemText
                classes={{ primary: classes.title }}
                primary="Shifts"
              />
              {data.schedule.viewerCanCreateShifts && (
                <IconButton
                  component={RouterLink}
                  edge="end"
                  size="small"
                  to={`/teams/${teamId}/schedules/${scheduleId}/shifts/new`}
                >
                  <Icons.PlusCircleOutline
                    color={styles.primary.color}
                    size={30}
                  />
                </IconButton>
              )}
            </Box>
          }
        />
        <Divider className={classes.divider} />
        <CardContent className={classes.content}>
          {data.schedule.timeline.shiftSegments.length > 0 ? (
            data.schedule.timeline.shiftSegments.map((item, index) => (
              <ShiftSegment
                emptyText={emptyText('coverage')}
                index={index}
                item={item}
                key={`${item.shift.id}-${item.rows.length}`}
                periodEnd={periodEnd}
                periodStart={periodStart}
                refetching={refetching}
                rightIcon={
                  item.shift.viewerCanAdminister ? (
                    <IconButton
                      className={classes.outlineButton}
                      component={RouterLink}
                      to={`/teams/${teamId}/schedules/${scheduleId}/shifts/${item.shift.id}/edit`}
                      size="large"
                    >
                      <Icons.Pencil color={styles.primary.color} size={15} />
                    </IconButton>
                  ) : null
                }
                schedule={data.schedule}
                selectedValue={selectedValue}
                type="shift"
              />
            ))
          ) : (
            <Typography className={classes.subtitle}>
              {emptyText('shifts')}
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  }

  function renderOverrideLayer() {
    return (
      <Card className={classes.card} variant="outlined">
        <CardHeader
          className={classes.cardHeader}
          title={
            <Box className="flex items-center">
              <ListItemText
                classes={{ primary: classes.title }}
                primary="Overrides"
              />
              {data.schedule.viewerCanCreateOverrides ? (
                data.schedule.shifts.nodes.length > 0 ? (
                  <IconButton
                    component={RouterLink}
                    edge="end"
                    size="small"
                    to={`/teams/${teamId}/schedules/${scheduleId}/overrides/new`}
                  >
                    <Icons.PlusCircleOutline
                      color={styles.primary.color}
                      size={30}
                    />
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      edge="end"
                      onClick={() => setShowNoShiftDialog(true)}
                      size="small"
                      style={{ padding: 0 }}
                    >
                      <Icons.PlusCircleOutline
                        color={styles.primary.color}
                        size={30}
                      />
                    </IconButton>
                    <AlertDialog
                      cancelText="Ok"
                      content="You don't have any shifts to override"
                      handleClose={() => setShowNoShiftDialog(false)}
                      open={showNoShiftDialog}
                      title="You need a shift"
                    />
                  </>
                )
              ) : null}
            </Box>
          }
        />
        <Divider className={classes.divider} />
        <CardContent className={classes.content}>
          {data.schedule.timeline.overrideSegments.length > 0 ? (
            data.schedule.timeline.overrideSegments.map((item, index) => (
              <ShiftSegment
                emptyText={emptyText('overrides')}
                index={index}
                item={item}
                key={`${item.shift.id}-${item.rows.length}`}
                periodEnd={periodEnd}
                periodStart={periodStart}
                refetching={refetching}
                schedule={data.schedule}
                selectedValue={selectedValue}
                type="override"
              />
            ))
          ) : (
            <Typography className={classes.subtitle}>
              {emptyText('overrides')}
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  }

  function renderFinalLayer() {
    return (
      <Card className={classes.card} variant="outlined">
        <CardHeader
          className={classes.cardHeader}
          title={
            <Box className="flex items-center">
              <ListItemText
                classes={{ primary: classes.title }}
                primary="Final"
              />
            </Box>
          }
        />
        <Divider className={classes.divider} />
        <CardContent className={classes.content}>
          {data.schedule.timeline.finalSegments.length > 0 ? (
            data.schedule.timeline.finalSegments.map((item, index) => (
              <ShiftSegment
                emptyText={emptyText('coverage')}
                index={index}
                item={item}
                key={`${item.shift.id}-${item.rows.length}`}
                periodEnd={periodEnd}
                periodStart={periodStart}
                refetching={refetching}
                schedule={data.schedule}
                selectedValue={selectedValue}
                type="final"
              />
            ))
          ) : (
            <Typography className={classes.subtitle}>
              {emptyText('coverage')}
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  }

  return (
    <R5Container>
      <R5Title title={`Schedule - ${data.schedule?.name}`} />
      <R5Header
        editUrl={data.schedule.viewerCanAdminister ? 'edit' : null}
        title={`${data.schedule.name} ${
          (data.schedule.isDefault &&
            (data.schedule.name !== 'Default' ? '(default)' : 'Schedule')) ||
          ''
        }`}
      >
        <R5ButtonGroup
          className="mr-2"
          buttons={
            mobileView
              ? [
                  {
                    label: '1 Day',
                    to: `?timelineStartsAt=${timelineStartsAt}&timelineInterval=DAY&timelineIntervalCount=1`,
                    value: 0,
                  },
                  {
                    label: '1 Week',
                    to: `?timelineStartsAt=${timelineStartsAt}&timelineInterval=WEEK&timelineIntervalCount=1`,
                    value: 1,
                  },
                  {
                    label: '2 Weeks',
                    to: `?timelineStartsAt=${timelineStartsAt}&timelineInterval=WEEK&timelineIntervalCount=2`,
                    value: 2,
                  },
                ]
              : [
                  {
                    label: '1 Day',
                    to: `?timelineStartsAt=${timelineStartsAt}&timelineInterval=DAY&timelineIntervalCount=1`,
                    value: 0,
                  },
                  {
                    label: '1 Week',
                    to: `?timelineStartsAt=${timelineStartsAt}&timelineInterval=WEEK&timelineIntervalCount=1`,
                    value: 1,
                  },
                  {
                    label: '2 Weeks',
                    to: `?timelineStartsAt=${timelineStartsAt}&timelineInterval=WEEK&timelineIntervalCount=2`,
                    value: 2,
                  },
                  {
                    label: '1 Month',
                    to: `?timelineStartsAt=${timelineStartsAt}&timelineInterval=MONTH&timelineIntervalCount=1`,
                    value: 3,
                  },
                ]
          }
          selectedValue={selectedValue}
        />
        <Box
          className={`flex flex-row items-center ${
            mobileView ? 'flex-1 justify-between' : ''
          }`}
        >
          <Typography
            className={classes.formattedPeriod}
            color="textSecondary"
            noWrap
          >
            {formattedPeriod()}
          </Typography>
          <Box className="flex flex-row items-center justify-between">
            <IconButton
              className={classes.outlineButton}
              onClick={handlePreviousPeriod}
              size="large"
            >
              <Icons.ChevronLeft color={styles.primary.color} size={20} />
            </IconButton>
            <Button
              className={classes.todayOutlineButton}
              onClick={handlePressToday}
              variant="outlined"
            >
              Today
            </Button>
            <IconButton
              className={classes.outlineButton}
              onClick={handleNextPeriod}
              size="large"
            >
              <Icons.ChevronRight color={styles.primary.color} size={20} />
            </IconButton>
          </Box>
        </Box>
      </R5Header>
      {data.schedule?.shifts.nodes.length > 0 ? (
        <>
          {renderShiftLayer()}
          {renderOverrideLayer()}
          {renderFinalLayer()}
        </>
      ) : (
        renderEmpty()
      )}
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(3),
  },
  cardHeader: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  formattedPeriod: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  outlineButton: {
    backgroundColor: styles.container.backgroundColor,
    color: styles.primary.color,
    border: `1px solid ${styles.border.color}`,
    padding: 6,
    marginRight: theme.spacing(0.5),
  },
  todayOutlineButton: {
    backgroundColor: styles.container.backgroundColor,
    color: styles.primary.color,
    border: `1px solid ${styles.border.color}`,
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
  },
  subtitle: {
    color: styles.lightLabel.color,
    fontSize: 14,
  },
  title: {
    fontSize: 16,
    fontWeight: 800,
    paddingBottom: theme.spacing(0.25),
    paddingTop: theme.spacing(0.25),
  },
}))

const FRAGMENTS = {
  period: gql`
    fragment TimelinePeriod on ScheduleTimelineSegmentPeriod {
      actualStartsAt
      actualEndsAt
      startsAt
      endsAt
      override {
        id
        viewerCanAdminister
        fromUser {
          id
          name
          initials
        }
      }
      target {
        id
        name
        initials
        accentColor
      }
    }
  `,
}

const QUERY = gql`
  query Schedule(
    $scheduleId: ID!
    $teamId: ID!
    $startsAt: Int!
    $endsAt: Int!
  ) {
    schedule(id: $scheduleId, teamId: $teamId) {
      id
      name
      isDefault
      viewerCanAdminister
      viewerCanCreateOverrides
      viewerCanCreateShifts
      team {
        id
        name
      }
      shifts(first: 30) {
        nodes {
          id
          name
        }
      }
      timeline(startsAt: $startsAt, endsAt: $endsAt) {
        shiftSegments {
          shift {
            id
            name
            coverageType
            viewerCanAdminister
          }
          rows {
            periods {
              ...TimelinePeriod
            }
          }
        }
        overrideSegments {
          shift {
            id
            name
            coverageType
          }
          rows {
            periods {
              ...TimelinePeriod
            }
          }
        }
        finalSegments {
          shift {
            id
            name
            coverageType
          }
          rows {
            periods {
              ...TimelinePeriod
            }
          }
        }
      }
    }
  }
  ${FRAGMENTS.period}
`

const SCHEDULE_UPDATE_SUBSCRIPTION = gql`
  subscription onScheduleUpdated($accountId: ID!, $teamId: ID!, $id: ID) {
    scheduleUpdated(accountId: $accountId, teamId: $teamId, id: $id) {
      id
      name
    }
  }
`

const SHIFT_CREATE_SUBSCRIPTION = gql`
  subscription onShiftCreated($accountId: ID!, $teamId: ID!, $scheduleId: ID) {
    shiftCreated(
      accountId: $accountId
      teamId: $teamId
      scheduleId: $scheduleId
    ) {
      id
    }
  }
`

const SHIFT_UPDATE_SUBSCRIPTION = gql`
  subscription onShiftUpdated($accountId: ID!, $teamId: ID!, $scheduleId: ID) {
    shiftUpdated(
      accountId: $accountId
      teamId: $teamId
      scheduleId: $scheduleId
    ) {
      id
      accountId
      teamId
      scheduleId
      createdAt
      name
      timeZone
      coverageType
      rotationType
      rotationLength
      startsAt
      endsAt
      timePeriods {
        sunday {
          fromTime
          toTime
        }
        monday {
          fromTime
          toTime
        }
        tuesday {
          fromTime
          toTime
        }
        wednesday {
          fromTime
          toTime
        }
        thursday {
          fromTime
          toTime
        }
        friday {
          fromTime
          toTime
        }
        saturday {
          fromTime
          toTime
        }
      }
      participantIds
    }
  }
`

const SHIFT_DELETE_SUBSCRIPTION = gql`
  subscription onShiftDeleted($accountId: ID!, $teamId: ID!, $scheduleId: ID) {
    shiftDeleted(
      accountId: $accountId
      teamId: $teamId
      scheduleId: $scheduleId
    ) {
      id
    }
  }
`

const OVERRIDE_CREATE_SUBSCRIPTION = gql`
  subscription onOverrideCreated(
    $accountId: ID!
    $teamId: ID!
    $scheduleId: ID
  ) {
    overrideCreated(
      accountId: $accountId
      teamId: $teamId
      scheduleId: $scheduleId
    ) {
      id
    }
  }
`

const OVERRIDE_UPDATE_SUBSCRIPTION = gql`
  subscription onOverrideUpdated(
    $accountId: ID!
    $teamId: ID!
    $scheduleId: ID
  ) {
    overrideUpdated(
      accountId: $accountId
      teamId: $teamId
      scheduleId: $scheduleId
    ) {
      id
      accountId
      teamId
      scheduleId
      fromUserId
      toUserId
      startsAt
      endsAt
      shiftIds
      createdAt
    }
  }
`

const OVERRIDE_DELETE_SUBSCRIPTION = gql`
  subscription onOverrideDeleted(
    $accountId: ID!
    $teamId: ID!
    $scheduleId: ID
  ) {
    overrideDeleted(
      accountId: $accountId
      teamId: $teamId
      scheduleId: $scheduleId
    ) {
      id
    }
  }
`
