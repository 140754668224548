import * as Icons from '../../components/icons'

import { AddAlert, NotificationsPaused, Warning } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { R5Error, R5Container, R5Title } from '../../components/shared'
import React, { useEffect, useState } from 'react'
import { Redirect, Link as RouterLink } from '@reach/router'
import {
  applePushNotificationService,
  firebaseCloudMessaging,
} from '../../constants/app'
import { useBack, useMutation, useQuery, useWelcome } from '../../hooks'

import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useJunkDrawer } from '../../context/junkDrawer'

export default function NotificationsPage({ navigate }) {
  const classes = useStyles()
  const { dismissWelcome } = useWelcome()
  const { junkDrawer, updateJunkDrawer } = useJunkDrawer()
  const { user } = useCurrents()

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const [alertLoading, setAlertLoading] = useState(false)
  const [loading, setLoading] = useState(false)

  const { data, errors } = useQuery(INCIDENT_NOTIFICATION_RULE_QUERY)
  const contactMethodMutation = useMutation(UPDATE_CONTACT_METHOD)
  const incidentNotificationRuleMutation = useMutation(
    UPDATE_INCIDENT_NOTIFICATION_RULE
  )
  const [defaultIncidentNotificationRule, setDefaultIncidentNotificationRule] =
    useState()

  const testMutation = useMutation(TEST_CONTACT_METHOD)
  const canReceiveCriticalAlert =
    junkDrawer.welcome &&
    [applePushNotificationService, firebaseCloudMessaging].includes(
      junkDrawer.welcome.pushContactMethod.pushProvider
    )

  useBack(user.role === 'OWNER' ? '/welcome/install' : '/welcome')

  useEffect(() => {
    setDefaultIncidentNotificationRule(
      _.find(data.viewer?.incidentNotificationRules.nodes, 'isDefault')
    )
  }, [data, setDefaultIncidentNotificationRule])

  async function handleUseSensibleDefault() {
    setLoading(true)

    let steps = [
      {
        delayInMinutes: 0,
        targets: [
          {
            id: junkDrawer.welcome.pushContactMethod.id,
            sendCriticalAlert: false,
          },
        ],
      },
    ]

    if (canReceiveCriticalAlert) {
      steps.push({
        delayInMinutes: 1,
        targets: [
          {
            id: junkDrawer.welcome.pushContactMethod.id,
            sendCriticalAlert: true,
            criticalAlertVolume: 40,
          },
        ],
      })
      steps.push({
        delayInMinutes: 2,
        targets: [
          {
            id: junkDrawer.welcome.pushContactMethod.id,
            sendCriticalAlert: true,
            criticalAlertVolume: 100,
          },
        ],
      })
    }

    try {
      await Promise.all([
        contactMethodMutation({
          contactMethodId: junkDrawer.welcome.pushContactMethod.id,
          userId: junkDrawer.welcome.pushContactMethod.userId,
          coverageChangeNotifications: {
            onCallEnded: true,
            onCallStarted: true,
          },
        }),
        incidentNotificationRuleMutation({
          incidentNotificationRuleId: defaultIncidentNotificationRule.id,
          repeatAfterInMinutes: 1,
          steps,
          userId: defaultIncidentNotificationRule.userId,
        }),
      ])

      setLoading(false)
      updateJunkDrawer('welcome', {
        ...junkDrawer.welcome,
        useSensibleDefault: true,
      })
    } catch (error) {
      setAlertLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  async function handleCriticalTest() {
    setAlertLoading(true)

    try {
      await testMutation({
        contactMethodId: junkDrawer.welcome.pushContactMethod.id,
        sendCriticalAlert: true,
        userId: junkDrawer.welcome.pushContactMethod.userId,
      })

      setAlertLoading(false)
      updateJunkDrawer('welcome', {
        ...junkDrawer.welcome,
        sentCriticalAlert: true,
      })
    } catch (error) {
      setAlertLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function handleDismiss() {
    navigate('/')
    dismissWelcome()
  }

  if (_.isUndefined(junkDrawer.welcome))
    return <Redirect to="/welcome" noThrow />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container maxWidth={mobileView ? 'xs' : 'sm'}>
      <R5Title title="Welcome" />
      <Box className="flex flex-col items-center justify-center text-center mt-1/10">
        <Typography className={classes.title}>Nice!</Typography>
        <Typography className={classes.subTitle} color="textSecondary">
          Your{' '}
          {junkDrawer.welcome.pushContactMethod.pushProvider ===
          applePushNotificationService
            ? junkDrawer.welcome.pushContactMethod.description
            : junkDrawer.welcome.pushContactMethod.to}{' '}
          is ready for alerts and we just sent you a test message as
          confirmation. Now, let's configure the timing for notifications.
        </Typography>
        <List>
          {canReceiveCriticalAlert && (
            <Box
              className={`mt-xl ${
                junkDrawer.welcome.useSensibleDefault ||
                junkDrawer.welcome.ignoreSensibleDefault
                  ? classes.listItem
                  : classes.listItemPair
              }`}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor: junkDrawer.welcome.sentCriticalAlert
                        ? styles.success.color
                        : null,
                    }}
                  >
                    {junkDrawer.welcome.sentCriticalAlert ? (
                      <Icons.Check color="#f6f7fb" size={20} />
                    ) : (
                      <Warning />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Critical Alerts"
                  secondary="Critical alerts always make a sound and bypass Do Not Disturb. Want to try one? Lock your phone to hear the sound."
                />
              </ListItem>
              <Grid className={classes.marginLeft} container spacing={1}>
                <Grid item>
                  <Button
                    disabled={alertLoading}
                    onClick={handleCriticalTest}
                    size="small"
                    variant="outlined"
                  >
                    🔺 Send a test Critical Alert
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box
            className={`mt-xl ${
              junkDrawer.welcome.useSensibleDefault ||
              junkDrawer.welcome.ignoreSensibleDefault
                ? classes.listItem
                : classes.listItemPair
            }`}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: junkDrawer.welcome.useSensibleDefault
                      ? styles.success.color
                      : null,
                  }}
                >
                  {junkDrawer.welcome.useSensibleDefault ? (
                    <Icons.Check color="#f6f7fb" size={20} />
                  ) : (
                    <AddAlert />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Notification Rules"
                secondary={`Recommendation: Sends a push notification every minute until acknowledgement${
                  canReceiveCriticalAlert
                    ? ' with a standard initial notification, a Critical Alert second notification at 40% volume, and a Critical Alert third notification at full volume. Critical Alert notifications bypass Do Not Disturb'
                    : ''
                }.`}
              />
            </ListItem>
            <Grid className={classes.marginLeft} container spacing={1}>
              <Grid item>
                <Button
                  disabled={
                    loading ||
                    junkDrawer.welcome.useSensibleDefault ||
                    !defaultIncidentNotificationRule
                  }
                  onClick={handleUseSensibleDefault}
                  size="small"
                  variant="outlined"
                >
                  {junkDrawer.welcome.useSensibleDefault
                    ? 'Done!'
                    : '👍 Configure with these settings'}
                </Button>
              </Grid>
              <Grid item>
                {!junkDrawer.welcome.useSensibleDefault && (
                  <Button
                    disabled={loading}
                    onClick={() =>
                      updateJunkDrawer('welcome', {
                        ...junkDrawer.welcome,
                        ignoreSensibleDefault: true,
                      })
                    }
                    size="small"
                    variant="outlined"
                  >
                    ⛔️ No thanks, I want to customize it myself
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          {(junkDrawer.welcome.useSensibleDefault ||
            junkDrawer.welcome.ignoreSensibleDefault) && (
            <ListItem className={`mt-xl ${classes.listItem}`}>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: junkDrawer.welcome.useSensibleDefault
                      ? styles.success.color
                      : null,
                  }}
                >
                  {junkDrawer.welcome.useSensibleDefault ? (
                    <Icons.Check color="#f6f7fb" size={20} />
                  ) : (
                    <NotificationsPaused />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  junkDrawer.welcome.useSensibleDefault
                    ? 'All set!'
                    : 'Do it yourself'
                }
                secondary={
                  <>
                    {junkDrawer.welcome.useSensibleDefault ? (
                      <Typography component="span" variant="body2">
                        You’ll be notified via repeated push notification when
                        an incident is assigned to you.
                      </Typography>
                    ) : (
                      <Typography component="span" variant="body2">
                        You can edit your notification rules anytime from the
                        notifications tab in your{' '}
                        <Link
                          component={RouterLink}
                          to="/profile/notifications"
                          underline="hover"
                        >
                          profile
                        </Link>
                        .
                      </Typography>
                    )}
                    <Typography component="span" variant="body2">
                      {' '}
                      Check our{' '}
                      <Link
                        href="https://www.readyfive.io/docs/"
                        rel="noopener noreferrer"
                        target="_blank"
                        underline="hover"
                      >
                        docs
                      </Link>{' '}
                      or{' '}
                      <Link component={RouterLink} to="/help" underline="hover">
                        get in touch
                      </Link>{' '}
                      if you need additional help.
                    </Typography>
                  </>
                }
                secondaryTypographyProps={{ component: 'span' }}
              />
            </ListItem>
          )}
        </List>
        <Box className="mt-xl items-center">
          <Grid className="flex justify-center" container spacing={1}>
            <Grid item>
              <Button
                component={RouterLink}
                size="small"
                to={user.role === 'OWNER' ? '/welcome/install' : '/welcome'}
                variant="outlined"
              >
                Back: Install mobile app
              </Button>
            </Grid>
            {(junkDrawer.welcome.useSensibleDefault ||
              junkDrawer.welcome.ignoreSensibleDefault) && (
              <Grid item>
                <Button
                  disabled={
                    !junkDrawer.welcome.useSensibleDefault &&
                    !junkDrawer.welcome.ignoreSensibleDefault
                  }
                  onClick={handleDismiss}
                  size="small"
                  variant="outlined"
                >
                  I'm finished setting things up, close this dialog
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&::before': {
      content: 'no-open-quote',
      position: 'absolute',
      left: '2.2rem',
      backgroundColor: styles.border.color,
      height: '45%',
      width: 1,
      zIndex: -1,
    },
    '&:first-child:before': {
      top: theme.spacing(12),
    },
    '&:last-child:before': {
      top: theme.spacing(0.5),
    },
  },
  listItemPair: {
    '&::before': {
      content: 'no-open-quote',
      position: 'absolute',
      left: '2.2rem',
      backgroundColor: styles.border.color,
      height: '45%',
      width: 1,
      zIndex: -1,
    },
    '&:first-child:before': {
      top: theme.spacing(12),
    },
    '&:last-child:before': {
      height: 0,
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(9),
  },
  subTitle: {
    fontSize: 24,
    fontWeight: 600,
  },
  title: {
    fontSize: 40,
    fontWeight: 900,
  },
}))

const INCIDENT_NOTIFICATION_RULE_QUERY = gql`
  query WelcomeStepTwo {
    viewer {
      id
      incidentNotificationRules(first: 30) {
        nodes {
          id
          isDefault
          name
          userId
        }
      }
    }
  }
`

const TEST_CONTACT_METHOD = gql`
  mutation TestContactMethod($input: TestContactMethodInput!) {
    testContactMethod(input: $input) {
      contactMethod {
        id
      }
    }
  }
`

const UPDATE_CONTACT_METHOD = gql`
  mutation UpdateContactMethod($input: UpdateContactMethodInput!) {
    updateContactMethod(input: $input) {
      contactMethod {
        id
      }
    }
  }
`

const UPDATE_INCIDENT_NOTIFICATION_RULE = gql`
  mutation UpdateIncidentNotificationRule(
    $input: UpdateIncidentNotificationRuleInput!
  ) {
    updateIncidentNotificationRule(input: $input) {
      incidentNotificationRule {
        id
        name
      }
    }
  }
`
