import * as Icons from '../../components/icons'

import {
  AmazonCloudWatch,
  AmazonSNS,
  Azure,
  Bugsnag,
  Datadog,
  GoogleCloud,
  GrafanaAlerting,
  Honeycomb,
  NewRelic,
  Pingdom,
  Slack,
  Updown,
} from '../../components/illustrations'
import { Avatar, CardMedia } from '@mui/material'

import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function AvailableIntegrations({ index = true }) {
  const indexClasses = useIndexStyles()
  const itemClasses = useItemStyles()
  const classes = index ? indexClasses : itemClasses
  const size = index ? 30 : 15
  return [
    {
      description:
        "We'll supply an email address that creates an incident for each message received.",
      docsUrl: 'https://www.readyfive.io/docs/integrations/email',
      icon: (
        <Avatar className={classes.email}>
          <Icons.Envelope color="#fff" size={size} />
        </Avatar>
      ),
      isOutbound: false,
      name: 'Email',
      provider: 'EMAIL',
    },
    {
      description:
        'Make custom requests to a URL with alert details to create and resolve incidents.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/https',
      icon: (
        <Avatar className={classes.https}>
          <Icons.Lock color="#fff" size={size} />
        </Avatar>
      ),
      isOutbound: false,
      name: 'HTTPS',
      provider: 'HTTPS',
    },
    {
      description:
        'Handle CloudWatch alarm events to create and resolve incidents.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/amazon-cloudwatch',
      icon: (
        <CardMedia
          className={classes.amazon}
          component="img"
          image={AmazonCloudWatch}
          title="Amazon CloudWatch"
        />
      ),
      isOutbound: false,
      name: 'Amazon CloudWatch',
      provider: 'AMAZON_CLOUD_WATCH',
    },
    {
      description:
        'Publish custom data to an SNS topic with alert details to create and resolve incidents.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/amazon-sns',
      icon: (
        <CardMedia
          className={classes.amazon}
          component="img"
          image={AmazonSNS}
          title="Amazon SNS"
        />
      ),
      isOutbound: false,
      name: 'Amazon SNS',
      provider: 'AMAZON_SNS',
    },
    {
      description:
        'Handle Azure Monitor alert events to create and resolve incidents.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/azure',
      icon: (
        <CardMedia
          className={classes.azure}
          component="img"
          image={Azure}
          title="Azure"
        />
      ),
      isOutbound: false,
      name: 'Azure',
      provider: 'AZURE_MONITOR',
    },
    {
      description: 'Handle exception notifications from your applications.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/bugsnag',
      icon: (
        <Avatar className={classes.bugsnag}>
          <CardMedia
            className={classes.bugsnagIcon}
            component="img"
            image={Bugsnag}
            title="Bugsnag"
          />
        </Avatar>
      ),
      isOutbound: false,
      name: 'Bugsnag',
      provider: 'BUGSNAG',
    },
    {
      description:
        'Create and resolve incidents from triggered monitor alerts sent by Datadog.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/datadog',
      icon: (
        <CardMedia
          className={classes.datadog}
          component="img"
          image={Datadog}
          title="Datadog"
        />
      ),
      isOutbound: false,
      name: 'Datadog',
      provider: 'DATADOG',
    },
    {
      description:
        'Handle Google Cloud Monitoring alert webhooks to create and resolve incidents.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/google-cloud',
      icon: (
        <CardMedia
          className={classes.googleCloud}
          component="img"
          image={GoogleCloud}
          title="Google Cloud"
        />
      ),
      isOutbound: false,
      name: 'Google Cloud',
      provider: 'GOOGLE_CLOUD_MONITORING',
    },
    {
      description:
        'Handle Grafana Alerting webhooks to create and resolve incidents.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/grafana-alerting',
      icon: (
        <CardMedia
          className={classes.grafanaAlerting}
          component="img"
          image={GrafanaAlerting}
          title="Grafana"
        />
      ),
      isOutbound: false,
      name: 'Grafana',
      provider: 'GRAFANA_ALERTING',
    },
    {
      description: 'Handle Honeycomb triggers to create and resolve incidents.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/honeycomb',
      icon: (
        <CardMedia
          className={classes.honeycomb}
          component="img"
          image={Honeycomb}
          title="Honeycomb"
        />
      ),
      isOutbound: false,
      name: 'Honeycomb',
      provider: 'HONEYCOMB',
    },
    {
      description:
        'Handle alerts from New Relic metric thresholds to create and resolve incidents.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/new-relic',
      icon: (
        <CardMedia
          className={classes.newRelic}
          component="img"
          image={NewRelic}
          title="New Relic"
        />
      ),
      isOutbound: false,
      name: 'New Relic',
      provider: 'NEW_RELIC',
    },
    {
      description:
        "Get notified when your monitored endpoints are down and resolve when they're back up.",
      docsUrl: 'https://www.readyfive.io/docs/integrations/solarwinds-pingdom',
      icon: (
        <CardMedia
          className={classes.pingdom}
          component="img"
          image={Pingdom}
          title="Pingdom"
        />
      ),
      isOutbound: false,
      name: 'Pingdom',
      provider: 'SOLARWINDS_PINGDOM',
    },
    {
      description:
        'Send incident details to your channel and respond to incidents from there.',
      docsUrl: 'https://www.readyfive.io/docs/integrations/slack',
      helpText:
        'We need to authorize your Slack account to link it to this team. You will be redirected to a Slack authorization page where we will ask for all the relevant permissions Ready Five needs to integrate properly.',
      icon: (
        <Avatar className={classes.slack}>
          <CardMedia
            className={classes.slackIcon}
            component="img"
            image={Slack}
            title="Slack"
          />
        </Avatar>
      ),
      isOutbound: true,
      name: 'Slack',
      provider: 'SLACK',
    },
    {
      description:
        "Get notified when your checks are down and resolve when they're back up.",
      docsUrl: 'https://www.readyfive.io/docs/integrations/updown',
      icon: (
        <CardMedia
          className={classes.updown}
          component="img"
          image={Updown}
          title="Updown"
        />
      ),
      isOutbound: false,
      name: 'Updown',
      provider: 'UPDOWN',
    },
    {
      description:
        "Get notified when your monitored endpoints are down and resolve when they're back up.",
      docsUrl: 'https://www.readyfive.io/docs/integrations/uptime-robot',
      icon: <Avatar className={classes.uptimeRobot}>UR</Avatar>,
      isOutbound: false,
      name: 'UptimeRobot',
      provider: 'UPTIME_ROBOT',
    },
  ]
}

const useItemStyles = makeStyles((theme) => ({
  amazon: {
    height: 32,
    width: 32,
  },
  azure: {
    height: 32,
    width: 32,
  },
  bugsnag: {
    height: 32,
    width: 32,
  },
  bugsnagIcon: {
    height: 40,
    width: 40,
  },
  datadog: {
    height: 32,
    width: 29,
  },
  email: {
    backgroundColor: styles.primary.color,
    height: 32,
    width: 32,
  },
  googleCloud: {
    height: 26,
    width: 32,
  },
  grafanaAlerting: {
    height: 32,
    width: 32,
  },
  honeycomb: {
    height: 30,
    width: 32,
  },
  https: {
    backgroundColor: '#3B4751',
    height: 32,
    width: 32,
  },
  newRelic: {
    height: 26,
    width: 32,
  },
  pingdom: {
    height: 32,
    width: 32,
  },
  slack: {
    height: 32,
    width: 32,
  },
  slackIcon: {
    backgroundColor: '#4A154B',
    height: 40,
    width: 40,
  },
  updown: {
    height: 32,
    width: 32,
  },
  uptimeRobot: {
    backgroundColor: '#3bd671',
    height: 32,
    fontSize: 14,
    width: 32,
  },
}))

const useIndexStyles = makeStyles((theme) => ({
  amazon: {
    height: 60,
    width: 60,
  },
  azure: {
    height: 60,
    width: 60,
  },
  bugsnag: {
    height: 60,
    width: 60,
  },
  bugsnagIcon: {
    height: 75,
    width: 75,
  },
  datadog: {
    height: 60,
    width: 56,
  },
  email: {
    backgroundColor: styles.primary.color,
    height: 60,
    width: 60,
  },
  googleCloud: {
    height: 60,
    width: 74,
  },
  grafanaAlerting: {
    height: 60,
    width: 60,
  },
  honeycomb: {
    height: 60,
    width: 64,
  },
  https: {
    backgroundColor: '#3B4751',
    height: 60,
    width: 60,
  },
  newRelic: {
    height: 60,
    width: 74,
  },
  pingdom: {
    height: 60,
    width: 60,
  },
  slack: {
    backgroundColor: '#4A154B',
    height: 60,
    width: 60,
  },
  slackIcon: {
    height: 60,
    width: 60,
  },
  updown: {
    height: 60,
    width: 60,
  },
  uptimeRobot: {
    backgroundColor: '#3bd671',
    height: 60,
    width: 60,
  },
}))
