import {
  CreateContactMethodPage,
  EditContactMethodPage,
  VerifyContactMethodPage,
} from '../../../pages/Profile'
import { Redirect, Router } from '@reach/router'

import React from 'react'

export default function ContactMethodsStack() {
  return (
    <Router>
      <Redirect from="/" to="/profile/notifications" noThrow />
      <CreateContactMethodPage path="new" />
      <EditContactMethodPage path=":contactMethodId/edit" />
      <VerifyContactMethodPage path=":contactMethodId/verify" />
    </Router>
  )
}
