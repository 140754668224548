import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'

import React from 'react'
import { Link as RouterLink } from '@reach/router'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function UserItem({ user }) {
  const classes = useStyles()
  const { user: currentUser } = useCurrents()

  return (
    <Card className={classes.card} key={user.id} variant="outlined">
      <CardActionArea tabIndex={-1}>
        <CardContent className={classes.content}>
          <ListItem
            component={RouterLink}
            to={currentUser.id === user.id ? '/profile' : `/users/${user.id}`}
          >
            <ListItemAvatar className={classes.listItemAvatar}>
              <Avatar className={classes.avatar} src={user.avatarUrl}>
                {user.initials}
              </Avatar>
            </ListItemAvatar>
            <ListItemText classes={{ primary: classes.title }}>
              {user.name}
            </ListItemText>
            {user.isDeactivated && (
              <Typography className={classes.deactivated} color="textSecondary">
                deactivated
              </Typography>
            )}
          </ListItem>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: styles.primary.color,
    borderRadius: 32,
    fontSize: 12,
    fontWeight: 800,
    height: 32,
    width: 32,
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  deactivated: {
    color: styles.lightLabel.color,
    fontSize: 14,
    fontWeight: 'bold',
  },
  listItemAvatar: {
    minWidth: theme.spacing(5),
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
}))
