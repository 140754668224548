import { FormControl, TextField } from '@mui/material'
import React, { useCallback, useEffect } from 'react'

import { Autocomplete } from '@mui/material'
import { R5Error } from '../shared'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useQuery } from '../../hooks'

export default function MembershipSelect({
  autoFocus = true,
  className,
  memberships,
  setTeam,
  team,
}) {
  const classes = useStyles()
  const { data, errors, fetchMore, fetchingMore, loading } = useQuery(QUERY)

  function handleNext() {
    if (fetchingMore) return
    if (!data.teams.pageInfo.hasNextPage) return

    fetchMore({
      variables: {
        cursor: data.teams.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult.teams.nodes.length
          ? {
              teams: {
                __typename: previousResult.teams.__typename,
                nodes: [
                  ...previousResult.teams.nodes,
                  ...fetchMoreResult.teams.nodes,
                ],
                pageInfo: fetchMoreResult.teams.pageInfo,
              },
            }
          : previousResult
      },
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNextDebounced = useCallback(_.debounce(handleNext, 500), [
    data.teams?.pageInfo?.hasNextPage,
    fetchingMore,
  ])

  useEffect(() => {
    if (!data.teams) return
    handleNextDebounced()
  }, [data.teams, handleNextDebounced])

  if (errors) return <R5Error errors={errors} />

  return (
    <FormControl
      className={`${classes.width} ${className ? ` ${className}` : null}`}
    >
      <Autocomplete
        blurOnSelect
        classes={{
          inputRoot: classes.inputRoot,
          root: classes.root,
        }}
        disableClearable
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id="target"
        options={
          data.teams?.nodes
            ? _.filter(
                data.teams.nodes,
                (t) =>
                  t.id === team?.id ||
                  !_.map(memberships, 'team.id').includes(t.id)
              )
            : []
        }
        onChange={(_event, option) => setTeam(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            variant="outlined"
            placeholder={team ? null : 'Search...'}
          />
        )}
        size="small"
        value={loading ? 'Loading...' : team}
      />
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    backgroundColor: styles.container.backgroundColor,
    borderRadius: theme.spacing(1),
    color: styles.primary.color,
    fontSize: 14,
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: styles.primary.color,
      },
    },
  },
  width: {
    minWidth: 200,
  },
}))

const QUERY = gql`
  query MembershipSelect($cursor: String) {
    teams(after: $cursor, first: 30) {
      nodes {
        id
        name
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
