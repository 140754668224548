import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import {
  EscalationPolicyList,
  R5ButtonGroup,
  R5Search,
  UserList,
} from '../../components/shared'
import React, { useState } from 'react'

import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function EditIncidentModal({
  incident,
  setShowEditIncidentModal,
  showEditIncidentModal,
}) {
  const classes = useStyles()
  const assignMutation = useMutation(ASSIGN_INCIDENT)
  const [assignTo, setAssignTo] = useState(incident.assignedTo)
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [validationErrors, setValidationErrors] = useState([])

  const [search, setSearch] = useState('')
  const [selectedValue, setSelectedValue] = useState('policies')

  function handleSelect(item) {
    let newAssignTo = null

    if (assignTo.find((assignee) => assignee.id === item.id)) {
      newAssignTo = _.reject(assignTo, (assignee) => assignee.id === item.id)
    } else {
      newAssignTo = _.concat(assignTo, item)
    }

    setAssignTo(newAssignTo)
  }

  function handleDelete(item) {
    let newAssignTo = null
    newAssignTo = _.reject(assignTo, (assignee) => assignee.id === item.id)
    setAssignTo(newAssignTo)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await assignMutation({
        assignToIds: assignTo.map((item) => item.id),
        incidentId: incident.id,
      })
      setLoading(false)
      handleClose()
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  function handleClose() {
    setShowEditIncidentModal(false)
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row my-sm justify-end">
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            onClick={handleClose}
            size="large"
          >
            Cancel
          </Button>
          {
            <Button
              disabled={loading || _.isEmpty(assignTo)}
              onClick={handleSubmit}
              size="large"
              variant="contained"
            >
              Reassign
            </Button>
          }
        </Box>
      </Box>
    )
  }

  return (
    <Dialog
      aria-labelledby="add-integration-dialog-title"
      aria-describedby="add-integration-dialog-description"
      fullWidth
      onClose={handleClose}
      open={showEditIncidentModal}
      maxWidth="xs"
    >
      <DialogTitle id="add-integration-dialog-title">
        <Box className="flex flex-row justify-between items-center">
          <Typography style={styles.headerTitleStyle} variant="h4">
            Reassign Incident
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Icons.Close color={styles.primary.color} size={20} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className="flex flex-col mb-md">
        <Box className="flex flex-row items-center">
          <R5ButtonGroup
            buttons={[
              { label: 'Policies', value: 'policies' },
              { label: 'Users', value: 'users' },
            ]}
            onChange={setSelectedValue}
            selectedValue={selectedValue}
          />
        </Box>
        <Box className="mt-3">
          {validationErrors['assignToIds'] && (
            <FormHelperText error>
              {validationErrors['assignToIds']}
            </FormHelperText>
          )}
          {!_.some(assignTo) ? (
            <Typography className={classes.subtitle} color="textSecondary">
              Select one or more users/policies
            </Typography>
          ) : (
            <Grid className="flex flex-1" container spacing={1}>
              {assignTo.map((assignToItem) => (
                <Grid item key={assignToItem.id}>
                  <Chip
                    color="primary"
                    label={assignToItem.name}
                    onDelete={() => handleDelete(assignToItem)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        <Divider className={classes.divider} />
        <Box className="flex flex-1 flex-col">
          <R5Search
            className="p-3"
            fullWidth
            loading={fetching}
            onChange={setSearch}
            value={search}
          />
          <Box className={classes.height}>
            {selectedValue === 'policies' ? (
              <EscalationPolicyList
                handleSelect={handleSelect}
                search={search}
                selectedValues={assignTo}
                setFetching={setFetching}
              />
            ) : (
              <UserList
                handleSelect={handleSelect}
                search={search}
                selectedValues={assignTo}
                setFetching={setFetching}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actions}>
        {renderFooter()}
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  divider: {
    backgroundColor: styles.primary.color,
    height: 2,
    marginTop: theme.spacing(1.5),
  },
  height: {
    height: 250,
    overflow: 'scroll',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 12,
    marginBottom: theme.spacing(0.875),
    marginTop: theme.spacing(0.875),
  },
}))

const ASSIGN_INCIDENT = gql`
  mutation AssignIncident($input: AssignIncidentInput!) {
    assignIncident(input: $input) {
      incident {
        id
        assignedToIds
      }
    }
  }
`
