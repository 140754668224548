import { Button, Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'

export default function PeriodPill({
  index,
  period,
  periodEnd,
  periodStart,
  renderDetails,
  showDetails,
  containerDimensions,
}) {
  const classes = useStyles()

  function positions() {
    const totalWidth = periodEnd.unix() - periodStart.unix()
    const periodPositionStart = period.startsAt - periodStart.unix()
    const periodPositionEnd = period.endsAt - periodStart.unix()

    const leftPercentageStart = periodPositionStart / totalWidth
    const leftPercentageEnd = periodPositionEnd / totalWidth
    const width = leftPercentageEnd - leftPercentageStart

    return [leftPercentageStart * 100, width * 100]
  }

  const borderRadiusStart = moment
    .unix(period.actualStartsAt)
    .isBefore(moment.unix(period.startsAt))
    ? 0
    : 100

  const borderRadiusEnd =
    moment.unix(period.actualEndsAt).isAfter(moment.unix(period.endsAt)) ||
    _.isNull(period.actualEndsAt)
      ? 0
      : 100

  return (
    <div
      style={{
        position: 'absolute',
        top: index * 35 + 10,
        left: `${positions()[0]}%`,
        width: `${positions()[1]}%`,
      }}
    >
      <Button
        className={`flex flex-1 items-center justify-center`}
        fullWidth
        style={{
          borderColor: period.target.accentColor,
          borderBottomLeftRadius: borderRadiusStart,
          borderBottomRightRadius: borderRadiusEnd,
          borderTopLeftRadius: borderRadiusStart,
          borderTopRightRadius: borderRadiusEnd,
          backgroundColor: period.target.accentColor,
          minHeight: 30,
          minWidth: 0,
          padding: 0,
        }}
        onClick={showDetails}
      >
        <Typography className={classes.periodText} noWrap>
          {renderPillOwner(containerDimensions, positions()[1], period.target)}
        </Typography>
      </Button>
      {renderDetails()}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  periodText: {
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1,
  },
}))

function renderPillOwner(containerDimensions, pillWidthPercentage, target) {
  const pillWidthInPoints =
    (containerDimensions.width * pillWidthPercentage) / 100

  // If a pill is less than 18 points wide (the minimum we need to fit
  // a letter), render nothing. Less than 30 points render first letter
  // of initials, otherwise render both letters.
  if (pillWidthInPoints < 18) {
    return ''
  } else if (pillWidthInPoints < 30) {
    return target.initials[0]
  }

  return target.initials
}
