import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'

import { Link as RouterLink } from '@reach/router'
import TeamOptions from '../../constants/TeamOptions'
import _ from 'lodash'
import { styles } from '../../constants/styles'

export default function MembershipItem({ membership }) {
  const classes = useStyles()

  const CardWrapper = membership.viewerCanAdminister ? CardActionArea : Fragment

  return (
    <Card className={classes.card} key={membership.id} variant="outlined">
      <CardWrapper>
        <CardContent className={classes.content}>
          <ListItem
            component={membership.viewerCanAdminister ? RouterLink : null}
            to={
              membership.viewerCanAdminister
                ? `/teams/${membership.teamId}/members/${membership.id}/edit`
                : null
            }
          >
            <ListItemAvatar className={classes.listItemAvatar}>
              <Avatar
                className={classes.avatar}
                src={membership.user.avatarUrl}
              >
                {membership.user.initials}
              </Avatar>
            </ListItemAvatar>
            <ListItemText classes={{ primary: classes.title }}>
              {membership.user.name}
            </ListItemText>
            <Typography className={classes.deactivated} color="textSecondary">
              {membership.user.role === 'MEMBER'
                ? _.find(TeamOptions.roles, { value: membership.role }).label
                : 'Administrator'}
            </Typography>
          </ListItem>
        </CardContent>
      </CardWrapper>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: styles.primary.color,
    borderRadius: 32,
    fontSize: 12,
    fontWeight: 800,
    height: 32,
    width: 32,
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  deactivated: {
    color: styles.lightLabel.color,
    fontSize: 14,
    fontWeight: 'bold',
  },
  listItemAvatar: {
    minWidth: theme.spacing(5),
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
}))
