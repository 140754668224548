import { Button, Card, CardContent, Divider } from '@mui/material'
import {
  EmailForm,
  MFAForm,
  MainForm,
  PasswordForm,
} from '../../components/Profile'
import { R5Container, R5Header, R5Title } from '../../components/shared'

import React from 'react'
import { Link as RouterLink } from '@reach/router'
import makeStyles from '@mui/styles/makeStyles'
import { useBack } from '../../hooks'
import { useCurrents } from '../../context/currents'

export default function EditProfilePage() {
  useBack('/profile')
  const classes = useStyles()
  const { user } = useCurrents()

  function renderFooter() {
    return (
      <CardContent
        className={`flex flex-1 flex-row justify-end ${classes.content}`}
      >
        <Button
          classes={{ root: classes.marginRight }}
          component={RouterLink}
          to="/profile"
        >
          Cancel
        </Button>
      </CardContent>
    )
  }

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Profile" />
      <R5Header title="Edit Profile" />
      <Card className={classes.card} variant="outlined">
        <MainForm />
        <Divider />
        {user.viewerCanChangeEmailAddress && (
          <>
            <EmailForm />
            <Divider />
            <PasswordForm />
            <Divider />
            <MFAForm />
            <Divider />
          </>
        )}
        {renderFooter()}
      </Card>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}))
