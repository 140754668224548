import {
  CreateOverridePage,
  CreateShiftPage,
  EditOverridePage,
  EditSchedulePage,
  EditShiftPage,
  SchedulePage,
} from '../../../pages/Teams'
import { Router } from '@reach/router'

import React from 'react'

export default function SchedulesStack({ location }) {
  return (
    <Router>
      <SchedulePage path="/" />
      <EditSchedulePage path="edit" />

      <CreateShiftPage path="shifts/new" />
      <EditShiftPage path="shifts/:shiftId/edit" />

      <CreateOverridePage path="overrides/new" />
      <EditOverridePage path="overrides/:overrideId/edit" />
    </Router>
  )
}
