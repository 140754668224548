const delayAfterInMinutesOptions = [
  { label: 'Immediately', value: 0 },
  { label: 'After 1 minute', value: 1 },
  { label: 'After 2 minutes', value: 2 },
  { label: 'After 3 minutes', value: 3 },
  { label: 'After 4 minutes', value: 4 },
  { label: 'After 5 minutes', value: 5 },
  { label: 'After 6 minutes', value: 6 },
  { label: 'After 7 minutes', value: 7 },
  { label: 'After 8 minutes', value: 8 },
  { label: 'After 9 minutes', value: 9 },
  { label: 'After 10 minutes', value: 10 },
  { label: 'After 11 minutes', value: 11 },
  { label: 'After 12 minutes', value: 12 },
  { label: 'After 13 minutes', value: 13 },
  { label: 'After 14 minutes', value: 14 },
  { label: 'After 15 minutes', value: 15 },
  { label: 'After 16 minutes', value: 16 },
  { label: 'After 17 minutes', value: 17 },
  { label: 'After 18 minutes', value: 18 },
  { label: 'After 19 minutes', value: 19 },
  { label: 'After 20 minutes', value: 20 },
  { label: 'After 25 minutes', value: 25 },
  { label: 'After 30 minutes', value: 30 },
  { label: 'After 35 minutes', value: 35 },
  { label: 'After 40 minutes', value: 40 },
  { label: 'After 45 minutes', value: 45 },
  { label: 'After 50 minutes', value: 50 },
  { label: 'After 55 minutes', value: 55 },
  { label: 'After 1 hour', value: 60 },
  { label: 'After 1.5 hours', value: 90 },
  { label: 'After 2 hours', value: 120 },
  { label: 'After 6 hours', value: 360 },
  { label: 'After 12 hours', value: 720 },
  { label: 'After 1 day', value: 1440 },
  { label: 'After 2 days', value: 2880 },
  { label: 'After 3 days', value: 4320 },
  { label: 'After 4 days', value: 5760 },
  { label: 'After 5 days', value: 7200 },
  { label: 'After 6 days', value: 8640 },
  { label: 'After 1 week', value: 10080 },
]

const actionOptions = [
  {
    label: 'Notify on-call users in schedule',
    value: 'NOTIFY_ON_CALL_USERS_IN_SCHEDULE',
    placeHolder: 'No schedule selected',
  },
  {
    label: 'Notify on-duty users in schedule',
    value: 'NOTIFY_ON_DUTY_USERS_IN_SCHEDULE',
    placeHolder: 'No schedule selected',
  },
  {
    label: 'Notify any on-duty or on-call users in schedule',
    value: 'NOTIFY_ON_DUTY_AND_ON_CALL_USERS_IN_SCHEDULE',
    placeHolder: 'No schedule selected',
  },
  {
    label: 'Notify all users in schedule',
    value: 'NOTIFY_ALL_USERS_IN_SCHEDULE',
    placeHolder: 'No schedule selected',
  },
  {
    label: 'Notify everyone on team',
    value: 'NOTIFY_TEAM',
    placeHolder: 'No team selected',
  },
  {
    label: 'Notify user',
    value: 'NOTIFY_USER',
    placeHolder: 'No user selected',
  },
  {
    label: 'Assign another escalation policy',
    value: 'ASSIGN_ESCALATION_POLICY',
    placeHolder: 'No escalation policy selected',
  },
]

const ifOptions = [
  { label: 'Not acknowledged', value: 'NOT_ACKNOWLEDGED' },
  { label: 'Not closed', value: 'NOT_CLOSED' },
]

const roles = [
  { label: 'Member', value: 'MEMBER' },
  { label: 'Administrator', value: 'ADMINISTRATOR' },
]

const initializers = [
  {
    label:
      'Add me to the team and schedule me for around the clock on-call coverage.',
    value: 'FULL_SETUP',
  },
  {
    label:
      'Add me to the team and target me directly in the default escalation policy.',
    value: 'QUICK_SETUP',
  },
  { label: "I got this, don't do anything else.", value: 'NO_SETUP' },
]

const transformableProviders = ['EMAIL', 'HTTPS']

const teamOptions = {
  actionOptions,
  delayAfterInMinutesOptions,
  ifOptions,
  initializers,
  roles,
  transformableProviders,
}

export default teamOptions
