import {
  CreateEventRulePage,
  CreateIntegrationPage,
  EditEventRulePage,
  EditIntegrationPage,
  IntegrationPage,
} from '../../../pages/Teams'

import React from 'react'
import { Router } from '@reach/router'

export default function IntegrationsStack() {
  return (
    <Router>
      <CreateIntegrationPage path="new" />
      <EditIntegrationPage path=":integrationId/edit" />

      <IntegrationPage path=":integrationId" tab="setup" />
      <IntegrationPage path=":integrationId/eventRules" tab="eventRules" />
      <CreateEventRulePage path=":integrationId/eventRules/new" />
      <EditEventRulePage path=":integrationId/eventRules/:eventRuleIndex/edit" />
    </Router>
  )
}
