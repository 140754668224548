import { unstable_batchedUpdates } from 'react-dom'
import { GraphQLAPI } from '@aws-amplify/api-graphql'
import { Auth } from 'aws-amplify'
import gql from 'graphql-tag'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useMounted from '../hooks/useMounted'
import {
  LoginError,
  R5Logo,
  SplashScreen,
  UserDeactivated,
} from '../components/shared'
import RealTimeClient from '../utils/realTimeClient'
import _ from 'lodash'

const CurrentsContext = createContext()

function useCurrents() {
  return useContext(CurrentsContext)
}

const currentAccountIdStorageKey = '@currentAccountId'
const currentUserIdStorageKey = '@currentUserId'

const fetchCognitoUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser()
  } catch (_err) {}
}

function CurrentsProvider({ wssEndpoint, ...props }) {
  const mounted = useMounted()

  // Initial auth loading state stores whether or not we have completed a
  // request attempt to fetch Cognito credentials. This will be set to false as
  // soon as an auth attempt request returns (regardless of success or
  // failure).
  const [initialAuthLoadComplete, setInitialAuthLoadComplete] = useState(false)

  // Initial data loading state stores whether or not we have completed an
  // initial request attempt to fetch current user data from GraphQL
  // (regardless of success or failure).
  const [initialDataLoadComplete, setInitialDataLoadComplete] = useState(false)

  // Whether or not the connection is known to be in a disconnected state
  const [disconnected, setDisconnected] = useState(false)

  const [account, setAccount] = useState(null)
  const [user, setUser] = useState(null)
  const [cognitoUser, setCognitoUser] = useState(null)
  const accountId = account?.id
  const userId = user?.id

  const realTimeClientRef = useRef(null)

  const unsubscribe = useCallback((subscriptionId) => {
    const sub = _.find(realTimeClientRef.current.subscriptions, {
      id: subscriptionId,
    })
    sub?.unsubscribe()
  }, [])

  const subscribe = useCallback(
    ({ query, variables, onData }) => {
      if (!realTimeClientRef.current) {
        realTimeClientRef.current = new RealTimeClient(wssEndpoint)
      }
      const sub = realTimeClientRef.current.subscribe({
        query,
        variables,
        headers: { 'x-ready-five-account-id': accountId },
        onData,
      })

      return {
        unsubscribe: () => unsubscribe(sub.id),
      }
    },
    [accountId, unsubscribe, wssEndpoint]
  )

  useEffect(() => {
    if (
      realTimeClientRef.current &&
      realTimeClientRef.current.wssEndpoint !== wssEndpoint
    ) {
      realTimeClientRef.current.terminateConnection()
      realTimeClientRef.current = null
    }
  }, [wssEndpoint])

  const loadCurrentUserData = useCallback(async () => {
    setDisconnected(false)

    const cu = await fetchCognitoUser()
    if (!cu) {
      // We can't possibly have current user data without first being
      // authenticated with Cognito.
      if (mounted) {
        unstable_batchedUpdates(() => {
          setInitialAuthLoadComplete(true)

          // Reset everything else
          setCognitoUser(null)
          setUser(null)
          setAccount(null)
          setInitialDataLoadComplete(false)
        })
      }
      return
    }

    if (mounted) {
      unstable_batchedUpdates(() => {
        setCognitoUser(cu)
        setInitialAuthLoadComplete(true)
      })
    }

    let additionalHeaders = {}

    try {
      const currentAccountId = localStorage.getItem(currentAccountIdStorageKey)
      additionalHeaders['x-ready-five-account-id'] = currentAccountId
    } catch (_err) {}

    try {
      const { data } = await GraphQLAPI.graphql({ query }, additionalHeaders)
      if (mounted) {
        localStorage.setItem(currentAccountIdStorageKey, data.viewer.account.id)
        localStorage.setItem(currentUserIdStorageKey, data.viewer.id)

        unstable_batchedUpdates(() => {
          setAccount(data.viewer.account)
          setUser({ ...data.viewer, viewerAccounts: data.viewerAccounts.nodes })
          setInitialDataLoadComplete(true)
        })
      }
    } catch (error) {
      if (mounted) {
        // We're authenticated with Cognito, but GraphQL fetching the current
        // user failed. Maybe they were removed from all accounts or the network
        // is down.
        if (error.errors[0].message === 'Network Error') {
          setDisconnected(error.errors)
          return
        }

        localStorage.removeItem(currentUserIdStorageKey)
        localStorage.removeItem(currentAccountIdStorageKey)

        unstable_batchedUpdates(() => {
          setAccount(null)
          setUser(null)
          setCognitoUser(cu)
          setInitialDataLoadComplete(true)
        })
      }
    }
  }, [mounted])

  // The load() function should contain everything to load or reload current
  // user data.
  const load = useCallback(async () => {
    await loadCurrentUserData()
  }, [loadCurrentUserData])

  // Load everything on mount.
  useEffect(() => {
    load()
  }, [load])

  // Subscribe to real-time updates for the current user. Upon receipt of an
  // update, update the new data directly, then trigger a full reload.
  useEffect(() => {
    if (accountId) {
      const { unsubscribe } = subscribe({
        accountId,
        query: currentUserUpdatedSubscription,
        variables: {
          accountId,
          id: userId,
        },
        onData: (data) => {
          if (!mounted) return
          setUser((prevUser) => ({ ...prevUser, ...data.userUpdated }))
          load()
        },
      })
      return unsubscribe
    }
  }, [mounted, subscribe, accountId, userId, load])

  const setCurrentAccountId = useCallback(
    async (accountId) => {
      localStorage.setItem(currentAccountIdStorageKey, accountId)
      load()
    },
    [load]
  )

  const setCurrentUserId = useCallback(
    async (userId, accountId) => {
      localStorage.setItem(currentUserIdStorageKey, userId)
      localStorage.setItem(currentAccountIdStorageKey, accountId)
      load()
    },
    [load]
  )

  const signIn = useCallback(
    async (email, password, redirect) => {
      const user = await Auth.signIn(email, password)

      if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        return user
      } else {
        await load()
        if (typeof redirect === 'function') redirect()
      }
    },
    [load]
  )

  const confirmSignIn = useCallback(
    async (user, code, redirect) => {
      await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
      await load()
      if (typeof redirect === 'function') redirect()
    },
    [load]
  )

  const signOut = useCallback(async () => {
    await Auth.signOut()

    localStorage.removeItem(currentUserIdStorageKey)
    localStorage.removeItem(currentAccountIdStorageKey)

    unstable_batchedUpdates(() => {
      // Reset all state and re-run initial load
      setInitialAuthLoadComplete(false)
      setInitialDataLoadComplete(false)
      setCognitoUser(null)
      setUser(null)
      setAccount(null)
    })
    load()
  }, [load])

  const contextValue = useMemo(
    () => ({
      account,
      accountId,
      cognitoUser,
      user,
      userId,
      load,
      signIn,
      signOut,
      confirmSignIn,
      setCurrentAccountId,
      setCurrentUserId,
      subscribe,
    }),
    [
      account,
      accountId,
      cognitoUser,
      user,
      userId,
      load,
      signIn,
      signOut,
      confirmSignIn,
      setCurrentAccountId,
      setCurrentUserId,
      subscribe,
    ]
  )

  if (!initialAuthLoadComplete) {
    // Nothing has been loaded at all yet, no idea if the user already has a
    // session. Show the logo.
    return <R5Logo />
  }

  if (cognitoUser && !initialDataLoadComplete) {
    // We have an authenticated user, but current user data is not yet loaded.
    // Show the splash screen.
    return <SplashScreen />
  }

  if (disconnected) {
    return <LoginError errors={disconnected} />
  }

  if (cognitoUser && !user) {
    // Cognito user exists but GraphQL user does not, which means this user
    // previously had an account but was deactivated.
    return <UserDeactivated cognitoUser={cognitoUser} signOut={signOut} />
  }

  return (
    <CurrentsContext.Provider
      key={userId} // Replace all child components when the current user's ID changes
      value={contextValue}
      {...props}
    />
  )
}

export { CurrentsProvider, useCurrents }

const query = gql`
  query Currents {
    viewerAccounts(first: 30) {
      nodes {
        accountId
        accountName
        role
        userId
        userName
      }
    }
    viewer {
      id
      createdAt
      name
      initials
      timeZone
      role
      avatarUrl
      signInEmailAddress
      viewerCanAdminister
      viewerCanChangeEmailAddress
      viewerCanDeactivate
      viewerCanReactivate
      accountId
      account {
        id
        name
        createdAt
        teamsCount
        activeUsersCount
        billingSubscriptionStatus
        billingSubscriptionTrialEndsAt
        billingPaymentCardAdded
        viewerCanAdminister
        viewerCanCreateIncidents
        viewerCanCreateTeams
        viewerCanInviteUsers
        viewerCanManageBilling
      }
      autoResponder {
        until
      }
    }
  }
`

const currentUserUpdatedSubscription = gql`
  subscription onUserUpdated($accountId: ID!, $id: ID) {
    userUpdated(accountId: $accountId, id: $id) {
      id
      createdAt
      name
      initials
      timeZone
      role
      autoResponder {
        until
      }
    }
  }
`
