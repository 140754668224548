import { useCallback, useEffect } from 'react'

import { useJunkDrawer } from '../context/junkDrawer'

export default function useBack(backUrl) {
  const { updateJunkDrawer } = useJunkDrawer()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateJunkDrawerMemo = useCallback(updateJunkDrawer, [])

  useEffect(() => {
    updateJunkDrawerMemo('back', backUrl)
  }, [backUrl, updateJunkDrawerMemo])
}
