import {
  Button,
  Grid,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'

import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useMutation } from '../../hooks'

export default function InvitationItem({ invitation, onInvitationMutation }) {
  const classes = useStyles()
  const theme = useTheme()
  const acceptMutation = useMutation(ACCEPT_INVITATION)
  const declineMutation = useMutation(DECLINE_INVITATION)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [declineLoading, setDecineLoading] = useState(false)
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  async function handleAcceptInvitation() {
    setAcceptLoading(true)
    await acceptMutation({
      accountId: invitation.accountId,
      invitationId: invitation.id,
    })
    onInvitationMutation()
  }

  async function handleDeclineInvitation() {
    setDecineLoading(true)
    await declineMutation({
      accountId: invitation.accountId,
      invitationId: invitation.id,
    })
    onInvitationMutation()
  }

  return (
    <ListItem>
      <Grid className="items-center" container spacing={1}>
        <Grid item xs={12} sm={6}>
          <ListItemText
            classes={{
              primary: classes.primary,
              secondary: classes.secondary,
            }}
            primary={invitation.accountName}
            secondary={`Invited by ${invitation.invitedBy.name}`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            spacing={1}
            justifyContent={mobileView ? null : 'flex-end'}
          >
            <Grid item>
              <Button
                onClick={acceptLoading ? null : handleAcceptInvitation}
                variant="outlined"
              >
                {acceptLoading ? 'Loading...' : 'Accept'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={declineLoading ? null : handleDeclineInvitation}
                variant="outlined"
              >
                {declineLoading ? 'Loading...' : 'Decline'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 14,
    height: 14,
    width: 14,
  },
  avatarRoot: {
    minWidth: theme.spacing(5),
  },
  primary: {
    fontWeight: 'bold',
  },
  secondary: {
    fontWeight: 'bold',
  },
}))

const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input) {
      invitationId
    }
  }
`

const DECLINE_INVITATION = gql`
  mutation DeclineInvitation($input: DeclineInvitationInput!) {
    declineInvitation(input: $input) {
      invitationId
    }
  }
`
