import React, { createContext, useContext } from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { useCurrents } from './currents'
import { useEffect } from 'react'
import _ from 'lodash'

class MyErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error: { error }, errorInfo: errorInfo })
  }

  render() {
    if (this.state.error) return this.props.FallbackComponent(this.state.error)

    return this.props.children
  }
}

let ErrorBoundary = MyErrorBoundary

if (_.some(process.env.REACT_APP_BUGSNAG_API_KEY)) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    enabledReleaseStages: ['production'],
    logger: null,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
  })

  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
}

const BugsnagContext = createContext()

const BugsnagProvider = (props) => {
  const { account, user } = useCurrents()

  useEffect(() => {
    if (_.some(process.env.REACT_APP_BUGSNAG_API_KEY)) {
      const cb = (event) => {
        if (user) event.setUser(user.id, user.signInEmailAddress, user.name)
        if (account) event.addMetadata('account', account)
      }
      Bugsnag.addOnError(cb)
      return () => Bugsnag.removeOnError(cb)
    }
  }, [account, user])

  return (
    <BugsnagContext.Provider value={{ Bugsnag, ErrorBoundary }} {...props} />
  )
}

const useBugsnag = () => useContext(BugsnagContext)

export { BugsnagContext, BugsnagProvider, useBugsnag }
