import {
  ContactMethodsStack,
  IncidentNotificationRulesStack,
  PersonalAccessTokensStack,
  SlackStack,
} from './Profile'
import { EditProfilePage, ProfilePage } from '../../pages/Profile'
import { Redirect, Router } from '@reach/router'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'

export default function ProfileStack() {
  const storedFilter = localStorage.getItem('@filterState/profile')

  return (
    <Router>
      <Redirect from="/" to={storedFilter || 'details'} noThrow />
      <ProfilePage path="details" />
      <ProfilePage path="notifications" tab="notifications" />
      <ProfilePage path="security" tab="security" />
      <EditProfilePage path="/edit" />

      <ContactMethodsStack path="contactMethods/*" />
      <IncidentNotificationRulesStack path="rules/*" />
      <PersonalAccessTokensStack path="tokens/*" />
      <SlackStack path="slack/*" />

      <NotFoundPage default />
    </Router>
  )
}
