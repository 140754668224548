import {
  AppUpdatedSnackbar,
  BadRequestDialog,
  ForbiddenDialog,
  PaymentRequiredDialog,
  R5AppBar,
  R5Drawer,
} from '../components/shared'
import React from 'react'

import makeStyles from '@mui/styles/makeStyles'

export default function AppLayout({ children }) {
  const classes = useStyles()

  return (
    <>
      <R5AppBar />
      <nav className={classes.drawer}>
        <R5Drawer />
      </nav>
      <main className={classes.content}>
        <AppUpdatedSnackbar />
        <ForbiddenDialog />
        <PaymentRequiredDialog />
        <BadRequestDialog />
        {children}
      </main>
    </>
  )
}

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
}))
