import React from 'react'

export default function Add({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.100647H8.00003V8.10064H0L2.52881e-07 10.1006H8.00003V18.1006H10V10.1006L18 10.1006V8.10064H10V0.100647Z"
        fill={color}
      />
    </svg>
  )
}
