import { AlertDialog, R5TextField } from '../../components/shared'
import {
  Box,
  Button,
  CardContent,
  FormHelperText,
  IconButton,
  InputAdornment,
} from '@mui/material'
import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { Auth } from 'aws-amplify'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'

const cognitoShortPasswordError = 'Member must have length greater'
const cognitoPasswordSpacesError = 'Member must satisfy regular expression'
const cognitoPasswordLowerCaseError = 'Password must have lowercase characters'
const cognitoPasswordUpperCaseError = 'Password must have uppercase characters'
const cognitoPasswordNumericError = 'Password must have numeric characters'

export default function PasswordForm() {
  const classes = useStyles()
  const { cognitoUser, load } = useCurrents()

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [showPasswordUpdatedDialog, setShowPasswordUpdatedDialog] =
    useState(false)

  const [validationErrors, setValidationErrors] = useState({})

  async function handlePasswordSubmit(event) {
    event.preventDefault()

    setPasswordLoading(true)
    setValidationErrors({})

    try {
      await Auth.changePassword(cognitoUser, oldPassword, newPassword)
      setPasswordLoading(false)
      setOldPassword('')
      setNewPassword('')
      setShowPasswordUpdatedDialog(true)
    } catch (error) {
      setPasswordLoading(false)
      if (error.message.includes(cognitoShortPasswordError)) {
        setValidationErrors({ newPassword: 'Password is too short' })
      } else if (error.message.includes(cognitoPasswordSpacesError)) {
        setValidationErrors({ newPassword: 'Password cannot contain spaces' })
      } else if (
        error.message.includes(cognitoPasswordLowerCaseError) ||
        error.message.includes(cognitoPasswordUpperCaseError) ||
        error.message.includes(cognitoPasswordNumericError)
      ) {
        setValidationErrors({
          newPassword:
            'Password must have at least one uppercase, lowercase, and numeric character',
        })
      } else if (error.message) {
        setValidationErrors({ password: error.message })
      }
    }
  }

  function handlePasswordCompare() {
    if (_.isEqual(newPassword, newPasswordConfirm)) {
      if (validationErrors['newPasswordConfirm'] === 'Passwords do not match')
        setValidationErrors({ newPasswordConfirm: null })
    } else {
      setValidationErrors({ newPasswordConfirm: 'Passwords do not match' })
    }
  }

  return (
    <CardContent className={classes.content}>
      <form className="flex flex-1 flex-col" onSubmit={handlePasswordSubmit}>
        <R5TextField
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  setShowOldPassword(
                    (prevShowOldPassword) => !prevShowOldPassword
                  )
                }
                size="small"
                tabIndex={-1}
                title={showOldPassword ? 'Hide Password' : 'Show Password'}
              >
                {showOldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          error={_.some(validationErrors['password'])}
          label="Old Password"
          onChange={setOldPassword}
          required
          type={showOldPassword ? 'text' : 'password'}
          value={oldPassword}
        />
        {validationErrors['password'] && (
          <FormHelperText error>{validationErrors['password']}</FormHelperText>
        )}
        <R5TextField
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  setShowNewPassword(
                    (prevShowNewPassword) => !prevShowNewPassword
                  )
                }
                size="small"
                tabIndex={-1}
                title={showNewPassword ? 'Hide Password' : 'Show Password'}
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          error={_.some(validationErrors['newPassword'])}
          label="New Password"
          onChange={setNewPassword}
          required
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
        />
        {validationErrors['newPassword'] && (
          <FormHelperText error>
            {validationErrors['newPassword']}
          </FormHelperText>
        )}
        <R5TextField
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  setShowNewPasswordConfirm(
                    (prevShowNewPasswordConfirm) => !prevShowNewPasswordConfirm
                  )
                }
                size="small"
                tabIndex={-1}
                title={
                  showNewPasswordConfirm ? 'Hide Password' : 'Show Password'
                }
              >
                {showNewPasswordConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          error={_.some(validationErrors['newPasswordConfirm'])}
          label="Confirm New Password"
          onBlur={handlePasswordCompare}
          onChange={setNewPasswordConfirm}
          required
          type={showNewPasswordConfirm ? 'text' : 'password'}
          value={newPasswordConfirm}
        />
        {validationErrors['newPasswordConfirm'] && (
          <FormHelperText error>
            {validationErrors['newPasswordConfirm']}
          </FormHelperText>
        )}
        <Box className="flex flex-1 flex-row justify-end mt-md">
          <Button
            disabled={
              passwordLoading ||
              oldPassword.length === 0 ||
              newPassword.length === 0 ||
              !_.isEqual(newPassword, newPasswordConfirm)
            }
            type="submit"
            variant="outlined"
          >
            Update Password
          </Button>
        </Box>
      </form>
      <AlertDialog
        cancelText="Ok"
        content="Your password was successfully updated."
        handleClose={() => {
          setShowPasswordUpdatedDialog(false)
          load()
        }}
        open={showPasswordUpdatedDialog}
        title="Password Updated"
      />
    </CardContent>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}))
