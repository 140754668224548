import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { Paper } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function R5ButtonGroup({
  buttons,
  className,
  onChange,
  saveState,
  selectedValue,
  size,
}) {
  const classes = useStyles()

  return (
    <Paper
      elevation={0}
      className={className}
      sx={{
        display: 'flex',
        backgroundColor: styles.headerButtonGroup.backgroundColor,
        borderRadius: 100,
        flexWrap: 'wrap',
      }}
    >
      <ToggleButtonGroup
        classes={{
          grouped: size === 'small' ? classes.smallGrouped : classes.grouped,
        }}
        exclusive
        onChange={
          onChange
            ? (_event, value) => (_.isNull(value) ? undefined : onChange(value))
            : undefined
        }
        size="small"
        value={selectedValue}
      >
        {buttons.map(({ disabled, label, to, value }) => (
          <ToggleButton
            component={_.isUndefined(to) ? undefined : RouterLink}
            disabled={disabled}
            key={value}
            style={{
              backgroundColor:
                selectedValue === value
                  ? styles.buttonGroupSelectedButton.backgroundColor
                  : null,
              color:
                selectedValue === value
                  ? styles.buttonGroupSelectedText.color
                  : styles.buttonGroupText.color,
            }}
            onClick={
              saveState ? () => localStorage.setItem(saveState, to) : null
            }
            sx={{ whiteSpace: 'nowrap' }}
            to={to}
            value={value}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    borderWidth: 0,
    fontSize: 14,
    fontWeight: 'bold',
    padding: '0.25em 1.25em',
    '&:not(:first-child)': {
      borderRadius: '100px !important',
    },
    '&:first-child': {
      borderRadius: '100px !important',
    },
  },
  smallGrouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    borderWidth: 0,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '0.25em 0.75em',
    '&:not(:first-child)': {
      borderRadius: '100px !important',
    },
    '&:first-child': {
      borderRadius: '100px !important',
    },
  },
}))
