import {
  CreateIncidentNotificationRulePage,
  CreateIncidentNotificationRuleStepPage,
  EditIncidentNotificationRulePage,
  EditIncidentNotificationRuleStepPage,
  IncidentNotificationRulePage,
} from '../../../pages/Profile'
import { Redirect, Router } from '@reach/router'

import React from 'react'

export default function IncidentNotificationRulesStack() {
  return (
    <Router>
      <Redirect from="/" to="/profile/notifications" noThrow />

      <CreateIncidentNotificationRulePage path="new" />
      <IncidentNotificationRulePage path=":incidentNotificationRuleId" />
      <EditIncidentNotificationRulePage path=":incidentNotificationRuleId/edit" />

      <CreateIncidentNotificationRuleStepPage path=":incidentNotificationRuleId/steps/new" />
      <EditIncidentNotificationRuleStepPage path=":incidentNotificationRuleId/steps/:stepId/edit" />
    </Router>
  )
}
