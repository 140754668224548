import React from 'react'

export default function Teams({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2 6.20003C13.2 7.96736 11.7673 9.40007 9.99995 9.40007C8.23262 9.40007 6.79991 7.96736 6.79991 6.20003C6.79991 4.4327 8.23262 3 9.99995 3C11.7673 3 13.2 4.4327 13.2 6.20003ZM8.40007 14.2001C8.40007 15.9675 6.96736 17.4002 5.20003 17.4002C3.4327 17.4002 2 15.9675 2 14.2001C2 12.4328 3.4327 11.0001 5.20003 11.0001C6.96736 11.0001 8.40007 12.4328 8.40007 14.2001ZM14.7999 17.4002C16.5672 17.4002 17.9999 15.9675 17.9999 14.2001C17.9999 12.4328 16.5672 11.0001 14.7999 11.0001C13.0325 11.0001 11.5998 12.4328 11.5998 14.2001C11.5998 15.9675 13.0325 17.4002 14.7999 17.4002Z"
        fill={color}
      />
    </svg>
  )
}
