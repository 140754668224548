import React from 'react'

export default function MinusCircleOutline({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26099 10.9131H12.7393V9.48395H7.26099V10.9131Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 15.5709C6.92328 15.5709 4.42911 13.0767 4.42911 10C4.42911 6.92328 6.92328 4.42911 10 4.42911C13.0767 4.42911 15.5709 6.92328 15.5709 10C15.5709 13.0767 13.0767 15.5709 10 15.5709Z"
        fill={color}
      />
    </svg>
  )
}
