import { ActivityIndicator, R5Error, Loader } from '../shared'
import { Box, Button, List, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as NotFound } from '../illustrations/NotFound.svg'
import { Link as RouterLink } from '@reach/router'
import UserItem from './UserItem'
import _ from 'lodash'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'

export default function AllUsers({ search }) {
  const { user, accountId, subscribe } = useCurrents()
  const { data, errors, fetchMore, fetchingMore, loading, refetch } =
    useQuery(QUERY)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])
  const handleNextDebounced = useMemo(
    () => _.debounce(handleNext, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.users?.pageInfo?.hasNextPage, fetchingMore]
  )

  useEffect(() => {
    if (!data.users) return
    if (search.length > 0) handleNextDebounced()
  }, [data.users, handleNextDebounced, search.length])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: USER_CREATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: USER_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: USER_DELETE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  function handleNext() {
    if (fetchingMore) return

    if (data.users.pageInfo.hasNextPage) {
      fetchMore({
        variables: { cursor: data.users.pageInfo.endCursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.users.nodes.length
            ? {
                users: {
                  __typename: previousResult.users.__typename,
                  nodes: [
                    ...previousResult.users.nodes,
                    ...fetchMoreResult.users.nodes,
                  ],
                  pageInfo: fetchMoreResult.users.pageInfo,
                },
              }
            : previousResult
        },
      })
    }
  }

  function renderNoResults() {
    if (fetchingMore) return null

    return (
      <Box className="flex flex-col  items-center justify-center">
        <NotFound height={150} width={150} />
        <Typography variant="h5" gutterBottom>
          No users match your search
        </Typography>
        {user.account.viewerCanInviteUsers && (
          <Box className="flex flex-1 flex-row">
            <Button
              className="mr-sm"
              component={RouterLink}
              to="/users/invite"
              variant="outlined"
            >
              + Invite {search}
            </Button>
            <Button
              component={RouterLink}
              to="?q=scope:deactivated"
              variant="outlined"
            >
              View Deactivated Users
            </Button>
          </Box>
        )}
      </Box>
    )
  }

  const selectedUsers = data.users.nodes.filter((user) =>
    user.name.toLowerCase().includes(search.toLowerCase())
  )

  return selectedUsers.length ? (
    <InfiniteScroll
      dataLength={selectedUsers.length}
      hasChildren={_.some(selectedUsers)}
      hasMore={data.users.pageInfo.hasNextPage}
      loader={<ActivityIndicator />}
      next={handleNext}
    >
      <List>
        {selectedUsers.map((user) => (
          <UserItem key={user.id} user={user} />
        ))}
      </List>
    </InfiniteScroll>
  ) : (
    renderNoResults()
  )
}

const QUERY = gql`
  query AllUsers($cursor: String) {
    users(after: $cursor, first: 30) {
      nodes {
        id
        name
        initials
        isDeactivated
        role
        avatarUrl
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const USER_CREATE_SUBSCRIPTION = gql`
  subscription onUserCreated($accountId: ID!) {
    userCreated(accountId: $accountId) {
      id
    }
  }
`

const USER_UPDATE_SUBSCRIPTION = gql`
  subscription onUserUpdated($accountId: ID!) {
    userUpdated(accountId: $accountId) {
      id
      updatedAt
    }
  }
`

const USER_DELETE_SUBSCRIPTION = gql`
  subscription onUserDeleted($accountId: ID!) {
    userDeleted(accountId: $accountId) {
      id
    }
  }
`
