import React from 'react'

import AlertDialog from './AlertDialog'
import { MenuItem } from '@mui/material'

export default class ConfirmMenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  render() {
    const {
      cancelText,
      children,
      confirmContent,
      confirmText,
      onConfirm,
      title,
      ...restProps
    } = this.props

    return (
      <>
        <MenuItem onClick={() => this.setState({ open: true })} {...restProps}>
          {children ? children : 'Delete'}
        </MenuItem>
        <AlertDialog
          cancelText={cancelText}
          confirmText={confirmText}
          content={confirmContent}
          handleClose={() => this.setState({ open: false })}
          handleConfirm={() => {
            this.setState({ open: false })
            onConfirm()
          }}
          open={this.state.open}
          title={title}
        />
      </>
    )
  }
}
