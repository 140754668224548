import React from 'react'

export default function Person({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5002 9.8C12.6541 9.8 14.4002 8.05391 14.4002 5.9C14.4002 3.74609 12.6541 2 10.5002 2C8.34625 2 6.60016 3.74609 6.60016 5.9C6.60016 8.05391 8.34625 9.8 10.5002 9.8ZM10.5 11.5333L4 13.7699V18.4667H17V13.7699L10.5 11.5333Z"
        fill={color}
      />
    </svg>
  )
}
