import moment from 'moment-timezone'

function coverageDateFormat(momentDate) {
  if (moment.unix(0).diff(momentDate) === 0) {
    return '∞'
  }

  const now = moment()
  const currentYear = now.year()
  const date = momentDate.tz(moment.tz.guess(true))
  const isSameYear = date.year() === currentYear
  const timeEndsWithZero = date.minute() === 0
  const isToday = now.isSame(date, 'day')
  const isTomorrow = now.add(1, 'day').isSame(date, 'day')

  const dayFormat = isToday
    ? '[Today]'
    : isTomorrow
    ? '[Tomorrow]'
    : isSameYear
    ? 'ddd, MMM D'
    : 'ddd, MMM D YYYY'
  const timeFormat = timeEndsWithZero ? 'h A' : 'h:mm A'
  const format = `${dayFormat} [at] ${timeFormat}`

  const formatted = date.format(format)

  if (formatted === 'Tomorrow at 12 AM') {
    return '12 AM (Midnight)'
  }

  return formatted
}

// Takes a pair of moment dates that should be displayed as a range and
// formats them in a friendly way. If the date for the start/end is the
// same, strip it from the end display and replace the "at" in the start
// with "from" since it reads nicer.
function coverageDateFormatRange(startsAtMomentDate, endsAtMomentDate) {
  const startsAtFormatted = coverageDateFormat(startsAtMomentDate)
  const endsAtFormatted = coverageDateFormat(endsAtMomentDate)
  const startsAtDateOnly = startsAtFormatted.split('at')[0]
  const endsAtSplit = endsAtFormatted.split('at')
  if (startsAtDateOnly === endsAtSplit[0]) {
    return `${startsAtFormatted.replace('at', 'from')} - ${endsAtSplit[1]}`
  }
  return `${startsAtFormatted} - ${endsAtFormatted}`
}

export { coverageDateFormat, coverageDateFormatRange }
