import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { EscalationPolicyStepForm } from '../../components/Teams'
import React from 'react'
import _ from 'lodash'
import gql from 'graphql-tag'

export default function EditEscalationPolicyStepPage({
  escalationPolicyId,
  stepId,
  teamId,
}) {
  useBack(`/teams/${teamId}/escalationPolicies/${escalationPolicyId}`)
  const { data, errors, loading } = useQuery(QUERY, {
    teamId,
    escalationPolicyId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  const stepNumber =
    _.map(data.escalationPolicy.steps, 'id').indexOf(stepId) + 1

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Escalation Policy Step" />
      <R5Header title={`Step ${stepNumber}`} />

      <EscalationPolicyStepForm
        escalationPolicy={data.escalationPolicy}
        step={data.escalationPolicy.steps.find((step) => step.id === stepId)}
        team={data.escalationPolicy.team}
      />
    </R5Container>
  )
}

const QUERY = gql`
  query EditEscalationPolicyStep($teamId: ID!, $escalationPolicyId: ID!) {
    escalationPolicy(id: $escalationPolicyId, teamId: $teamId) {
      id
      name
      team {
        id
        name
      }
      steps {
        id
        condition
        delayInMinutes
        escalations {
          action
          target {
            id
            name
          }
        }
      }
    }
  }
`
