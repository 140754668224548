import React from 'react'

export default function Clock({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5385 6.76923V10.5385H12.9615V11.6154H9.46154V6.76923H10.5385Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM15.9231 10C15.9231 13.2712 13.2712 15.9231 10 15.9231C6.72878 15.9231 4.07692 13.2712 4.07692 10C4.07692 6.72878 6.72878 4.07692 10 4.07692C13.2712 4.07692 15.9231 6.72878 15.9231 10Z"
        fill={color}
      />
    </svg>
  )
}
