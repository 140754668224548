import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Autocomplete } from '@mui/material'
import { R5Error } from '../shared'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'

export default function TimeOffUserSelect({ setToUser, toUser }) {
  const classes = useStyles()
  const { user } = useCurrents()
  const { data, errors, fetchMore, fetchingMore, loading } = useQuery(QUERY)
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (data.users)
      setUsers(
        [
          { id: 'nobody', initials: 'NB', name: 'Nobody' },
          ...data.users.nodes,
        ].filter((user) => user.id !== user?.id)
      )
  }, [user.id, data, loading])

  useEffect(() => {
    if (!loading && !fetchingMore && data.users.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data.users.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.users.nodes.length
            ? {
                users: {
                  __typename: previousResult.users.__typename,
                  nodes: [
                    ...previousResult.users.nodes,
                    ...fetchMoreResult.users.nodes,
                  ],
                  pageInfo: fetchMoreResult.users.pageInfo,
                },
              }
            : previousResult
        },
      })
    }
  }, [data, fetchMore, fetchingMore, loading])

  if (errors) return <R5Error errors={errors} />

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent className={classes.content}>
        <FormControl fullWidth>
          <InputLabel className={classes.label} htmlFor="to-user">
            Who is covering you?
          </InputLabel>
          <Autocomplete
            blurOnSelect
            classes={{
              clearIndicator: classes.clearIndicator,
              inputRoot: classes.inputRoot,
              popupIndicator: classes.popupIndicator,
              root: classes.root,
            }}
            disableClearable
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.name
            }
            isOptionEqualToValue={(option, value) => option.name === value}
            id="to-user"
            options={users}
            onChange={(_event, option) => setToUser(option)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            size="small"
            value={toUser.name}
          />
        </FormControl>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
    backgroundColor: styles.primary.color,
    marginBottom: theme.spacing(3),
  },
  clearIndicator: {
    color: theme.palette.common.white,
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  inputRoot: {
    backgroundColor: '#ffffff33',
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
  popupIndicator: {
    color: theme.palette.common.white,
  },
  label: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
    width: 240,
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.common.white,
      },
    },
  },
}))

const QUERY = gql`
  query ToUserSelect($cursor: String) {
    users(after: $cursor, first: 30) {
      nodes {
        id
        name
        initials
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
