import {
  ActivityIndicator,
  Loader,
  R5AddButton,
  R5ButtonGroup,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import {
  AllActionButton,
  AllClosedIncidents,
  AllOpenIncidents,
  IncidentItem,
  MyClosedIncidents,
  MyOpenIncidents,
  MyTeamsClosedIncidents,
  MyTeamsOpenIncidents,
} from '../../components/Incidents'
import { Box, Typography } from '@mui/material'
import { useBack, useQueryParams } from '../../hooks'

import { ReactComponent as EmptyIncidents } from '../../components/illustrations/EmptyIncidents.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import React from 'react'
import { useCurrents } from '../../context/currents'

export default function IncidentsPage() {
  useBack(null)
  const { user } = useCurrents()
  let { scope = 'mine', state = 'open' } = useQueryParams()

  if (!['open', 'closed'].includes(state)) state = 'open'
  if (
    !(
      user.role !== 'MEMBER'
        ? ['mine', 'my-teams', 'all']
        : ['mine', 'my-teams']
    ).includes(scope)
  )
    scope = 'mine'

  const IncidentsComponent =
    scope === 'mine'
      ? state === 'open'
        ? MyOpenIncidents
        : MyClosedIncidents
      : scope === 'my-teams'
      ? state === 'open'
        ? MyTeamsOpenIncidents
        : MyTeamsClosedIncidents
      : scope === 'all' && state === 'open'
      ? AllOpenIncidents
      : AllClosedIncidents

  return (
    <R5Container>
      <R5Title title="Incidents" />
      <IncidentsComponent Incidents={Incidents} />
      {user.account.viewerCanCreateIncidents && (
        <R5AddButton to="/incidents/new" />
      )}
    </R5Container>
  )
}

function Incidents({ handleNext, incidents, loading }) {
  const { user } = useCurrents()
  let { scope = 'mine', state = 'open' } = useQueryParams()

  if (!['open', 'closed'].includes(state)) state = 'open'
  if (
    !(
      user.role !== 'MEMBER'
        ? ['mine', 'my-teams', 'all']
        : ['mine', 'my-teams']
    ).includes(scope)
  )
    scope = 'mine'

  function emptyText() {
    if (state === 'open') {
      switch (scope) {
        case 'mine':
          return "You're all clear!"
        case 'my-teams':
          return 'Your teams are all clear!'
        case 'all':
          return 'All clear!'
        default:
          return null
      }
    } else {
      switch (scope) {
        case 'mine':
          return "You don't have any closed incidents."
        case 'my-teams':
          return 'There are no closed incidents across your teams.'
        case 'all':
          return 'There are no closed incidents.'
        default:
          return null
      }
    }
  }

  function renderEmpty() {
    return (
      <div className="flex flex-col items-center justify-center mt-1/10 text-center">
        <EmptyIncidents />
        <Typography variant="h5" gutterBottom>
          {emptyText()}
        </Typography>
      </div>
    )
  }

  return (
    <>
      <R5Header
        title="Incidents"
        action={<AllActionButton incidents={incidents.nodes} />}
        addText="Add Incident"
        addUrl={user.account.viewerCanCreateIncidents ? '/incidents/new' : null}
        helpText={
          <>
            <Typography paragraph>
              <strong>
                An incident is an occurrence in your account that requires a
                person's attention.
              </strong>
            </Typography>
            <Typography paragraph>
              Since incidents can happen at any moment, it's important that each
              team has properly configured their escalation policies and
              schedules and that each person has setup their personal
              notification settings.
            </Typography>
          </>
        }
        helpUrl="https://www.readyfive.io/docs/incident-basics"
      >
        [
        <StateHeaderButton scope={scope} state={state} />
        ,
        <ScopeHeaderButton user={user} scope={scope} state={state} />]
      </R5Header>
      {loading ? (
        <Loader />
      ) : incidents.nodes.length > 0 ? (
        <InfiniteScroll
          dataLength={incidents.nodes.length}
          hasMore={incidents.pageInfo.hasPreviousPage}
          loader={<ActivityIndicator />}
          next={handleNext}
        >
          {incidents.nodes.map((incident) => (
            <Box className="mb-5" key={incident.id}>
              <IncidentItem incident={incident} index />
            </Box>
          ))}
        </InfiniteScroll>
      ) : (
        renderEmpty()
      )}
    </>
  )
}

function StateHeaderButton({ scope, state }) {
  return (
    <R5ButtonGroup
      buttons={[
        {
          label: 'Open',
          to: `${scope === 'mine' ? '' : `?q=scope:${scope}`}`,
          value: 'open',
        },
        {
          label: 'Closed',
          to: `?q=${scope === 'mine' ? '' : `scope:${scope}+`}state:closed`,
          value: 'closed',
        },
      ]}
      key={0}
      saveState="@filterState/incidents"
      selectedValue={state}
    />
  )
}

function ScopeHeaderButton({ user, scope, state }) {
  return (
    <R5ButtonGroup
      buttons={
        user.role !== 'MEMBER'
          ? [
              {
                label: 'Mine',
                to: `${state === 'open' ? '' : `?q=state:${state}`}`,
                value: 'mine',
              },
              {
                label: 'My Teams',
                to: `?q=scope:my-teams${
                  state === 'open' ? '' : `+state:${state}`
                }`,
                value: 'my-teams',
              },
              {
                label: 'All',
                to: `?q=scope:all${state === 'open' ? '' : `+state:${state}`}`,
                value: 'all',
              },
            ]
          : [
              {
                label: 'Mine',
                to: `${state === 'open' ? '' : `?q=state:${state}`}`,
                value: 'mine',
              },
              {
                label: 'My Teams',
                to: `?q=scope:my-teams${
                  state === 'open' ? '' : `+state:${state}`
                }`,
                value: 'my-teams',
              },
            ]
      }
      key={1}
      saveState="@filterState/incidents"
      selectedValue={scope}
    />
  )
}
