import * as Icons from '../icons'

import { Box, Card, CardContent, Typography } from '@mui/material'

import { ActivityIndicator } from '../../components/shared'
import InfiniteScroll from 'react-infinite-scroll-component'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'

export default function Timeline({ handleNext, incident }) {
  const classes = useStyles()

  function color(item) {
    switch (item.type) {
      case 'INCIDENT_ACKNOWLEDGED':
      case 'INCIDENT_AUTO_ACKNOWLEDGED':
        return '#FFAB2D'
      case 'INCIDENT_REDACTED':
      case 'INCIDENT_RESOLVED':
        return '#63E478'
      case 'INCIDENT_SNOOZED':
        return '#9BAFC6'
      case 'INCIDENT_TRIGGERED':
      case 'INCIDENT_RETRIGGERED':
        return '#EB5757'
      default:
        return '#3F47F4'
    }
  }

  function icon(item) {
    switch (item.type) {
      case 'INCIDENT_ACKNOWLEDGED':
      case 'INCIDENT_AUTO_ACKNOWLEDGED':
        return Icons.ExclamationCircle
      case 'INCIDENT_ASSIGNED':
        return Icons.PersonCircle
      case 'INCIDENT_REDACTED':
        return Icons.RedactedCircle
      case 'INCIDENT_RESOLVED':
        return Icons.CheckCircle
      case 'INCIDENT_SNOOZED':
        return Icons.ClockCircle
      case 'INCIDENT_TRIGGERED':
      case 'INCIDENT_RETRIGGERED':
        return Icons.BellCircle
      default:
        return null
    }
  }

  function mainAction(item) {
    switch (item.type) {
      case 'INCIDENT_ACKNOWLEDGED':
      case 'INCIDENT_AUTO_ACKNOWLEDGED':
      case 'INCIDENT_ASSIGNED':
      case 'INCIDENT_REDACTED':
      case 'INCIDENT_RESOLVED':
      case 'INCIDENT_SNOOZED':
      case 'INCIDENT_TRIGGERED':
      case 'INCIDENT_RETRIGGERED':
        return true
      default:
        return false
    }
  }

  function subtitle(item) {
    switch (item.type) {
      case 'CONTACT_ATTEMPT_EMAIL':
        return `Sent email to ${item.targets[0].name}`
      case 'CONTACT_ATTEMPT_PUSH':
        return `Sent push notification to ${item.targets[0].name}`
      case 'CONTACT_ATTEMPT_PUSH_CRITICAL':
        return `Sent push notification to ${item.targets[0].name} (critical alert)`
      case 'CONTACT_ATTEMPT_SLACK':
        return `Sent Slack notification to ${item.targets[0].name}`
      case 'CONTACT_ATTEMPT_SMS':
        return `Sent text message to ${item.targets[0].name}`
      case 'CONTACT_ATTEMPT_VOICE':
        return `Sent voice call to ${item.targets[0].name}`
      case 'CONTACT_ATTEMPT_FAILED_NO_STEPS':
        return `Failed to contact ${item.targets[0].name} because their notification rules are not configured`
      case 'CONTACT_ATTEMPT_FAILED_SEND_ERROR':
        return `Failed to contact ${item.targets[0].name} due to an unknown error`
      case 'ESCALATION_FAILED_NO_STEPS':
        return `Failed to escalate to anyone in the ${item.targets[0].name} policy because no steps are configured`
      case 'INCIDENT_ACKNOWLEDGED':
        return `Acknowledged by ${item.actor.name}, triggers again ${moment
          .unix(item.triggerAt)
          .format('M/D/YY LT')}`
      case 'INCIDENT_AUTO_ACKNOWLEDGED':
        return `Auto responder for ${
          item.actor.name
        } acknowledged, triggers again ${moment
          .unix(item.triggerAt)
          .format('M/D/YY LT')}`
      case 'INCIDENT_ASSIGNED':
        return `${item.actor.name} assigned to ${item.targets
          .map((t) => t.name)
          .join(', ')}`
      case 'INCIDENT_REDACTED':
        return `Redacted by ${item.actor.name}`
      case 'INCIDENT_RESOLVED':
        return `Resolved by ${item.actor.name}`
      case 'INCIDENT_SNOOZED':
        return `Snoozed by ${item.actor.name} until ${moment
          .unix(item.triggerAt)
          .format('M/D/YY LT')}`
      case 'INCIDENT_TRIGGERED':
        if (!item.targets.length) return `Triggered by ${item.actor.name}`
        return `Triggered by ${item.actor.name}, assigned to ${item.targets
          .map((t) => t.name)
          .join(', ')}`
      case 'INCIDENT_RETRIGGERED':
        if (!item.targets.length) return `Retriggered by ${item.actor.name}`
        return `Retriggered by ${item.actor.name}, assigned to ${item.targets
          .map((t) => t.name)
          .join(', ')}`
      default:
        return item.type
    }
  }

  function EventItem({ event }) {
    const IconComponent = icon(event)
    return (
      <Card className={classes.root} variant="outlined">
        <CardContent className={classes.content}>
          <Box className="flex flex-1 flex-row items-center">
            <Box className="pr-3">
              {mainAction(event) ? (
                <IconComponent color={color(event)} size={40} />
              ) : (
                <Box className="m-3">
                  <div
                    style={{
                      backgroundColor: '#3F47F4',
                      borderRadius: 16,
                      height: 16,
                      width: 16,
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box>
              <Typography className={classes.subtitle} color="textSecondary">
                {moment.unix(event.createdAt).format('M/D/YY LT')}
              </Typography>
              <Typography className={classes.title}>
                {subtitle(event)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    )
  }

  return (
    <InfiniteScroll
      dataLength={incident.events.nodes.length}
      hasMore={incident.events.pageInfo.hasPreviousPage}
      loader={<ActivityIndicator />}
      next={handleNext}
    >
      {incident.events.nodes.map((event, index) => (
        <Box key={index}>
          <EventItem event={event} key={index} />
          {index !== incident.events.nodes.length - 1 && (
            <Box className={classes.divider} />
          )}
        </Box>
      ))}
    </InfiniteScroll>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  divider: {
    backgroundColor: styles.border.color,
    height: theme.spacing(3),
    marginLeft: '2.5em',
    width: 3,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '140%',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '140%',
  },
}))
