const names = [
  { value: 'Home', label: 'Home' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Work', label: 'Work' },
]

const types = [
  { value: 'EMAIL', label: 'Email' },
  { value: 'SMS', label: 'Phone (SMS)' },
  { value: 'VOICE', label: 'Phone (Voice)' },
  { value: 'SLACK', label: 'Slack' },
]

const actions = [
  {
    label: 'Send a push notification to',
    value: 'PUSH',
  },
  { label: 'Send an email to', value: 'EMAIL' },
  { label: 'Send an SMS to', value: 'SMS' },
  { label: 'Make a phone call to', value: 'VOICE' },
  { label: 'Send a Slack message to', value: 'SLACK' },
]

const repeatAfterInMinutes = [
  { label: '1 minute', value: 1 },
  { label: '2 minutes', value: 2 },
  { label: '3 minutes', value: 3 },
  { label: '4 minutes', value: 4 },
  { label: '5 minutes', value: 5 },
  { label: '6 minutes', value: 6 },
  { label: '7 minutes', value: 7 },
  { label: '8 minutes', value: 8 },
  { label: '9 minutes', value: 9 },
  { label: '10 minutes', value: 10 },
  { label: '11 minutes', value: 11 },
  { label: '12 minutes', value: 12 },
  { label: '13 minutes', value: 13 },
  { label: '14 minutes', value: 14 },
  { label: '15 minutes', value: 15 },
  { label: '16 minutes', value: 16 },
  { label: '17 minutes', value: 17 },
  { label: '18 minutes', value: 18 },
  { label: '19 minutes', value: 19 },
  { label: '20 minutes', value: 20 },
  { label: '25 minutes', value: 25 },
  { label: '30 minutes', value: 30 },
  { label: '35 minutes', value: 35 },
  { label: '40 minutes', value: 40 },
  { label: '45 minutes', value: 45 },
  { label: '50 minutes', value: 50 },
  { label: '55 minutes', value: 55 },
  { label: '1 hour', value: 60 },
  { label: '1.5 hours', value: 90 },
  { label: '2 hours', value: 120 },
  { label: '6 hours', value: 360 },
  { label: '12 hours', value: 720 },
  { label: '1 day', value: 1440 },
]

const delayInMinutes = [
  { label: 'Immediately', value: 0 },
  { label: 'After 1 minute', value: 1 },
  { label: 'After 2 minutes', value: 2 },
  { label: 'After 3 minutes', value: 3 },
  { label: 'After 4 minutes', value: 4 },
  { label: 'After 5 minutes', value: 5 },
  { label: 'After 6 minutes', value: 6 },
  { label: 'After 7 minutes', value: 7 },
  { label: 'After 8 minutes', value: 8 },
  { label: 'After 9 minutes', value: 9 },
  { label: 'After 10 minutes', value: 10 },
  { label: 'After 11 minutes', value: 11 },
  { label: 'After 12 minutes', value: 12 },
  { label: 'After 13 minutes', value: 13 },
  { label: 'After 14 minutes', value: 14 },
  { label: 'After 15 minutes', value: 15 },
  { label: 'After 16 minutes', value: 16 },
  { label: 'After 17 minutes', value: 17 },
  { label: 'After 18 minutes', value: 18 },
  { label: 'After 19 minutes', value: 19 },
  { label: 'After 20 minutes', value: 20 },
  { label: 'After 25 minutes', value: 25 },
  { label: 'After 30 minutes', value: 30 },
  { label: 'After 35 minutes', value: 35 },
  { label: 'After 40 minutes', value: 40 },
  { label: 'After 45 minutes', value: 45 },
  { label: 'After 50 minutes', value: 50 },
  { label: 'After 55 minutes', value: 55 },
  { label: 'After 1 hour', value: 60 },
  { label: 'After 1.5 hours', value: 90 },
  { label: 'After 2 hours', value: 120 },
  { label: 'After 6 hours', value: 360 },
  { label: 'After 12 hours', value: 720 },
  { label: 'After 1 day', value: 1440 },
]

const profileOptions = {
  actions,
  delayInMinutes,
  names,
  repeatAfterInMinutes,
  types,
}

export default profileOptions
