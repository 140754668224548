import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  ConfirmButton,
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Infotip,
  R5RadioGroup,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'
import { useBack, useMutation, useQuery } from '../../hooks'

import { NotFoundPage } from '../../pages/utils'
import { Link as RouterLink } from '@reach/router'
import { User } from '../../components/illustrations'
import UserOptions from '../../constants/UserOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function EditUserPage({ navigate, userId }) {
  useBack(`/users/${userId}`)
  return (
    <R5Container>
      <R5Title title="Edit User" />
      <R5Header title="Edit User" />
      <UserLoader navigate={navigate} userId={userId} />
    </R5Container>
  )
}

function UserLoader({ navigate, userId }) {
  const { data, loading, errors } = useQuery(QUERY, { userId })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (
    !(
      data.user.viewerCanChangeRole ||
      data.user.viewerCanDeactivate ||
      data.user.viewerCanReactivate
    )
  )
    return <NotFoundPage />

  return <UserForm navigate={navigate} user={data.user} />
}

function UserForm({ navigate, user }) {
  const classes = useStyles()
  const changeRoleMutation = useMutation(CHANGE_USER_ROLE)
  const deactivateMutation = useMutation(DEACTIVATE_USER)
  const reactivateMutation = useMutation(REACTIVATE_USER)
  const [role, setRole] = useState(user.role)
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission

    setLoading(true)

    try {
      await changeRoleMutation({
        userId: user.id,
        role,
      })
      setLoading(false)
      navigate(`/users/${user.id}`)
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDeactivate() {
    setLoading(true)

    try {
      await deactivateMutation({ userId: user.id })
      setLoading(false)
      navigate('/users')
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  async function handleReactivate() {
    setLoading(true)

    try {
      await reactivateMutation({ userId: user.id })
      setLoading(false)
      navigate(`/users/${user.id}`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-sm ${
          user.viewerCanDeactivate || user.viewerCanReactivate
            ? 'justify-between'
            : 'justify-end'
        }`}
      >
        {!user.isDeactivated && user.viewerCanDeactivate && (
          <ConfirmButton
            confirmContent={`Are you sure you want to deactivate ${user.name} in this account? They will be removed from any teams, removed as targets from any integrations, will no longer be counted as an active user for billing purposes, and will no longer have login access to this account.`}
            confirmText="Yes, deactivate user"
            error
            onConfirm={handleDeactivate}
            title="Deactivate User"
            variant="outlined"
          >
            Deactivate User
          </ConfirmButton>
        )}
        {user.isDeactivated && user.viewerCanReactivate && (
          <ConfirmButton
            color="primary"
            confirmContent={`Are you sure you want reactivate ${user.name} in this account? They will be able to login again and will be counted as an active user for billing purposes.`}
            confirmText="Yes, reactivate user"
            onConfirm={handleReactivate}
            title="Reactivate User"
            variant="outlined"
          >
            Reactivate User
          </ConfirmButton>
        )}

        <Box className="flex flex-row">
          <Button
            className={classes.marginRight}
            component={RouterLink}
            size="large"
            to={`/users/${user.id}`}
          >
            Cancel
          </Button>

          <Button
            disabled={
              loading || user.isDeactivated || !user.viewerCanChangeRole
            }
            size="large"
            type="submit"
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Card variant="outlined">
      <form onSubmit={handleSubmit}>
        <CardContent className={classes.content}>
          <Box className="flex flex-1 flex-row items-center">
            <Box className="flex flex-1 flex-col">
              <Typography className={classes.title} color="textSecondary">
                User
              </Typography>
              <ListItem disableGutters>
                <ListItemIcon className={classes.icon}>
                  <Icons.Person color={styles.lightLabel.color} size={20} />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.subtitle }}>
                  {user.name}
                </ListItemText>
              </ListItem>
              <Box className="mt-lg">
                <R5RadioGroup
                  buttons={
                    user.role === 'OWNER'
                      ? UserOptions.roles
                      : _.reject(UserOptions.roles, { label: 'Owner' })
                  }
                  disabled={
                    user.role === 'OWNER' ||
                    user.isDeactivated ||
                    !user.viewerCanChangeRole
                  }
                  error={_.some(validationErrors['role'])}
                  label="Role"
                  onChange={setRole}
                  toolTip={
                    <R5Infotip
                      learnMore="https://www.readyfive.io/docs/users/roles-and-permissions"
                      title={
                        <>
                          <Typography paragraph>
                            <strong>Member</strong>
                            <br />
                            Can view and act on incidents assigned to them or
                            their teams and can be assigned as a member or
                            administrator of teams.
                          </Typography>
                          <Typography paragraph>
                            <strong>Administrator</strong>
                            <br />
                            Can view and manage all users, teams, and incidents.
                          </Typography>
                          <Typography paragraph>
                            <strong>Owner</strong>
                            <br />
                            Can create and manage all teams, can invite,
                            deactivate, and reactivate users, and can manage the
                            account subscription and billing information.
                          </Typography>
                        </>
                      }
                    />
                  }
                  value={role}
                />
              </Box>
            </Box>
            <Hidden implementation="css" smDown>
              <CardMedia
                alt="User"
                component="img"
                image={User}
                sx={{ width: 160 }}
              />
            </Hidden>
          </Box>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>{renderFooter()}</CardActions>
      </form>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
}))

const QUERY = gql`
  query EditUser($userId: ID!) {
    user(id: $userId) {
      id
      name
      role
      isDeactivated
      viewerCanChangeRole
      viewerCanDeactivate
      viewerCanReactivate
    }
  }
`

const CHANGE_USER_ROLE = gql`
  mutation ChangeUserRole($input: ChangeUserRoleInput!) {
    changeUserRole(input: $input) {
      user {
        id
        role
      }
    }
  }
`

const DEACTIVATE_USER = gql`
  mutation DeactivateUser($input: DeactivateUserInput!) {
    deactivateUser(input: $input) {
      user {
        id
        name
      }
    }
  }
`

const REACTIVATE_USER = gql`
  mutation ReactivateUser($input: ReactivateUserInput!) {
    reactivateUser(input: $input) {
      user {
        id
        name
      }
    }
  }
`
