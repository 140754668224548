import {
  Card,
  CardContent,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material'

import { Autocomplete } from '@mui/material'
import React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function ShiftsSelect({
  schedule,
  setShifts,
  shifts,
  validationErrors,
}) {
  const classes = useStyles()

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent className={classes.content}>
        <FormControl fullWidth>
          <InputLabel
            className={classes.label}
            error={_.some(validationErrors['shiftIds'])}
            htmlFor="from-user"
          >
            Shifts
          </InputLabel>
          <Autocomplete
            blurOnSelect
            classes={{
              inputRoot: classes.inputRoot,
              root: classes.root,
            }}
            disableClearable
            filterSelectedOptions
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.name
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            id="from-user"
            multiple
            options={schedule.shifts.nodes}
            onChange={(_event, option) => setShifts(option)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={_.some(validationErrors['shiftIds'])}
                variant="outlined"
                placeholder={shifts.length > 0 ? null : 'No shifts selected'}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  color="primary"
                  label={option.name}
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            size="small"
            value={shifts}
          />
          {validationErrors['shiftIds'] && (
            <FormHelperText error>
              {validationErrors['shiftIds']}
            </FormHelperText>
          )}
        </FormControl>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  inputRoot: {
    borderRadius: theme.spacing(1),
    color: styles.primary.color,
    fontSize: 14,
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  margin: {
    marginTop: theme.spacing(3.5),
  },
  root: {
    backgroundColor: styles.container.backgroundColor,
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: styles.primary.color,
      },
    },
  },
}))
