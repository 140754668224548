import { FormControl, InputLabel, TextField, Typography } from '@mui/material'

import { Autocomplete } from '@mui/material'
import React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'

const zones = moment.tz.names()

export default function TimeZoneSelect({
  helpText,
  timeZone,
  setTimeZone,
  validationErrors,
}) {
  const classes = useStyles()

  return (
    <FormControl className={classes.margin}>
      <InputLabel
        className={classes.label}
        error={_.some(validationErrors['timeZone'])}
        htmlFor="time-zone"
      >
        Time Zone
      </InputLabel>
      <Autocomplete
        blurOnSelect
        classes={{
          inputRoot: classes.inputRoot,
          root: classes.root,
        }}
        disableClearable
        id="time-zone"
        options={zones}
        onChange={(_event, option) => setTimeZone(option)}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        size="small"
        value={timeZone}
      />
      {helpText && (
        <Typography className={classes.subTitle}>{helpText}</Typography>
      )}
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    backgroundColor: styles.container.backgroundColor,
    borderRadius: theme.spacing(1),
    color: styles.primary.color,
    fontSize: 14,
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  margin: {
    marginTop: theme.spacing(3.5),
    minWidth: 200,
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: styles.primary.color,
      },
    },
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 12,
    marginTop: theme.spacing(1),
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
}))
