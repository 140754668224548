import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { EscalationPolicyStepForm } from '../../components/Teams'
import React from 'react'
import gql from 'graphql-tag'

export default function CreateEscalationPolicyStepPage({
  escalationPolicyId,
  teamId,
}) {
  useBack(`/teams/${teamId}/escalationPolicies/${escalationPolicyId}`)
  const { data, errors, loading } = useQuery(QUERY, {
    teamId,
    escalationPolicyId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Escalation Policy Step" />
      <R5Header title={`Step ${data.escalationPolicy.steps.length + 1}`} />
      <EscalationPolicyStepForm
        escalationPolicy={data.escalationPolicy}
        team={data.escalationPolicy.team}
      />
    </R5Container>
  )
}

const QUERY = gql`
  query CreateEscalationPolicyStep($teamId: ID!, $escalationPolicyId: ID!) {
    escalationPolicy(id: $escalationPolicyId, teamId: $teamId) {
      id
      name
      team {
        id
        name
      }
      steps {
        id
        condition
        delayInMinutes
        escalations {
          action
          target {
            id
            name
          }
        }
      }
    }
  }
`
