import React from 'react'

export default function Bell({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.76475 2H11.2353V3.24049C13.4963 3.78585 15.1739 5.79436 15.1739 8.18866V11.5762L17 12.6836V15.133H3V12.6836L4.82609 11.5762V8.18866C4.82609 5.79433 6.50372 3.7858 8.76475 3.24047V2ZM12.1995 16.5714C12.1995 17.6203 11.12 18.4706 9.78837 18.4706C8.45676 18.4706 7.37728 17.6203 7.37728 16.5714H12.1995Z"
        fill={color}
      />
    </svg>
  )
}
