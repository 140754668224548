import { OutlinedInput } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function R5Input({ clear, onChange, ...restProps }) {
  const classes = useStyles()
  const { helperText, InputProps, InputLabelProps, ...reallyRestProps } =
    restProps

  return (
    <OutlinedInput
      classes={{
        root: clear ? classes.clearRoot : classes.root,
        input: clear ? classes.clearInput : classes.input,
        ...InputProps?.classes,
      }}
      color="primary"
      onChange={onChange ? ({ target: { value } }) => onChange(value) : null}
      {...InputProps}
      {...reallyRestProps}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  input: {
    color: styles.primary.color,
    fontSize: 14,
    padding: theme.spacing(1.5),
    minWidth: 150,
  },
  root: {
    backgroundColor: styles.container.backgroundColor,
    borderRadius: theme.spacing(1),
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&:hover fieldset': {
      borderColor: `${styles.primary.color} !important`,
    },
  },
  clearInput: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
    padding: theme.spacing(1.5),
    minWidth: 150,
  },
  clearRoot: {
    backgroundColor: '#ffffff33',
    borderRadius: theme.spacing(1),
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.common.white} !important`,
    },
  },
}))
