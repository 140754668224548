/** @jsxImportSource @emotion/react */

import * as Icons from '../icons'

import { ActivityIndicator, R5Error } from '../shared'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useCallback, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'
import { css } from '@emotion/react'
import gql from 'graphql-tag'
import { styles } from '../../constants/styles'
import { useQuery } from '../../hooks'

export default function TeamMemberList({
  handleSelect,
  search,
  selectedValues,
  team,
}) {
  const classes = useStyles()
  const { data, errors, fetchMore, fetchingMore, loading } = useQuery(
    TEAM_MEMBERS_QUERY,
    {
      teamId: team.id,
    }
  )

  function handleNext() {
    if (fetchingMore) return
    if (!data.team.memberships.pageInfo.hasNextPage) return

    fetchMore({
      variables: {
        cursor: data.team.memberships.pageInfo.endCursor,
        teamId: data.team.id,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult.team.memberships.nodes.length
          ? {
              team: {
                ...previousResult.team,
                memberships: {
                  __typename: previousResult.team.memberships.__typename,
                  nodes: [
                    ...previousResult.team.memberships.nodes,
                    ...fetchMoreResult.team.memberships.nodes,
                  ],
                  pageInfo: fetchMoreResult.team.memberships.pageInfo,
                },
              },
            }
          : previousResult
      },
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNextDebounced = useCallback(_.debounce(handleNext, 500), [
    data.team?.memberships?.pageInfo?.hasNextPage,
    fetchingMore,
  ])

  useEffect(() => {
    if (!data.team) return
    if (search.length > 0) handleNextDebounced()
  }, [data.team, handleNextDebounced, search.length])

  if (loading) return <ActivityIndicator className="m-md" size={30} />
  if (errors) return <R5Error errors={errors} />

  const selectedMembers = data.team.memberships.nodes.filter((membership) =>
    membership.user.name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <div className="flex" css={stylesheet}>
      <InfiniteScroll
        dataLength={selectedMembers.length}
        hasChildren={_.some(selectedMembers)}
        hasMore={data.team.memberships.pageInfo.hasNextPage}
        height={200}
        loader={<ActivityIndicator className="ml-md" />}
        next={handleNextDebounced}
      >
        <List dense className={classes.list}>
          {selectedMembers.map((membership, index) => (
            <ListItem
              button
              className={classes.li}
              divider={index === selectedMembers.length - 1 ? false : true}
              key={membership.user.id}
              onClick={() => handleSelect(membership.user)}
            >
              <ListItemAvatar>
                <Avatar
                  className={classes.avatar}
                  src={membership.user.avatarUrl}
                >
                  {membership.user.initials}
                </Avatar>
              </ListItemAvatar>
              <ListItemText>{membership.user.name}</ListItemText>
              <ListItemSecondaryAction>
                {selectedValues.find(
                  (value) => value.id === membership.user.id
                ) ? (
                  <Icons.CheckCircle color="#6dd76b" size={32} />
                ) : null}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </InfiniteScroll>
    </div>
  )
}

const stylesheet = css`
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: styles.primary.color,
    fontSize: 12,
    fontWeight: 800,
    height: 32,
    width: 32,
  },
  li: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(82, 89, 255, 0.1)',
      borderRadius: 8,
    },
  },
  list: {
    height: 200,
  },
}))

const TEAM_MEMBERS_QUERY = gql`
  query TeamMemberList($cursor: String, $teamId: ID!) {
    team(id: $teamId) {
      id
      memberships(after: $cursor, first: 30) {
        nodes {
          id
          user {
            id
            initials
            name
            avatarUrl
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`
