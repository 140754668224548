/** @jsxImportSource @emotion/react */

import * as Icons from '../icons'

import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import { LogoWithText } from '../illustrations'
import { css } from '@emotion/react'
import makeStyles from '@mui/styles/makeStyles'

export default function R5DrawerLoader() {
  const classes = useStyles()

  function renderDrawer() {
    return (
      <div className="flex flex-1 flex-col" css={stylesheet}>
        <Box className="flex flex-1 flex-col sidebar">
          <Box
            className={`${classes.toolbar} flex items-center justify-center`}
          >
            <LogoWithText />
          </Box>
          <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
          <Box className="flex flex-1 flex-col justify-between">
            <List className="p-0">
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  className={
                    window.location.pathname.includes('/incidents')
                      ? 'current'
                      : null
                  }
                  component="a"
                >
                  <ListItemIcon>
                    <Icons.Bell color="#ced5de" size={18} />
                  </ListItemIcon>
                  <ListItemText primary="Incidents" />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  className={
                    window.location.pathname.includes('/teams')
                      ? 'current'
                      : null
                  }
                  component="a"
                >
                  <ListItemIcon>
                    <Icons.Teams color="#ced5de" size={18} />
                  </ListItemIcon>
                  <ListItemText primary="Teams" />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  className={
                    window.location.pathname.includes('/users')
                      ? 'current'
                      : null
                  }
                  component="a"
                >
                  <ListItemIcon>
                    <Icons.People color="#ced5de" size={18} />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </div>
    )
  }

  return (
    <Hidden smDown implementation="css">
      <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
        {renderDrawer()}
      </Drawer>
    </Hidden>
  )
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 250,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: '#21233f',
    width: 250,
  },
  content: {
    minHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 250,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}))

const stylesheet = css`
  .sidebar {
    .current {
      background-color: #3f47f4;
      color: #fff;
      .icon path {
        fill: #fff;
      }
    }
    a {
      text-decoration: none;
      padding: 1.5em 1em;
      transition: all linear 0.1s;
      width: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      &:hover {
        background-color: #3f47f4;
        color: #fff;
        .icon path {
          fill: #fff;
        }
      }
      .icon {
        width: 18px;
        margin-right: 10px;
        path {
          fill: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
`
