import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

import React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

export default function R5RadioGroup({
  buttons,
  className,
  disabled,
  error,
  label,
  onChange,
  toolTip,
  value,
  ...restProps
}) {
  const classes = useStyles()

  return (
    <FormControl component="fieldset" error={!!error}>
      <Box className="flex flex-1 flex-row items-center">
        <FormLabel
          className={`${classes.label}${toolTip ? ' mr-xs' : ''}`}
          component="legend"
        >
          {label}
        </FormLabel>
        {toolTip}
      </Box>
      <RadioGroup
        aria-label={label}
        name={_.kebabCase(label)}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        {...restProps}
      >
        {buttons.map(({ label, value }) => (
          <FormControlLabel
            className={className}
            control={<Radio color="primary" disabled={disabled} />}
            key={value}
            label={label}
            value={value}
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  label: { fontSize: 14, fontWeight: 'bold', textTransform: 'uppercase' },
}))
