import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import { TeamForm } from '../../components/Teams'
import gql from 'graphql-tag'

export default function EditTeamPage({ teamId }) {
  useBack(`/teams/${teamId}/coverage`)
  const { data, loading, errors } = useQuery(QUERY, {
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.team.viewerCanAdminister) return <NotFoundPage />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Team" />
      <R5Header title="Edit Team" />
      <TeamForm team={data.team} />
    </R5Container>
  )
}

const QUERY = gql`
  query EditTeam($teamId: ID!) {
    team(id: $teamId) {
      id
      name
      accentColor
      viewerCanAdminister
    }
  }
`
