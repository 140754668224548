import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormHelperText,
  Typography,
} from '@mui/material'
import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Infotip,
  R5RadioGroup,
  R5Search,
  R5Title,
  UserList,
} from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'
import { useBack, useMutation, useQuery } from '../../hooks'

import { NotFoundPage } from '../utils'
import TeamOptions from '../../constants/TeamOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function AddMembershipPage({ teamId }) {
  useBack(`/teams/${teamId}/members`)
  const { data, loading, errors } = useQuery(QUERY, {
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.team.viewerCanCreateMemberships) return <NotFoundPage />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Add Team Member" />
      <R5Header title="Add Team Member" />
      <AddMembershipForm team={data.team} />
    </R5Container>
  )
}
function AddMembershipForm({ team }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const membershipMutation = useMutation(CREATE_MEMBERSHIP)
  const [validationErrors, setValidationErrors] = useState([])
  const [search, setSearch] = useState('')
  const [user, setUser] = useState({})
  const [role, setRole] = useState('MEMBER')
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(true)

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)
    try {
      const response = await membershipMutation({
        userId: user.id,
        teamId: team.id,
        role: role,
      })
      setLoading(false)
      if (response.data.createMembership) {
        navigate(`/teams/${team.id}/members`)
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  return (
    <form autoComplete="off" className="flex flex-col" onSubmit={handleSubmit}>
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.content}>
          <R5RadioGroup
            buttons={TeamOptions.roles}
            disabled={_.some(user) && user.role !== 'MEMBER'}
            error={_.some(validationErrors['role'])}
            label="Role"
            onChange={setRole}
            row
            toolTip={
              <R5Infotip
                learnMore="https://www.readyfive.io/docs/team-basics"
                title={
                  <>
                    <Typography paragraph>
                      <strong>Member</strong>
                      <br />
                      Can be participants in team schedules, can participate in
                      shift overrides, and can be targeted by integrations.
                    </Typography>
                    <Typography paragraph>
                      <strong>Administrator</strong>
                      <br />
                      Can fully manage everything on the team.
                    </Typography>
                  </>
                }
              />
            }
            value={
              _.some(user) && user.role !== 'MEMBER' ? 'ADMINISTRATOR' : role
            }
          />
        </CardContent>
        <CardActions className={classes.actions}>
          <Box className="flex flex-1 flex-col">
            <R5Search
              className="p-3"
              fullWidth
              loading={fetching}
              onChange={setSearch}
              value={search}
            />
            {validationErrors['userId'] && (
              <FormHelperText error>
                {validationErrors['userId']}
              </FormHelperText>
            )}
            <UserList
              handleSelect={setUser}
              search={search}
              selectedValues={[user]}
              setFetching={setFetching}
              withNobody={false}
            />
            <Box className="ml-sm">
              <Button
                component={RouterLink}
                startIcon={
                  <Icons.PlusCircleOutline
                    color={styles.primary.color}
                    size={30}
                  />
                }
                size="large"
                to="/users/invite"
                state={{ team }}
                variant="text"
              >
                Invite {search.length > 0 ? search : 'new user'}
              </Button>
            </Box>
          </Box>
        </CardActions>
      </Card>
      <Box className="flex flex-1 flex-row justify-between my-5">
        <Button
          component={RouterLink}
          size="large"
          to={`/teams/${team.id}/members`}
        >
          Cancel
        </Button>
        <Button
          disabled={loading || _.isEmpty(user)}
          size="large"
          type="submit"
          variant="contained"
        >
          Add Team Member
        </Button>
      </Box>
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingTop: 0,
  },
  card: {
    borderRadius: 16,
  },
  content: {
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  divider: {
    backgroundColor: styles.primary.color,
    height: 2,
  },
  label: {
    fontSize: 16,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: 12,
  },
}))

const QUERY = gql`
  query AddMembership($teamId: ID!) {
    team(id: $teamId) {
      id
      name
      accentColor
      viewerCanCreateMemberships
    }
  }
`

const CREATE_MEMBERSHIP = gql`
  mutation CreateMembership($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      membership {
        id
      }
    }
  }
`
