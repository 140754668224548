const applePushNotificationService = 'APNS'
const applePushNotificationServiceSB = 'APNSSANDBOX'
const configStorageKey = '@config'
const firebaseCloudMessaging = 'FCM'
const jsonTheme = {
  scheme: 'rjv-default',
  author: 'mac gainor',
  base00: 'rgba(0, 0, 0, 0)', // background
  base01: 'rgb(245, 245, 245)',
  base02: 'rgb(235, 235, 235)', // dropdown line
  base03: '#93a1a1',
  base04: 'rgba(0, 0, 0, 0.3)',
  base05: '#586e75',
  base06: '#073642',
  base07: '#002b36', // keys and braces
  base08: '#d33682',
  base09: '#cb4b16', // strings
  base0A: '#dc322f',
  base0B: '#859900', // floats
  base0C: '#6c71c4',
  base0D: '#586e75', // collapse triangle
  base0E: '#2aa198', // booleans
  base0F: '#268bd2', // integers
}
const publicIdentifierStorageKey = '@publicIdentifier'
const rootScreensRegex =
  /\/account\/?\/billing\/?$|\/help\/?$|\/incidents\/?$|\/profile\/?$|\/profile\/details\/?$|\/profile\/notifications\/?$|\/profile\/security\/?$|\/teams\/?$|\/users\/?$|\/welcome\/?$/

const validEmailRegex =
  // eslint-disable-next-line no-control-regex
  /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

export {
  applePushNotificationService,
  applePushNotificationServiceSB,
  configStorageKey,
  firebaseCloudMessaging,
  jsonTheme,
  publicIdentifierStorageKey,
  rootScreensRegex,
  validEmailRegex,
}
