import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import React from 'react'
import { Link as RouterLink } from '@reach/router'
import makeStyles from '@mui/styles/makeStyles'

export default function TeamItem({ team }) {
  const classes = useStyles()

  return (
    <Card className={classes.card} key={team.id} variant="outlined">
      <CardActionArea tabIndex={-1}>
        <CardContent className={classes.content}>
          <ListItem component={RouterLink} to={`/teams/${team.id}`}>
            <ListItemIcon className={classes.icon}>
              <Box
                className={classes.avatar}
                style={{ backgroundColor: team.accentColor }}
              />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.primary }}>
              {team.name}
            </ListItemText>
          </ListItem>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 12,
    height: 12,
    width: 12,
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  icon: {
    minWidth: theme.spacing(3),
  },
  primary: {
    fontSize: 14,
    fontWeight: 600,
  },
}))
