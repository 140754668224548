import { Box, Typography } from '@mui/material'

import { AlertDialog } from '../../components/shared'
import React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

export default function TimeOffAlert({
  setShowTimeOffAlert,
  setTimeOffShifts,
  showTimeOffAlert,
  timeOffShifts,
}) {
  const classes = useStyles()

  return (
    <AlertDialog
      cancelText="Ok"
      content={
        <>
          {_.some(timeOffShifts.success) && (
            <Box className={_.some(timeOffShifts.failed) ? 'mb-md' : ''}>
              <Typography>
                Overrides were successfully made in the following shifts.
              </Typography>
              <Box className="mt-sm">
                {timeOffShifts.success.map((success, index) => (
                  <Typography className={classes.shifts} key={index}>
                    {success}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
          {_.some(timeOffShifts.failed) && (
            <Box>
              <Typography>
                One or more overrides could not be created. The combination of
                starting/ending time, shifts applied, and person being covered
                conflicts with an existing override.
              </Typography>
              <Box className="mt-sm">
                {timeOffShifts.failed.map((failed, index) => (
                  <Typography className={classes.shifts} key={index}>
                    {failed}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
        </>
      }
      handleClose={() => {
        setTimeOffShifts({ failed: [], success: [] })
        setShowTimeOffAlert(false)
      }}
      open={showTimeOffAlert}
      title="Time Off"
    />
  )
}

const useStyles = makeStyles((theme) => ({
  shifts: {
    fontSize: 14,
    fontWeight: '600',
  },
}))
