import {
  Card,
  CardContent,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'

import DOMPurify from 'dompurify'
import Linkify from 'react-linkify'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import ReactJson from 'react-json-view'
import ReactMarkdown from 'react-markdown'
import _ from 'lodash'
import { isValidJSON } from '../../utils'
import { jsonTheme } from '../../constants/app'
import makeStyles from '@mui/styles/makeStyles'

export default function Details({ incident }) {
  const classes = useStyles()

  function renderSource() {
    return (
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.content}>
          <ListItemText
            classes={{
              primary: classes.primary,
              root: classes.root,
              secondary: classes.secondary,
            }}
            primary="Source"
            primaryTypographyProps={{ color: 'textSecondary' }}
            secondary={
              <Linkify
                componentDecorator={(href, text, i) => (
                  <Link
                    key={i}
                    href={href}
                    target="_blank"
                    rel="noreferrer noopener"
                    underline="hover"
                  >
                    {text}
                  </Link>
                )}
              >
                {incident.source}
              </Linkify>
            }
            secondaryTypographyProps={{
              color: 'textPrimary',
              component: 'span',
            }}
          />
        </CardContent>
      </Card>
    )
  }

  function renderEmpty() {
    return (
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.content}>
          <Typography className={classes.primary} color="textSecondary">
            No Details Recorded
          </Typography>
        </CardContent>
      </Card>
    )
  }

  function renderText() {
    return (
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.content}>
          <Linkify
            componentDecorator={(href, text, i) => (
              <Link
                key={i}
                href={href}
                target="_blank"
                rel="noreferrer noopener"
                underline="hover"
              >
                {text}
              </Link>
            )}
          >
            {ReactHtmlParser(DOMPurify.sanitize(incident.details))}
          </Linkify>
        </CardContent>
      </Card>
    )
  }

  function renderJSON() {
    const details = JSON.parse(incident.details)
    const datadogEventMsg = details.event_msg // Datadog
    const googleDocumentation = details.incident?.documentation // Google
    const otherKeys = _.omit(details, 'event_msg')

    function renderDatadogEventMsg() {
      const regex = /%%%(.*?)%%%/gs
      const markdownMatchesWithDelims = datadogEventMsg.match(regex)
      const markdownMatches = markdownMatchesWithDelims.map((match) =>
        match.replace(regex, '$1')
      )

      const parts = datadogEventMsg.split('%%%')

      return (
        <Card className={classes.card} key="event_msg" variant="outlined">
          <CardContent className={classes.content}>
            <ListItemText
              classes={{
                primary: classes.primary,
                root: classes.root,
                secondary: classes.secondary,
              }}
              primary="Event Message"
              primaryTypographyProps={{ color: 'textSecondary' }}
              secondary={parts.map((part, index) => {
                if (markdownMatches.includes(part)) {
                  return (
                    <ReactMarkdown
                      components={{
                        a: ({ children, href }) => (
                          <Link
                            href={href}
                            target="_blank"
                            rel="noreferrer noopener"
                            underline="hover"
                          >
                            {children}
                          </Link>
                        ),
                        hr: () => <Divider className="my-md" />,
                        li: ({ children }) => (
                          <ListItem>
                            <ListItemText>{children}</ListItemText>
                          </ListItem>
                        ),
                        ol: ({ children }) => (
                          <List disablePadding>{children}</List>
                        ),
                        p: ({ children }) => (
                          <Typography paragraph>{children}</Typography>
                        ),
                        ul: ({ children }) => (
                          <List disablePadding>{children}</List>
                        ),
                      }}
                      key={index}
                    >
                      {part}
                    </ReactMarkdown>
                  )
                } else {
                  return (
                    <Typography key={index} paragraph>
                      {part}
                    </Typography>
                  )
                }
              })}
              secondaryTypographyProps={{
                color: 'textPrimary',
                component: 'span',
              }}
            />
          </CardContent>
        </Card>
      )
    }

    function renderGoogleDocumentation(content, mimeType) {
      return (
        <Card className={classes.card} key="event_msg" variant="outlined">
          <CardContent className={classes.content}>
            <ListItemText
              classes={{
                primary: classes.primary,
                root: classes.root,
                secondary: classes.secondary,
              }}
              primary="Documentation"
              primaryTypographyProps={{ color: 'textSecondary' }}
              secondary={
                mimeType === 'text/markdown' ? (
                  <ReactMarkdown
                    components={{
                      a: ({ children, href }) => (
                        <Link
                          href={href}
                          target="_blank"
                          rel="noreferrer noopener"
                          underline="hover"
                        >
                          {children}
                        </Link>
                      ),
                      hr: () => <Divider className="my-md" />,
                      li: ({ children }) => (
                        <ListItem>
                          <ListItemText>{children}</ListItemText>
                        </ListItem>
                      ),
                      ol: ({ children }) => (
                        <List disablePadding>{children}</List>
                      ),
                      p: ({ children }) => (
                        <Typography paragraph>{children}</Typography>
                      ),
                      ul: ({ children }) => (
                        <List disablePadding>{children}</List>
                      ),
                    }}
                  >
                    {content}
                  </ReactMarkdown>
                ) : (
                  <Typography paragraph>{content}</Typography>
                )
              }
              secondaryTypographyProps={{
                color: 'textPrimary',
                component: 'span',
              }}
            />
          </CardContent>
        </Card>
      )
    }

    return [
      datadogEventMsg ? renderDatadogEventMsg() : null,
      googleDocumentation
        ? renderGoogleDocumentation(
            googleDocumentation.content,
            googleDocumentation.mime_type
          )
        : null,
      ..._.map(otherKeys, (value, key) => {
        return (
          <Card className={classes.card} key={key} variant="outlined">
            <CardContent className={classes.content}>
              <ListItemText
                classes={{
                  primary: classes.primary,
                  root: classes.root,
                  secondary: classes.secondary,
                }}
                primary={key}
                primaryTypographyProps={{ color: 'textSecondary' }}
                secondary={
                  <Linkify
                    componentDecorator={(href, text, i) => (
                      <Link
                        key={i}
                        href={href}
                        target="_blank"
                        rel="noreferrer noopener"
                        underline="hover"
                      >
                        {text}
                      </Link>
                    )}
                  >
                    {isValidJSON(value) ? (
                      <ReactJson
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                        name={null}
                        src={
                          typeof value === 'object' ? value : JSON.parse(value)
                        }
                        theme={jsonTheme}
                      />
                    ) : (
                      ReactHtmlParser(DOMPurify.sanitize(value))
                    )}
                  </Linkify>
                }
                secondaryTypographyProps={{
                  color: 'textPrimary',
                  component: 'span',
                }}
              />
            </CardContent>
          </Card>
        )
      }),
    ]
  }

  return (
    <>
      {!_.isEmpty(incident.source) && renderSource()}
      {_.isEmpty(incident.details)
        ? renderEmpty()
        : incident.detailsType === 'TEXT'
        ? renderText()
        : renderJSON()}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(3),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  root: {
    marginBottom: 0,
    marginTop: 0,
  },
  primary: {
    fontWeight: 600,
    wordBreak: 'break-all',
  },
  secondary: {
    wordBreak: 'break-all',
  },
}))
