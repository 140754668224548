import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from '@reach/router'
import { useCurrents } from '../../context/currents'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useMutation } from '../../hooks'
import gql from 'graphql-tag'

const LoginSSORedirect = () => {
  const { user, userId } = useCurrents()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const isNewUser = moment
    .unix(user.createdAt)
    .isAfter(moment().subtract(10, 'minutes'))
  const [finishedSettingTimeZone, setFinishedSettingTimeZone] = useState(false)
  const updateTimeZone = useMutation(UPDATE_TIMEZONE)

  // Custom state passed across SSO login is currently unused, but can be
  // accessed here.
  let customState
  if (query.state?.length > 0) {
    const customStateHex = query.state.split('-')[1]
    customState = hex2ascii(customStateHex)
  }
  if (process.env.NODE_ENV === 'development') console.log(customState)

  // Set the timezone for new SSO authenticated users based on their browser
  // timezone.
  useEffect(() => {
    async function setTimeZone() {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      await updateTimeZone({ userId, timeZone })
      setFinishedSettingTimeZone(true)
    }

    if (!isNewUser) {
      setFinishedSettingTimeZone(true)
      return
    }
    setTimeZone()
  }, [isNewUser, updateTimeZone, userId])

  let redirectToPath = '/incidents'
  if (isNewUser) {
    redirectToPath = '/welcome'
  }

  if (!finishedSettingTimeZone) {
    return null // a spinner would be better but who has time
  }

  return <Redirect to={redirectToPath} noThrow />
}

function hex2ascii(h) {
  var hex = h.toString() // force conversion
  var str = ''
  for (var i = 0; i < hex.length; i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
  return str
}

export default LoginSSORedirect

const UPDATE_TIMEZONE = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        timeZone
      }
    }
  }
`
