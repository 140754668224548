import React from 'react'

export default function Check({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.81829 13.7026L3.05787 8.94213L2 10L7.81829 15.8183L18.3118 5.32483L17.2539 4.26696L7.81829 13.7026Z"
        fill={color}
      />
    </svg>
  )
}
