import { Helmet } from 'react-helmet'
import React from 'react'

export default function R5Title({ title }) {
  return (
    <Helmet>
      <title>{title} - Ready Five</title>
    </Helmet>
  )
}
