import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material'
import { EscalationPolicyList, R5ButtonGroup, R5Search, UserList } from '.'
import React, { useState } from 'react'

import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function AssignToSelect({
  assignTo,
  className,
  multiple = true,
  setAssignTo,
  validationErrors,
}) {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [selectedValue, setSelectedValue] = useState('policies')
  const [fetching, setFetching] = useState(true)

  function handleSelect(item) {
    let newAssignTo = null

    if (multiple) {
      if (assignTo.find((assignee) => assignee.id === item.id)) {
        newAssignTo = _.reject(assignTo, (assignee) => assignee.id === item.id)
      } else {
        newAssignTo = _.concat(assignTo, item)
      }
    } else {
      newAssignTo = item
    }

    setAssignTo(newAssignTo)
  }

  function handleDelete(item) {
    let newAssignTo = null
    if (multiple) {
      newAssignTo = _.reject(assignTo, (assignee) => assignee.id === item.id)
    } else {
      newAssignTo = null
    }

    setAssignTo(newAssignTo)
  }

  return (
    <Card
      className={`${classes.card} ${classes.margin} ${className}`}
      variant="outlined"
    >
      <CardContent className={classes.content}>
        <Box className="flex flex-row justify-between items-center">
          <Typography color="textSecondary" className={classes.label}>
            Assign To
          </Typography>
          <R5ButtonGroup
            buttons={[
              { label: 'Policies', value: 'policies' },
              { label: 'Users', value: 'users' },
            ]}
            onChange={setSelectedValue}
            selectedValue={selectedValue}
          />
        </Box>
        <Box className="mt-3">
          {validationErrors['assignToIds'] && (
            <FormHelperText error>
              {validationErrors['assignToIds']}
            </FormHelperText>
          )}
          {!_.some(assignTo) ? (
            <Typography className={classes.subtitle} color="textSecondary">
              {multiple
                ? 'Select one or more users/policies'
                : 'Select a user or policy'}
            </Typography>
          ) : (
            <Grid className="flex flex-1" container spacing={1}>
              {multiple ? (
                assignTo.map((assignToItem) => (
                  <Grid item key={assignToItem.id}>
                    <Chip
                      color="primary"
                      label={assignToItem.name}
                      onDelete={() => handleDelete(assignToItem)}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item key={assignTo.id}>
                  <Chip
                    color="primary"
                    label={assignTo.name}
                    onDelete={() => handleDelete(assignTo)}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </CardContent>
      <Divider className={classes.divider} />
      <Box className="flex flex-1 flex-col">
        <R5Search
          className="p-3"
          fullWidth
          loading={fetching}
          onChange={setSearch}
          value={search}
        />
        <Box className={classes.height}>
          <Divider />
          {selectedValue === 'policies' ? (
            <EscalationPolicyList
              handleSelect={handleSelect}
              search={search}
              selectedValues={
                multiple ? assignTo : _.some(assignTo) ? [assignTo] : []
              }
              setFetching={setFetching}
            />
          ) : (
            <UserList
              handleSelect={handleSelect}
              search={search}
              selectedValues={
                multiple ? assignTo : _.some(assignTo) ? [assignTo] : []
              }
              setFetching={setFetching}
            />
          )}
        </Box>
      </Box>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 16,
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  divider: {
    backgroundColor: styles.primary.color,
    height: 2,
  },
  height: {
    height: 300,
    overflow: 'scroll',
  },
  label: {
    fontSize: 16,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  margin: {
    marginTop: theme.spacing(3.5),
  },
  subtitle: {
    fontSize: 12,
    marginBottom: theme.spacing(1.75),
  },
}))
