import * as Icons from '../icons'

import { Box, InputBase } from '@mui/material'

import { ActivityIndicator } from '../../components/shared'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function R5Search({
  className,
  fullWidth,
  loading,
  onChange,
  value,
  ...restProps
}) {
  const classes = useStyles()
  return (
    <Box className={`flex flex-1 ${className ? className : ''}`}>
      <Box className={fullWidth ? classes.fullSearch : classes.search}>
        <Box className={classes.searchIcon}>
          <Icons.MagnifyingGlass
            color={styles.searchBarSearchIcon.color}
            size={24}
          />
        </Box>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          className="w-100"
          inputProps={{ 'aria-label': 'search' }}
          onChange={({ target: { value } }) => onChange(value)}
          placeholder="Search…"
          value={value}
          {...restProps}
        />
        {loading && (
          <Box className={classes.loaderIcon}>
            <ActivityIndicator className="mr-sm mt-xs" size={20} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  fullSearch: {
    backgroundColor: '#fff',
    borderColor: '#e5e7eb',
    borderRadius: 100,
    borderStyle: 'solid',
    borderWidth: 1,
    marginLeft: 0,
    position: 'relative',
    width: '100%',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  loaderIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    top: 0,
    right: -10,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  search: {
    backgroundColor: '#fff',
    borderColor: '#e5e7eb',
    borderRadius: 100,
    borderStyle: 'solid',
    borderWidth: 1,
    marginLeft: 0,
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
