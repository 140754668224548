import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'

import AppRouter from './navigation/AppRouter'
import { R5Error } from './components/shared'
import { Helmet } from 'react-helmet'
import R5Theme from './constants/R5Theme'
import React from 'react'
import { enterprise } from './utils'
import { useBugsnag } from './context/bugsnag'

function App() {
  const { ErrorBoundary } = useBugsnag()

  return (
    <ErrorBoundary
      FallbackComponent={(error) => (
        <R5Error errors={error.error.message} noHelp />
      )}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={R5Theme}>
          {!enterprise() && (
            <Helmet>
              <script
                defer
                data-site="RHIWGONC"
                data-spa="auto"
                src="https://cdn.usefathom.com/script.js"
              ></script>
            </Helmet>
          )}
          <CssBaseline />
          <AppRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  )
}

export default App
