import React from 'react'

export default function Menu({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 5.45455H19V3H1V5.45455ZM19 11.1818H1V8.72729H19V11.1818ZM19 16.9091H1V14.4546H19V16.9091Z"
        fill={color}
      />
    </svg>
  )
}
