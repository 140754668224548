import * as Icons from '../../components/icons'

import { Button, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { useLocation, useNavigate } from '@reach/router'

import React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { rootScreensRegex } from '../../constants/app'
import { styles } from '../../constants/styles'
import { useJunkDrawer } from '../../context/junkDrawer'

export default function R5BackButton() {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { junkDrawer } = useJunkDrawer()

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  function handleGoBack() {
    navigate(junkDrawer.back)
  }

  const display = _.isEmpty(junkDrawer.back) ? 'invisible' : 'inline-flex'

  return mobileView ? (
    <IconButton
      aria-label="back"
      className={`${classes.back} ${display}`}
      edge="start"
      onClick={handleGoBack}
      size="small"
    >
      <Icons.ChevronLeft color={styles.primary.color} size={32} />
    </IconButton>
  ) : (
    <Button
      aria-label="back"
      className={`${classes.back} ${display}`}
      onClick={handleGoBack}
      startIcon={<Icons.ChevronLeft color={styles.primary.color} size={30} />}
      style={{
        display: location.pathname.match(rootScreensRegex)
          ? 'hidden'
          : 'inline-flex',
      }}
    >
      Back
    </Button>
  )
}

const useStyles = makeStyles((theme) => ({
  back: {
    marginLeft: '-10px !important',
  },
}))
