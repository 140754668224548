import { Box, Button, Typography } from '@mui/material'
import { R5Container, R5Title } from '../../components/shared'
import React, { useEffect, useState } from 'react'

import { ReactComponent as ErrorSvg } from '../../components/illustrations/Error.svg'
import { ReactComponent as NetworkError } from '../../components/illustrations/NetworkError.svg'
import { NotFoundPage } from '../../pages/utils'
import { Link as RouterLink } from '@reach/router'
import makeStyles from '@mui/styles/makeStyles'

export default function R5Error({ errors, noHelp = false }) {
  return notFoundError(errors) ? (
    <NotFoundPage />
  ) : (
    <R5Container maxWidth="xs">
      <R5Title title={errors === 'Network Error' ? 'No Connection' : 'Sorry'} />
      {process.env.NODE_ENV === 'development' ? (
        <DevError errors={errors} />
      ) : (
        <ProdError errors={errors} noHelp={noHelp} />
      )}
    </R5Container>
  )
}

function notFoundError(errors) {
  return (
    errors === 'Resource not found' ||
    errors?.undefined === 'Resource not found' ||
    (errors[0] && Object.values(errors[0]).includes('Resource not found'))
  )
}

function DevError({ errors }) {
  const classes = useStyles()

  useEffect(() => {
    console.log(errors)
  }, [errors])

  return (
    <Box className="flex flex-col items-center justify-center text-center mt-1/10">
      <Typography className={classes.title}>Error</Typography>
      <Typography className={classes.subTitle} color="textSecondary">
        {JSON.stringify(errors)}
      </Typography>
    </Box>
  )
}

function ProdError({ errors, noHelp }) {
  const classes = useStyles()
  const [lastPress, setLastPress] = useState(null)
  const [tapCount, setTapCount] = useState(0)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const networkError = errors === 'Network Error'

  function handleErrorTap() {
    const now = Date.now()

    setLastPress(now)
    if (now - lastPress <= 300) {
      if (tapCount < 5 - 1) {
        setTapCount(tapCount + 1)
      } else {
        setTapCount(0)
        setShowErrorMessage(!showErrorMessage)
      }
    } else {
      setTapCount(1)
    }
  }

  return (
    <Box className="flex flex-col items-center justify-center text-center mt-1/10">
      {networkError ? (
        <NetworkError
          onClick={handleErrorTap}
          className={`${classes.width} m-lg`}
        />
      ) : (
        <ErrorSvg
          onClick={handleErrorTap}
          className={`${classes.width} m-lg`}
        />
      )}
      <Typography className={classes.title}>
        {networkError ? 'No Connection.' : 'Sorry about that.'}
      </Typography>
      {showErrorMessage ? (
        <Typography className={classes.subTitle} color="textSecondary">
          {console.log(JSON.stringify(errors))}
          {JSON.stringify(errors)}
        </Typography>
      ) : (
        <Typography className={classes.subTitle} color="textSecondary">
          {networkError
            ? 'It looks like you might be having some network issues. Please check your internet connection and try again.'
            : 'You’ve encountered an error on our end and we’ve been notified about it. Try again in a few minutes or contact us and we can help you get this resolved.'}
        </Typography>
      )}
      <Box>
        {!noHelp && !networkError && (
          <Button
            className="mt-lg mr-lg"
            component={RouterLink}
            to="/help"
            variant="outlined"
          >
            Get Help
          </Button>
        )}
        <Button
          className="mt-lg"
          onClick={() => window.location.reload()}
          variant="outlined"
        >
          Reload
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: 24,
    fontWeight: 600,
  },
  title: {
    fontSize: 40,
    fontWeight: 900,
  },
  width: {
    width: '100%',
    height: 225,
  },
}))
