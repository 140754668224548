import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  Divider,
  FormControlLabel,
  Hidden,
  Typography,
} from '@mui/material'
import {
  Loader,
  R5Container,
  R5Header,
  R5TextField,
  R5Title,
} from '../../components/shared'
import React, { useEffect, useState } from 'react'
import { useBack, useMutation, useQuery } from '../../hooks'

import { Account } from '../../components/illustrations'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import { enterprise } from '../../utils'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'
import { styles } from '../../constants/styles'

export default function EditAccountPage({ navigate }) {
  useBack(enterprise() ? null : '/account/billing')
  const classes = useStyles()
  const { account, load } = useCurrents()
  const updateMutation = useMutation(UPDATE_ACCOUNT)
  const [name, setName] = useState(account.name)
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)

    try {
      await updateMutation({ name })
      setLoading(false)
      navigate(enterprise() ? '/' : '/account/billing')
      load()
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Your Account" />
      <R5Header title="Your Account" />

      <Card variant="outlined">
        <CardHeader
          title="Account Settings"
          titleTypographyProps={{
            className: classes.cardTitle,
            color: 'textSecondary',
          }}
        />
        <Divider style={{ backgroundColor: styles.primary.color, height: 2 }} />
        <form onSubmit={handleSubmit}>
          <CardContent className={classes.content}>
            <Box className="flex flex-row items-center">
              <Box className="flex flex-col mr-10">
                <R5TextField
                  error={_.some(validationErrors['name'])}
                  label="Name"
                  onChange={setName}
                  placeholder="Account Name"
                  required
                  value={name}
                />
              </Box>
              <Hidden implementation="css" smDown>
                <CardMedia
                  alt="Account"
                  component="img"
                  image={Account}
                  sx={{ width: 160 }}
                />
              </Hidden>
            </Box>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Box className="flex flex-1 flex-row my-sm justify-end">
              <Box className="flex flex-row items-center">
                <Button
                  classes={{ root: classes.marginRight }}
                  component={RouterLink}
                  size="large"
                  to={enterprise() ? '/' : '/account/billing'}
                >
                  Cancel
                </Button>

                <Button
                  disabled={loading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save account name
                </Button>
              </Box>
            </Box>
          </CardActions>
        </form>
      </Card>
      <SSOSection navigate={navigate} />
    </R5Container>
  )
}

const SSOSection = ({ navigate }) => {
  const [submitting, setSubmitting] = useState(false)
  const [allowSelfJoin, setAllowSelfJoin] = useState(false)
  const [requireForNewUsers, setRequireForNewUsers] = useState(false)
  const classes = useStyles()

  const updateSSOConfig = useMutation(UPDATE_SSO_CONFIG)
  const { data, loading } = useQuery(SSO_CONFIGS)
  const ssoConfig = _.head(data?.ssoConfigs?.nodes)

  useEffect(() => {
    if (ssoConfig) {
      setAllowSelfJoin(ssoConfig.allowSelfJoin)
      setRequireForNewUsers(ssoConfig.requireForNewUsers)
    }
  }, [ssoConfig])

  async function handleSubmit(event) {
    event.preventDefault()
    setSubmitting(true)

    try {
      await updateSSOConfig({
        provider: ssoConfig.provider,
        domain: ssoConfig.domain,
        allowSelfJoin,
        requireForNewUsers,
      })
      setSubmitting(false)
      navigate(enterprise() ? '/' : '/account/billing')
    } catch (error) {
      setSubmitting(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  return (
    <Card variant="outlined" className="mt-8">
      <CardHeader
        title="Single-Sign-On (SSO) Settings"
        titleTypographyProps={{
          className: classes.cardTitle,
          color: 'textSecondary',
        }}
      />
      <Divider style={{ backgroundColor: styles.primary.color, height: 2 }} />
      {loading && <Loader />}
      {!loading && !ssoConfig && (
        <CardContent>
          <Typography className={classes.primary}>
            Single-sign-on is not enabled for this account.
          </Typography>
          <Typography color="textSecondary" style={{ fontSize: 12 }}>
            The primary owner of this account can enable SSO by signing in to
            Ready Five with the same email address as their current Ready Five
            user account, but using their organization's SSO provider.
          </Typography>
        </CardContent>
      )}
      {!loading && ssoConfig && (
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Box className="flex flex-col gap-8">
              <Typography className={classes.primary}>
                Your account is configured for single-sign-on with{' '}
                {ssoConfig.provider} on {ssoConfig.domain}.
              </Typography>
              <FormControlLabel
                classes={{
                  label: classes.secondary,
                  labelPlacementStart: classes.checkLabelStart,
                }}
                className="flex flex-1 justify-between"
                control={
                  <Checkbox
                    checked={requireForNewUsers}
                    color="primary"
                    onChange={({ target: { checked } }) => {
                      setRequireForNewUsers(checked)
                    }}
                    name="required-for-new-users"
                  />
                }
                label={`Require future new users to sign up using ${ssoConfig.provider} SSO with a ${ssoConfig.domain} email address. Existing users signing in are unaffected, and can switch to SSO at any time.`}
                labelPlacement="start"
              />
              <FormControlLabel
                classes={{
                  label: classes.secondary,
                  labelPlacementStart: classes.checkLabelStart,
                }}
                className="flex flex-1 justify-between"
                control={
                  <Checkbox
                    checked={allowSelfJoin}
                    color="primary"
                    onChange={({ target: { checked } }) => {
                      setAllowSelfJoin(checked)
                    }}
                    name="allow-self-join"
                  />
                }
                label={`Allow anyone with a ${ssoConfig.domain} email address to self-join as a member of this Ready Five account using ${ssoConfig.provider} SSO.`}
                labelPlacement="start"
              />
              <Typography color="textSecondary" style={{ fontSize: 12 }}>
                Regardless of your choices in the above settings, no one can
                create a separate Ready Five account using a {ssoConfig.domain}{' '}
                email address.
              </Typography>
            </Box>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Box className="flex flex-1 flex-row my-sm justify-end">
              <Box className="flex flex-row items-center">
                <Button
                  classes={{ root: classes.marginRight }}
                  component={RouterLink}
                  size="large"
                  to={enterprise() ? '/' : '/account/billing'}
                >
                  Cancel
                </Button>

                <Button
                  disabled={submitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Save single-sign-on settings
                </Button>
              </Box>
            </Box>
          </CardActions>
        </form>
      )}
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  checkLabelStart: {
    marginLeft: 0,
  },
  content: {
    padding: theme.spacing(5),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  primary: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  secondary: {
    color: '#000000',
    fontSize: 14,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}))

const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
        name
      }
    }
  }
`

const SSO_CONFIGS = gql`
  query SSOConfigs {
    ssoConfigs(first: 30) {
      nodes {
        provider
        domain
        requireForNewUsers
        allowSelfJoin
      }
    }
  }
`

const UPDATE_SSO_CONFIG = gql`
  mutation UpdateSSOConfig($input: UpdateSSOConfigInput!) {
    updateSSOConfig(input: $input) {
      ssoConfig {
        domain
        provider
        requireForNewUsers
        allowSelfJoin
      }
    }
  }
`
