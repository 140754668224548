import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Hidden,
  Link,
  Typography,
} from '@mui/material'
import {
  Help,
  Logo,
  SignInFront,
  SignInBack,
  GoogleIcon,
} from '../../components/illustrations'
import { R5Flash, R5Input, R5Title } from '../../components/shared'
import React, { useState } from 'react'

import { Auth } from 'aws-amplify'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import { enterprise } from '../../utils'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useJunkDrawer } from '../../context/junkDrawer'
import { useQueryParams } from '../../hooks'
import { configStorageKey } from '../../constants/app'

export default function SignInPage({ navigate }) {
  const classes = useStyles()
  const { signIn, confirmSignIn } = useCurrents()
  const { signInProviders } = JSON.parse(localStorage.getItem(configStorageKey))
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const { returnPath } = useQueryParams()
  const { updateJunkDrawer } = useJunkDrawer()

  const [showHelpDialog, setShowHelpDialog] = useState(false)
  const showHelp = () => setShowHelpDialog(true)
  const cancelHelp = () => setShowHelpDialog(false)

  const [confirmUser, setConfirmUser] = useState()
  const [code, setCode] = useState('')

  const showGoogleSignInButton = signInProviders.includes('Google')
  const showEmailPasswordSignInForm = signInProviders.includes('EmailPassword')
  const showOrSeparator = showGoogleSignInButton && showEmailPasswordSignInForm

  async function handleSignIn(event) {
    event.preventDefault()

    setError(null)
    setLoading(true)
    try {
      const user = await signIn(
        email,
        password,
        returnPath ? () => navigate(returnPath) : null
      )

      if (user) {
        setLoading(false)
        setConfirmUser(user)
      }
    } catch (err) {
      setLoading(false)
      setError(err.message)
    }
  }

  async function handleConfirmSignIn(event) {
    event.preventDefault()

    setError(null)
    setLoading(true)

    try {
      await confirmSignIn(
        confirmUser,
        code,
        returnPath ? () => navigate(returnPath) : null
      )
    } catch (err) {
      setLoading(false)
      setError(err.message)
      if (err.message.includes('session is expired')) {
        setConfirmUser(undefined)
      }
    }
  }

  function renderHelpDialog() {
    return (
      <Dialog
        onClose={cancelHelp}
        aria-labelledby="help-dialog"
        open={showHelpDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent
          align="center"
          id="help-dialog"
          style={{ paddingTop: 30 }}
        >
          <Grid className="items-center" container spacing={3}>
            <Grid className="flex flex-1 justify-center" item xs={12} sm={6}>
              <Box className="flex flex-1 flex-col mr-sm">
                <Typography className={classes.title}>
                  We're here to help
                </Typography>
                <Typography className={classes.subtitle} color="textSecondary">
                  Have a question? Ready Five support is standing by.
                </Typography>
              </Box>
            </Grid>
            <Grid className="flex flex-1 justify-center" item sm={6}>
              <Hidden implementation="css" smDown>
                <CardMedia
                  alt="Help"
                  component="img"
                  image={Help}
                  sx={{ width: 200 }}
                />
              </Hidden>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className="mx-md my-sm">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button
                className={classes.helpButton}
                fullWidth
                href="mailto:support@readyfive.io"
                size="large"
                target="_blank"
                variant="contained"
              >
                Email Support
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                className={classes.helpButton}
                fullWidth
                href="https://twitter.com/intent/tweet?text=%40getreadyfive%20"
                size="large"
                target="_blank"
                variant="contained"
              >
                Ask us on Twitter
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container className="flex w-full items-center h-full justify-between p-0 max-w-[100%] min-h-[100vh]">
      <R5Title title="Sign in" />
      <Box className={classes.imgCol}>
        <img
          className={classes.frontImg}
          src={SignInFront}
          alt="Illustration of a phone with the Ready Five UI on the screen."
        />
        <img
          className={classes.backImg}
          src={SignInBack}
          alt="Background illustration of a futuristic city."
        />
      </Box>
      <Box style={{ margin: '0 auto', maxWidth: '400px' }}>
        <CardContent className={classes.content}>
          <R5Flash />
          <Box>
            <Logo
              className={classes.logo}
              color={styles.primary.color}
              size={65}
            />
            <Typography className={classes.title}>
              Sign in to your account
            </Typography>
            {confirmUser ? (
              <Box className="flex flex-1 flex-row items-start">
                <Icons.Lock color={styles.primary.color} size={30} />
                <Typography className={classes.subtitle}>
                  Enter the 6-digit code generated by your authenticator app.
                </Typography>
              </Box>
            ) : (
              !enterprise() && (
                <Typography className={classes.subtitle}>
                  or{' '}
                  <Link
                    className={classes.link}
                    color="primary"
                    component={RouterLink}
                    to="/signup"
                    underline="hover"
                  >
                    start your 14-day free trial
                  </Link>
                </Typography>
              )
            )}
            {error && (
              <Typography className={classes.error}>
                {error}
                {error === 'User is not confirmed.' && (
                  <Link
                    className={`${classes.link} ml-xs`}
                    component={RouterLink}
                    onClick={() => {
                      updateJunkDrawer('tempPassword', password)
                      Auth.resendSignUp(email)
                    }}
                    style={{ color: '#fff' }}
                    size="small"
                    to={`/signup/confirm?email=${encodeURIComponent(email)}`}
                    underline="hover"
                  >
                    Confirm
                  </Link>
                )}
              </Typography>
            )}
          </Box>
          {confirmUser ? (
            <form onSubmit={handleConfirmSignIn}>
              <R5Input
                autoFocus
                autoComplete="one-time-code"
                fullWidth
                onChange={setCode}
                placeholder="Code"
                value={code}
              />
              <Button
                classes={{
                  disabled: classes.disabled,
                  root: classes.confirmButton,
                }}
                disabled={loading || _.isEmpty(code)}
                fullWidth
                style={{ marginTop: 20 }}
                size="large"
                type="submit"
                variant="contained"
              >
                {loading ? (
                  <CircularProgress color="inherit" size={21} />
                ) : (
                  'Sign in'
                )}
              </Button>
            </form>
          ) : (
            <form onSubmit={handleSignIn}>
              {showGoogleSignInButton && (
                <Button
                  className={classes.googleButton}
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={() => {
                    Auth.federatedSignIn({
                      provider: 'Google',
                      customState: JSON.stringify({}),
                    })
                  }}
                >
                  <img
                    src={GoogleIcon}
                    alt="Google Icon"
                    style={{
                      maxWidth: '18px',
                      marginRight: 10,
                    }}
                  />
                  Sign in with Google
                </Button>
              )}
              {showOrSeparator && (
                <>
                  <Box className={classes.spacer}></Box>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    - OR -
                  </Typography>
                  <Box className={classes.spacer}></Box>
                </>
              )}
              {showEmailPasswordSignInForm && (
                <>
                  <R5Input
                    autoComplete="username"
                    autoFocus
                    fullWidth
                    onChange={setEmail}
                    placeholder="Email address"
                    startAdornment={
                      <Icons.Envelope
                        size={25}
                        color={styles.lightLabel.color}
                      />
                    }
                    type="email"
                    value={email}
                  />
                  <R5Input
                    autoComplete="current-password"
                    className="mt-lg"
                    fullWidth
                    onChange={setPassword}
                    placeholder="Password"
                    startAdornment={
                      <Icons.Key size={25} color={styles.lightLabel.color} />
                    }
                    type="password"
                    value={password}
                  />
                  <Button
                    classes={{
                      disabled: classes.disabled,
                      root: classes.button,
                    }}
                    style={{ marginTop: 20 }}
                    disabled={
                      loading || _.isEmpty(email) || _.isEmpty(password)
                    }
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={21} />
                    ) : (
                      'Sign in'
                    )}
                  </Button>
                </>
              )}
            </form>
          )}
          <CardActions className="flex flex-1 justify-between">
            <Link
              className={`${classes.link} cursor-pointer`}
              color="primary"
              onClick={showHelp}
              underline="hover"
            >
              Need Help?
            </Link>
            {confirmUser ? (
              <Link
                className={classes.link}
                color="primary"
                component={RouterLink}
                to="/login"
                underline="hover"
              >
                Back to sign in
              </Link>
            ) : (
              showEmailPasswordSignInForm && (
                <Link
                  className={classes.link}
                  color="primary"
                  component={RouterLink}
                  to="/forgot"
                  underline="hover"
                >
                  Forgot your password?
                </Link>
              )
            )}
          </CardActions>
        </CardContent>
      </Box>
      {renderHelpDialog()}
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 800,
    marginTop: theme.spacing(3.75),
    textTransform: 'uppercase',
  },
  card: {
    borderRadius: theme.spacing(1),
    minHeight: 556,
  },
  confirmButton: {
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 800,
    marginTop: theme.spacing(17.75),
    textTransform: 'uppercase',
  },
  disabled: {
    backgroundColor: `${styles.primaryLight.color} !important`,
    color: 'white !important',
  },
  error: {
    backgroundColor: styles.error.backgroundColor,
    borderColor: styles.error.color,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    color: 'white',
    marginTop: 20,
    marginBottom: 20,
    padding: 12,
    width: '100%',
  },
  googleButton: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: styles.primary.color,
    color: styles.primary.color,
    marginBottom: 0,
    marginTop: 0,
    borderRadius: 8,
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  helpButton: {
    borderRadius: theme.spacing(1),
    fontWeight: 800,
  },
  link: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    marginTop: 20,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  title: {
    fontSize: 50,
    fontWeight: 600,
    lineHeight: '107.9%',
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  columnContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
    padding: '0',
    maxWidth: '100%',
  },
  imgCol: {
    height: '100vh',
    width: '50%',
    backgroundColor: '#4147EB',
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
    '@media (max-width: 925px)': {
      display: 'none',
    },
  },
  backImg: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: '0',
  },
  frontImg: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '-350px',
    top: '50%',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: '60%',
    minWidth: '580px',
    display: 'block',
  },
}))
