import { Box, Button, CardContent, FormHelperText } from '@mui/material'
import { useState } from 'react'
import { R5TextField } from '../shared'
import { useStyles } from './EmailForm'
import gql from 'graphql-tag'
import { useMutation } from '../../hooks'
import { useCurrents } from '../../context/currents'

export default function EmailConfirmationForm({
  email,
  setCodeSent,
  setShowEmailUpdatedDialog,
}) {
  const classes = useStyles()
  const { userId } = useCurrents()
  const verifyChangeUserEmailAddressMutation = useMutation(
    VERIFY_CHANGE_USER_EMAIL_ADDRESS
  )
  const [emailConfirmationCode, setEmailConfirmationCode] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  async function handleConfirmEmailChange(event) {
    event.preventDefault()

    setIsConfirming(true)
    setValidationErrors({})

    try {
      await verifyChangeUserEmailAddressMutation({
        userId,
        verificationCode: emailConfirmationCode,
      })
      setCodeSent(false)
      setIsConfirming(false)
      setShowEmailUpdatedDialog(true)
      setEmailConfirmationCode('')
    } catch (error) {
      setIsConfirming(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  return (
    <CardContent className={classes.content}>
      <form
        className="flex flex-1 flex-col"
        onSubmit={handleConfirmEmailChange}
      >
        <R5TextField
          error={validationErrors['verificationCode']}
          label="Verification Code"
          onChange={setEmailConfirmationCode}
          type="tel"
          value={emailConfirmationCode}
        />
        <FormHelperText>
          Please check your email ({email}) for your verification code.
        </FormHelperText>
        <Box className="flex flex-1 flex-row justify-end mt-md">
          <Button disabled={isConfirming} type="submit" variant="outlined">
            Confirm email address change
          </Button>
        </Box>
      </form>
    </CardContent>
  )
}

const VERIFY_CHANGE_USER_EMAIL_ADDRESS = gql`
  mutation VerifyChangeUserEmailAddress(
    $input: VerifyChangeUserEmailAddressInput!
  ) {
    verifyChangeUserEmailAddress(input: $input) {
      userEmailAddressChange {
        newEmailAddress
        completedAt
        expiresAt
        verificationRequired
      }
    }
  }
`
