import * as Icons from '../../components/icons'

import { Box, Card, CardContent, Typography } from '@mui/material'

import ContactMethodItem from './ContactMethodItem'
import { IconButton } from '@mui/material'
import { R5Infotip } from '../../components/shared'
import React from 'react'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function ContactMethods({ EmptyText, viewer }) {
  const classes = useStyles()
  const hasContactMethods = viewer.contactMethods.nodes.length > 0

  return (
    <Box className="mt-5">
      <Box className="flex flex-1 flex-row items-center justify-between">
        <Box className="flex items-center">
          <Typography className={classes.header} color="textSecondary">
            Contact Methods
          </Typography>
          <R5Infotip
            learnMore="https://www.readyfive.io/docs/users/contact-methods"
            title={
              <Typography paragraph>
                A contact method is a way that we can contact you when you're
                being notified about an incident. Contact methods are private to
                individual users, so the details (email address, phone number,
                etc.) are not visible to anyone else.
              </Typography>
            }
          />
        </Box>

        <IconButton
          component={RouterLink}
          size="small"
          to="/profile/contactMethods/new"
        >
          <Icons.PlusCircleOutline color={styles.primary.color} size={35} />
        </IconButton>
      </Box>
      {hasContactMethods ? (
        _.map(
          _.groupBy(viewer.contactMethods.nodes, 'type'),
          (contactMethods, type) => (
            <ContactMethodItem
              contactMethods={contactMethods}
              key={type}
              type={type}
            />
          )
        )
      ) : (
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.content}>
            <EmptyText text="You have no contact methods" />
          </CardContent>
        </Card>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  header: {
    fontSize: 14,
    fontWeight: 900,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
}))
