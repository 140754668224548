import React from 'react'

export default function Key({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.7222 14.6087C8.00677 14.6087 9.91242 12.9581 10.3499 10.7652H12.9626V13.3275H15.1799V10.7652H17.9998V8.54779H10.2813C9.73833 6.50385 7.90288 5 5.7222 5C3.1142 5 1 7.15097 1 9.80433C1 12.4577 3.1142 14.6087 5.7222 14.6087ZM5.7222 12.3913C7.06969 12.3913 8.22702 11.2693 8.22702 9.80433C8.22702 8.3394 7.06969 7.21738 5.7222 7.21738C4.37472 7.21738 3.21738 8.3394 3.21738 9.80433C3.21738 11.2693 4.37472 12.3913 5.7222 12.3913Z"
        fill={color}
      />
    </svg>
  )
}
