import React from 'react'

export default function EditBox({ className, color, size }) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2699 6.00783L14.2621 3L8.25187 9.01025L8.25187 12.0181H11.2597L17.2699 6.00783ZM15.3719 6.00783L14.2621 4.89803L9.59398 9.56617V10.676H10.7038L15.3719 6.00783ZM7.6974 3.55593H3V17.6481H17.0922V12.9507H15.7501V16.306H4.34211V4.89804H7.6974V3.55593Z"
        fill={color}
      />
    </svg>
  )
}
