import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Typography,
} from '@mui/material'
import {
  ConfirmButton,
  R5ButtonGroup,
  R5Input,
  R5Select,
  R5TextField,
  TimePeriodSettings,
  TimeZoneSelect,
} from '../../components/shared'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'

import { Alert } from '@mui/material'
import { MobileDateTimePicker } from '@mui/lab'
import { ParticipantsSelect } from '../../components/Teams'
import { Slider } from '@mui/material'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useMutation } from '../../hooks'

const ROTATION_TYPE_OPTIONS = [
  { label: 'None', value: 'NONE' },
  { label: 'Hourly', value: 'HOURLY' },
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' },
]

export default function ShiftForm({ scheduleId, shift, teamId }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { user } = useCurrents()
  const [loading, setLoading] = useState(false)
  const shiftMutation = useMutation(shift ? UPDATE_SHIFT : CREATE_SHIFT)
  const deleteMutation = useMutation(DELETE_SHIFT)
  const [name, setName] = useState(shift ? shift.name : '')
  const [participants, setParticipants] = useState(
    shift
      ? shift.participants.nodes.map((participant) => ({
          value: participant.id,
          label: participant.name,
        }))
      : []
  )
  const extractTimes = ({ fromTime, toTime }) => ({
    fromTime,
    toTime,
  })
  const [timePeriods, setTimePeriods] = useState({
    monday:
      shift?.timePeriods.monday.length > 0
        ? shift.timePeriods.monday.map(extractTimes)
        : [],
    tuesday:
      shift?.timePeriods.tuesday.length > 0
        ? shift.timePeriods.tuesday.map(extractTimes)
        : [],
    wednesday:
      shift?.timePeriods.wednesday.length > 0
        ? shift.timePeriods.wednesday.map(extractTimes)
        : [],
    thursday:
      shift?.timePeriods.thursday.length > 0
        ? shift.timePeriods.thursday.map(extractTimes)
        : [],
    friday:
      shift?.timePeriods.friday.length > 0
        ? shift.timePeriods.friday.map(extractTimes)
        : [],
    saturday:
      shift?.timePeriods.saturday.length > 0
        ? shift.timePeriods.saturday.map(extractTimes)
        : [],
    sunday:
      shift?.timePeriods.sunday.length > 0
        ? shift.timePeriods.sunday.map(extractTimes)
        : [],
  })
  const [rotationLength, setRotationLength] = useState(
    shift ? shift.rotationLength : 0
  )
  const [coverageType, setCoverageType] = useState(
    shift ? shift.coverageType : 'ON_CALL'
  )
  const [rotationType, setRotationType] = useState(
    shift ? shift.rotationType : 'NONE'
  )
  const [timeZone, setTimeZone] = useState(
    shift ? shift.timeZone : user.timeZone
  )
  const [startsAt, setStartsAt] = useState(
    shift
      ? moment.unix(shift.startsAt).tz(timeZone).local(true).toDate()
      : moment('9:00', 'HH:mm').toDate()
  )
  const [endsAt, setEndsAt] = useState(
    shift
      ? _.isInteger(shift.endsAt)
        ? moment.unix(shift.endsAt).tz(timeZone).local(true).toDate()
        : moment
            .unix(shift.startsAt)
            .tz(timeZone)
            .local(true)
            .add(1, 'day')
            .toDate()
      : moment('17:00', 'HH:mm').add(1, 'day').toDate()
  )
  const [validationErrors, setValidationErrors] = useState([])
  const [withEndsAt, setWithEndsAt] = useState(
    shift ? _.isInteger(shift.endsAt) : false
  )

  useEffect(() => {
    if (startsAt > endsAt) setEndsAt(moment(startsAt).add(1, 'day').toDate())
  }, [endsAt, startsAt])

  async function handleDelete() {
    setLoading(true)
    try {
      await deleteMutation({
        shiftId: shift.id,
        scheduleId,
        teamId,
      })
      setLoading(false)
      navigate(`/teams/${teamId}/schedules/${scheduleId}`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission

    setLoading(true)
    try {
      await shiftMutation({
        teamId,
        scheduleId,
        shiftId: shift?.id,
        name,
        timeZone,
        coverageType,
        rotationType,
        rotationLength,
        startsAt: moment
          .tz(moment(startsAt).format('Y-MM-DDTHH:mm:ss'), timeZone)
          .unix(),
        endsAt: withEndsAt
          ? moment
              .tz(moment(endsAt).format('Y-MM-DDTHH:mm:ss'), timeZone)
              .unix()
          : null,
        participantIds: participants.map((participant) => participant.value),
        timePeriods,
      })
      setLoading(false)
      navigate(`/teams/${teamId}/schedules/${scheduleId}`)
    } catch (error) {
      setLoading(false)
      if (error.errors) {
        window.scrollTo(0, 0)
        setValidationErrors(error.errors)
      }
    }
  }

  function maxRotationLength(type = rotationType) {
    let max = 0

    switch (type) {
      case 'HOURLY':
        max = 720
        break
      case 'DAILY':
        max = 365
        break
      case 'WEEKLY':
        max = 52
        break
      default:
        max = 0
    }

    return max
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-xxl ${
          shift ? 'justify-between' : 'justify-end'
        }`}
      >
        {shift && (
          <ConfirmButton
            confirmContent="Are you sure you want to delete this shift from the schedule?
          Doing so may create an immediate gap in on-call coverage."
            error
            onConfirm={handleDelete}
            title="Delete Shift"
          />
        )}

        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/teams/${teamId}/schedules/${scheduleId}`}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {shift ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  function renderShiftType() {
    return (
      <FormControl className={classes.margin}>
        <Box className="flex flex-row justify-between items-center">
          <Box className="mr-sm">
            <InputLabel
              className={classes.label}
              error={_.some(validationErrors['coverageType'])}
              htmlFor="coverage-type"
            >
              Type
            </InputLabel>
            <Typography className={classes.subTitle}>
              {coverageType === 'ON_CALL'
                ? 'On-call shifts typically represent time periods where team members are expected to be available, but not necessarily during normal work hours.'
                : 'On-duty shifts typically represent normal work hours, where team members don’t need to be notified of shift changes or have different notification preferences.'}
            </Typography>
          </Box>
          <R5ButtonGroup
            buttons={[
              { label: 'On Call', value: 'ON_CALL' },
              { label: 'On Duty', value: 'ON_DUTY' },
            ]}
            onChange={setCoverageType}
            selectedValue={coverageType}
          />
        </Box>
      </FormControl>
    )
  }

  function renderShiftLength() {
    return (
      rotationType !== 'NONE' && (
        <FormControl className={classes.margin}>
          <InputLabel
            className={classes.label}
            error={_.some(validationErrors['rotationLength'])}
            htmlFor="rotation-length"
          >
            Rotation Length
          </InputLabel>
          <Slider
            className={classes.input}
            max={maxRotationLength()}
            min={1}
            name="rotation-length"
            onChange={(_event, value) => setRotationLength(value)}
            value={rotationLength}
          />
          <Typography color="textSecondary">
            {rotationLength}{' '}
            {
              _.find(
                [
                  { label: 'hour', value: 'HOURLY' },
                  { label: 'day', value: 'DAILY' },
                  { label: 'week', value: 'WEEKLY' },
                ],
                { value: rotationType }
              ).label
            }
            {rotationLength > 1 ? 's' : ''}
          </Typography>
          {validationErrors['rotationLength'] && (
            <FormHelperText error>
              {validationErrors['rotationLength']}
            </FormHelperText>
          )}
        </FormControl>
      )
    )
  }

  function renderStartsAt() {
    return (
      <FormControl className={classes.margin}>
        <InputLabel
          className={classes.label}
          error={_.some(validationErrors['startsAt'])}
          htmlFor="starts-at"
        >
          Starts
        </InputLabel>
        <MobileDateTimePicker
          className={classes.input}
          error={_.some(validationErrors['startsAt'])}
          inputFormat="MMMM D hh:mm a"
          minutesStep={15}
          onChange={setStartsAt}
          OpenPickerButtonProps={{ color: 'primary' }}
          renderInput={(props) => <R5Input {...props} />}
          value={startsAt}
        />
      </FormControl>
    )
  }

  function renderEndsAt() {
    return (
      <FormControl
        className={classes.margin}
        error={_.some(validationErrors['endsAt'])}
      >
        <FormControlLabel
          classes={{
            label: classes.checkLabel,
            labelPlacementStart: classes.checkLabelStart,
          }}
          className="flex flex-1 justify-between"
          control={
            <Checkbox
              checked={withEndsAt}
              color="primary"
              onChange={({ target: { checked } }) => {
                setWithEndsAt(checked)
              }}
              name="with-ends-at"
            />
          }
          label="Ends"
          labelPlacement="start"
        />
        {withEndsAt && (
          <MobileDateTimePicker
            error={_.some(validationErrors['endsAt'])}
            inputFormat="MMMM D hh:mm a"
            minDate={moment(startsAt)}
            minutesStep={15}
            onChange={setEndsAt}
            OpenPickerButtonProps={{ color: 'primary' }}
            style={{ marginTop: 0 }}
            renderInput={(props) => <R5Input {...props} />}
            value={endsAt}
          />
        )}
      </FormControl>
    )
  }

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      {_.some(validationErrors) && (
        <Alert className="mb-lg" severity="error" variant="filled">
          One or more errors are present. Please fix them and try again.
        </Alert>
      )}
      <R5TextField
        className="mt-0"
        error={validationErrors['name']}
        label="Shift Name"
        onChange={setName}
        placeholder="Weekdays"
        required
        value={name}
      />
      <ParticipantsSelect
        participants={participants}
        setParticipants={setParticipants}
        teamId={teamId}
        validationErrors={validationErrors}
      />
      {renderShiftType()}
      <R5Select
        error={validationErrors['rotationType']}
        label="Rotates"
        onChange={(value) => {
          setRotationType(value)
          if (value === 'NONE') setRotationLength(0)
          if (rotationLength === 0) setRotationLength(1)
          if (rotationLength > maxRotationLength(value))
            setRotationLength(maxRotationLength(value))
        }}
        options={ROTATION_TYPE_OPTIONS}
        value={rotationType}
      />
      {renderShiftLength()}
      <TimeZoneSelect
        helpText="This is the time zone in effect for the dates and times defined on this shift. Participants can still operate in any time zone."
        setTimeZone={setTimeZone}
        timeZone={timeZone}
        validationErrors={validationErrors}
      />
      {renderStartsAt()}
      {renderEndsAt()}
      <TimePeriodSettings
        helpText="By default, shifts can have periods assigned to participants at any time of day on all days of the week. Time periods only add coverage for the specified days and times."
        setTimePeriods={setTimePeriods}
        timePeriods={timePeriods}
        validationErrors={validationErrors}
      />
      {renderFooter()}
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  checkLabel: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  checkLabelStart: {
    marginLeft: 0,
  },
  input: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  margin: {
    marginTop: theme.spacing(3.5),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    paddingBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 12,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
}))

const CREATE_SHIFT = gql`
  mutation CreateShift($input: CreateShiftInput!) {
    createShift(input: $input) {
      shift {
        id
        name
        timeZone
        coverageType
        rotationType
        rotationLength
        startsAt
        endsAt
        participants(first: 30) {
          nodes {
            id
            name
          }
        }
        timePeriods {
          monday {
            fromTime
            toTime
          }
          tuesday {
            fromTime
            toTime
          }
          wednesday {
            fromTime
            toTime
          }
          thursday {
            fromTime
            toTime
          }
          friday {
            fromTime
            toTime
          }
          saturday {
            fromTime
            toTime
          }
          sunday {
            fromTime
            toTime
          }
        }
      }
    }
  }
`

const UPDATE_SHIFT = gql`
  mutation UpdateShift($input: UpdateShiftInput!) {
    updateShift(input: $input) {
      shift {
        id
        name
        timeZone
        coverageType
        rotationType
        rotationLength
        startsAt
        endsAt
        participants(first: 30) {
          nodes {
            id
            name
          }
        }
        timePeriods {
          monday {
            fromTime
            toTime
          }
          tuesday {
            fromTime
            toTime
          }
          wednesday {
            fromTime
            toTime
          }
          thursday {
            fromTime
            toTime
          }
          friday {
            fromTime
            toTime
          }
          saturday {
            fromTime
            toTime
          }
          sunday {
            fromTime
            toTime
          }
        }
      }
    }
  }
`

const DELETE_SHIFT = gql`
  mutation DeleteShift($input: DeleteShiftInput!) {
    deleteShift(input: $input) {
      shiftId
    }
  }
`
