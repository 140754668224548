import * as Icons from '../icons'

import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { R5Input, R5RadioGroup, R5TextField } from '../../components/shared'

import EventRuleOptions from '../../constants/EventRuleOptions'
import React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function EventRuleCondition({
  condition,
  conditionIndex,
  conditions,
  index,
  setConditions,
  validationErrors,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Card className={classes.card} key={index} variant="outlined">
      <CardContent className="pt-0">
        <Grid className="items=center" container spacing={1}>
          <Grid item xs={6} sm={6}>
            <R5TextField
              label="Field"
              error={validationErrors['field']}
              onChange={(field) =>
                setConditions((prevConditions) => {
                  let newConditions = [...prevConditions]
                  newConditions[index] = {
                    caseSensitiveValue:
                      prevConditions[index].caseSensitiveValue,
                    expectedValue: prevConditions[index].expectedValue,
                    field,
                    operation: prevConditions[index].operation,
                    negateOperation: prevConditions[index].negateOperation,
                  }
                  return newConditions
                })
              }
              placeholder="_body.fieldName"
              required
              value={condition.field}
            />
          </Grid>
          <Grid item className="flex flex-1 flex-row justify-end" xs={6} sm={6}>
            {conditions.length > 1 && (
              <IconButton
                onClick={() =>
                  setConditions((prevConditions) => {
                    return _.filter(
                      prevConditions,
                      (_condition, index) => index !== conditionIndex
                    )
                  })
                }
                size="small"
              >
                <Icons.MinusCircleOutline
                  color={styles.error.color}
                  size={25}
                />
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            className={mobileView ? 'mt-md' : classes.margin}
          >
            <R5RadioGroup
              buttons={
                EventRuleOptions.isOperations.includes(condition.operation)
                  ? EventRuleOptions.isNegateOperation
                  : EventRuleOptions.negateOperation
              }
              label=""
              onChange={(negateOperation) =>
                setConditions((prevConditions) => {
                  let newConditions = [...prevConditions]
                  newConditions[index] = {
                    caseSensitiveValue:
                      prevConditions[index].caseSensitiveValue,
                    expectedValue: prevConditions[index].expectedValue,
                    field: prevConditions[index].field,
                    operation: prevConditions[index].operation,
                    negateOperation: negateOperation === 'true',
                  }
                  return newConditions
                })
              }
              row
              value={`${condition.negateOperation}`}
            />
            <Select
              classes={{ select: classes.select }}
              id="operation"
              input={<R5Input />}
              onChange={(operation) =>
                setConditions((prevConditions) => {
                  let newConditions = [...prevConditions]
                  newConditions[index] = {
                    caseSensitiveValue:
                      prevConditions[index].caseSensitiveValue,
                    expectedValue: prevConditions[index].expectedValue,
                    field: prevConditions[index].field,
                    operation,
                    negateOperation: prevConditions[index].negateOperation,
                  }
                  return newConditions
                })
              }
              value={condition.operation}
            >
              {EventRuleOptions.criteriaOperations.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {conditions[index].operation !== 'EXIST' && (
            <>
              <Grid className="flex flex-1" item xs={12} sm={6}>
                <R5TextField
                  label="Value"
                  onChange={(value) =>
                    setConditions((prevConditions) => {
                      let newConditions = [...prevConditions]
                      newConditions[index] = {
                        caseSensitiveValue:
                          prevConditions[index].caseSensitiveValue,
                        expectedValue: value,
                        field: prevConditions[index].field,
                        operation: prevConditions[index].operation,
                        negateOperation: prevConditions[index].negateOperation,
                      }
                      return newConditions
                    })
                  }
                  placeholder=""
                  value={condition.expectedValue}
                />
              </Grid>
              {EventRuleOptions.caseSensitiveOperations.includes(
                condition.operation
              ) && (
                <Grid item xs={12} sm={6}>
                  <FormControl
                    className={mobileView ? 'mt-md' : classes.extraMargin}
                  >
                    <FormControlLabel
                      classes={{
                        label: classes.checkLabel,
                        labelPlacementStart: classes.checkLabelStart,
                      }}
                      className="flex flex-1 justify-between"
                      control={
                        <Checkbox
                          checked={condition.caseSensitiveValue}
                          color="primary"
                          onChange={({ target: { checked } }) => {
                            setConditions((prevConditions) => {
                              let newConditions = [...prevConditions]
                              newConditions[index] = {
                                caseSensitiveValue: checked,
                                expectedValue:
                                  prevConditions[index].expectedValue,
                                field: prevConditions[index].field,
                                operation: prevConditions[index].operation,
                                negateOperation:
                                  prevConditions[index].negateOperation,
                              }
                              return newConditions
                            })
                          }}
                          name="case-sensitive-value"
                        />
                      }
                      label="Case Sensitive"
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
    boxShadow: '0px 10px 12px rgba(0, 0, 0, 0.05)',
    marginBottom: theme.spacing(1),
  },
  checkLabel: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  checkLabelStart: {
    marginLeft: 0,
  },
  extraMargin: {
    marginTop: theme.spacing(6.5),
  },
  margin: {
    marginTop: theme.spacing(3.5),
  },
  select: {
    fontWeight: 'bold',
  },
}))
