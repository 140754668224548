import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Hidden,
  Typography,
} from '@mui/material'
import {
  ConfirmButton,
  R5Container,
  R5Header,
  R5TextField,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'
import { useBack, useMutation, useQueryParams } from '../../hooks'

import { VerifyContactMethod } from '../../components/illustrations'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'

export default function VerifyContactMethodPage({ contactMethodId }) {
  useBack('/profile/notifications')
  const classes = useStyles()
  const navigate = useNavigate()
  const { user } = useCurrents()

  const { code: codeParam } = useQueryParams()
  const deleteMutation = useMutation(DELETE_CONTACT_METHOD)
  const submitMutation = useMutation(SUBMIT_CONTACT_METHOD_VERIFICATION)
  const requestVerifyMutation = useMutation(REQUEST_CONTACT_METHOD_VERIFICATION)
  const [validationErrors, setValidationErrors] = useState([])
  const [code, setCode] = useState(codeParam || '')
  const [codeSent, setCodeSent] = useState(false)
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission
    setLoading(true)

    try {
      const response = await submitMutation({
        contactMethodId,
        userId: user.id,
        verificationCode: code,
      })
      setLoading(false)

      if (response.data.submitContactMethodVerification) {
        navigate('/profile/notifications')
      } else {
        setValidationErrors({ code: 'Incorrect verification code' })
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleRequestVerification() {
    setLoading(true)
    await requestVerifyMutation({
      contactMethodId: contactMethodId,
      userId: user.id,
    })
    setCodeSent(true)
    setLoading(false)
  }

  async function handleDelete() {
    try {
      await deleteMutation({
        contactMethodId: contactMethodId,
        userId: user.id,
      })
      navigate('/profile/notifications')
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row items-center justify-between my-sm">
        <Box>
          <ConfirmButton
            confirmContent="Are you sure you want to delete this contact method? Doing so will
          immediately remove this contact method as a target in any
          notification rules and may prevent you from being contacted when an
          incident is assigned to you."
            error
            onConfirm={handleDelete}
            title="Delete Contact Method"
          >
            Delete Contact Method
          </ConfirmButton>
        </Box>
        <Box className="flex flex-row items-center">
          <Button
            className={classes.marginRight}
            component={RouterLink}
            size="large"
            to="/profile/notifications"
          >
            Cancel
          </Button>
          <Button
            disabled={loading || _.isEmpty(code)}
            size="large"
            type="submit"
            variant="contained"
          >
            Verify
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Verify Contact Method" />
      <R5Header title="Verify Contact Method" />
      <Box>
        <Typography className="flex flex-0" color="textSecondary">
          Now we need to verify your new contact method. Get your device ready
          and click "Send me a code".
        </Typography>
        <Box className="flex justify-end">
          <Button
            disabled={loading || codeSent}
            onClick={handleRequestVerification}
            variant="outlined"
          >
            {codeSent ? 'Code sent' : 'Send me a code'}
          </Button>
        </Box>
      </Box>
      <Card className="mt-md" variant="outlined">
        <form onSubmit={handleSubmit}>
          <CardContent className={classes.content}>
            <Box className="flex flex-1 ">
              <Box className="flex flex-1 mr-lg">
                <R5TextField
                  error={validationErrors['verificationCode']}
                  label="Verification Code"
                  onChange={setCode}
                  placeholder="111111"
                  value={code}
                />
              </Box>
              <Hidden implementation="css" smDown>
                <CardMedia
                  alt="Verify Contact Method"
                  component="img"
                  image={VerifyContactMethod}
                  sx={{ width: 160 }}
                />
              </Hidden>
            </Box>
          </CardContent>

          <Divider />
          <CardActions className={classes.actions}>
            {renderFooter()}
          </CardActions>
        </form>
      </Card>
    </R5Container>
  )
}
const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
}))

const SUBMIT_CONTACT_METHOD_VERIFICATION = gql`
  mutation SubmitContactMethodVerification(
    $input: SubmitContactMethodVerificationInput!
  ) {
    submitContactMethodVerification(input: $input) {
      contactMethod {
        id
      }
    }
  }
`

const REQUEST_CONTACT_METHOD_VERIFICATION = gql`
  mutation RequestContactMethodVerification(
    $input: RequestContactMethodVerificationInput!
  ) {
    requestContactMethodVerification(input: $input) {
      contactMethod {
        id
      }
    }
  }
`

const DELETE_CONTACT_METHOD = gql`
  mutation DeleteContactMethod($input: DeleteContactMethodInput!) {
    deleteContactMethod(input: $input) {
      contactMethodId
    }
  }
`
