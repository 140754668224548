import { Box } from '@mui/material'
import { Logo } from '../illustrations'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function R5Title() {
  const classes = useStyles()

  return (
    <Box
      className={`${classes.background} flex justify-center items-center h-screen`}
    >
      <Logo color={styles.primary.color} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#f6f7fb',
  },
}))
