import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useCallback, useEffect } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useBack, useQuery } from '../../hooks'
import { Alert } from '@mui/material'
import { IntegrationSetup } from '../../components/Teams'
import gql from 'graphql-tag'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function IntegrationPage({ integrationId, teamId }) {
  useBack(`/teams/${teamId}/integrations`)
  const classes = useStyles()
  const { data, errors, loading, refetch } = useQuery(QUERY, {
    integrationId,
    teamId,
  })
  const { accountId, subscribe } = useCurrents()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INTEGRATION_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        id: integrationId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId, integrationId])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container>
      <R5Title title={`Integration - ${data.integration.name}`} />
      {data.integration.isDisabled && (
        <Alert className="mb-sm" severity="warning">
          This integration is disabled, so alerts received at this integration's
          address are ignored.
        </Alert>
      )}
      <R5Header
        editUrl={
          data.integration.viewerCanAdminister
            ? `/teams/${data.integration.teamId}/integrations/${data.integration.id}/edit`
            : null
        }
        title={data.integration.name}
      >
        {data.integration.description.length > 0 && (
          <Typography className={classes.subtitle}>
            {data.integration.description}
          </Typography>
        )}
      </R5Header>
      <IntegrationSetup
        integration={data.integration}
        refetchMemo={refetchMemo}
      />
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: styles.ruleTitle.color,
    fontSize: 15,
    fontWeight: 'bold',
  },
}))

const QUERY = gql`
  query IntegrationPage($integrationId: ID!, $teamId: ID!) {
    integration(id: $integrationId, teamId: $teamId) {
      id
      isDisabled
      isOutbound
      accountId
      teamId
      team {
        id
        name
      }
      createdAt
      updatedAt
      key
      name
      description
      type
      provider
      providerImageUrl
      providerInfoUrl
      address
      assignTo {
        id
        name
      }
      viewerCanAdminister
      eventRules {
        action
        stopEvaluatingOnMatch
        value
        criteria {
          match
          conditions {
            field
            expectedValue
            operation
            negateOperation
          }
        }
      }
      transforms {
        targetField
        template
      }
      alerts(last: 1) {
        nodes {
          id
          accountId
          teamId
          integrationId
          state
          status
          payload {
            body
            headers
            queryStringParameters
            transforms {
              targetField
              template
            }
            transformedPayload {
              action
              body
              details
              groupKey
              idempotencyKey
              severity
              source
              timestamp
              title
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`

const INTEGRATION_UPDATE_SUBSCRIPTION = gql`
  subscription onIntegrationUpdated($accountId: ID!, $teamId: ID!, $id: ID!) {
    integrationUpdated(accountId: $accountId, teamId: $teamId, id: $id) {
      id
      updatedAt
    }
  }
`
