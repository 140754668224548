import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from '@mui/material'
import { R5TextField, R5Title } from '.'
import React, { useState } from 'react'

import { Logo } from '../illustrations'
import R5Theme from '../../constants/R5Theme'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function UserDeactivated({ cognitoUser, signOut }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={R5Theme}>
        <CssBaseline />
        <WrappedContainer cognitoUser={cognitoUser} signOut={signOut} />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

function WrappedContainer({ cognitoUser, signOut }) {
  const classes = useStyles()
  const [accountName, setAccountName] = useState('')
  return (
    <Container maxWidth="xs">
      <R5Title title="User Deactivated" />
      <Box className="mt-1/4">
        <Card className={classes.card} variant="outlined">
          <CardContent className={classes.content}>
            <Box className="flex flex-1 flex-col justify-center items-center text-center mb-lg">
              <Logo
                className={classes.logo}
                color={styles.primary.color}
                size={65}
              />
              <Typography className={classes.title}>Join an account</Typography>
              <Typography className={classes.error}>
                Your email and password were valid, but{' '}
                <Typography className="font-bold" component="span">
                  {
                    cognitoUser.getSignInUserSession().getIdToken().payload
                      .email
                  }
                </Typography>{' '}
                is not currently a member of any accounts.
              </Typography>
            </Box>

            <Box className={classes.marginHorizontal}>
              <Typography className={classes.mutedText}>
                Here are your options.
              </Typography>
              <Typography className={classes.subtitle}>
                Accept an invitation
              </Typography>
              <Typography className={classes.mutedText}>
                If you received an email inviting you to join an account, click
                the link in that email to accept the invitation.
              </Typography>

              <OrDivider />

              <Typography className={classes.subtitle}>
                Sign out of this account
              </Typography>
              <Typography className={classes.mutedText}>
                You can sign out of this account and sign in with a different
                email address that is a member of the account you're looking
                for.
              </Typography>
              <Button
                className={classes.button}
                fullWidth
                onClick={() => signOut()}
                variant="outlined"
              >
                Sign out
              </Button>

              <OrDivider />

              <Typography className={classes.subtitle}>
                Create a new account
              </Typography>
              <R5TextField
                className="mt-sm"
                inputProps={{ autoComplete: 'off' }}
                label="Account Name"
                onChange={setAccountName}
                placeholder="New account name"
                value={accountName}
              />
              <Button
                classes={{
                  disabled: classes.disabled,
                  root: classes.confirmButton,
                }}
                disabled={_.isEmpty(accountName)}
                onClick={() =>
                  signOut().then(
                    () =>
                      (window.location = `/signup?accountName=${accountName}`)
                  )
                }
                size="large"
                variant="contained"
              >
                Continue to account creation
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}

function OrDivider() {
  const classes = useStyles()
  return (
    <Box className="flex flex-1 flex-row mt-md">
      <Box className={classes.border} />
      <Typography className={classes.or} component="span">
        OR
      </Typography>
      <Box className={classes.border} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  border: {
    backgroundColor: styles.border.color,
    height: 1,
    flex: 1,
    alignSelf: 'center',
  },
  button: {
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 800,
    marginTop: theme.spacing(4),
    textTransform: 'uppercase',
  },
  card: {
    borderRadius: theme.spacing(1),
    minHeight: 556,
  },
  confirmButton: {
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 800,
    marginTop: theme.spacing(4),
    textTransform: 'uppercase',
  },
  disabled: {
    backgroundColor: `${styles.primaryLight.color} !important`,
    color: 'white !important',
  },
  error: {
    backgroundColor: styles.error.backgroundColor,
    borderColor: styles.error.color,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    color: 'white',
    marginTop: 20,
    padding: 12,
    paddingLeft: 25,
    paddingRight: 25,
    width: '80%',
    wordBreak: 'break-word',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  marginHorizontal: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  mutedText: {
    color: styles.label.color,
    fontSize: 14,
  },
  or: {
    color: styles.label.color,
    fontSize: 14,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  subtitle: {
    color: styles.darkLabel.color,
    fontSize: 16,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    lineHeight: '107.9%',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}))
