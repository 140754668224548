const actionOptions = [
  { label: 'Set alert status', value: 'SET_ALERT_ACTION' },
  { label: 'Set alert severity', value: 'SET_ALERT_SEVERITY' },
  { label: 'Suppress alert', value: 'SUPPRESS_ALERT' },
]

const alertActions = [
  { label: 'Triggered', value: 'TRIGGER' },
  { label: 'Acknowledged', value: 'ACKNOWLEDGE' },
  { label: 'Resolved', value: 'RESOLVE' },
]

const criteriaMatch = [
  { label: 'Any', value: 'ANY' },
  { label: 'All', value: 'ALL' },
  { label: 'None', value: 'NONE' },
]

const criteriaOperationLabels = [
  { label: 'Begins with', value: 'BEGIN_WITH' },
  { label: 'Contains', value: 'CONTAIN' },
  { label: 'Ends with', value: 'END_WITH' },
  { label: 'Equals', value: 'EQUAL' },
  { label: 'Exists', value: 'EXIST' },
  { label: 'Is greater than', value: 'GREATER_THAN' },
  { label: 'Is greater than or equal to', value: 'GREATER_THAN_OR_EQUAL' },
  { label: 'Is less than', value: 'LESS_THAN' },
  { label: 'Is less than or equal to', value: 'LESS_THAN_OR_EQUAL' },
]

const criteriaOperations = [
  { label: 'Begin with', value: 'BEGIN_WITH' },
  { label: 'Contain', value: 'CONTAIN' },
  { label: 'End with', value: 'END_WITH' },
  { label: 'Equal', value: 'EQUAL' },
  { label: 'Exist', value: 'EXIST' },
  { label: 'Greater than', value: 'GREATER_THAN' },
  { label: 'Greater than or equal to', value: 'GREATER_THAN_OR_EQUAL' },
  { label: 'Less than', value: 'LESS_THAN' },
  { label: 'Less than or equal to', value: 'LESS_THAN_OR_EQUAL' },
]

const isOperations = [
  'GREATER_THAN',
  'GREATER_THAN_OR_EQUAL',
  'LESS_THAN',
  'LESS_THAN_OR_EQUAL',
]

const caseSensitiveOperations = ['BEGIN_WITH', 'CONTAIN', 'END_WITH', 'EQUAL']

const isNegateOperation = [
  { label: 'Is', value: 'false' },
  { label: 'Is not', value: 'true' },
]

const negateOperation = [
  { label: 'Does', value: 'false' },
  { label: 'Does not', value: 'true' },
]

const severityOptions = [
  { label: 'SEV-1', value: 'SEV1' },
  { label: 'SEV-2', value: 'SEV2' },
  { label: 'SEV-3', value: 'SEV3' },
  { label: 'SEV-4', value: 'SEV4' },
  { label: 'SEV-5', value: 'SEV5' },
]

const eventRuleOptions = {
  actionOptions,
  alertActions,
  caseSensitiveOperations,
  criteriaMatch,
  criteriaOperationLabels,
  criteriaOperations,
  isNegateOperation,
  isOperations,
  negateOperation,
  severityOptions,
}

export default eventRuleOptions
