import { AlertDialog, ConfirmButton } from '../../components/shared'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Slider,
  Typography,
} from '@mui/material'
import { R5Error, Loader } from '../../components/shared'
import { R5Container, R5Header, R5Title } from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'
import { useBack, useQuery } from '../../hooks'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useMutation } from '../../hooks'
import { VolumeDown, VolumeUp } from '@mui/icons-material'

export default function EditContactMethodPage({ contactMethodId, navigate }) {
  useBack('/profile/notifications/')
  const { user } = useCurrents()
  const { data, errors, loading } = useQuery(QUERY, {
    contactMethodId,
    userId: user.id,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Contact Method" />
      <R5Header title={data.contactMethod.to} />
      <ContactMethodForm contactMethod={data.contactMethod} />
    </R5Container>
  )
}

function ContactMethodForm({ contactMethod }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const deleteMutation = useMutation(DELETE_CONTACT_METHOD)
  const testMutation = useMutation(TEST_CONTACT_METHOD)
  const updateMutation = useMutation(UPDATE_CONTACT_METHOD)
  const [loading, setLoading] = useState(false)
  const [criticalAlertVolume, setCriticalAlertVolume] = useState(100)
  const [onCallStarted, setOnCallStarted] = useState(
    contactMethod.coverageChangeNotifications.onCallStarted
  )
  const [onCallEnded, setOnCallEnded] = useState(
    contactMethod.coverageChangeNotifications.onCallEnded
  )
  const [onCallOncePerRotation, setOnCallOncePerRotation] = useState(
    contactMethod.coverageChangeNotifications.onCallOncePerRotation
  )
  const [onDutyStarted, setOnDutyStarted] = useState(
    contactMethod.coverageChangeNotifications.onDutyStarted
  )
  const [onDutyEnded, setOnDutyEnded] = useState(
    contactMethod.coverageChangeNotifications.onDutyEnded
  )
  const [onDutyOncePerRotation, setOnDutyOncePerRotation] = useState(
    contactMethod.coverageChangeNotifications.onDutyOncePerRotation
  )
  const [showTestSentAlertDialog, setShowTestSentAlertDialog] = useState(false)
  const [showCriticalTestSentAlertDialog, setShowCriticalTestSentAlertDialog] =
    useState(false)

  const hasNotificationPreferences =
    contactMethod.type === 'PUSH' ||
    contactMethod.type === 'EMAIL' ||
    contactMethod.type === 'SLACK'

  let standardTestHeaderText
  let standardTestSecondaryText
  let standardButtonText
  let standardAlertDialogTitle
  let standardAlertDialogContent
  let criticalTestHeaderText
  let criticalTestSecondaryText
  let criticalButtonText
  let criticalAlertDialogTitle
  let criticalAlertDialogContent

  switch (contactMethod.type) {
    case 'EMAIL':
      standardTestHeaderText = 'Test this email address'
      standardTestSecondaryText = `This will send a test email message to ${contactMethod.to} so you can ensure this contact method works as expected.`
      standardButtonText = 'Send test email'
      standardAlertDialogTitle = 'Email sent'
      standardAlertDialogContent = `A test email message was sent to ${contactMethod.to}.`
      break
    case 'PUSH':
      standardTestHeaderText = 'Standard notification test'
      standardTestSecondaryText =
        'This will send a test push notification to this device so you can ensure this contact method works as expected.'
      standardButtonText = 'Send a test notification'
      standardAlertDialogTitle = 'Push notification sent'
      standardAlertDialogContent =
        'A test push notification was sent. If you did not receive it, check the notification settings on your device.'
      criticalTestHeaderText = 'Critical alert test'
      criticalTestSecondaryText = `This will send a test Critical Alert push notification to this device. You can adjust the test alert's volume using the slider.`
      criticalButtonText = 'Send a Critical Alert test'
      criticalAlertDialogTitle = 'Critical Alert sent'
      criticalAlertDialogContent =
        'A test Critical Alert was sent. If you did not receive it or it did not display as a Critical Alert, check the notification settings on your device.'
      break
    case 'SLACK':
      standardTestHeaderText = 'Test message'
      standardTestSecondaryText =
        'This will send a test Slack direct message to you so you can ensure this contact method works as expected.'
      standardButtonText = 'Send test message'
      standardAlertDialogTitle = 'Slack message sent'
      standardAlertDialogContent = `A test Slack message was sent to ${contactMethod.to}.`
      break
    case 'SMS':
      standardTestHeaderText = 'Test notification'
      standardTestSecondaryText =
        'This will send a test SMS message to this number so you can ensure this contact method works as expected.'
      standardButtonText = 'Send test message'
      standardAlertDialogTitle = 'SMS sent'
      standardAlertDialogContent = `A test SMS message was sent to ${contactMethod.to}.`
      break
    case 'VOICE':
      standardTestHeaderText = 'Test notification'
      standardTestSecondaryText =
        'This will send a test voice message to this number so you can ensure this contact method works as expected.'
      standardButtonText = 'Make test call'
      standardAlertDialogTitle = 'Voice call made'
      standardAlertDialogContent = `A test voice call was made to ${contactMethod.to}.`
      break
    default:
      standardTestHeaderText = 'Test notification'
      standardTestSecondaryText = `This will send a test notification so you can ensure this contact method works as expected.`
      standardButtonText = 'Send a test notification'
      standardAlertDialogTitle = 'Notification sent'
      standardAlertDialogContent =
        'A test notification was sent to this contact method.'
      break
  }

  async function handleTest({ sendCriticalAlert, criticalAlertVolume }) {
    try {
      await testMutation({
        contactMethodId: contactMethod.id,
        sendCriticalAlert,
        criticalAlertVolume,
        userId: contactMethod.userId,
      })

      sendCriticalAlert
        ? setShowCriticalTestSentAlertDialog(true)
        : setShowTestSentAlertDialog(true)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  async function handleDelete() {
    try {
      await deleteMutation({
        contactMethodId: contactMethod.id,
        userId: contactMethod.userId,
      })
      navigate('/profile/notifications')
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)

    try {
      await updateMutation({
        contactMethodId: contactMethod.id,
        userId: contactMethod.userId,
        coverageChangeNotifications: {
          onCallStarted,
          onCallEnded,
          onCallOncePerRotation,
          onDutyStarted,
          onDutyEnded,
          onDutyOncePerRotation,
        },
      })

      setLoading(false)
      navigate('/profile/notifications')
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row items-center justify-between my-sm">
        <Box>
          <ConfirmButton
            confirmContent="Are you sure you want to delete this contact method? Doing so will
          immediately remove this contact method as a target in any
          notification rules and may prevent you from being contacted when an
          incident is assigned to you."
            error
            onConfirm={handleDelete}
            title="Delete Contact Method"
          >
            Delete Contact Method
          </ConfirmButton>
        </Box>
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to="/profile/notifications"
          >
            Cancel
          </Button>
          {hasNotificationPreferences && (
            <Button
              disabled={loading}
              size="large"
              type="submit"
              variant="contained"
            >
              Update
            </Button>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title="Send Test Messages"
        titleTypographyProps={{
          className: classes.cardTitle,
          color: 'textSecondary',
        }}
      />
      <Divider style={{ backgroundColor: styles.primary.color, height: 2 }} />
      <CardContent className={classes.content}>
        <List disablePadding className="mb-4">
          <ListItem className="flex items-center">
            <ListItemText
              className="flex-1 pr-4"
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
              primary={standardTestHeaderText}
              secondary={standardTestSecondaryText}
              secondaryTypographyProps={{ component: 'span' }}
            />
            <Box className="flex items-center">
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleTest({ sendCriticalAlert: false })}
              >
                {standardButtonText}
              </Button>
            </Box>
          </ListItem>
          {criticalTestHeaderText && (
            <>
              <ListItem>
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secondary,
                  }}
                  primary={criticalTestHeaderText}
                  secondary={criticalTestSecondaryText}
                  secondaryTypographyProps={{ component: 'span' }}
                />
              </ListItem>
              <ListItem className="flex items-center">
                <VolumeDown htmlColor={styles.primary.color} />
                <Slider
                  className={'flex-1 mr-3 ml-2'}
                  step={30}
                  min={10}
                  max={100}
                  value={criticalAlertVolume}
                  valueLabelDisplay="auto"
                  valueLabelFormat={`${criticalAlertVolume}%`}
                  onChange={({ target: { value } }) =>
                    setCriticalAlertVolume(value)
                  }
                />
                <VolumeUp htmlColor={styles.primary.color} className={'mr-8'} />
                <Button
                  className={'flex'}
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    handleTest({ sendCriticalAlert: true, criticalAlertVolume })
                  }
                >
                  {criticalButtonText}
                </Button>
              </ListItem>
            </>
          )}
        </List>
        <AlertDialog
          cancelText="Ok"
          content={standardAlertDialogContent}
          handleClose={() => setShowTestSentAlertDialog(false)}
          open={showTestSentAlertDialog}
          title={standardAlertDialogTitle}
        />
        <AlertDialog
          cancelText="Ok"
          content={criticalAlertDialogContent}
          handleClose={() => setShowCriticalTestSentAlertDialog(false)}
          open={showCriticalTestSentAlertDialog}
          title={criticalAlertDialogTitle}
        />
      </CardContent>

      <form onSubmit={handleSubmit}>
        {hasNotificationPreferences && (
          <>
            <Divider />
            <CardHeader
              title="Notification Preferences"
              titleTypographyProps={{
                className: classes.cardTitle,
                color: 'textSecondary',
              }}
            />
            <Divider
              style={{ backgroundColor: styles.primary.color, height: 2 }}
            />
            <CardContent>
              <Box className="flex flex-row items-center">
                <Box className="flex flex-1 flex-col">
                  <Typography className={classes.primary}>On Call</Typography>
                  <FormControlLabel
                    classes={{
                      label: classes.secondary,
                      labelPlacementStart: classes.checkLabelStart,
                    }}
                    className="flex flex-1 justify-between"
                    control={
                      <Checkbox
                        checked={onCallStarted}
                        color="primary"
                        onChange={({ target: { checked } }) => {
                          setOnCallStarted(checked)
                          if (!checked && !onCallEnded) {
                            setOnCallOncePerRotation(false)
                          }
                        }}
                        name="on-call-started"
                      />
                    }
                    label="Get a notification when your on-call shifts start"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.secondary,
                      labelPlacementStart: classes.checkLabelStart,
                    }}
                    className="flex flex-1 justify-between"
                    control={
                      <Checkbox
                        checked={onCallEnded}
                        color="primary"
                        onChange={({ target: { checked } }) => {
                          setOnCallEnded(checked)
                          if (!checked && !onCallStarted) {
                            setOnCallOncePerRotation(false)
                          }
                        }}
                        name="on-call-ended"
                      />
                    }
                    label="Get a notification when your on-call shifts end"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.secondary,
                      labelPlacementStart: classes.checkLabelStart,
                    }}
                    className="flex flex-1 justify-between"
                    control={
                      <Checkbox
                        checked={
                          onCallOncePerRotation &&
                          (onCallStarted || onCallEnded)
                        }
                        disabled={!(onCallStarted || onCallEnded)}
                        color="primary"
                        onChange={({ target: { checked } }) => {
                          setOnCallOncePerRotation(checked)
                        }}
                        name="on-call-once-per-rotation"
                      />
                    }
                    label="Limit start and end notifications to once per rotation"
                    labelPlacement="start"
                  />
                  <Divider className="my-lg" />
                  <Typography className={classes.primary}>On Duty</Typography>
                  <FormControlLabel
                    classes={{
                      label: classes.secondary,
                      labelPlacementStart: classes.checkLabelStart,
                    }}
                    className="flex flex-1 justify-between"
                    control={
                      <Checkbox
                        checked={onDutyStarted}
                        color="primary"
                        onChange={({ target: { checked } }) => {
                          setOnDutyStarted(checked)
                          if (!checked && !onDutyEnded) {
                            setOnDutyOncePerRotation(false)
                          }
                        }}
                        name="on-duty-started"
                      />
                    }
                    label="Get a notification when your on-duty shifts start"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.secondary,
                      labelPlacementStart: classes.checkLabelStart,
                    }}
                    className="flex flex-1 justify-between"
                    control={
                      <Checkbox
                        checked={onDutyEnded}
                        color="primary"
                        onChange={({ target: { checked } }) => {
                          setOnDutyEnded(checked)
                          if (!checked && !onDutyStarted) {
                            setOnDutyOncePerRotation(false)
                          }
                        }}
                        name="on-duty-ended"
                      />
                    }
                    label="Get a notification when your on-duty shifts end"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.secondary,
                      labelPlacementStart: classes.checkLabelStart,
                    }}
                    className="flex flex-1 justify-between"
                    control={
                      <Checkbox
                        checked={
                          onDutyOncePerRotation &&
                          (onDutyStarted || onDutyEnded)
                        }
                        disabled={!(onDutyStarted || onDutyEnded)}
                        color="primary"
                        onChange={({ target: { checked } }) => {
                          setOnDutyOncePerRotation(checked)
                        }}
                        name="on-duty-once-per-rotation"
                      />
                    }
                    label="Limit start and end notifications to once per rotation"
                    labelPlacement="start"
                  />
                </Box>
              </Box>
            </CardContent>
            <Divider />
          </>
        )}
        <CardActions className={classes.actions}>{renderFooter()}</CardActions>
      </form>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  checkLabel: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  checkLabelStart: {
    marginLeft: 0,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
  input: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    paddingBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  margin: {
    marginTop: theme.spacing(3.5),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  primary: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  secondary: {
    color: '#000000',
    fontSize: 14,
  },
  subTitle: {
    fontWeight: 600,
    fontSize: 12,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
}))

const QUERY = gql`
  query EditContactMethod($contactMethodId: ID!, $userId: ID!) {
    contactMethod(id: $contactMethodId, userId: $userId) {
      id
      type
      to
      description
      userId
      pushProvider
      coverageChangeNotifications {
        onCallStarted
        onCallEnded
        onCallOncePerRotation
        onDutyStarted
        onDutyEnded
        onDutyOncePerRotation
      }
    }
  }
`

const UPDATE_CONTACT_METHOD = gql`
  mutation UpdateContactMethod($input: UpdateContactMethodInput!) {
    updateContactMethod(input: $input) {
      contactMethod {
        id
      }
    }
  }
`

const TEST_CONTACT_METHOD = gql`
  mutation TestContactMethod($input: TestContactMethodInput!) {
    testContactMethod(input: $input) {
      contactMethod {
        id
      }
    }
  }
`

const DELETE_CONTACT_METHOD = gql`
  mutation DeleteContactMethod($input: DeleteContactMethodInput!) {
    deleteContactMethod(input: $input) {
      contactMethodId
    }
  }
`
