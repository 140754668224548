import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  ConfirmButton,
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Infotip,
  R5RadioGroup,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'
import { useBack, useMutation, useQuery } from '../../hooks'

import { NotFoundPage } from '../utils'
import { Link as RouterLink } from '@reach/router'
import TeamOptions from '../../constants/TeamOptions'
import { User } from '../../components/illustrations'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function EditMembershipPage({ navigate, membershipId, teamId }) {
  useBack(`/teams/${teamId}/members`)
  const { data, loading, errors } = useQuery(QUERY, { membershipId, teamId })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.membership.viewerCanAdminister) return <NotFoundPage />

  return (
    <R5Container>
      <R5Title title="Edit Membership" />
      <R5Header title="Edit Membership" />
      <MembershipForm navigate={navigate} membership={data.membership} />
    </R5Container>
  )
}

function MembershipForm({ navigate, membership }) {
  const classes = useStyles()
  const updateMutation = useMutation(UPDATE_MEMBERSHIP)
  const deleteMutation = useMutation(DELETE_MEMBERSHIP)
  const [role, setRole] = useState(membership.role)
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault() // Block native form submission

    setLoading(true)

    try {
      await updateMutation({
        membershipId: membership.id,
        teamId: membership.teamId,
        role,
      })
      setLoading(false)
      navigate(`/teams/${membership.teamId}/members`)
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)

    try {
      await deleteMutation({
        membershipId: membership.id,
        teamId: membership.teamId,
      })
      setLoading(false)
      navigate(`/teams/${membership.teamId}/members`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-sm ${
          membership.viewerCanAdminister ? 'justify-between' : 'justify-end'
        }`}
      >
        <ConfirmButton
          confirmContent={
            <>
              <Typography paragraph>
                Are you sure you want to remove {membership.user.name} from the{' '}
                {membership.team.name} team? They will also be removed as a
                participant in any shifts on this team.
              </Typography>
              <Typography paragraph>
                <strong>
                  Any current or future coverage in these shifts will be
                  replaced with the "Nobody" user, which may create gaps in
                  future coverage. Be sure to review schedule coverage after
                  removal.
                </strong>
              </Typography>
            </>
          }
          confirmText="Yes, remove from team"
          disabled={loading}
          error
          onConfirm={handleDelete}
          title="Remove from team"
          variant="outlined"
        >
          Remove from team
        </ConfirmButton>
        <Box className="flex flex-row">
          <Button
            className={classes.marginRight}
            component={RouterLink}
            size="large"
            to={`/teams/${membership.teamId}/members`}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || membership.user.role !== 'MEMBER'}
            size="large"
            type="submit"
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Card variant="outlined">
      <form onSubmit={handleSubmit}>
        <CardContent className={classes.content}>
          <Box className="flex flex-1 flex-row items-center">
            <Box className="flex flex-1 flex-col">
              <Typography className={classes.title} color="textSecondary">
                Member
              </Typography>
              <ListItem disableGutters>
                <ListItemIcon className={classes.icon}>
                  <Icons.Person color={styles.lightLabel.color} size={20} />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.subtitle }}>
                  {membership.user.name}
                </ListItemText>
              </ListItem>
              <Box className="mt-lg">
                <R5RadioGroup
                  buttons={TeamOptions.roles}
                  disabled={membership.user.role !== 'MEMBER'}
                  error={_.some(validationErrors['role'])}
                  label="Role"
                  onChange={setRole}
                  row
                  toolTip={
                    <R5Infotip
                      learnMore="https://www.readyfive.io/docs/team-basics"
                      title={
                        <>
                          <Typography paragraph>
                            <strong>Member</strong>
                            <br />
                            Can be participants in team schedules, can
                            participate in shift overrides, and can be targeted
                            by integrations.
                          </Typography>
                          <Typography paragraph>
                            <strong>Administrator</strong>
                            <br />
                            Can fully manage everything on the team.
                          </Typography>
                        </>
                      }
                    />
                  }
                  value={
                    membership.user.role === 'MEMBER' ? role : 'ADMINISTRATOR'
                  }
                />
              </Box>
            </Box>
            <Hidden implementation="css" smDown>
              <CardMedia
                alt="Membership"
                component="img"
                image={User}
                sx={{ width: 160 }}
              />
            </Hidden>
          </Box>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>{renderFooter()}</CardActions>
      </form>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
}))

const QUERY = gql`
  query EditMembership($membershipId: ID!, $teamId: ID!) {
    membership(id: $membershipId, teamId: $teamId) {
      id
      role
      teamId
      viewerCanAdminister
      user {
        id
        name
        role
      }
      team {
        name
      }
    }
  }
`

const UPDATE_MEMBERSHIP = gql`
  mutation UpdateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      membership {
        id
      }
    }
  }
`

const DELETE_MEMBERSHIP = gql`
  mutation DeleteMembership($input: DeleteMembershipInput!) {
    deleteMembership(input: $input) {
      membershipId
    }
  }
`
