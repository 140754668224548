import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { R5Container, R5Title } from '../../components/shared'

import { NotFound } from '../../components/illustrations'
import React from 'react'
import { useBack } from '../../hooks'

export default function NotFoundPage() {
  useBack(-1)
  const classes = useStyles()

  return (
    <R5Container maxWidth="xs">
      <R5Title title="Not Found" />
      <Box className="flex flex-col items-center justify-center text-center mt-1/10">
        <img alt="Not Found" src={NotFound} width={298} />
        <Typography className={classes.title}>Not Found</Typography>
        <Typography className={classes.subTitle} color="textSecondary">
          Oops...looks like the page you are looking for doesn't exist.
        </Typography>
      </Box>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  subTitle: {
    fontSize: 24,
    fontWeight: 600,
  },
  title: {
    fontSize: 40,
    fontWeight: 900,
  },
}))
