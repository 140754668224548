import { configStorageKey } from '../constants/app'

export default function currentDomain() {
  const config = JSON.parse(localStorage.getItem(configStorageKey))

  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : config.publicIdentifier === 'staging'
    ? 'https://app.staging.readyfive.io'
    : 'https://app.readyfive.io'
}
