import { Box, Button, Grid } from '@mui/material'
import { Details, Notifications, Security } from '../../components/Profile'
import {
  R5ButtonGroup,
  R5Container,
  R5Flash,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'

import TimeOffAlert from '../../components/Profile/TimeOffAlert'
import TimeOffModal from '../../components/Profile/TimeOffModal'
import { UserHeader } from '../../components/Users'
import { useBack } from '../../hooks'
import { useCurrents } from '../../context/currents'

export default function ProfilePage({ tab = 'details' }) {
  useBack(null)

  const { user } = useCurrents()
  const [showTimeOffModal, setShowTimeOffModal] = useState(false)
  const [showTimeOffAlert, setShowTimeOffAlert] = useState(false)
  const [timeOffShifts, setTimeOffShifts] = useState({
    failed: [],
    success: [],
  })

  return (
    <>
      <R5Title title="Profile" />
      <UserHeader subtitle={user.signInEmailAddress} user={user} />

      <R5Container>
        <R5Flash />
        <Box className="flex flex-0 my-md">
          <Grid className="justify-center items-center" container spacing={3}>
            <Grid item>
              <R5ButtonGroup
                buttons={[
                  {
                    label: 'Details',
                    to: '/profile/details',
                    value: 'details',
                  },
                  {
                    label: 'Notifications',
                    to: '/profile/notifications',
                    value: 'notifications',
                  },
                  {
                    label: 'Security',
                    to: '/profile/security',
                    value: 'security',
                  },
                ]}
                saveState="@filterState/profile"
                selectedValue={tab}
              />
            </Grid>
            <Grid item xs>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={() => {
                      setTimeOffShifts({ failed: [], success: [] })
                      setShowTimeOffModal(true)
                    }}
                    size="small"
                    variant="outlined"
                  >
                    + Time off
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {tab === 'notifications' ? (
          <Notifications />
        ) : tab === 'security' ? (
          <Security />
        ) : (
          <Details />
        )}
      </R5Container>
      {showTimeOffModal && (
        <TimeOffModal
          setShowTimeOffAlert={setShowTimeOffAlert}
          setShowTimeOffModal={setShowTimeOffModal}
          showTimeOffModal={showTimeOffModal}
          timeOffShifts={timeOffShifts}
          setTimeOffShifts={setTimeOffShifts}
        />
      )}
      <TimeOffAlert
        setShowTimeOffAlert={setShowTimeOffAlert}
        setTimeOffShifts={setTimeOffShifts}
        showTimeOffAlert={showTimeOffAlert}
        timeOffShifts={timeOffShifts}
      />
    </>
  )
}
