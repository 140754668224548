import { AlertDialog, ConfirmButton } from '../shared'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'

import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function UserItem({ invitation }) {
  const classes = useStyles()
  const theme = useTheme()
  const deleteMutation = useMutation(DELETE_INVITATION)
  const resendMutation = useMutation(RESEND_INVITATION)

  const [showResendDialog, setShowResendDialog] = useState(false)
  const smallView = useMediaQuery(theme.breakpoints.down('md'))

  async function handleDelete() {
    try {
      await deleteMutation({ invitationId: invitation.id })
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  async function handleResend() {
    try {
      await resendMutation({ invitationId: invitation.id })
      setShowResendDialog(true)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  const SecondaryWrapper = smallView ? Box : ListItemSecondaryAction

  return (
    <Card className={classes.card} key={invitation.id} variant="outlined">
      <CardContent className={classes.content}>
        <ListItem>
          <ListItemAvatar className={classes.listItemAvatar}>
            <Avatar className={classes.avatar}>
              {invitation.emailAddress[0]}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            classes={{ primary: classes.title }}
            primary={invitation.emailAddress}
            secondary={
              <Grid container spacing={1}>
                <Grid item sm={3}>
                  <Typography component="span" className={classes.subtitle}>
                    <strong>Role: </strong>
                    {invitation.role.toLowerCase()}
                  </Typography>
                </Grid>
                <Grid item sm={7}>
                  <Typography component="span" className={classes.subtitle}>
                    <strong>Teams: </strong>
                    {_.some(invitation.memberships)
                      ? invitation.memberships
                          .map(
                            (membership) =>
                              `${
                                membership.team.name
                              } (${membership.role.toLowerCase()})`
                          )
                          .join(', ')
                      : 'none'}
                  </Typography>
                </Grid>
              </Grid>
            }
            secondaryTypographyProps={{ component: 'span' }}
          />
          <SecondaryWrapper className="flex flex-row">
            {invitation.viewerCanAdminister && (
              <ConfirmButton
                confirmContent="Are you sure you want to delete this invitation?"
                error
                onConfirm={handleDelete}
                title="Delete Invitation"
              />
            )}
            {invitation.viewerCanResend && (
              <Button onClick={handleResend}>Resend</Button>
            )}
          </SecondaryWrapper>
        </ListItem>
      </CardContent>
      <AlertDialog
        cancelText="Ok"
        content={`Invitation resent to ${invitation.emailAddress}`}
        handleClose={() => setShowResendDialog(false)}
        open={showResendDialog}
        title="Resend Invitation"
      />
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: styles.primary.color,
    borderRadius: 32,
    fontSize: 12,
    fontWeight: 800,
    height: 32,
    width: 32,
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
  deactivated: {
    color: styles.lightLabel.color,
    fontSize: 14,
    fontWeight: 'bold',
  },
  listItemAvatar: {
    minWidth: theme.spacing(5),
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    wordBreak: 'break-all',
  },
  subtitle: {
    fontSize: 14,
  },
}))

const RESEND_INVITATION = gql`
  mutation ResendInvitation($input: ResendInvitationInput!) {
    resendInvitation(input: $input) {
      invitation {
        id
      }
    }
  }
`

const DELETE_INVITATION = gql`
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      invitationId
    }
  }
`
