import { Amplify } from 'aws-amplify'
import axios from 'axios'
import { ForbiddenError } from '../components/shared'
import { configStorageKey, publicIdentifierStorageKey } from '../constants/app'

function amplifyConfigure(config) {
  localStorage.setItem(configStorageKey, JSON.stringify(config))

  const redirectRootUrl = window.location.protocol + '//' + window.location.host
  const redirectSignIn = redirectRootUrl + '/login/sso'
  const redirectSignOut = redirectRootUrl

  const amplifyConfig = {
    Auth: {
      region: config.awsRegion,
      userPoolId: config.cognitoUserPoolId,
      userPoolWebClientId: config.cognitoUserPoolWebClientId,
      mandatorySignIn: true,
      oauth: {
        domain: config.cognitoUserPoolHostedDomain,
        scope: ['openid'],
        redirectSignIn,
        redirectSignOut,
        responseType: 'code',
      },
    },
    aws_appsync_graphqlEndpoint: config.appSyncGraphQLEndpoint,
    aws_appsync_region: config.awsRegion,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  }

  Amplify.configure(amplifyConfig)
}

function fetchAmplifyConfig() {
  return axios('/config.json').then(({ data: { publicIdentifier } }) => {
    localStorage.setItem(publicIdentifierStorageKey, publicIdentifier)

    return axios(
      `https://installations.ent.readyfive.io/${publicIdentifier}.json`
    ).catch(({ response }) => {
      localStorage.removeItem(publicIdentifierStorageKey)
      throw response.status >= 400
        ? new ForbiddenError(
            'Could not find your enterprise installation with the identifier provided'
          )
        : new Error(response.statusText)
    })
  })
}

export { amplifyConfigure, fetchAmplifyConfig }
