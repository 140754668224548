import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import React from 'react'
import { Link as RouterLink } from '@reach/router'
import TeamOptions from '../../constants/TeamOptions'
import _ from 'lodash'
import { styles } from '../../constants/styles'

export default function MembershipItem({ membership, user }) {
  const classes = useStyles()

  return (
    <ListItem
      button
      component={RouterLink}
      to={`/teams/${membership.teamId}/members`}
    >
      <ListItemAvatar classes={{ root: classes.avatarRoot }}>
        <div
          className={classes.avatar}
          style={{ backgroundColor: membership.team.accentColor }}
        ></div>
      </ListItemAvatar>
      <ListItemText
        classes={{ primary: classes.primary }}
        primary={membership.team.name}
      />
      <Typography className={classes.role} color="textSecondary">
        {membership.user.role === 'MEMBER'
          ? _.find(TeamOptions.roles, { value: membership.role }).label
          : 'Administrator'}
      </Typography>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 14,
    height: 14,
    width: 14,
  },
  avatarRoot: {
    minWidth: theme.spacing(5),
  },
  primary: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  role: {
    color: styles.lightLabel.color,
    fontSize: 14,
    fontWeight: 'bold',
  },
  secondary: {
    fontWeight: 'bold',
  },
}))
