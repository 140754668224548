import { AllTeams, MyTeams } from '../../components/Teams'
import {
  R5AddButton,
  R5ButtonGroup,
  R5Container,
  R5Header,
  R5Search,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'
import { useBack, useQueryParams } from '../../hooks'

import { Typography } from '@mui/material'
import { useCurrents } from '../../context/currents'

export default function TeamsPage() {
  useBack(null)
  const { user } = useCurrents()
  const { scope = 'mine' } = useQueryParams()
  const [search, setSearch] = useState('')

  return (
    <R5Container>
      <R5Title title="Teams" />
      <R5Header
        addText="Add Team"
        addUrl={user.account.viewerCanCreateTeams ? '/teams/new' : null}
        helpText={
          <Typography paragraph>
            Teams are the primary way to organize people and content in your
            Ready Five account. Each team has escalation policies, schedules,
            integrations, and memberships independent of other teams.
          </Typography>
        }
        helpUrl="https://www.readyfive.io/docs/team-basics"
        title="Teams"
      >
        <R5ButtonGroup
          buttons={[
            { label: 'Mine', to: '', value: 'mine' },
            { label: 'All', to: '?q=scope:all', value: 'all' },
          ]}
          saveState="@filterState/teams"
          selectedValue={scope}
        />
        <R5Search onChange={setSearch} value={search} />
      </R5Header>

      {scope === 'mine' ? (
        <MyTeams search={search} />
      ) : (
        <AllTeams search={search} />
      )}
      {user.account.viewerCanCreateTeams && <R5AddButton to="/teams/new" />}
    </R5Container>
  )
}
