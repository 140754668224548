import * as Icons from '../../components/icons'

import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { R5Error, Loader, R5Container, R5Title } from '../../components/shared'
import React, { useEffect } from 'react'
import { useBack, useQuery } from '../../hooks'

import { AccountBalance } from '@mui/icons-material'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import { enterprise } from '../../utils'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useJunkDrawer } from '../../context/junkDrawer'

export default function OwnerPage() {
  useBack(null)
  const classes = useStyles()
  const theme = useTheme()
  const { data, errors, loading } = useQuery(QUERY)

  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const { junkDrawer, updateJunkDrawer } = useJunkDrawer()
  const { user } = useCurrents()

  useEffect(() => {
    if (_.isUndefined(junkDrawer.welcome)) updateJunkDrawer('welcome', {})
  }, [junkDrawer, updateJunkDrawer])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  const shortName = user.name.split(' ').slice(0, -1).join(' ')

  return (
    <R5Container maxWidth={mobileView ? 'xs' : 'sm'}>
      <R5Title title="Welcome" />
      <Box className="flex flex-col items-center justify-center text-center mt-1/10">
        <Typography className={classes.title}>Hi {shortName}!</Typography>
        <Typography className={classes.subTitle} color="textSecondary">
          We're thrilled you're here. Here are some things we recommend doing to
          get your account setup.
        </Typography>
        <List>
          <ListItem
            button
            className={`mt-xl ${classes.listItem}`}
            component={RouterLink}
            to="/teams/new"
          >
            <ListItemAvatar>
              <Avatar
                style={{
                  backgroundColor:
                    data.viewer.account.teamsCount === 0
                      ? null
                      : styles.success.color,
                }}
              >
                {data.viewer.account.teamsCount === 0 ? (
                  <Icons.Teams color="#f6f7fb" size={20} />
                ) : (
                  <Icons.Check color="#f6f7fb" size={20} />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Create your teams"
              secondary="Teams are the heart of Ready Five. You'll need at least one to begin setting up your schedules and integrations."
            />
          </ListItem>
          <ListItem
            button
            className={`mt-xl ${classes.listItem}`}
            component={RouterLink}
            to="/users/invite"
          >
            <ListItemAvatar>
              <Avatar
                style={{
                  backgroundColor:
                    data.viewer.account.activeUsersCount < 2
                      ? null
                      : styles.success.color,
                }}
              >
                {data.viewer.account.activeUsersCount < 2 ? (
                  <Icons.People color="#f6f7fb" size={20} />
                ) : (
                  <Icons.Check color="#f6f7fb" size={20} />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Invite others"
              secondary="Bring on your team members to take their turn in responding to incidents. Once they accept, you can begin scheduling their shifts."
            />
          </ListItem>
          {!enterprise() && (
            <ListItem
              button
              className={`mt-xl ${classes.listItem}`}
              component={RouterLink}
              to="/account"
            >
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundBlendMode: 'overlay',
                    backgroundColor: data.viewer.account.billingPaymentCardAdded
                      ? styles.success.color
                      : null,
                  }}
                >
                  {data.viewer.account.billingPaymentCardAdded ? (
                    <Icons.Check color="#f6f7fb" size={20} />
                  ) : (
                    <AccountBalance />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Setup billing"
                secondary="Happy with how things are going and want to continue after your trial? Add a card, choose your subscription, and join us for the long haul."
              />
            </ListItem>
          )}
        </List>
        <Box className="mt-xl">
          <Button
            component={RouterLink}
            size="small"
            to="/welcome/install"
            variant="outlined"
          >
            Next: Install mobile app
          </Button>
        </Box>
      </Box>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderRadius: 14,
    '&::before': {
      content: 'no-open-quote',
      position: 'absolute',
      left: '2.2rem',
      backgroundColor: styles.border.color,
      height: enterprise() ? '130%' : '250%',
      width: 1,
      zIndex: -1,
    },
    '&:first-child:before': {
      top: theme.spacing(8),
    },
    '&:last-child:before': {
      height: 0,
    },
  },
  subTitle: {
    fontSize: 24,
    fontWeight: 600,
  },
  title: {
    fontSize: 40,
    fontWeight: 900,
  },
}))

const QUERY = gql`
  query Currents {
    viewerAccounts(first: 30) {
      nodes {
        accountId
        accountName
        role
        userId
        userName
      }
    }
    viewer {
      id
      createdAt
      name
      initials
      timeZone
      role
      viewerCanAdminister
      viewerCanDeactivate
      viewerCanReactivate
      accountId
      account {
        id
        name
        createdAt
        teamsCount
        activeUsersCount
        billingSubscriptionStatus
        billingSubscriptionTrialEndsAt
        billingPaymentCardAdded
        viewerCanAdminister
        viewerCanCreateIncidents
        viewerCanCreateTeams
        viewerCanInviteUsers
        viewerCanManageBilling
      }
    }
  }
`
