import * as Icons from '../icons'

import {
  Box,
  Card,
  CardContent,
  IconButton,
  List,
  Snackbar,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Alert } from '@mui/material'
import { FileCopy } from '@mui/icons-material'
import PersonalAccessTokenItem from './PersonalAccessTokenItem'
import { R5Infotip } from '../../components/shared'
import { Link as RouterLink } from '@reach/router'
import { copyToClipboard } from '../../utils'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useJunkDrawer } from '../../context/junkDrawer'

export default function PersonalAccessTokens({ EmptyText, viewer }) {
  const classes = useStyles()
  const { junkDrawer, updateJunkDrawer } = useJunkDrawer()
  const [copied, setCopied] = useState(false)
  const [token, setToken] = useState()
  const hasPersonalAccessTokens = viewer.personalAccessTokens.nodes.length > 0

  useEffect(() => {
    if (junkDrawer.personalAccessToken) {
      setToken(junkDrawer.personalAccessToken)
      updateJunkDrawer('personalAccessToken', null)
    }
  }, [junkDrawer.personalAccessToken, updateJunkDrawer])

  return (
    <Box className="mt-5">
      <Box className="flex flex-1 flex-row items-center justify-between">
        <Box className="flex items-center">
          <Typography className={classes.header} color="textSecondary">
            Personal Access Tokens
          </Typography>
          <R5Infotip
            learnMore="https://www.readyfive.io/docs/users/personal-access-tokens"
            title={
              <Typography paragraph>
                Personal access tokens grant programmatic access to the Ready
                Five GraphQL API. Each user can create one or more personal
                access tokens in their account and each can be used as a
                permanent authentication token for API requests.
              </Typography>
            }
          />
        </Box>
        <IconButton
          component={RouterLink}
          size="small"
          to="/profile/tokens/new"
        >
          <Icons.PlusCircleOutline color={styles.primary.color} size={35} />
        </IconButton>
      </Box>
      {token && (
        <>
          <Alert className="mb-sm" icon={false} severity="info">
            Make sure to copy your new personal access token now. You won't be
            able to see it again!
          </Alert>
          <Alert
            action={
              <IconButton
                onClick={() => {
                  copyToClipboard(token.accessToken)
                  setCopied(true)
                }}
                size="small"
              >
                <FileCopy />
              </IconButton>
            }
            className="mb-sm"
            severity="success"
          >
            {token.accessToken}
          </Alert>
          <Snackbar
            autoHideDuration={3000}
            onClose={() => setCopied(false)}
            open={copied}
          >
            <Alert severity="info">Token copied to clipboard.</Alert>
          </Snackbar>
        </>
      )}
      <List>
        {hasPersonalAccessTokens ? (
          viewer.personalAccessTokens.nodes.map((personalAccessToken) => (
            <PersonalAccessTokenItem
              personalAccessToken={personalAccessToken}
              key={personalAccessToken.id}
            />
          ))
        ) : (
          <Card className={classes.card} variant="outlined">
            <CardContent className={classes.content}>
              <EmptyText text="You have no personal access tokens" />
            </CardContent>
          </Card>
        )}
      </List>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  header: {
    fontSize: 14,
    fontWeight: 900,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
}))
