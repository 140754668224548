import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
} from '@mui/material'
import {
  ConfirmButton,
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5TextField,
  R5Title,
} from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'
import { useBack, useMutation, useQuery } from '../../hooks'

import { AvailableIntegrations } from '../../components/Teams'
import { NotFoundPage } from '../utils'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'

export default function EditIntegrationPage({ integrationId, teamId }) {
  useBack(`/teams/${teamId}/integrations/${integrationId}`)
  const { data, errors, loading } = useQuery(QUERY, {
    integrationId,
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.integration.viewerCanAdminister) return <NotFoundPage />

  return <IntegrationForm integration={data.integration} teamId={teamId} />
}

function IntegrationForm({ integration, teamId }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const integrationMutation = useMutation(UPDATE_INTEGRATION)
  const deleteMutation = useMutation(DELETE_INTEGRATION)
  const [name, setName] = useState(integration.name)
  const [description, setDescription] = useState(integration.description)
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)

    try {
      await integrationMutation({
        teamId,
        integrationId: integration.id,
        name,
        description,
      })

      setLoading(false)
      navigate(`/teams/${teamId}/integrations/${integration.id}`)
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)
    try {
      await deleteMutation({ teamId, integrationId: integration.id })
      setLoading(false)
      navigate(`/teams/${teamId}/integrations`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-sm ${
          integration ? 'justify-between' : 'justify-end'
        }`}
      >
        <ConfirmButton
          confirmContent="Are you sure you want to delete this integration?"
          disabled={loading}
          error
          onConfirm={handleDelete}
          title="Delete Integration"
        />
        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/teams/${teamId}/integrations/${integration.id}`}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Box>
    )
  }

  const provider = AvailableIntegrations({ index: false }).find(
    (i) => i.provider === integration.provider
  )?.name

  return (
    <R5Container maxWidth="sm">
      <R5Title title={`Edit ${provider} Integration`} />
      <R5Header title={`Edit ${provider} Integration`} />
      <Card variant="outlined">
        <form onSubmit={handleSubmit}>
          <CardContent className="flex flex-col p-xl">
            <R5TextField
              error={_.some(validationErrors['name'])}
              label="Name"
              onChange={setName}
              placeholder="Integration Name"
              required
              value={name}
            />
            <R5TextField
              error={_.some(validationErrors['description'])}
              label="Description"
              onChange={setDescription}
              placeholder="What this integration is used for"
              value={description}
            />
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            {renderFooter()}
          </CardActions>
        </form>
      </Card>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  img: {
    width: 500,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(2),
  },
}))

const UPDATE_INTEGRATION = gql`
  mutation UpdateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      integration {
        id
        name
      }
    }
  }
`

const DELETE_INTEGRATION = gql`
  mutation DeleteIntegration($input: DeleteIntegrationInput!) {
    deleteIntegration(input: $input) {
      integrationId
    }
  }
`

const QUERY = gql`
  query EditIntegration($integrationId: ID!, $teamId: ID!) {
    integration(id: $integrationId, teamId: $teamId) {
      id
      address
      name
      description
      provider
      viewerCanAdminister
    }
  }
`
