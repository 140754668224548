const mainBackground = '#FFFFFF'
const subBackground = '#FFFFFF'
const border = '#DBE4F0'
const error = '#EB5757'
const info = '#2d66fa'
const primary = '#3F47F4'
const secondary = '#2d66fa'
const success = '#63E478'
const warning = '#FFAB2D'
const well = '#F1F3F8'

const styles = {
  altBorder: {
    color: '#E0DDFF',
  },
  border: {
    color: border,
  },
  buttonGroup: {
    backgroundColor: border,
    borderColor: 'transparent',
    borderRadius: 100,
    borderWidth: 0,
    height: 30,
    marginHorizontal: 0,
    marginVertical: 0,
    paddingVertical: 0,
  },
  buttonGroupButton: {
    borderRadius: 100,
    margin: 2,
  },
  buttonGroupSelectedButton: {
    backgroundColor: '#FFFFFF',
  },
  buttonGroupSelectedText: {
    color: primary,
  },
  buttonGroupText: {
    color: '#9BAFC6',
    fontSize: 13,
    fontWeight: '800',
    margin: 0,
  },
  container: {
    backgroundColor: mainBackground,
    flex: 1,
  },
  darkLabel: {
    color: '#010101',
  },
  darkText: {
    color: '#535353',
  },
  error: {
    backgroundColor: '#FF7676',
    color: error,
  },
  formHeaderStyle: {
    backgroundColor: subBackground,
    borderBottomColor: primary,
    borderBottomWidth: 2,
    elevation: 0,
    height: 55,
  },
  headerBorder: {
    borderBottomColor: primary,
    borderBottomWidth: 2,
  },
  headerButtonGroup: {
    backgroundColor: '#DAE1F3',
    borderRadius: 100,
    borderWidth: 0,
    height: 30,
    marginHorizontal: 0,
    marginVertical: 0,
    paddingVertical: 0,
  },
  headerButtonGroupSelectedButton: {
    backgroundColor: '#FFFFFF',
  },
  headerButtonGroupSelectedText: {
    color: primary,
  },
  headerButtonGroupText: {
    color: '#9BAFC6',
    fontSize: 13,
    fontWeight: '800',
    margin: 0,
  },
  headerStyle: {
    backgroundColor: '#F6F7F9',
    borderBottomWidth: 0,
    elevation: 0,
  },
  headerTint: {
    color: primary,
  },
  headerTitleStyle: {
    color: '#323840',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
  info: {
    color: info,
  },
  modal: {
    backgroundColor: subBackground,
  },
  label: {
    color: '#738DAB',
  },
  lightLabel: {
    color: '#9BAFC6',
  },
  lightSubtitle: {
    color: '#BEBEBE',
  },
  pending: {
    color: warning,
  },
  picker: {
    backgroundColor: well,
  },
  pickerItem: {
    color: '#000000',
  },
  placeHolderText: {
    color: '#9BAFC6',
  },
  primary: {
    color: primary,
  },
  primaryDark: {
    color: '#372CB3',
  },
  primaryLight: {
    color: '#A49BFF',
  },
  r5Purple: {
    color: '#5A4BF8',
  },
  ruleTitle: {
    color: '#2F3D80',
  },
  ruleSubtitle: {
    color: '#2F3D80',
  },
  searchBarInput: {
    color: '#738DAB',
  },
  searchBarSearchIcon: {
    color: '#9BAFC6',
  },
  secondary: {
    color: secondary,
  },
  selectTitle: {
    color: primary,
  },
  subBorder: {
    color: border,
  },
  success: {
    color: success,
  },
  switchThumb: {
    color: null,
  },
  switchTrackFalse: {
    color: border,
  },
  switchTrackTrue: {
    color: primary,
  },
  tabBarBorder: {
    color: border,
  },
  tabBarActive: {
    backgroundColor: subBackground,
    color: primary,
  },
  tabBarInactive: {
    backgroundColor: subBackground,
    color: '#9BAFC6',
  },
  tabButton: {
    backgroundColor: well,
    borderWidth: 0,
    marginBottom: 15,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
  },
  tabButtonButton: {
    borderRadius: 5,
  },
  tabButtonSelectedButton: {
    backgroundColor: primary,
  },
  tabButtonText: {
    color: '#9BAFC6',
    fontSize: 14,
    fontWeight: 'bold',
  },
  textInput: {
    backgroundColor: subBackground,
    color: '#535353',
    fontSize: 15,
    fontWeight: '600',
    marginHorizontal: 5,
  },
  textInputLabel: {
    color: '#010101',
    fontSize: 15,
    fontWeight: 'bold',
    letterSpacing: 1,
    marginBottom: 15,
    marginHorizontal: 5,
    textTransform: 'uppercase',
  },
  textInputPlaceHolderText: {
    color: '#738DAB',
  },
  warning: {
    color: warning,
  },
  well: {
    backgroundColor: well,
  },
  wellBorder: {
    color: '#D9E1F5',
  },
  wellText: {
    color: '#728AA6',
  },
}

export { styles }
