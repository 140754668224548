import * as Icons from '../icons'

import { ActivityIndicator, R5Error } from '../shared'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '../../hooks'

export default function EscalationPolicyList({
  selectedValues,
  handleSelect,
  search,
  team,
}) {
  const classes = useStyles()
  const { data, errors, loading } = useQuery(TEAM_ESCALATION_POLICIES_QUERY, {
    teamId: team.id,
  })

  if (loading) return <ActivityIndicator className="m-md" size={30} />
  if (errors) return <R5Error errors={errors} />

  const selectedPolicies = data.team.escalationPolicies.nodes.filter(
    (escalationPolicy) =>
      escalationPolicy.name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <List dense className={classes.list}>
      {selectedPolicies.map((escalationPolicy, index) => (
        <ListItem
          button
          className={classes.li}
          divider={index === selectedPolicies.length - 1 ? false : true}
          key={escalationPolicy.id}
          onClick={() =>
            handleSelect({
              id: escalationPolicy.id,
              name: `${team.name}: ${escalationPolicy.name}`,
            })
          }
        >
          <ListItemText>{escalationPolicy.name}</ListItemText>
          <ListItemSecondaryAction>
            {selectedValues.find(
              (value) => value.id === escalationPolicy.id
            ) ? (
              <Icons.CheckCircle color="#6dd76b" size={32} />
            ) : null}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}

const useStyles = makeStyles((theme) => ({
  li: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(82, 89, 255, 0.1)',
      borderRadius: 8,
    },
  },
  list: {
    height: 200,
  },
  subheader: {
    lineHeight: 2,
  },
}))

const TEAM_ESCALATION_POLICIES_QUERY = gql`
  query IntegrationAssignToEscalationPolicy($teamId: ID!) {
    team(id: $teamId) {
      id
      name
      escalationPolicies(first: 30) {
        nodes {
          id
          name
        }
      }
    }
  }
`
