import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

import React from 'react'
import { useCurrents } from '../../context/currents'
import { useJunkDrawer } from '../../context/junkDrawer'
import { useNavigate } from '@reach/router'

export default function ForbiddenDialog() {
  const { load } = useCurrents()
  const navigate = useNavigate()
  const { junkDrawer, updateJunkDrawer } = useJunkDrawer()

  function handleClose() {
    updateJunkDrawer('forbidden', false)
    navigate(-1).then(load)
  }

  return (
    <Dialog
      aria-labelledby="forbidden-dialog-title"
      aria-describedby="forbidden-dialog-description"
      fullWidth
      onClose={handleClose}
      open={Boolean(junkDrawer.forbidden)}
      maxWidth="xs"
    >
      <DialogTitle id="forbidden-dialog-title">Forbidden</DialogTitle>
      <DialogContent>
        <DialogContentText id="forbidden-dialog-description">
          {junkDrawer.forbidden}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}
