import * as Icons from '../icons'

import { Box, IconButton, List, Typography } from '@mui/material'
import { R5Error, Loader, R5Infotip } from '../shared'
import { EscalationPolicyItem, ScheduleItem } from '.'
import { useCallback, useEffect } from 'react'
import { Link as RouterLink } from '@reach/router'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'

export default function Coverage({ teamId }) {
  const classes = useStyles()
  const { data, errors, loading, refetch } = useQuery(COVERAGE_QUERY, {
    teamId,
  })
  const { accountId, subscribe } = useCurrents()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: TEAM_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        id: teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: SCHEDULE_CREATE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: SCHEDULE_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: SCHEDULE_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: ESCALATION_POLICY_CREATE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: ESCALATION_POLICY_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: ESCALATION_POLICY_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        teamId,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, teamId])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  const { escalationPolicies, schedules } = data.team

  return (
    <>
      <Box>
        <Box className="flex flex-row justify-between items-center">
          <Box className="flex items-center">
            <Typography className={classes.header} color="textSecondary">
              Schedules
            </Typography>
            <R5Infotip
              learnMore="https://www.readyfive.io/docs/schedule-basics"
              title={
                <Typography paragraph>
                  Schedules are the calendar of your future on-call and on-duty
                  coverage. When an incident is assigned to an escalation
                  policy, that policy can target the schedule, which will notify
                  the right person.
                </Typography>
              }
            />
          </Box>
          {data.team.viewerCanCreateSchedules && (
            <IconButton
              component={RouterLink}
              size="small"
              to={`/teams/${data.team.id}/schedules/new`}
            >
              <Icons.PlusCircleOutline color={styles.primary.color} size={35} />
            </IconButton>
          )}
        </Box>
        <List>
          {schedules.nodes.map((schedule) => (
            <ScheduleItem
              key={schedule.id}
              schedule={schedule}
              team={data.team}
            />
          ))}
        </List>
      </Box>

      <Box className="mt-5">
        <Box className="flex flex-row justify-between items-center">
          <Box className="flex items-center">
            <Typography className={classes.header} color="textSecondary">
              Escalation Policies
            </Typography>
            <R5Infotip
              learnMore="https://www.readyfive.io/docs/escalation-policy-basics"
              title={
                <Typography paragraph>
                  Escalation Policies are where you define the methods and
                  timing for sending notifications in response to an incident
                  being assigned.
                </Typography>
              }
            />
          </Box>
          {data.team.viewerCanCreateEscalationPolicies && (
            <IconButton
              component={RouterLink}
              size="small"
              to={`/teams/${data.team.id}/escalationPolicies/new`}
            >
              <Icons.PlusCircleOutline color={styles.primary.color} size={35} />
            </IconButton>
          )}
        </Box>
        <List>
          {escalationPolicies.nodes.map((escalationPolicy) => (
            <EscalationPolicyItem
              escalationPolicy={escalationPolicy}
              key={escalationPolicy.id}
              team={data.team}
            />
          ))}
        </List>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 16,
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  header: {
    fontSize: 14,
    fontWeight: 900,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
}))

const COVERAGE_QUERY = gql`
  query Coverage($teamId: ID!) {
    team(id: $teamId) {
      id
      name
      accentColor
      viewerCanAdminister
      viewerCanCreateEscalationPolicies
      viewerCanCreateSchedules
      schedules(first: 30) {
        nodes {
          id
          name
          isDefault
          currentCoverage {
            onCall {
              user {
                id
                name
                initials
                avatarUrl
              }
              shift {
                id
                name
              }
              override {
                fromUser {
                  id
                  name
                }
              }
              startsAt
              endsAt
              rotationStartsAt
              rotationEndsAt
            }
            onDuty {
              user {
                id
                name
                initials
                avatarUrl
              }
              shift {
                id
                name
              }
              override {
                fromUser {
                  id
                  name
                }
              }
              startsAt
              endsAt
              rotationStartsAt
              rotationEndsAt
            }
          }
          upcomingCoverage {
            onCall {
              user {
                id
                name
                initials
                avatarUrl
              }
              shift {
                id
                name
              }
              override {
                fromUser {
                  id
                  name
                }
              }
              startsAt
              endsAt
              rotationStartsAt
              rotationEndsAt
            }
            onDuty {
              user {
                id
                name
                initials
                avatarUrl
              }
              shift {
                id
                name
              }
              override {
                fromUser {
                  id
                  name
                }
              }
              startsAt
              endsAt
              rotationStartsAt
              rotationEndsAt
            }
          }
        }
      }
      escalationPolicies(first: 30) {
        nodes {
          id
          name
          isDefault
          steps {
            id
            condition
            delayInMinutes
            escalations {
              action
              target {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

const TEAM_UPDATE_SUBSCRIPTION = gql`
  subscription onTeamUpdated($accountId: ID!, $id: ID!) {
    teamUpdated(accountId: $accountId, id: $id) {
      id
      name
      accentColor
    }
  }
`

const SCHEDULE_CREATE_SUBSCRIPTION = gql`
  subscription onScheduleCreated($accountId: ID!, $teamId: ID!) {
    scheduleCreated(accountId: $accountId, teamId: $teamId) {
      id
    }
  }
`

const SCHEDULE_UPDATE_SUBSCRIPTION = gql`
  subscription onScheduleUpdated($accountId: ID!, $teamId: ID!) {
    scheduleUpdated(accountId: $accountId, teamId: $teamId) {
      id
      accountId
      teamId
      name
      createdAt
      isDefault
    }
  }
`

const SCHEDULE_DELETE_SUBSCRIPTION = gql`
  subscription onScheduleDeleted($accountId: ID!, $teamId: ID!) {
    scheduleDeleted(accountId: $accountId, teamId: $teamId) {
      id
    }
  }
`

const ESCALATION_POLICY_CREATE_SUBSCRIPTION = gql`
  subscription onEscalationPolicyCreated($accountId: ID!, $teamId: ID!) {
    escalationPolicyCreated(accountId: $accountId, teamId: $teamId) {
      id
    }
  }
`

const ESCALATION_POLICY_UPDATE_SUBSCRIPTION = gql`
  subscription onEscalationPolicyUpdated($accountId: ID!, $teamId: ID!) {
    escalationPolicyUpdated(accountId: $accountId, teamId: $teamId) {
      id
      updatedAt
    }
  }
`

const ESCALATION_POLICY_DELETE_SUBSCRIPTION = gql`
  subscription onEscalationPolicyDeleted($accountId: ID!, $teamId: ID!) {
    escalationPolicyDeleted(accountId: $accountId, teamId: $teamId) {
      id
    }
  }
`
