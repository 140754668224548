import * as Icons from '../icons'

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import ProfileOptions from '../../constants/ProfileOptions'
import { R5Tooltip } from '../../components/shared'
import { Warning } from '@mui/icons-material'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { navigate } from '@reach/router'
import { styles } from '../../constants/styles'

export default function IncidentNotificationRuleStep({
  incidentNotificationRule,
  step,
}) {
  const classes = useStyles()
  const [showCriticalTooltip, setShowCriticalTooltip] = useState(false)

  function stepColor() {
    return [
      styles.primary.color,
      '#FFC23F',
      '#FFA73F',
      '#FF903F',
      '#FF6D3F',
      '#FF3F62',
    ]
  }

  function colorForStep() {
    return stepNumber() > stepColor().length
      ? _.last(stepColor())
      : stepColor()[stepNumber() - 1]
  }

  function stepNumber() {
    return (
      _.findIndex(incidentNotificationRule.steps, {
        id: step.id,
      }) + 1
    )
  }

  return (
    <ListItem disableGutters>
      <Card className={classes.card} variant="outlined">
        <CardActionArea
          onClick={() =>
            navigate(
              `/profile/rules/${incidentNotificationRule.id}/steps/${step.id}/edit`
            )
          }
        >
          <CardHeader
            title={
              <Box className="flex flex-row justify-between items-center">
                <Box className="flex flex-row items-center">
                  <Box className="mr-sm">
                    <Icons.Clock color={styles.lightLabel.color} size={30} />
                  </Box>
                  <Typography className={classes.cardTitle}>
                    {parseInt(step.delayInMinutes) === 0
                      ? 'Immediately'
                      : _.find(ProfileOptions.delayInMinutes, {
                          value: step.delayInMinutes,
                        }).label}
                  </Typography>
                </Box>
                <Icons.ChevronRight color={styles.lightLabel.color} size={20} />
              </Box>
            }
          />
        </CardActionArea>
        <Divider
          className={classes.divider}
          style={{ backgroundColor: colorForStep() }}
        />
        <CardContent className={classes.content}>
          {step.contactMethods.nodes.map((contactMethod, i) => (
            <Box
              key={`${contactMethod.id}-contactMethod-${i}`}
              className="flex flex-row justify-between items-center"
            >
              <Box>
                {i !== 0 && (
                  <Typography className={classes.ampersand}>AND</Typography>
                )}
                <ListItemText
                  classes={{
                    primary: classes.primary,
                    secondary: classes.secondary,
                  }}
                  primary={
                    _.find(ProfileOptions.actions, {
                      value: contactMethod.type,
                    })?.label || contactMethod.type
                  }
                  secondary={contactMethod.to}
                />
              </Box>
              {_.find(step.targets, { id: contactMethod.id })
                .sendCriticalAlert ? (
                <R5Tooltip
                  open={showCriticalTooltip}
                  placement="left"
                  setOpen={setShowCriticalTooltip}
                  title={`Sends a critical alert (${
                    _.find(step.targets, { id: contactMethod.id })
                      .criticalAlertVolume
                  }% volume)`}
                >
                  <IconButton
                    onClick={() => setShowCriticalTooltip(!showCriticalTooltip)}
                    size="small"
                  >
                    <Warning style={{ color: styles.error.color }} size={25} />
                  </IconButton>
                </R5Tooltip>
              ) : null}
            </Box>
          ))}
        </CardContent>
      </Card>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  ampersand: {
    color: styles.lightLabel.color,
    fontSize: 15,
    fontWeight: 'bold',
  },
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 900,
    color: styles.ruleTitle.color,
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  divider: {
    height: 2,
  },
  primary: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.lightLabel.color,
  },
  secondary: {
    fontSize: 14,
    fontWeight: 'bold',
    color: styles.ruleSubtitle.color,
  },
}))
