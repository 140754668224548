import { ContactMethods, NotificationRules } from '../../components/Profile'
import { R5Error, Loader } from '../../components/shared'
import { ListItem, Typography } from '@mui/material'
import { useCallback, useEffect } from 'react'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'

export default function Notifications() {
  const { data, errors, loading, refetch } = useQuery(QUERY)

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return <NotificationsLoaded refetch={refetch} viewer={data.viewer} />
}

function NotificationsLoaded({ refetch, viewer }) {
  const { accountId, subscribe } = useCurrents()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: CONTACT_METHOD_CREATE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: CONTACT_METHOD_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: CONTACT_METHOD_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INCIDENT_NOTIFICATION_RULE_CREATE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INCIDENT_NOTIFICATION_RULE_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INCIDENT_NOTIFICATION_RULE_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  return (
    <>
      <ContactMethods EmptyText={EmptyText} viewer={viewer} />
      <NotificationRules EmptyText={EmptyText} viewer={viewer} />
    </>
  )
}

function EmptyText({ text }) {
  const classes = useStyles()

  return (
    <ListItem dense disableGutters>
      <Typography className={classes.cardEmpty} color="textSecondary">
        {text}
      </Typography>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  cardEmpty: {
    fontSize: 14,
  },
}))

const QUERY = gql`
  query Notifications {
    viewer {
      id
      name
      initials
      accountId
      contactMethods(first: 30) {
        nodes {
          id
          type
          description
          to
          verifiedAt
          userId
          createdAt
          coverageChangeNotifications {
            onCallEnded
            onDutyEnded
            onCallStarted
            onDutyStarted
          }
        }
      }
      incidentNotificationRules(first: 30) {
        nodes {
          id
          name
          isDefault
          steps {
            id
            delayInMinutes
            contactMethods(first: 30) {
              nodes {
                id
                type
                description
                to
              }
            }
            targets {
              id
              sendCriticalAlert
              criticalAlertVolume
            }
          }
          repeatAfterInMinutes
          timePeriods {
            monday {
              fromTime
              toTime
            }
            tuesday {
              fromTime
              toTime
            }
            wednesday {
              fromTime
              toTime
            }
            thursday {
              fromTime
              toTime
            }
            friday {
              fromTime
              toTime
            }
            saturday {
              fromTime
              toTime
            }
            sunday {
              fromTime
              toTime
            }
          }
          userId
        }
      }
    }
  }
`

const CONTACT_METHOD_CREATE_SUBSCRIPTION = gql`
  subscription onContactMethodCreated($accountId: ID!, $userId: ID!) {
    contactMethodCreated(accountId: $accountId, userId: $userId) {
      id
    }
  }
`

const CONTACT_METHOD_UPDATE_SUBSCRIPTION = gql`
  subscription onContactMethodUpdated($accountId: ID!, $userId: ID!) {
    contactMethodUpdated(accountId: $accountId, userId: $userId) {
      id
      accountId
      userId
      type
      to
      pushProvider
      description
      verifiedAt
      createdAt
      failedAt
    }
  }
`

const CONTACT_METHOD_DELETE_SUBSCRIPTION = gql`
  subscription onContactMethodDeleted($accountId: ID!, $userId: ID!) {
    contactMethodDeleted(accountId: $accountId, userId: $userId) {
      id
    }
  }
`

const INCIDENT_NOTIFICATION_RULE_CREATE_SUBSCRIPTION = gql`
  subscription onIncidentNotificationRuleCreated(
    $accountId: ID!
    $userId: ID!
  ) {
    incidentNotificationRuleCreated(accountId: $accountId, userId: $userId) {
      id
    }
  }
`

const INCIDENT_NOTIFICATION_RULE_UPDATE_SUBSCRIPTION = gql`
  subscription onIncidentNotificationRuleUpdated(
    $accountId: ID!
    $userId: ID!
  ) {
    incidentNotificationRuleUpdated(accountId: $accountId, userId: $userId) {
      id
      updatedAt
    }
  }
`

const INCIDENT_NOTIFICATION_RULE_DELETE_SUBSCRIPTION = gql`
  subscription onIncidentNotificationRuleDeleted(
    $accountId: ID!
    $userId: ID!
  ) {
    incidentNotificationRuleDeleted(accountId: $accountId, userId: $userId) {
      id
    }
  }
`
