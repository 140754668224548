import * as Icons from '../../components/icons'

import { ListItem, ListItemText } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import React from 'react'
import UserOptions from '../../constants/UserOptions'
import _ from 'lodash'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function AccountItem({
  viewerAccount: { accountId, accountName, role, userId },
}) {
  const classes = useStyles()
  const { user, setCurrentUserId } = useCurrents()

  return (
    <ListItem
      button
      onClick={
        user.id === userId ? null : () => setCurrentUserId(userId, accountId)
      }
    >
      <ListItemText
        classes={{ primary: classes.primary, secondary: classes.secondary }}
        primary={accountName}
        secondary={_.find(UserOptions.roles, { value: role }).label}
      />
      {user.id === userId && (
        <Icons.CheckCircle color={styles.primary.color} size={35} />
      )}
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  primary: {
    fontWeight: 'bold',
  },
  secondary: {
    color: styles.lightLabel.color,
    fontWeight: 'bold',
  },
}))
