import { useEffect } from 'react'
import { useQueryParams } from '../../hooks'
import { Redirect } from '@reach/router'
import _ from 'lodash'
import { useCurrents } from '../../context/currents'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { accountId, user, userId, setCurrentAccountId } = useCurrents()
  const { accountId: accountIdFromParams } = useQueryParams()
  const viewerAccountIds = _.map(user?.viewerAccounts, 'accountId')

  useEffect(() => {
    if (!accountId) return
    if (accountId === accountIdFromParams) return
    if (!viewerAccountIds.includes(accountIdFromParams)) return
    setCurrentAccountId(accountIdFromParams)
  }, [accountId, accountIdFromParams, setCurrentAccountId, viewerAccountIds])

  if (!userId && location.pathname !== '/login')
    return (
      <Redirect
        to={`/login${
          location.pathname !== '/' && location.pathname !== '/logout'
            ? `?returnPath=${encodeURIComponent(location.pathname)}`
            : ''
        }`}
        noThrow
      />
    )

  return <Component location={location} {...rest} />
}

export default PrivateRoute
