import {
  AlertDialog,
  R5TextField,
  TimeZoneSelect,
} from '../../components/shared'
import { Box, Button, CardContent } from '@mui/material'
import React, { useState } from 'react'

import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'
import { useMutation } from '../../hooks'

export default function MainForm() {
  const classes = useStyles()
  const updateMutation = useMutation(UPDATE_USER)
  const { user, load } = useCurrents()

  const [name, setName] = useState(user.name)
  const [timeZone, setTimeZone] = useState(user.timeZone)
  const [loading, setLoading] = useState(false)
  const [showUpdatedDialog, setShowUpdatedDialog] = useState(false)

  const [validationErrors, setValidationErrors] = useState({})

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)
    setValidationErrors({})

    try {
      await updateMutation({
        userId: user.id,
        name,
        timeZone,
      })

      setLoading(false)
      setShowUpdatedDialog(true)
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  return (
    <CardContent className={classes.content}>
      <form className="flex flex-1 flex-col" onSubmit={handleSubmit}>
        <R5TextField
          error={_.some(validationErrors['name'])}
          label="Name"
          onChange={setName}
          placeholder="Account Name"
          required
          value={name}
        />
        <TimeZoneSelect
          setTimeZone={setTimeZone}
          timeZone={timeZone}
          validationErrors={validationErrors}
        />
        <Box className="flex flex-1 flex-row justify-end mt-md">
          <Button
            disabled={
              loading || (name === user.name && timeZone === user.timeZone)
            }
            type="submit"
            variant="outlined"
          >
            Update
          </Button>
        </Box>
      </form>
      <AlertDialog
        cancelText="Ok"
        content="Your profile was successfully updated."
        handleClose={() => {
          setShowUpdatedDialog(false)
          load()
        }}
        open={showUpdatedDialog}
        title="Updated"
      />
    </CardContent>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
}))

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        name
      }
    }
  }
`
