import React from 'react'

export default function Logo({ color = 'black', size = '100', ...restProps }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M50.142 70.551H52.4482C59.9286 70.551 66.7585 66.4131 70.1882 59.8101L72.8197 54.733C73.7658 52.9135 72.4353 50.7125 70.3656 50.7125H45.6183C43.7556 50.7125 42.4251 52.5027 42.9573 54.2928L47.481 68.6141C47.8358 69.7586 48.9298 70.551 50.142 70.551Z"
        fill={color}
      />
      <path
        d="M96.3252 29.7882L99.6662 23.4493C100.672 21.5711 99.2819 19.3114 97.1531 19.3114H35.8022C33.8804 19.3114 32.5203 21.1603 33.0821 22.9798L37.931 38.3282C38.3154 39.5021 39.4093 40.2944 40.6511 40.2944H78.7922C86.1543 40.3238 92.925 36.2739 96.3252 29.7882Z"
        fill={color}
      />
      <path
        d="M21.6995 21.5422C21.2856 20.2509 20.0438 19.3412 18.6541 19.3412H10.6416H4.19601C2.03765 19.3412 0.500177 21.3955 1.15064 23.4498L19.0976 79.3556C19.5116 80.6762 20.7534 81.5566 22.143 81.5566H36.6011C38.7595 81.5566 40.2674 79.5023 39.6465 77.448L21.6995 21.5422Z"
        fill={color}
      />
    </svg>
  )
}
