export default function isValidJSON(string) {
  if (typeof string === 'object' && string !== null) return true

  try {
    const json = JSON.parse(string)
    if (typeof json === 'object' && json !== null) return true
  } catch (e) {
    return false
  }
  return false
}
