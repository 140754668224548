import React from 'react'

export default function Dots({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.78571" cy="9.78571" r="1.78571" fill={color} />
      <circle cx="10.5001" cy="9.78571" r="1.78571" fill={color} />
      <circle cx="16.2142" cy="9.78571" r="1.78571" fill={color} />
    </svg>
  )
}
