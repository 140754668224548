import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Hidden,
  Typography,
} from '@mui/material'
import { R5Container, R5Title } from '../../components/shared'

import { Help } from '../../components/illustrations'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useBack } from '../../hooks'

export default function HelpPage() {
  useBack(null)
  const classes = useStyles()
  return (
    <R5Container maxWidth="sm">
      <R5Title title="Help" />
      <Card className="mt-xxl" variant="outlined">
        <CardContent className={classes.content}>
          <Box className="flex flex-row items-center">
            <Box className="flex flex-col mr-lg">
              <Typography className={classes.title}>
                We're here to help
              </Typography>
              <Typography className={classes.subtitle} color="textSecondary">
                Have a question? Ready Five support is standing by.
              </Typography>
            </Box>

            <Hidden implementation="css" smDown>
              <CardMedia
                alt="Help"
                component="img"
                image={Help}
                sx={{ width: 120 }}
              />
            </Hidden>
          </Box>
        </CardContent>
        <Divider />
        <CardActions className="mx-md my-sm">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                fullWidth
                href="https://www.readyfive.io/docs"
                rel="noopener noreferrer"
                target="_blank"
                variant="contained"
              >
                Documentation
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                component="a"
                fullWidth
                id="create_support_ticket"
                variant="contained"
              >
                Create Support Ticket
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                href="https://twitter.com/intent/tweet?text=%40getreadyfive%20"
                rel="noopener noreferrer"
                target="_blank"
                variant="contained"
              >
                Ask Us On Twitter
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      <Typography
        className="flex flex-0 tems-center justify-center mt-xxl"
        color="textSecondary"
        variant="body2"
      >
        Ready Five Version {process.env.REACT_APP_REVISION.slice(0, 7)}
      </Typography>
    </R5Container>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(5),
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  img: {
    width: 225,
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  title: {
    fontSize: 24,
    fontWeight: 900,
  },
}))
