import { InboundIntegrationModal } from './InboundIntegrationModal'
import React from 'react'
import { SlackIntegrationModal } from './SlackIntegrationModal'

export default function IntegrationModal({
  integration,
  setIntegration,
  setShowAddIntegrationModal,
  showAddIntegrationModal,
  teamId,
}) {
  return integration.provider === 'SLACK' ? (
    <SlackIntegrationModal
      integration={integration}
      setIntegration={setIntegration}
      setShowAddIntegrationModal={setShowAddIntegrationModal}
      showAddIntegrationModal={showAddIntegrationModal}
      teamId={teamId}
    />
  ) : (
    <InboundIntegrationModal
      integration={integration}
      setIntegration={setIntegration}
      setShowAddIntegrationModal={setShowAddIntegrationModal}
      showAddIntegrationModal={showAddIntegrationModal}
      teamId={teamId}
    />
  )
}
