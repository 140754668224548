import { R5Error, Loader } from '../../components/shared'
import { R5Container, R5Header, R5Title } from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { IncidentNotificationRuleStepForm } from '../../components/Users'
import React from 'react'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'

export default function CreateIncidentNotificationRuleStepPage({
  incidentNotificationRuleId,
}) {
  useBack(`/profile/rules/${incidentNotificationRuleId}`)
  const { user } = useCurrents()
  const { data, errors, loading } = useQuery(QUERY, {
    incidentNotificationRuleId,
    userId: user.id,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Notification Rule Step" />
      <R5Header
        title={`Step ${data.incidentNotificationRule.steps.length + 1}`}
      />
      <IncidentNotificationRuleStepForm
        incidentNotificationRule={data.incidentNotificationRule}
        viewer={data.viewer}
      />
    </R5Container>
  )
}

const QUERY = gql`
  query CreateIncidentNotificationRuleStep(
    $userId: ID!
    $incidentNotificationRuleId: ID!
  ) {
    viewer {
      id
      contactMethods(first: 30) {
        nodes {
          id
          type
          description
          to
          pushProvider
          verifiedAt
          user {
            id
          }
        }
      }
    }
    incidentNotificationRule(id: $incidentNotificationRuleId, userId: $userId) {
      id
      name
      isDefault
      steps {
        id
        delayInMinutes
        targets {
          id
          sendCriticalAlert
          criticalAlertVolume
        }
      }
      userId
    }
  }
`
