import { FormControl, TextField } from '@mui/material'

import { Autocomplete } from '@mui/material'
import { R5Error } from '../../components/shared'
import React from 'react'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useQuery } from '../../hooks'

export default function TargetScheduleSelect({ setTarget, target, teamId }) {
  const classes = useStyles()
  const { data, errors, loading } = useQuery(QUERY, { teamId })

  if (errors) return <R5Error errors={errors} />

  return (
    <FormControl fullWidth className={classes.margin}>
      <Autocomplete
        blurOnSelect
        classes={{
          clearIndicator: classes.clearIndicator,
          inputRoot: classes.inputRoot,
          popupIndicator: classes.popupIndicator,
          root: classes.root,
        }}
        disableClearable
        fullWidth
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id="target"
        options={data.team?.schedules?.nodes || []}
        onChange={(_event, option) => setTarget(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={target ? null : 'Search...'}
            required
          />
        )}
        size="small"
        value={loading ? 'Loading...' : target}
      />
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  clearIndicator: {
    color: theme.palette.common.white,
  },
  inputRoot: {
    backgroundColor: '#ffffff33',
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
  margin: {
    marginTop: theme.spacing(3.5),
    minWidth: 200,
  },
  popupIndicator: {
    color: theme.palette.common.white,
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.common.white,
      },
    },
  },
}))

const QUERY = gql`
  query TargetSchedule($teamId: ID!) {
    team(id: $teamId) {
      id
      name
      schedules(first: 30) {
        nodes {
          id
          name
        }
      }
    }
  }
`
