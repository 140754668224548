import { Box, Button, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import { Alert } from '@mui/material'
import _ from 'lodash'
import axios from 'axios'
import { globalHistory } from '@reach/router'

export default function AppUpdatedSnackbar() {
  const [showReload, setShowReload] = useState(false)

  const checkRevision = useMemo(
    () =>
      _.debounce(
        () =>
          axios(`/REVISION.txt?at=${new Date().getTime()}`).then((response) => {
            if (response.data.trim() !== process.env.REACT_APP_REVISION.trim())
              setShowReload(true)
          }),
        10000,
        { maxWait: 60000 }
      ),
    []
  )

  useEffect(() => {
    const historyUnsubscribe = globalHistory.listen(({ action }) => {
      if (action === 'PUSH') checkRevision()
    })

    return historyUnsubscribe
  }, [checkRevision])

  async function clearCache() {
    if (caches) {
      const names = await caches.keys()
      await Promise.all(names.map((name) => caches.delete(name)))
    }
    window.location.reload()
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={showReload}
    >
      <Alert icon={false} severity="info" variant="filled">
        <Typography paragraph variant="body2">
          We're always making changes and improvements
          <br /> to Ready Five.
        </Typography>
        <Box className="flex flex-row justify-between items-center">
          <Typography variant="body2">Let's keep things up to date.</Typography>
          <Button
            color="inherit"
            onClick={clearCache}
            size="small"
            variant="outlined"
          >
            Reload now
          </Button>
        </Box>
      </Alert>
    </Snackbar>
  )
}
