import { createTheme } from '@mui/material/styles'
import { styles } from './styles'

const R5Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        text: {
          '&:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          textTransform: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
      styleOverrides: {
        root: {
          transform: 'translate(0, 1.5px) scale(0.75)',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
          '&.Mui-selected': {
            '&:focus': {
              boxShadow: `0px 0px 0px 1px ${styles.primary.color}`,
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: '#f6f7fb',
    },
    error: {
      main: styles.error.color,
    },
    info: {
      main: styles.primary.color,
    },
    primary: {
      main: styles.primary.color,
    },
    secondary: {
      main: styles.secondary.color,
    },
    success: {
      main: styles.success.color,
    },
    text: {
      secondary: styles.label.color,
    },
    warning: {
      main: styles.warning.color,
    },
  },
  themeName: 'Ready Five',
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  },
})

export default R5Theme
