import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function AlertDialog({
  cancelText,
  confirmText,
  content,
  handleClose,
  handleConfirm,
  open,
  style,
  title,
}) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      onClose={handleClose}
      open={open}
      maxWidth="xs"
      {...style}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component="span" id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          {cancelText ? cancelText : 'Cancel'}
        </Button>
        {handleConfirm && (
          <Button
            disabled={loading}
            className={classes.confirmButton}
            onClick={async () => {
              setLoading(true)
              await handleConfirm()
              setLoading(false)
              handleClose()
            }}
          >
            {confirmText ? confirmText : 'Yes'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    color: styles.error.color,
  },
}))
