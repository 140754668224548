import { FormControl, TextField } from '@mui/material'
import React, { useEffect } from 'react'

import { Autocomplete } from '@mui/material'
import { R5Error } from '../../components/shared'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useQuery } from '../../hooks'

export default function TargetUserSelect({ setTarget, target }) {
  const classes = useStyles()
  const { data, errors, fetchMore, fetchingMore, loading } = useQuery(QUERY)

  useEffect(() => {
    if (!loading && !fetchingMore && data.users.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data.users.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.users.nodes.length
            ? {
                users: {
                  __typename: previousResult.users.__typename,
                  nodes: [
                    ...previousResult.users.nodes,
                    ...fetchMoreResult.users.nodes,
                  ],
                  pageInfo: fetchMoreResult.users.pageInfo,
                },
              }
            : previousResult
        },
      })
    }
  }, [data, fetchMore, fetchingMore, loading])

  if (errors) return <R5Error errors={errors} />

  return (
    <FormControl fullWidth className={classes.margin}>
      <Autocomplete
        blurOnSelect
        classes={{
          clearIndicator: classes.clearIndicator,
          inputRoot: classes.inputRoot,
          popupIndicator: classes.popupIndicator,
          root: classes.root,
        }}
        disableClearable
        fullWidth
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id="target"
        options={
          data.users?.nodes
            ? [
                { id: 'nobody', initials: 'NB', name: 'Nobody' },
                ...data.users.nodes,
              ]
            : []
        }
        onChange={(_event, option) => setTarget(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={target ? null : 'Search...'}
            required
            variant="outlined"
          />
        )}
        size="small"
        value={loading ? 'Loading...' : target}
      />
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  clearIndicator: {
    color: theme.palette.common.white,
  },
  inputRoot: {
    backgroundColor: '#ffffff33',
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
  margin: {
    marginTop: theme.spacing(3.5),
    minWidth: 200,
  },
  popupIndicator: {
    color: theme.palette.common.white,
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.common.white,
      },
    },
  },
}))

const QUERY = gql`
  query TargetUser($cursor: String) {
    users(after: $cursor, first: 30) {
      nodes {
        id
        name
        initials
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
