import React, { createContext, useContext, useEffect, useState } from 'react'

import _ from 'lodash'
import { useCurrents } from './currents'

const QueryCacheContext = createContext()

const COVERAGE_KEYS = ['endsAt', 'schedule', 'shift', 'startsAt', 'team']

const PERIOD_KEYS = [
  'actualEndsAt',
  'actualStartsAt',
  'endsAt',
  'override',
  'startsAt',
  'target',
]
const SEGMENT_KEYS = ['rows', 'shift']

function coverageComparitor(obj, src) {
  return obj.shift.id === src.shift.id
}

function periodComparitor(obj, src) {
  return (
    obj.actualStartsAt === src.actualStartsAt &&
    obj.actualEndsAt === src.actualEndsAt &&
    obj.target.id === src.target.id
  )
}

function segmentComparitor(obj, src) {
  return obj.shift.id === src.shift.id
}

function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    if (_.isEqual(mergeKeys(objValue, srcValue), COVERAGE_KEYS)) {
      return _.uniqWith(objValue.concat(srcValue), coverageComparitor)
    } else if (_.isEqual(mergeKeys(objValue, srcValue), PERIOD_KEYS)) {
      return _.uniqWith(objValue.concat(srcValue), periodComparitor)
    } else if (_.isEqual(mergeKeys(objValue, srcValue), SEGMENT_KEYS)) {
      return _.uniqWith(objValue.concat(srcValue), segmentComparitor)
    } else {
      return _.uniqBy(objValue.concat(srcValue), 'id')
    }
  }
}

function mergeKeys(obj, src) {
  const objKeys = obj.length > 0 ? Object.keys(obj[0]) : []
  const srcKeys = src.length > 0 ? Object.keys(src[0]) : []
  return (objKeys.length > 0 ? objKeys : srcKeys).sort()
}

const QueryCacheProvider = (props) => {
  const [queryCache, setQueryCache] = useState({})
  const { user, account } = useCurrents()

  useEffect(clearQueryCache, [account, user])

  function updateQueryCache(storageKey, data, reset = false) {
    if (_.isEqual(queryCache[storageKey], data)) return

    setQueryCache((prevQueryCache) => {
      let newQueryCache = { ...prevQueryCache }
      if (reset) {
        newQueryCache[storageKey] = data
      } else {
        newQueryCache[storageKey] = _.mergeWith(
          newQueryCache[storageKey],
          data,
          customizer
        )
      }

      return newQueryCache
    })
  }

  function clearQueryCache() {
    setQueryCache({})
  }

  return (
    <QueryCacheContext.Provider
      value={{ queryCache, updateQueryCache }}
      {...props}
    />
  )
}

function useQueryCache() {
  return useContext(QueryCacheContext)
}

export { QueryCacheProvider, useQueryCache }
