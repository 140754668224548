import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ConfirmButton, R5Input } from '../../components/shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'

import { Alert } from '@mui/material'
import TargetEscalationPolicySelect from './TargetEscalationPolicySelect'
import TargetScheduleSelect from './TargetScheduleSelect'
import TargetUserSelect from './TargetUserSelect'
import TeamOptions from '../../constants/TeamOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function EscalationPolicyStepForm({
  escalationPolicy,
  step,
  team,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const escalationPolicyMutation = useMutation(UPDATE_ESCALATION_POLICY)
  const [condition] = useState(step ? step.condition : 'NOT_ACKNOWLEDGED')
  const [delayInMinutes, setDelayInMinutes] = useState(
    step ? step.delayInMinutes : 0
  )
  const [escalations, setEscalations] = useState(
    step
      ? step.escalations
      : [
          {
            action: 'NOTIFY_ON_DUTY_AND_ON_CALL_USERS_IN_SCHEDULE',
            target: null,
          },
        ]
  )
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState()

  function newSteps() {
    return _.concat(escalationPolicy.steps, {
      condition,
      delayInMinutes,
      escalations,
    }).map(({ condition, delayInMinutes, escalations }) => ({
      condition,
      delayInMinutes,
      escalations: escalations.map(({ action, target }) => {
        return { action, targetId: target?.id }
      }),
    }))
  }

  function updatedSteps() {
    return escalationPolicy.steps.map(
      ({
        id,
        condition: prevCondition,
        delayInMinutes: prevDelayInMinutes,
        escalations: prevEscalations,
      }) => {
        if (id === step.id) {
          return {
            condition,
            delayInMinutes,
            escalations: escalations.map(({ action, target }) => {
              return { action, targetId: target?.id }
            }),
          }
        }
        return {
          condition: prevCondition,
          delayInMinutes: prevDelayInMinutes,
          escalations: prevEscalations.map(({ action, target }) => {
            return { action, targetId: target?.id }
          }),
        }
      }
    )
  }

  function deletedSteps() {
    return _.reject(escalationPolicy.steps, {
      id: step.id,
    }).map(({ condition, delayInMinutes, escalations }) => ({
      condition,
      delayInMinutes,
      escalations: escalations.map(({ action, target }) => {
        return { action, targetId: target?.id }
      }),
    }))
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)

    try {
      await escalationPolicyMutation({
        teamId: team.id,
        escalationPolicyId: escalationPolicy?.id,
        steps: step ? updatedSteps() : newSteps(),
      })
      setLoading(false)
      navigate(`/teams/${team.id}/escalationPolicies/${escalationPolicy.id}`)
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)

    try {
      await escalationPolicyMutation({
        teamId: team.id,
        escalationPolicyId: escalationPolicy.id,
        steps: deletedSteps(),
      })
      setLoading(false)
      navigate(`/teams/${team.id}/escalationPolicies/${escalationPolicy.id}`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function select(index) {
    let select = undefined
    switch (escalations[index].action) {
      case 'ASSIGN_ESCALATION_POLICY':
        select = TargetEscalationPolicySelect
        break
      case 'NOTIFY_ON_CALL_USERS_IN_SCHEDULE':
      case 'NOTIFY_ON_DUTY_USERS_IN_SCHEDULE':
      case 'NOTIFY_ON_DUTY_AND_ON_CALL_USERS_IN_SCHEDULE':
      case 'NOTIFY_ALL_USERS_IN_SCHEDULE':
        select = TargetScheduleSelect
        break
      case 'NOTIFY_USER':
        select = TargetUserSelect
        break
      default:
        select = null
    }
    return select
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-xxl ${
          step ? 'justify-between' : 'justify-end'
        }`}
      >
        {step && (
          <ConfirmButton
            confirmContent="Are you sure you want to remove this step?"
            error
            onConfirm={handleDelete}
            title="Delete Step"
          />
        )}

        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/teams/${team.id}/escalationPolicies/${escalationPolicy.id}`}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {step ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  function renderWho(index) {
    const SelectComponent = select(index)
    return (
      <SelectComponent
        action={escalations[index].action}
        escalationPolicy={escalationPolicy}
        setTarget={(target) =>
          setEscalations((prevEscalations) => {
            let newEscalations = [...prevEscalations]
            newEscalations[index] = {
              action: prevEscalations[index].action,
              target,
            }
            return newEscalations
          })
        }
        target={escalations[index].target}
        teamId={team.id}
      />
    )
  }

  function renderHeader() {
    return (
      <CardHeader
        title={
          <Select
            classes={{ select: classes.select }}
            id="delay-in-minutes"
            input={<R5Input />}
            onChange={setDelayInMinutes}
            value={delayInMinutes}
          >
            {TeamOptions.delayAfterInMinutesOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        }
      />
    )
  }

  function renderAction(escalation, index) {
    return (
      <Card className={classes.paper} key={index} variant="outlined">
        <CardContent>
          <Box className="flex flex-1 flex-row justify-between items-start">
            <Select
              classes={{
                icon: classes.popupIndicator,
                select: mobileView ? classes.mobileSelect : null,
              }}
              input={<R5Input clear />}
              onChange={(value) =>
                setEscalations((prevEscalations) => {
                  let newEscalations = [...prevEscalations]
                  newEscalations[index] = {
                    action: value,
                    target:
                      value === 'NOTIFY_TEAM'
                        ? { id: team.id, name: team.name }
                        : escalations[index].action.includes('SCHEDULE') &&
                          value.includes('SCHEDULE')
                        ? newEscalations[index].target
                        : null,
                  }
                  return newEscalations
                })
              }
              value={escalation.action}
            >
              {TeamOptions.actionOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {escalations.length > 1 && (
              <IconButton
                onClick={() => {
                  setEscalations((prevEscalations) =>
                    _.filter(prevEscalations, (_escalation, j) => index !== j)
                  )
                }}
                size="small"
              >
                <Icons.MinusCircleOutline
                  color={styles.error.color}
                  size={30}
                />
              </IconButton>
            )}
          </Box>
          {escalations[index].action === 'NOTIFY_TEAM' ? (
            <Box style={{ color: 'white' }}>
              <Typography
                className={`ml-xs ${classes.teamName}`}
                color="inherit"
              >
                {team.name}
              </Typography>
            </Box>
          ) : (
            renderWho(index)
          )}
        </CardContent>
      </Card>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {_.some(validationErrors) && (
        <Alert className="mb-lg" severity="error" variant="filled">
          One or more errors are present. Please fix them and try again.
        </Alert>
      )}
      <Card className={classes.card} variant="outlined">
        {renderHeader()}
        <Divider />
        <CardContent className={classes.content}>
          {escalations.map((escalation, index) => {
            return renderAction(escalation, index)
          })}
          <Button
            onClick={() => {
              setEscalations((prevEscalations) => [
                ...prevEscalations,
                {
                  action: 'NOTIFY_ON_DUTY_AND_ON_CALL_USERS_IN_SCHEDULE',
                  target: null,
                },
              ])
            }}
            variant="outlined"
          >
            + Add Action
          </Button>
        </CardContent>
      </Card>
      {renderFooter()}
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  label: {
    color: styles.lightLabel.color,
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  noMarginRight: {
    marginRight: 0,
  },
  paper: {
    backgroundColor: styles.primary.color,
    marginBottom: theme.spacing(3),
  },
  popupIndicator: {
    color: theme.palette.common.white,
  },
  radioGroup: {
    '&:last-child': {
      marginRight: 0,
    },
  },
  radioLabel: {
    fontSize: 14,
    fontWeight: 600,
  },
  secondary: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  teamName: {
    fontWeight: 'bold',
    marginTop: 36,
    marginBottom: 6,
  },
  mobileSelect: {
    maxWidth: 100,
  },
  select: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
}))

const UPDATE_ESCALATION_POLICY = gql`
  mutation UpdateEscalationPolicy($input: UpdateEscalationPolicyInput!) {
    updateEscalationPolicy(input: $input) {
      escalationPolicy {
        id
        name
      }
    }
  }
`
