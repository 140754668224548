import { ClickAwayListener, Link, Tooltip, Typography } from '@mui/material'
import React, { forwardRef } from 'react'

import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

const R5Tooltip = forwardRef(
  ({ children, open, placement, learnMore, setOpen, title }, ref) => {
    const classes = useStyles()
    const openProps = _.isUndefined(open) ? {} : { open }

    return (
      <ClickAwayListener onClickAway={setOpen ? () => setOpen(false) : _.noop}>
        <Tooltip
          arrow
          classes={{
            arrow: classes.arrow,
            tooltip: classes.tooltip,
          }}
          placement={placement}
          ref={ref}
          title={
            <>
              <Typography className={classes.toolTipText} component="span">
                {title}
              </Typography>
              {learnMore && (
                <Link
                  className={classes.link}
                  href={learnMore}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="hover"
                >
                  Learn more
                </Link>
              )}
            </>
          }
          {...openProps}
        >
          {children}
        </Tooltip>
      </ClickAwayListener>
    )
  }
)

export default R5Tooltip

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: '#000000CC',
  },
  link: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '150%',
    textDecoration: 'underline',
  },
  tooltip: {
    backgroundColor: '#000000D9',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  tooltipText: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '150%',
  },
}))
