import * as Icons from '../../components/icons'

import {
  AlertDialog,
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Infotip,
  R5RadioGroup,
  R5TextField,
  R5Title,
  R5Tooltip,
} from '../../components/shared'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from '@mui/material'
import React, { forwardRef, useState } from 'react'
import { Link as RouterLink, useLocation } from '@reach/router'
import { useBack, useMutation, useQuery } from '../../hooks'

import { InviteUser } from '../../components/illustrations'
import { MembershipSelect } from '../../components/Users'
import UserOptions from '../../constants/UserOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'

export default function InviteUserPage({ location, navigate }) {
  useBack('/users')
  const classes = useStyles()
  const createMutation = useMutation(CREATE_INVITATION)
  const { data, errors, loading } = useQuery(QUERY)
  const { user } = useCurrents()

  const [validationErrors, setValidationErrors] = useState([])
  const [emailAddress, setEmailAddress] = useState('')
  const [role, setRole] = useState('MEMBER')
  const [memberships, setMemberships] = useState(
    location.state?.team
      ? [
          {
            role: 'MEMBER',
            team: {
              id: location.state.team.id,
              name: location.state.team.name,
            },
          },
        ]
      : []
  )
  const [submitting, setSubmitting] = useState(false)
  const [showInvitedDialog, setShowInvitedDialog] = useState(false)

  function handleRoleChange(role) {
    setRole(role)
    if (role === 'ADMINISTRATOR') {
      setMemberships((prevMemberships) =>
        _.map(prevMemberships, (membership) => ({
          ...membership,
          role: 'ADMINISTRATOR',
        }))
      )
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setSubmitting(true)

    try {
      const response = await createMutation({
        emailAddress,
        role,
        memberships: memberships.map(({ team: { id: teamId }, role }) => ({
          teamId,
          role,
        })),
      })

      if (response.data.createInvitation) {
        if (location.state?.team) {
          setShowInvitedDialog(true)
        } else {
          navigate('/users')
        }
      }
    } catch (error) {
      setSubmitting(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  function renderFooter() {
    return (
      <Box className="flex flex-1 flex-row justify-end my-2">
        <Box className="flex flex-row">
          <Button
            className={classes.marginRight}
            component={RouterLink}
            size="large"
            to="/users"
          >
            Cancel
          </Button>
          <Button
            disabled={submitting}
            size="large"
            type="submit"
            variant="contained"
          >
            Invite
          </Button>
        </Box>
      </Box>
    )
  }

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Invite User" />
      <R5Header title="Invite User" />
      <Typography className="mb-xl pl-sm pr-sm">
        Invited users will receive an email containing a link to sign up. Once
        they accept, they can be scheduled for coverage and incidents can be
        assigned to them.
      </Typography>
      <form onSubmit={handleSubmit}>
        <Card variant="outlined">
          <CardContent
            className={
              _.some(data.teams) ? classes.teamContent : classes.content
            }
          >
            <Box className="flex flex-1 flex-row items-center">
              <Box className="flex flex-1 flex-col mr-xl">
                <R5TextField
                  error={
                    validationErrors['emailAddress'] ||
                    validationErrors['userSeats']
                  }
                  inputProps={{ type: 'email' }}
                  label="Email Address"
                  onChange={setEmailAddress}
                  placeholder="user@email.com"
                  value={emailAddress}
                />
                {user.role === 'MEMBER' ? (
                  <R5Tooltip
                    title="You cannot invite someone with higher permissions than
                          yourself, so the role is capped at Member."
                  >
                    <RoleField
                      handleRoleChange={handleRoleChange}
                      role={role}
                      validationErrors={validationErrors}
                    />
                  </R5Tooltip>
                ) : (
                  <RoleField
                    handleRoleChange={handleRoleChange}
                    role={role}
                    validationErrors={validationErrors}
                  />
                )}
              </Box>
              <Box className="flex flex-1 justify-end">
                <Hidden implementation="css" smDown>
                  <CardMedia
                    alt="Invite User"
                    component="img"
                    image={InviteUser}
                    sx={{ width: 160 }}
                  />
                </Hidden>
              </Box>
            </Box>
          </CardContent>
          {!_.some(data.teams) && (
            <>
              <Divider />
              <CardActions className={classes.actions}>
                {renderFooter()}
              </CardActions>
            </>
          )}
        </Card>
        {_.some(data.teams) && (
          <>
            <Typography className="mt-xl mb-xl pl-sm pr-sm">
              You can (optionally) configure this user's teams and associated
              team-level permissions so they're all set when signing in for the
              first time.
            </Typography>
            <Card variant="outlined">
              <CardContent className={classes.teamContent}>
                <MembershipSection
                  memberships={memberships}
                  role={role}
                  setMemberships={setMemberships}
                />
              </CardContent>
            </Card>
            <Box className="mt-md">{renderFooter()}</Box>
          </>
        )}
      </form>
      <AlertDialog
        cancelText="Ok"
        content="Once they accept, they can be scheduled for coverage and incidents can be assigned to them."
        handleClose={() =>
          navigate(`/teams/${location.state.team.id}/members/new`)
        }
        open={showInvitedDialog}
        title="Invited!"
      />
    </R5Container>
  )
}

function MembershipSection({ memberships, role, setMemberships }) {
  const classes = useStyles()
  const location = useLocation()

  return (
    <Box className="mt-lg">
      <Box className="flex flex-row items-center">
        <Typography className={`${classes.label} mr-xs`}>Teams</Typography>
        <R5Infotip
          learnMore="https://www.readyfive.io/docs/team-basics"
          title={
            <>
              <Typography paragraph>
                <strong>Member</strong>
                <br />
                Can be participants in team schedules, can participate in shift
                overrides, and can be targeted by integrations.
              </Typography>
              <Typography paragraph>
                <strong>Administrator</strong>
                <br />
                Can fully manage everything on the team.
              </Typography>
            </>
          }
        />
      </Box>
      {memberships.map((membership, index) => (
        <Box className="mt-sm mb-lg" key={index}>
          <Grid container>
            <Grid item>
              <MembershipSelect
                autoFocus={index === 0 && location.state?.team ? false : true}
                className="mr-md"
                key={index}
                memberships={memberships}
                team={membership.team}
                setTeam={(team) =>
                  setMemberships((prevMemberships) => {
                    let newMemberships = [...prevMemberships]
                    newMemberships[index] = {
                      role: prevMemberships[index].role,
                      team,
                    }
                    return newMemberships
                  })
                }
              />
            </Grid>
            {role === 'ADMINISTRATOR' ? (
              <R5Tooltip
                title="Since they are being invited as an account administrator,
                    any teams must also have the administrator role."
              >
                <MembershipItem
                  index={index}
                  membership={membership}
                  memberships={memberships}
                  role={role}
                  setMemberships={setMemberships}
                />
              </R5Tooltip>
            ) : (
              <MembershipItem
                index={index}
                membership={membership}
                memberships={memberships}
                role={role}
                setMemberships={setMemberships}
              />
            )}
          </Grid>
        </Box>
      ))}
      <Button
        className={memberships.length === 0 ? 'mt-sm' : ''}
        disabled={_.some(memberships, (membership) =>
          _.isNull(membership.team)
        )}
        onClick={() => {
          setMemberships((prevMemberships) => [
            ...prevMemberships,
            {
              team: null,
              role: role === 'ADMINISTRATOR' ? 'ADMINISTRATOR' : 'MEMBER',
            },
          ])
        }}
        variant="outlined"
      >
        + {memberships.length === 0 ? 'Add team' : 'Add another team'}
      </Button>
    </Box>
  )
}

const RoleField = forwardRef(function RolField(
  { handleRoleChange, role, validationErrors, ...restProps },
  ref
) {
  const { user } = useCurrents()

  return (
    <Box ref={ref} {...restProps} className="mt-lg">
      <R5RadioGroup
        buttons={_.reject(UserOptions.roles, { label: 'Owner' })}
        disabled={user.role === 'MEMBER'}
        error={validationErrors['role']}
        label="Role"
        onChange={handleRoleChange}
        row
        toolTip={
          <R5Infotip
            learnMore="https://www.readyfive.io/docs/users/roles-and-permissions"
            title={
              <>
                <Typography paragraph>
                  <strong>Member</strong>
                  <br />
                  Can view and act on incidents assigned to them or their teams
                  and can be assigned as a member or administrator of teams.
                </Typography>
                <Typography paragraph>
                  <strong>Administrator</strong>
                  <br />
                  Can view and manage all users, teams, and incidents.
                </Typography>
              </>
            }
          />
        }
        value={role}
      />
    </Box>
  )
})

const MembershipItem = forwardRef(function MembershipItem(
  { index, membership, memberships, role, setMemberships, ...restProps },
  ref
) {
  return (
    <Grid className="flex flex-row items-center" ref={ref} item {...restProps}>
      <R5RadioGroup
        buttons={[
          { label: 'Member', value: 'MEMBER' },
          { label: 'Admin', value: 'ADMINISTRATOR' },
        ]}
        disabled={role === 'ADMINISTRATOR'}
        onChange={(role) =>
          setMemberships((prevMemberships) => {
            let newMemberships = [...prevMemberships]
            newMemberships[index] = {
              role,
              team: prevMemberships[index].team,
            }
            return newMemberships
          })
        }
        row
        value={membership.role}
      />
      {memberships.length > 0 && (
        <IconButton
          onClick={() => {
            setMemberships((prevMemberships) =>
              _.filter(prevMemberships, (_team, j) => index !== j)
            )
          }}
          size="small"
        >
          <Icons.MinusCircleOutline color={styles.error.color} size={30} />
        </IconButton>
      )}
    </Grid>
  )
})

const useStyles = makeStyles((theme) => ({
  actions: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5),
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  img: {
    width: 200,
  },
  label: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  marginTop: {
    marginTop: theme.spacing(3.5),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  teamContent: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(1),
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
}))

const CREATE_INVITATION = gql`
  mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitation {
        emailAddress
        role
      }
    }
  }
`

const QUERY = gql`
  query MembershipSelect($cursor: String) {
    teams(after: $cursor, first: 30) {
      nodes {
        id
        name
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
