import * as Icons from '../../components/icons'

import {
  Box,
  Card,
  CardContent,
  IconButton,
  List,
  Typography,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import EventRuleItem from './EventRuleItem'
import React from 'react'
import { Link as RouterLink } from '@reach/router'
import { styles } from '../../constants/styles'

export default function EventRules({ integration }) {
  const classes = useStyles()
  return (
    <Box className={classes.marginTop}>
      <Box className="flex flex-row justify-between items-center">
        <Typography className={classes.header} color="textSecondary">
          Event Rules
        </Typography>
        {integration.viewerCanAdminister && (
          <IconButton
            component={RouterLink}
            size="small"
            to={`/teams/${integration.teamId}/integrations/${integration.id}/eventRules/new`}
          >
            <Icons.PlusCircleOutline color={styles.primary.color} size={35} />
          </IconButton>
        )}
      </Box>
      <List disablePadding>
        {integration.eventRules.length > 0 ? (
          integration.eventRules.map((eventRule, index) => (
            <EventRuleItem
              key={index}
              eventRule={eventRule}
              eventRuleIndex={index}
              integration={integration}
            />
          ))
        ) : (
          <Card className={classes.card} variant="outlined">
            <CardContent className={classes.content}>
              <Typography className="py-xs" color="textSecondary">
                No Event Rules
              </Typography>
            </CardContent>
          </Card>
        )}
      </List>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  header: {
    fontSize: 14,
    fontWeight: 900,
    letterSpacing: 1,
    marginBottom: theme.spacing(1.25),
    marginTop: theme.spacing(1.25),
    textTransform: 'uppercase',
  },
  marginTop: {
    marginTop: theme.spacing(3.75),
  },
}))
