import * as Icons from '../../components/icons'

import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  ListItem,
  Typography,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import React from 'react'
import { Link as RouterLink } from '@reach/router'
import TeamOptions from '../../constants/TeamOptions'
import _ from 'lodash'
import { styles } from '../../constants/styles'

export default function EscalationPolicyStep({
  escalationPolicy,
  step,
  teamId,
}) {
  const classes = useStyles()

  function stepColor() {
    return [
      styles.primary.color,
      '#FFC23F',
      '#FFA73F',
      '#FF903F',
      '#FF6D3F',
      '#FF3F62',
    ]
  }

  function colorForStep() {
    return stepNumber() > stepColor().length
      ? _.last(stepColor())
      : stepColor()[stepNumber() - 1]
  }

  function stepNumber() {
    return (
      _.findIndex(escalationPolicy.steps, {
        id: step.id,
      }) + 1
    )
  }

  function renderStep() {
    return (
      <Box className="flex flex-col">
        {step.escalations.map((escalation, i) => (
          <Box key={`${step.id}-${escalation.targetId}-${i}`}>
            {i !== 0 && (
              <Typography className={classes.ampersand} color="textSecondary">
                AND
              </Typography>
            )}
            <Typography className={classes.subtitle} color="textSecondary">
              {
                _.find(TeamOptions.actionOptions, {
                  value: escalation.action,
                }).label
              }
            </Typography>
            <Typography className={classes.title}>
              {escalation.target.name}
            </Typography>
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Card className={classes.card} variant="outlined">
      <ListItem className="my-xs">
        <Box className="flex flex-1 justify-between">
          <Box className="flex flex-row items-center">
            <Box className="mr-sm">
              <Icons.Clock color={styles.lightLabel.color} size={25} />
            </Box>
            <Typography className={classes.title}>
              {parseInt(step.delayInMinutes) === 0
                ? 'Immediately'
                : _.find(TeamOptions.delayAfterInMinutesOptions, {
                    value: step.delayInMinutes,
                  }).label}
            </Typography>
          </Box>
          <Box className="flex flex-row items-center">
            <IconButton
              className={classes.outlineButton}
              component={RouterLink}
              to={`/teams/${teamId}/escalationPolicies/${escalationPolicy.id}/steps/${step.id}/edit`}
              size="large"
            >
              <Icons.Pencil color={styles.primary.color} size={15} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>

      <Divider style={{ backgroundColor: colorForStep(), height: 2 }} />
      <CardContent className={classes.content}>{renderStep()}</CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  ampersand: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(3),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  icon: {
    minWidth: theme.spacing(5),
  },
  outlineButton: {
    backgroundColor: styles.container.backgroundColor,
    color: styles.primary.color,
    border: `1px solid ${styles.border.color}`,
    padding: 6,
    marginRight: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    color: styles.ruleTitle.color,
    fontSize: 14,
    fontWeight: 900,
  },
}))
