import { CreateSupportTicketPage, HelpPage } from '../../pages/Profile'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import { Router } from '@reach/router'

export default function UserStack() {
  return (
    <Router>
      <HelpPage path="/" />
      <CreateSupportTicketPage path="/new" />

      <NotFoundPage default />
    </Router>
  )
}
