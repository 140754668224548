import { LoginError, R5Logo } from './components/shared'

import AdapterMoment from '@mui/lab/AdapterMoment'
import { Analytics } from 'aws-amplify'
import { BugsnagProvider } from './context/bugsnag'
import { CurrentsProvider } from './context/currents'
import IntercomProvider from './context/intercom'
import { JunkDrawerProvider } from './context/junkDrawer'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { QueryCacheProvider } from './context/queryCache'
import { useEffect, useState } from 'react'
import { amplifyConfigure, fetchAmplifyConfig } from './utils'

// Amplify.Logger.LOG_LEVEL = 'DEBUG'
Analytics.configure({ disabled: true })

export default function AppProviders({ children }) {
  const [config, setConfig] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    let ignore = false
    async function fetchConfig() {
      try {
        const resp = await fetchAmplifyConfig()
        if (!ignore) {
          amplifyConfigure(resp.data)
          setConfig(resp.data)
        }
      } catch (err) {
        if (!ignore) {
          setError(err)
        }
      }
    }
    fetchConfig()

    return () => {
      ignore = true
    }
  }, [])

  if (error) {
    return <LoginError errors={error} />
  }

  if (!config) {
    return <R5Logo />
  }

  return (
    <CurrentsProvider wssEndpoint={config.appSyncRealTimeGraphQLEndpoint}>
      <BugsnagProvider>
        <IntercomProvider>
          <QueryCacheProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <JunkDrawerProvider>{children}</JunkDrawerProvider>
            </LocalizationProvider>
          </QueryCacheProvider>
        </IntercomProvider>
      </BugsnagProvider>
    </CurrentsProvider>
  )
}
