import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

import R5Input from './R5Input'
import React from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

export default function R5Select({
  error,
  label,
  onChange,
  options,
  required,
  value,
}) {
  const classes = useStyles()

  return (
    <FormControl className={classes.margin}>
      {label && (
        <InputLabel
          className={classes.label}
          error={error}
          htmlFor={_.kebabCase(label)}
          required={required}
        >
          {label}
        </InputLabel>
      )}
      <Select
        error={error}
        id={_.kebabCase(label)}
        input={<R5Input />}
        onChange={onChange}
        required={required}
        value={value}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  margin: {
    marginTop: theme.spacing(3.5),
    minWidth: 200,
  },
}))
