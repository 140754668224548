import * as Icons from '../../components/icons'

import { AppBar, Avatar, Box, Hidden, IconButton, Toolbar } from '@mui/material'

import { Logo } from '../illustrations'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

const drawerWidth = 250

export default function R5AppBarLoader() {
  const classes = useStyles()

  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      <Toolbar className="flex justify-between">
        <Box className="flex flex-row items-center">
          <IconButton
            aria-label="menu"
            edge="start"
            className={classes.menuButton}
            size="large"
          >
            <Icons.Menu color={styles.primary.color} size={20} />
          </IconButton>
        </Box>
        <Hidden smUp implementation="css">
          <Logo color={styles.primary.color} size={35} />
        </Hidden>
        <IconButton edge="end" size="small">
          <Avatar className={classes.avatar}> </Avatar>
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: styles.container.backgroundColor,
    borderBottomColor: '#e5e7eb',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  avatar: {
    backgroundColor: styles.primary.color,
    color: '#fff',
    fontSize: 14,
    height: 32,
    width: 32,
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))
