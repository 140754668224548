import React from 'react'

export default function BellCircle({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM10.5763 6.26664H9.42338V6.84552C8.36823 7.1 7.58533 8.03732 7.58533 9.15468V10.7355L6.73315 11.2523V12.3954H13.2665V11.2523L12.4143 10.7355V9.15468C12.4143 8.03734 11.6314 7.10004 10.5763 6.84553V6.26664ZM9.90114 13.9529C10.5226 13.9529 11.0263 13.5561 11.0263 13.0666H8.77596C8.77596 13.5561 9.27972 13.9529 9.90114 13.9529Z"
        fill={color}
      />
    </svg>
  )
}
