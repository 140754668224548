import React from 'react'

export default function RedactedCircle({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM9.32915 8.02426C9.55695 7.78995 9.55695 7.41005 9.32915 7.17574C9.10134 6.94142 8.73199 6.94142 8.50419 7.17574L6.17085 9.57574C6.06146 9.68826 6 9.84087 6 10C6 10.1591 6.06146 10.3117 6.17085 10.4243L8.50419 12.8243C8.73199 13.0586 9.10134 13.0586 9.32915 12.8243C9.55695 12.5899 9.55695 12.2101 9.32915 11.9757L7.40829 10L9.32915 8.02426ZM12.8291 8.02426C13.057 7.78995 13.057 7.41005 12.8291 7.17574C12.6013 6.94142 12.232 6.94142 12.0042 7.17574L9.67085 9.57574C9.56146 9.68826 9.5 9.84087 9.5 10C9.5 10.1591 9.56146 10.3117 9.67085 10.4243L12.0042 12.8243C12.232 13.0586 12.6013 13.0586 12.8291 12.8243C13.057 12.5899 13.057 12.2101 12.8291 11.9757L10.9083 10L12.8291 8.02426Z"
        fill={color}
      />
    </svg>
  )
}
