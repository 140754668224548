/** @jsxImportSource @emotion/react */

import * as Icons from '../icons'

import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import { HowToReg } from '@mui/icons-material'
import { LogoWithText } from '../illustrations'
import { Link as RouterLink } from '@reach/router'
import { css } from '@emotion/react'
import makeStyles from '@mui/styles/makeStyles'
import { useJunkDrawer } from '../../context/junkDrawer'
import { useWelcome } from '../../hooks'

export default function R5Drawer() {
  const classes = useStyles()
  const { showWelcome } = useWelcome()
  const { junkDrawer, updateJunkDrawer } = useJunkDrawer()

  function renderDrawer(clipKey) {
    return (
      <div className="flex flex-1 flex-col" css={stylesheet}>
        <Box
          className="flex flex-1 flex-col sidebar"
          onClick={() => updateJunkDrawer('mobileOpen', false)}
          onKeyDown={() => updateJunkDrawer('mobileOpen', false)}
        >
          <Box
            className={`${classes.toolbar} flex items-center justify-center`}
          >
            <LogoWithText clipKey={clipKey} />
          </Box>
          <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
          <Box className="flex flex-1 flex-col justify-between">
            <List className="p-0">
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  component={RouterLink}
                  getProps={({ isPartiallyCurrent }) => ({
                    className: isPartiallyCurrent ? 'current' : null,
                  })}
                  to="/incidents"
                >
                  <ListItemIcon>
                    <Icons.Bell color="#ced5de" size={18} />
                  </ListItemIcon>
                  <ListItemText primary="Incidents" />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  component={RouterLink}
                  getProps={({ isPartiallyCurrent }) => ({
                    className: isPartiallyCurrent ? 'current' : null,
                  })}
                  to="/teams"
                >
                  <ListItemIcon>
                    <Icons.Teams color="#ced5de" size={18} />
                  </ListItemIcon>
                  <ListItemText primary="Teams" />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters disablePadding>
                <ListItemButton
                  component={RouterLink}
                  getProps={({ isPartiallyCurrent }) => ({
                    className: isPartiallyCurrent ? 'current' : null,
                  })}
                  to="/users"
                >
                  <ListItemIcon>
                    <Icons.People color="#ced5de" size={18} />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItemButton>
              </ListItem>
            </List>
            {showWelcome && (
              <List className="p-0">
                <ListItem disableGutters disablePadding>
                  <ListItemButton
                    component={RouterLink}
                    getProps={({ isPartiallyCurrent }) => ({
                      className: isPartiallyCurrent ? 'current' : null,
                    })}
                    to="/welcome"
                  >
                    <ListItemIcon>
                      <HowToReg style={{ color: '#ced5de' }} />
                    </ListItemIcon>
                    <ListItemText primary="Welcome" />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </Box>
        </Box>
      </div>
    )
  }

  return (
    <>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={Boolean(junkDrawer.mobileOpen)}
          onClose={() => updateJunkDrawer('mobileOpen', false)}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {renderDrawer('mobile')}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          {renderDrawer('desktop')}
        </Drawer>
      </Hidden>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 250,
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: '#21233f',
    width: 250,
  },
  content: {
    minHeight: '100vh',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 250,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}))

const stylesheet = css`
  .sidebar {
    .current {
      background-color: #3f47f4;
      color: #fff;
      .icon path {
        fill: #fff;
      }
    }
    a {
      text-decoration: none;
      padding: 1.5em 1em;
      transition: all linear 0.1s;
      width: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      &:hover {
        background-color: #3f47f4;
        color: #fff;
        .icon path {
          fill: #fff;
        }
      }
      .icon {
        width: 18px;
        margin-right: 10px;
        path {
          fill: rgba(255, 255, 255, 0.6);
        }
      }
      &:focus {
        background-color: #3f47f4;
        outline: none;
      }
    }
  }
`
