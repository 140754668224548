import { CreateSchedulePage } from '../../../pages/Teams'
import React from 'react'
import { Router } from '@reach/router'
import ScheduleStack from './ScheduleStack'

export default function SchedulesStack() {
  return (
    <Router>
      <CreateSchedulePage path="new" />
      <ScheduleStack path=":scheduleId/*" />
    </Router>
  )
}
