import React from 'react'

export default function Lock({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7H6V6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6V7H16V17H4V7ZM11.3333 6V7H8.66667V6C8.66667 5.26362 9.26362 4.66667 10 4.66667C10.7364 4.66667 11.3333 5.26362 11.3333 6ZM10 14C11.1046 14 12 13.1046 12 12C12 10.8954 11.1046 10 10 10C8.89543 10 8 10.8954 8 12C8 13.1046 8.89543 14 10 14Z"
        fill={color}
      />
    </svg>
  )
}
