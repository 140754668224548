import { BillingPage, EditAccountPage } from '../../pages/Account'
import { Redirect, Router } from '@reach/router'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import { enterprise } from '../../utils'
import { useCurrents } from '../../context/currents'

export default function Account() {
  const { user } = useCurrents()

  if (!user.account.viewerCanManageBilling) return <NotFoundPage />

  return (
    <Router>
      <Redirect from="/" to={enterprise() ? 'edit' : 'billing'} noThrow />
      {!enterprise() && <BillingPage path="billing" />}
      {user.account.viewerCanAdminister && <EditAccountPage path="edit" />}
      <NotFoundPage default />
    </Router>
  )
}
