import { AuthorizePage } from '../../../pages/Profile'
import React from 'react'
import { Router } from '@reach/router'

export default function SlackStack() {
  return (
    <Router>
      <AuthorizePage path="authorize" />
    </Router>
  )
}
