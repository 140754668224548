import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import _ from 'lodash'

import React from 'react'
import { useJunkDrawer } from '../../context/junkDrawer'

export default function BadRequestDialog() {
  const { junkDrawer, updateJunkDrawer } = useJunkDrawer()

  function handleClose() {
    updateJunkDrawer('badRequest', null)
  }

  return (
    <Dialog
      aria-labelledby="bad-request-dialog-title"
      aria-describedby="bad-request-dialog-description"
      fullWidth
      onClose={handleClose}
      open={!_.isNil(junkDrawer.badRequest)}
      maxWidth="xs"
    >
      <DialogTitle id="bad-request-dialog-title">
        {junkDrawer.badRequest?.errorInfo[0].title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="bad-request-dialog-description">
          {junkDrawer.badRequest?.errorInfo[0].detail}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}
