import {
  EditUserPage,
  InviteUserPage,
  UserPage,
  UsersPage,
} from '../../pages/Users'
import { Redirect, Router } from '@reach/router'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import _ from 'lodash'

export default function UserStack({ location }) {
  const storedFilter = localStorage.getItem('@filterState/users')
  const shouldRedirect = _.some(storedFilter) && _.isEmpty(location.search)

  return (
    <Router>
      {shouldRedirect && (
        <Redirect from="/" to={`/users${storedFilter}`} noThrow />
      )}
      <UsersPage path="/" />
      <InviteUserPage path="/invite" />
      <UserPage path=":userId" />
      <EditUserPage path=":userId/edit" />

      <NotFoundPage default />
    </Router>
  )
}
