import {
  BadRequestError,
  ForbiddenError,
  PaymentRequiredError,
  ValidationError,
} from '../components/shared'
import { useEffect, useRef } from 'react'

import { GraphQLAPI } from '@aws-amplify/api-graphql'
import { useCurrents } from '../context/currents'
import { useJunkDrawer } from '../context/junkDrawer'

export default function useMutation(query) {
  const { account } = useCurrents()
  const { updateJunkDrawer } = useJunkDrawer()
  const refCurrentAccount = useRef(account)

  const mutation = async (input) => {
    if (process.env.NODE_ENV === 'development')
      console.log('MUTATION', query, input)
    try {
      return await GraphQLAPI.graphql(
        { query, variables: { input } },
        { 'X-Ready-Five-Account-ID': refCurrentAccount.current?.id }
      )
    } catch (err) {
      if (process.env.NODE_ENV === 'development') console.warn(err)
      if (
        err.errors &&
        err.errors.length > 0 &&
        err.errors[0].errorType === 'VALIDATION_ERROR'
      ) {
        throw new ValidationError('VALIDATION_ERROR', err.errors[0].errorInfo)
      } else if (
        err.errors &&
        err.errors.length > 0 &&
        err.errors[0].errorType === 'PAYMENT_REQUIRED'
      ) {
        updateJunkDrawer('paymentRequired', err.errors[0].message)
        throw new PaymentRequiredError(err.errors[0].message)
      } else if (
        err.errors &&
        err.errors.length > 0 &&
        err.errors[0].errorType === 'FORBIDDEN'
      ) {
        updateJunkDrawer('forbidden', err.errors[0].message)
        throw new ForbiddenError(err.errors[0].message)
      } else if (
        err.errors &&
        err.errors.length > 0 &&
        err.errors[0].errorType === 'BAD_REQUEST'
      ) {
        updateJunkDrawer('badRequest', err.errors[0])
        throw new BadRequestError(err.errors[0].message)
      } else {
        throw err
      }
    }
  }

  useEffect(() => {
    refCurrentAccount.current = account
  }, [query, account])

  return mutation
}
