import {
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import React, { useState } from 'react'

import AvailableIntegrations from '../../components/Teams/AvailableIntegrations'
import { R5Tooltip } from '../../components/shared'
import { Link as RouterLink } from '@reach/router'
import { WarningOutlined } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function IntegrationItem({ integration }) {
  const classes = useStyles()
  const [showDisabledTooltip, setShowDisabledTooltip] = useState(false)
  const icon = AvailableIntegrations({ index: false }).find(
    (i) => i.provider === integration.provider
  )?.icon

  return (
    <Card className={classes.card} key={integration.id} variant="outlined">
      <CardActionArea component="span" tabIndex={-1}>
        <CardContent className={classes.content}>
          <ListItem
            component={RouterLink}
            to={`/teams/${integration.teamId}/integrations/${integration.id}`}
          >
            {icon && (
              <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            )}
            <ListItemText
              classes={{
                primary: classes.primary,
                secondary: classes.secondary,
              }}
              primary={integration.name}
              secondary={integration.description}
            />
            {integration.isDisabled && (
              <ListItemSecondaryAction>
                <R5Tooltip
                  open={showDisabledTooltip}
                  placement="left"
                  setOpen={setShowDisabledTooltip}
                  title="Disabled"
                >
                  <IconButton
                    onClick={() => setShowDisabledTooltip(!showDisabledTooltip)}
                    size="small"
                  >
                    <WarningOutlined
                      style={{ color: styles.warning.color }}
                      size={25}
                    />
                  </IconButton>
                </R5Tooltip>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  icon: {
    minWidth: theme.spacing(5),
  },
  primary: {
    fontSize: 14,
    fontWeight: 600,
  },
  secondary: {
    fontSize: 12,
    fontWeight: 600,
  },
}))
