import {
  AccountStack,
  HelpStack,
  IncidentsStack,
  ProfileStack,
  TeamsStack,
  UsersStack,
  WelcomeStack,
} from './stacks'
import {
  ForgotConfirmPage,
  ForgotPage,
  // HelpPage,
  SignInPage,
  SignOutPage,
  SignUpConfirmPage,
  SignUpPage,
} from '../pages/public'
import { InvitationPage, LoginSSORedirect, NotFoundPage } from '../pages/utils'
import { PrivateRoute, PublicOnlyRoute } from '../navigation/utils'
import { Redirect, Router } from '@reach/router'

import AppLayout from '../pages/AppLayout'
import React from 'react'

export default function AppRouter() {
  return (
    <Router>
      <PublicOnlyRoute component={SignInPage} path="login" />
      <PublicOnlyRoute component={ForgotPage} path="forgot" />
      <PublicOnlyRoute component={SignUpPage} path="signup" />
      <PublicOnlyRoute component={SignUpConfirmPage} path="signup/confirm" />
      <PublicOnlyRoute component={ForgotConfirmPage} path="forgot/confirm" />
      {/* <HelpPage path="help" /> */}
      <InvitationPage path="invitation" />
      <SignOutPage path="logout" />
      <PrivateRoute component={AppLayout} path="/">
        <Redirect from="/" to="incidents" noThrow />
        <IncidentsStack path="incidents/*" />
        <TeamsStack path="teams/*" />
        <UsersStack path="users/*" />
        <ProfileStack path="profile/*" />
        <AccountStack path="account/*" />
        <HelpStack path="help/*" />
        <WelcomeStack path="welcome/*" />
        <LoginSSORedirect path="login/sso" />

        <NotFoundPage default />
      </PrivateRoute>
    </Router>
  )
}
