import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { NotFoundPage } from '../../pages/utils'
import { OverrideForm } from '../../components/Teams'
import React from 'react'
import gql from 'graphql-tag'

export default function EditOverridePage({ overrideId, scheduleId, teamId }) {
  useBack(`/teams/${teamId}/schedules/${scheduleId}`)
  const { data, errors, loading } = useQuery(QUERY, {
    overrideId,
    scheduleId,
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.override.viewerCanAdminister) return <NotFoundPage />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Override" />
      <R5Header title="Edit Override" />
      <OverrideForm
        override={data.override}
        schedule={data.override.schedule}
      />
    </R5Container>
  )
}

const QUERY = gql`
  query EditOverride($overrideId: ID!, $teamId: ID!, $scheduleId: ID!) {
    override(id: $overrideId, scheduleId: $scheduleId, teamId: $teamId) {
      id
      startsAt
      endsAt
      viewerCanAdminister
      shifts(first: 30) {
        nodes {
          id
          name
        }
      }
      fromUser {
        id
        name
      }
      toUser {
        id
        name
      }
      schedule {
        id
        name
        shifts(first: 30) {
          nodes {
            id
            name
          }
        }
        teamId
      }
    }
  }
`
