import { R5Container, R5Header, R5Title } from '../../components/shared'

import React from 'react'
import { TeamForm } from '../../components/Teams'
import { useBack } from '../../hooks'

export default function CreateTeamPage() {
  useBack('/teams')
  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Team" />
      <R5Header title="Create Team" />
      <TeamForm />
    </R5Container>
  )
}
