import { Box, Button, CircularProgress, FormHelperText } from '@mui/material'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'

import gql from 'graphql-tag'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

const cardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      color: styles.primary.color,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      fontWeight: 600,
      '::placeholder': {
        color: styles.placeHolderText.color,
      },
      iconColor: styles.primary.color,
      ':-webkit-autofill': {
        color: styles.ruleTitle.color,
      },
    },
    invalid: {
      color: styles.error.color,
      iconColor: styles.error.color,
      ':-webkit-autofill': {
        color: styles.error.color,
      },
    },
  },
}

export default function StripeForm({
  card,
  setCardUpdating,
  setEditingCard,
  setShowSubscribeDialog,
  subscription,
}) {
  const stripe = useStripe()
  const elements = useElements()

  const updateBillingPaymentCardMutation = useMutation(
    UPDATE_BILLING_PAYMENT_CARD
  )

  const [loading, setLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})

  const handleSubmit = async (event) => {
    event.preventDefault() // Block native form submission

    if (!stripe || !elements) return
    setLoading(true)

    const cardElement = elements.getElement(CardElement)
    const { error, token } = await stripe.createToken(cardElement)
    if (error) {
      setLoading(false)
      setValidationErrors({ card: error.message })
    } else {
      try {
        await updateBillingPaymentCardMutation({ token: token.id })

        if (subscription?.status === 'TRIALING') setShowSubscribeDialog(true)

        setCardUpdating(true)
        setEditingCard(false)
      } catch (error) {
        setLoading(false)
        if (process.env.NODE_ENV === 'development') console.log(error)
      }
    }
  }

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardElement)
      cardElement.on('ready', () => cardElement.focus())
      cardElement.on('change', (event) => {
        if (event.error) {
          setValidationErrors({ card: event.error.message })
        } else {
          setValidationErrors({})
        }
      })
    }
  }, [elements])

  return (
    <form className="pt-sm" onSubmit={handleSubmit}>
      <CardElement options={cardElementOptions} />
      {validationErrors['card'] && (
        <FormHelperText className="absolute bottom-10" error>
          {validationErrors['card']}
        </FormHelperText>
      )}
      <Box className="flex justify-end mt-xxl">
        <Button
          className="mr-sm"
          disabled={!stripe || loading}
          size="small"
          onClick={() => setEditingCard(false)}
        >
          Cancel
        </Button>
        <Button
          className={loading ? 'px-xl' : null}
          disabled={!stripe || loading}
          type="submit"
          size="small"
          variant="outlined"
        >
          {loading ? (
            <CircularProgress color="inherit" size={21} />
          ) : card ? (
            'Update Card'
          ) : subscription?.status === 'TRIALING' ? (
            'OK, start my subscription'
          ) : (
            'Add Card'
          )}
        </Button>
      </Box>
    </form>
  )
}

const UPDATE_BILLING_PAYMENT_CARD = gql`
  mutation UpdateBillingPaymentCard($input: UpdateBillingPaymentCardInput!) {
    updateBillingPaymentCard(input: $input) {
      billingPaymentCard {
        brand
        country
        expiresAtMonth
        expiresAtYear
        last4
      }
    }
  }
`
