import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material'
import { R5Input, R5Title } from '../../components/shared'
import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { Auth } from 'aws-amplify'
import {
  Logo,
  SignInFront,
  SignInBack,
  GoogleIcon,
} from '../../components/illustrations'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { navigate } from '@reach/router'
import { styles } from '../../constants/styles'
import { useJunkDrawer } from '../../context/junkDrawer'
import { useQueryParams } from '../../hooks'
import { configStorageKey } from '../../constants/app'

const missingNameError = 'Name cannot be empty'
const missingUsernameError = 'Username cannot be empty'
const invalidUsernameError =
  'PreSignUp failed with error The provided email address is invalid.'
const missingPasswordError = 'Password cannot be empty'
const ambiguousSignupError =
  'You must supply accountName or accountId, invitationId, and invitationToken upon signup'
const accountNameTooLongError =
  'custom:accountName: String must be no longer than 64 characters'
const shortPasswordError = 'Member must have length greater'
const anotherShortPasswordError = 'Password not long enough'
const passwordSpacesError = 'Member must satisfy regular expression'
const passwordLowerCaseError = 'Password must have lowercase characters'
const passwordUpperCaseError = 'Password must have uppercase characters'
const passwordNumericError = 'Password must have numeric characters'

export default function SignUpPage() {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const { accountName: accountNameParam = '' } = useQueryParams()
  const [accountName, setAccountName] = useState(accountNameParam)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const { updateJunkDrawer } = useJunkDrawer()
  const { signInProviders } = JSON.parse(localStorage.getItem(configStorageKey))

  const showGoogleSignUpButton = signInProviders.includes('Google')
  const showEmailPasswordSignUpForm = signInProviders.includes('EmailPassword')
  const showOrSeparator = showGoogleSignUpButton && showEmailPasswordSignUpForm

  async function handleSubmit(event) {
    event.preventDefault()

    setError(null)
    setLoading(true)
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          'custom:accountName': accountName,
          'custom:timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
      updateJunkDrawer('tempPassword', password)
      navigate(`/signup/confirm?email=${encodeURIComponent(email)}`)
    } catch (err) {
      setLoading(false)
      if (err.message.includes(ambiguousSignupError)) {
        setError('Please specify an account name')
      } else if (err.message.includes(missingNameError)) {
        setError('Please specify your name')
      } else if (err.message.includes(missingUsernameError)) {
        setError('Please specify your email address')
      } else if (err.message.includes(invalidUsernameError)) {
        setError('Invalid email address')
      } else if (err.message.includes(missingPasswordError)) {
        setError('Please specify a password')
      } else if (err.message.includes(accountNameTooLongError)) {
        setError('Account name cannot exceed 64 characters')
      } else if (
        err.message.includes(shortPasswordError) ||
        err.message.includes(anotherShortPasswordError)
      ) {
        setError('Password is too short')
      } else if (err.message.includes(passwordSpacesError)) {
        setError('Password cannot contain spaces')
      } else if (
        err.message.includes(passwordLowerCaseError) ||
        err.message.includes(passwordUpperCaseError) ||
        err.message.includes(passwordNumericError)
      ) {
        setError(
          'Password must have at least one uppercase, lowercase, and numeric character'
        )
      } else {
        setError(err.message)
      }
    }
  }

  function handlePasswordCompare() {
    if (_.isEqual(password, passwordConfirm)) {
      if (error === 'Passwords do not match') setError(null)
    } else {
      setError('Passwords do not match')
    }
  }

  return (
    <Container className="flex w-full items-center h-full justify-between p-0 max-w-[100%] min-h-[100vh]">
      <R5Title title="Sign up" />
      <Box className={classes.imgCol}>
        <img
          className={classes.frontImg}
          src={SignInFront}
          alt="Illustration of a phone with the Ready Five UI on the screen."
        />
        <img
          className={classes.backImg}
          src={SignInBack}
          alt="Background illustration of a futuristic city."
        />
      </Box>
      <Box style={{ margin: '0 auto', maxWidth: '450px' }}>
        <CardContent className={classes.content}>
          <Box>
            <Logo
              className={classes.logo}
              color={styles.primary.color}
              size={65}
            />
            <Typography className={classes.title}>
              Try Ready Five free for 14 days
            </Typography>
            <Typography className={classes.subtitle}>
              Already have an account?{' '}
              <Link
                className={classes.link}
                component={RouterLink}
                color="primary"
                to="/login"
                underline="hover"
              >
                Sign in
              </Link>
            </Typography>
            {error && (
              <Typography className={classes.error}>{error}</Typography>
            )}
            {showGoogleSignUpButton && (
              <>
                <Box className={classes.spacer}></Box>
                <Button
                  className={classes.googleButton}
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={() => {
                    Auth.federatedSignIn({
                      provider: 'Google',
                      customState: JSON.stringify({}),
                    })
                  }}
                >
                  <img
                    src={GoogleIcon}
                    alt="Google Icon"
                    style={{
                      maxWidth: '18px',
                      marginRight: 10,
                    }}
                  />
                  Sign up with Google
                </Button>
              </>
            )}
            {showOrSeparator && (
              <>
                <Box className={classes.spacer}></Box>
                <Typography
                  style={{
                    textAlign: 'center',
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  - OR -
                </Typography>
                <Box className={classes.spacer}></Box>
              </>
            )}
            {showEmailPasswordSignUpForm && (
              <form style={{ marginTop: '1em' }} onSubmit={handleSubmit}>
                <R5Input
                  autoComplete="name"
                  autoFocus
                  error={error?.includes('your name')}
                  fullWidth
                  name="name"
                  onChange={setName}
                  placeholder="Enter your full name"
                  startAdornment={
                    <Icons.Person size={25} color={styles.lightLabel.color} />
                  }
                  value={name}
                />
                <R5Input
                  autoComplete="username"
                  className="mt-lg"
                  error={error?.includes('email')}
                  fullWidth
                  name="email"
                  onChange={setEmail}
                  placeholder="Enter your work email address"
                  startAdornment={
                    <Icons.Envelope size={25} color={styles.lightLabel.color} />
                  }
                  type="email"
                  value={email}
                />
                <R5Input
                  autoComplete="new-password"
                  className="mt-lg"
                  error={error?.includes('Password')}
                  fullWidth
                  name="password"
                  onChange={setPassword}
                  placeholder="Choose a password"
                  startAdornment={
                    <Icons.Key size={25} color={styles.lightLabel.color} />
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword(
                            (prevShowPassword) => !prevShowPassword
                          )
                        }
                        size="small"
                        tabIndex={-1}
                        title={showPassword ? 'Hide Password' : 'Show Password'}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                />
                <Box className="text-left">
                  <Typography className={classes.helpText}>
                    Password must have at least one uppercase, lowercase, and
                    numeric character.
                  </Typography>
                </Box>
                <R5Input
                  autoComplete="new-password"
                  className="mt-lg"
                  error={error?.includes('Password')}
                  fullWidth
                  name="password-confirm"
                  onBlur={handlePasswordCompare}
                  onChange={setPasswordConfirm}
                  placeholder="Confirm your password"
                  startAdornment={
                    <Icons.Key size={25} color={styles.lightLabel.color} />
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPasswordConfirm(
                            (prevShowPasswordConfirm) =>
                              !prevShowPasswordConfirm
                          )
                        }
                        size="small"
                        tabIndex={-1}
                        title={
                          showPasswordConfirm
                            ? 'Hide Password'
                            : 'Show Password'
                        }
                      >
                        {showPasswordConfirm ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  type={showPasswordConfirm ? 'text' : 'password'}
                  value={passwordConfirm}
                />
                <R5Input
                  autoComplete="organization"
                  className="mt-lg"
                  error={error?.includes('account name')}
                  fullWidth
                  name="account-name"
                  onChange={setAccountName}
                  placeholder="Enter your company or organization's name"
                  startAdornment={
                    <Icons.People size={25} color={styles.lightLabel.color} />
                  }
                  value={accountName}
                />
                <Button
                  classes={{
                    disabled: classes.disabled,
                    root: classes.button,
                  }}
                  disabled={
                    loading ||
                    _.isEmpty(name) ||
                    _.isEmpty(email) ||
                    _.isEmpty(password) ||
                    _.isEmpty(accountName) ||
                    !_.isEqual(password, passwordConfirm)
                  }
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={21} />
                  ) : (
                    'Sign up'
                  )}
                </Button>
              </form>
            )}
          </Box>
        </CardContent>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 800,
    marginTop: theme.spacing(3),
    textTransform: 'uppercase',
  },
  card: {
    borderRadius: theme.spacing(1),
    minHeight: 556,
  },
  disabled: {
    backgroundColor: `${styles.primaryLight.color} !important`,
    color: 'white !important',
  },
  error: {
    backgroundColor: styles.error.backgroundColor,
    borderColor: styles.error.color,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    color: 'white',
    marginTop: 20,
    padding: 12,
    width: '100%',
  },
  googleButton: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: styles.primary.color,
    color: styles.primary.color,
    marginBottom: 0,
    marginTop: 0,
    borderRadius: 8,
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(1),
  },
  helpText: {
    fontSize: 12,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: 50,
    fontWeight: 600,
    lineHeight: '107.9%',
    textAlign: 'left',
  },
  imgCol: {
    height: '100vh',
    width: '50%',
    backgroundColor: '#4147EB',
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
    '@media (max-width: 925px)': {
      display: 'none',
    },
  },
  backImg: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: '0',
  },
  frontImg: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '-350px',
    top: '50%',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: '60%',
    minWidth: '580px',
    display: 'block',
  },
  spacer: {
    marginTop: 20,
  },
}))
