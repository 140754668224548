import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { NotFoundPage } from '../../pages/utils'
import React from 'react'
import { ShiftForm } from '../../components/Teams'
import gql from 'graphql-tag'

export default function EditShiftPage({ scheduleId, shiftId, teamId }) {
  useBack(`/teams/${teamId}/schedules/${scheduleId}`)
  const { data, errors, loading } = useQuery(QUERY, {
    scheduleId,
    shiftId,
    teamId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />
  if (!data.shift.viewerCanAdminister) return <NotFoundPage />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Edit Shift" />
      <R5Header title="Edit Shift" />
      <ShiftForm scheduleId={scheduleId} shift={data.shift} teamId={teamId} />
    </R5Container>
  )
}

const QUERY = gql`
  query EditShiftScreen($shiftId: ID!, $teamId: ID!, $scheduleId: ID!) {
    shift(id: $shiftId, teamId: $teamId, scheduleId: $scheduleId) {
      id
      name
      timeZone
      coverageType
      rotationType
      rotationLength
      startsAt
      endsAt
      viewerCanAdminister
      participants(first: 30) {
        nodes {
          id
          name
        }
      }
      timePeriods {
        monday {
          fromTime
          toTime
        }
        tuesday {
          fromTime
          toTime
        }
        wednesday {
          fromTime
          toTime
        }
        thursday {
          fromTime
          toTime
        }
        friday {
          fromTime
          toTime
        }
        saturday {
          fromTime
          toTime
        }
        sunday {
          fromTime
          toTime
        }
      }
    }
  }
`
