import React from 'react'

export default function MagnifyingGlass({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7579 12.7729C15.5828 10.3648 15.4062 6.90245 13.2279 4.69843C10.8574 2.29988 7.01409 2.29988 4.64359 4.69843C2.2731 7.09698 2.2731 10.9858 4.64359 13.3843C6.78742 15.5535 10.1359 15.761 12.5101 14.0066L15.9822 17.5198L17.2157 16.2717L13.7579 12.7729ZM11.9944 5.94656C13.6836 7.65579 13.6836 10.427 11.9944 12.1362C10.3052 13.8455 7.56636 13.8455 5.87712 12.1362C4.18788 10.427 4.18788 7.65579 5.87712 5.94656C7.56636 4.23732 10.3052 4.23732 11.9944 5.94656Z"
        fill={color}
      />
    </svg>
  )
}
