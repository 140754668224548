import {
  R5Error,
  Loader,
  R5Container,
  R5Header,
  R5Title,
} from '../../components/shared'
import { useBack, useQuery } from '../../hooks'

import { EventRuleForm } from '../../components/Teams'
import React from 'react'
import gql from 'graphql-tag'

export default function CreateEventRulePage({ integrationId, teamId }) {
  useBack(`/teams/${teamId}/integrations/${integrationId}`)
  const { data, errors, loading } = useQuery(QUERY, { integrationId, teamId })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <R5Container maxWidth="sm">
      <R5Title title="Create Event Rule" />
      <R5Header title="Create Event Rule" />
      <EventRuleForm integration={data.integration} />
    </R5Container>
  )
}

const QUERY = gql`
  query CreateEventRule($integrationId: ID!, $teamId: ID!) {
    integration(id: $integrationId, teamId: $teamId) {
      id
      name
      teamId
      eventRules {
        action
        stopEvaluatingOnMatch
        value
        criteria {
          match
          conditions {
            caseSensitiveValue
            field
            expectedValue
            operation
            negateOperation
          }
        }
      }
    }
  }
`
