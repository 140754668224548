import { ActivityIndicator, R5Error, Loader } from '../shared'
import { Box, Button, List, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { ReactComponent as EmptyInvitations } from '../illustrations/EmptyInvitations.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import InvitationItem from './InvitationItem'
import { ReactComponent as NotFound } from '../illustrations/NotFound.svg'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'

export default function Invited({ search }) {
  const { accountId, account, subscribe } = useCurrents()
  const { data, errors, fetchMore, fetchingMore, loading, refetch } =
    useQuery(QUERY)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])
  const handleNextDebounced = useMemo(
    () => _.debounce(handleNext, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.invitations?.pageInfo?.hasNextPage, fetchingMore]
  )

  useEffect(() => {
    if (!data.invitations) return
    if (search.length > 0) handleNextDebounced()
  }, [data.invitations, handleNextDebounced, search.length])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INVITATION_CREATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INVITATION_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INVITATION_DELETE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  function handleNext() {
    if (fetchingMore) return

    if (data.invitations.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data.invitations.pageInfo.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.invitations.nodes.length
            ? {
                invitations: {
                  __typename: previousResult.invitations.__typename,
                  nodes: [
                    ...previousResult.invitations.nodes,
                    ...fetchMoreResult.invitations.nodes,
                  ],
                  pageInfo: fetchMoreResult.invitations.pageInfo,
                },
              }
            : previousResult
        },
      })
    }
  }

  function renderEmpty() {
    return (
      <Box className="flex flex-col items-center justify-center mt-1/10">
        <EmptyInvitations />
        <Typography variant="h5" gutterBottom>
          No pending invitations
        </Typography>
        {account.viewerCanInviteUsers && (
          <Button component={RouterLink} to="/users/invite" variant="outlined">
            + Invite User
          </Button>
        )}
      </Box>
    )
  }

  function renderNoResults() {
    if (fetchingMore) return null

    return (
      <Box className="flex flex-col items-center justify-center">
        <NotFound height={150} width={150} />
        <Typography variant="h5" gutterBottom>
          No invitations match your search
        </Typography>
        {account.viewerCanInviteUsers && (
          <Button component={RouterLink} to="/users/invite" variant="outlined">
            + Invite {search}
          </Button>
        )}
      </Box>
    )
  }

  const selectedInvitations = data.invitations.nodes.filter((invitation) =>
    invitation.emailAddress.toLowerCase().includes(search.toLowerCase())
  )

  return data.invitations.nodes.length ? (
    selectedInvitations.length ? (
      <InfiniteScroll
        dataLength={selectedInvitations.length}
        hasChildren={_.some(selectedInvitations)}
        hasMore={data.invitations.pageInfo.hasNextPage}
        loader={<ActivityIndicator />}
        next={handleNext}
      >
        <List>
          {selectedInvitations.map((invitation) => (
            <InvitationItem key={invitation.id} invitation={invitation} />
          ))}
        </List>
      </InfiniteScroll>
    ) : (
      renderNoResults()
    )
  ) : (
    renderEmpty()
  )
}

const QUERY = gql`
  query Invitations($cursor: String) {
    invitations(after: $cursor, first: 30) {
      nodes {
        id
        role
        emailAddress
        expiresAt
        viewerCanAdminister
        viewerCanResend
        viewerCanAccept
        viewerCanDecline
        memberships {
          team {
            id
            name
          }
          role
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const INVITATION_CREATE_SUBSCRIPTION = gql`
  subscription onInvitationCreated($accountId: ID!) {
    invitationCreated(accountId: $accountId) {
      id
    }
  }
`

const INVITATION_UPDATE_SUBSCRIPTION = gql`
  subscription onInvitationUpdated($accountId: ID!) {
    invitationUpdated(accountId: $accountId) {
      id
      accountId
      emailAddress
      role
      expiresAt
      createdAt
    }
  }
`

const INVITATION_DELETE_SUBSCRIPTION = gql`
  subscription onInvitationDeleted($accountId: ID!) {
    invitationDeleted(accountId: $accountId) {
      id
    }
  }
`
