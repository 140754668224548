import React from 'react'

export default function PlusCircleOutline({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5784 9.71636V7.86836H9.40236V9.71636H7.68502V10.7524H9.40236V12.6097H10.5784V10.7524H12.305V9.71636H10.5784Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10ZM10 15.963C6.70675 15.963 4.03704 13.2933 4.03704 10C4.03704 6.70675 6.70675 4.03704 10 4.03704C13.2933 4.03704 15.963 6.70675 15.963 10C15.963 13.2933 13.2933 15.963 10 15.963Z"
        fill={color}
      />
    </svg>
  )
}
