import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material'
import { ConfirmButton, R5Input } from '../../components/shared'
import { FromUserSelect, ShiftsSelect, ToUserSelect } from './'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'

import { Alert } from '@mui/material'
import { MobileDateTimePicker } from '@mui/lab'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { useCurrents } from '../../context/currents'
import { useMutation } from '../../hooks'

export default function OverrideForm({
  defaultShifts,
  override,
  period,
  schedule,
}) {
  const classes = useStyles()
  const { user } = useCurrents()
  const navigate = useNavigate()
  const overrideMutation = useMutation(
    override ? UPDATE_OVERRIDE : CREATE_OVERRIDE
  )
  const deleteMutation = useMutation(DELETE_OVERRIDE)

  const [endsAt, setEndsAt] = useState(
    override
      ? moment.unix(override.endsAt).toDate()
      : period
      ? moment.unix(period.actualEndsAt || period.endsAt).toDate()
      : moment().add(1, 'day').startOf('day').toDate()
  )
  const [fromUser, setFromUser] = useState(
    override
      ? override.fromUser
      : period?.target && period.target.id !== 'nobody'
      ? period.target
      : null
  )
  const [startsAt, setStartsAt] = useState(
    override
      ? moment.unix(override.startsAt).toDate()
      : period
      ? moment.unix(period.startsAt).toDate()
      : moment().startOf('day').toDate()
  )
  const [shifts, setShifts] = useState(
    override ? override.shifts.nodes : defaultShifts || []
  )
  const [toUser, setToUser] = useState(
    override
      ? override.toUser
      : period?.target && period.target.id === user.id
      ? { id: 'nobody', initials: 'NB', name: 'Nobody' }
      : user
  )
  const [validationErrors, setValidationErrors] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (startsAt > endsAt) setEndsAt(moment(startsAt).add(1, 'day').toDate())
  }, [endsAt, startsAt])

  function scheduleUrl() {
    return `/teams/${schedule.teamId}/schedules/${
      schedule.id
    }?timelineStartsAt=${moment(
      startsAt
    ).unix()}&timelineInterval=WEEK&timelineIntervalCount=2`
  }

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)
    try {
      await overrideMutation({
        endsAt: moment(endsAt).unix(),
        fromUserId: fromUser?.id || '',
        overrideId: override?.id,
        scheduleId: schedule.id,
        shiftIds: shifts.map((shift) => shift.id),
        startsAt: moment(startsAt).unix(),
        teamId: schedule.teamId,
        toUserId: toUser.id,
      })
      setLoading(false)
      navigate(scheduleUrl())
    } catch (error) {
      setLoading(false)
      if (error.errors) {
        window.scrollTo(0, 0)
        setValidationErrors(error.errors)
      }
    }
  }

  async function handleDelete() {
    setLoading(true)
    try {
      await deleteMutation({
        overrideId: override.id,
        teamId: schedule.teamId,
        scheduleId: schedule.id,
      })
      setLoading(false)
      navigate(scheduleUrl())
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-xxl ${
          override ? 'justify-between' : 'justify-end'
        }`}
      >
        {override && (
          <ConfirmButton
            confirmContent="Are you sure you want to delete this override?"
            error
            onConfirm={handleDelete}
            title="Delete Override"
          />
        )}

        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/teams/${schedule.teamId}/schedules/${schedule.id}`}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {override ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  function renderStartsAt() {
    return (
      <FormControl fullWidth>
        <InputLabel
          className={classes.label}
          error={_.some(validationErrors['startsAt'])}
          htmlFor="starts-at"
        >
          Starts
        </InputLabel>
        <MobileDateTimePicker
          error={_.some(validationErrors['startsAt'])}
          inputFormat="ddd, MMM D h:mma z"
          minutesStep={15}
          onChange={setStartsAt}
          OpenPickerButtonProps={{ color: 'primary' }}
          renderInput={({ inputProps, ...restProps }) => (
            <R5Input
              inputProps={{
                ...inputProps,
                value: moment(inputProps.value, 'ddd, MMM D h:mma z')
                  .tz(moment.tz.guess(true))
                  .format('ddd, MMM D h:mma z'),
              }}
              {...restProps}
            />
          )}
          value={startsAt}
        />
        {validationErrors['startsAt'] && (
          <FormHelperText error>{validationErrors['startsAt']}</FormHelperText>
        )}
      </FormControl>
    )
  }

  function renderEndsAt() {
    return (
      <FormControl fullWidth>
        <InputLabel
          className={classes.label}
          error={_.some(validationErrors['endsAt'])}
          htmlFor="ends-at"
        >
          Ends
        </InputLabel>
        <MobileDateTimePicker
          error={_.some(validationErrors['endsAt'])}
          inputFormat="ddd, MMM D h:mma z"
          minDate={moment(startsAt)}
          minutesStep={15}
          onChange={setEndsAt}
          OpenPickerButtonProps={{ color: 'primary' }}
          renderInput={({ inputProps, ...restProps }) => (
            <R5Input
              inputProps={{
                ...inputProps,
                value: moment(inputProps.value, 'ddd, MMM D h:mma z')
                  .tz(moment.tz.guess(true))
                  .format('ddd, MMM D h:mma z'),
              }}
              {...restProps}
            />
          )}
          value={endsAt}
        />
        {validationErrors['endsAt'] && (
          <FormHelperText error>{validationErrors['endsAt']}</FormHelperText>
        )}
      </FormControl>
    )
  }

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      {_.some(validationErrors) && (
        <Alert className="mb-lg" severity="error" variant="filled">
          One or more errors are present. Please fix them and try again.
        </Alert>
      )}
      <ToUserSelect
        fromUser={fromUser}
        setToUser={setToUser}
        toUser={toUser}
        validationErrors={validationErrors}
      />
      <FromUserSelect
        fromUser={fromUser}
        setFromUser={setFromUser}
        shifts={shifts}
        teamId={schedule.teamId}
        toUser={toUser}
        validationErrors={validationErrors}
      />
      <ShiftsSelect
        schedule={schedule}
        setShifts={setShifts}
        shifts={shifts}
        validationErrors={validationErrors}
      />
      <Card className={classes.card} variant="outlined">
        <CardHeader title={renderStartsAt()} />
        <Divider />
        <CardContent className={classes.content}>{renderEndsAt()}</CardContent>
      </Card>

      {renderFooter()}
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  label: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  margin: {
    marginTop: theme.spacing(3),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
}))

const CREATE_OVERRIDE = gql`
  mutation CreateOverride($input: CreateOverrideInput!) {
    createOverride(input: $input) {
      override {
        id
      }
    }
  }
`

const UPDATE_OVERRIDE = gql`
  mutation UpdateOverride($input: UpdateOverrideInput!) {
    updateOverride(input: $input) {
      override {
        id
      }
    }
  }
`

const DELETE_OVERRIDE = gql`
  mutation DeleteOverride($input: DeleteOverrideInput!) {
    deleteOverride(input: $input) {
      overrideId
    }
  }
`
