import * as Icons from '../../components/icons'

import { AlertDialog, R5Infotip } from '../../components/shared'
import { Box, Card, CardContent, List, Typography } from '@mui/material'
import React, { useState } from 'react'

import { IconButton } from '@mui/material'
import IncidentNotificationRuleItem from '../../components/Profile/IncidentNotificationRuleItem'
import { Link as RouterLink } from '@reach/router'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

export default function NotificationRules({ EmptyText, viewer }) {
  const classes = useStyles()
  const [showContactMethodDialog, setShowContactMethodDialog] = useState(false)

  const hasNoContactMethods = viewer.contactMethods.nodes.length === 0
  const hasNotificationRules = viewer.incidentNotificationRules.nodes.length > 0

  return (
    <Box className="mt-5">
      <Box className="flex flex-1 flex-row items-center justify-between">
        <Box className="flex items-center">
          <Typography className={classes.header} color="textSecondary">
            Notification Rules
          </Typography>
          <R5Infotip
            learnMore="https://www.readyfive.io/docs/users/notification-settings"
            title={
              <Typography paragraph>
                Configure your notification preferences so you're contacted the
                way you prefer. Notification rules are flexible and allow
                defining time-based steps for which method(s) to try first and
                which methods to fall back on.
              </Typography>
            }
          />
        </Box>
        {hasNoContactMethods ? (
          <>
            <IconButton
              onClick={() => setShowContactMethodDialog(true)}
              size="small"
            >
              <Icons.PlusCircleOutline color={styles.primary.color} size={35} />
            </IconButton>
            <AlertDialog
              cancelText="Ok"
              content="You need to add at least one contact method first"
              handleClose={() => setShowContactMethodDialog(false)}
              open={showContactMethodDialog}
              title="You need a contact method"
            />
          </>
        ) : (
          <IconButton
            component={RouterLink}
            size="small"
            to="/profile/rules/new"
          >
            <Icons.PlusCircleOutline color={styles.primary.color} size={35} />
          </IconButton>
        )}
      </Box>
      <List>
        {hasNotificationRules ? (
          viewer.incidentNotificationRules.nodes.map(
            (incidentNotificationRule) => (
              <IncidentNotificationRuleItem
                incidentNotificationRule={incidentNotificationRule}
                key={incidentNotificationRule.id}
              />
            )
          )
        ) : (
          <Card className={classes.card} variant="outlined">
            <CardContent className={classes.content}>
              <EmptyText text="You have no notification rules" />
            </CardContent>
          </Card>
        )}
      </List>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  header: {
    fontSize: 14,
    fontWeight: 900,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
}))
