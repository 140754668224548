import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'
import { ChevronLeft, ChevronRight, Loop } from '@mui/icons-material'
import React, { useState } from 'react'

import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'
import { coverageDateFormat } from '../../utils/dateFormat'
import { useCurrents } from '../../context/currents'

function coverageItemMatchesGroup(coverageItem, groupItem) {
  if (
    coverageItem.coverageType === groupItem.coverageType &&
    coverageItem.startsAt === groupItem.startsAt &&
    coverageItem.endsAt === groupItem.endsAt &&
    coverageItem.rotationStartsAt === groupItem.rotationStartsAt &&
    coverageItem.rotationEndsAt === groupItem.rotationEndsAt &&
    !coverageItem.override
  ) {
    return true
  }

  return false
}

// Takes a flat array of coverage items and returns them grouped in one or more
// arrays of coverage items, allowing the UI to group the same coverage period
// for multiple people in a single card element.
function groupedCoverageItemsForCoverage(coverage) {
  let groups = []

  _.forEach(coverage, (ci) => {
    let matchedExistingGroup = false
    _.forEach(groups, (gi, idx) => {
      if (coverageItemMatchesGroup(ci, gi[0])) {
        matchedExistingGroup = true
        groups[idx].push(ci)
        return false
      }
    })
    if (!matchedExistingGroup) groups.push([ci])
  })

  return groups
}

export default function ScheduleItem({ schedule, team }) {
  const classes = useStyles()

  function renderCurrent() {
    _.forEach(
      schedule.currentCoverage.onCall,
      (i) => (i.coverageType = 'ON_CALL')
    )
    _.forEach(
      schedule.currentCoverage.onDuty,
      (i) => (i.coverageType = 'ON_DUTY')
    )
    const sortedCoverage = _.sortBy(
      _.concat(
        schedule.currentCoverage.onCall,
        schedule.currentCoverage.onDuty
      ),
      'startsAt'
    )

    const coverageGroups = groupedCoverageItemsForCoverage(sortedCoverage)

    return (
      <Box>
        <Typography className={classes.subHeader}>Current Coverage</Typography>

        {coverageGroups.length === 0 ? (
          <Card className={classes.darkCard}>
            <CardContent className={classes.content}>
              <Typography className={classes.empty}>
                No one is currently on call or on duty.
              </Typography>
            </CardContent>
          </Card>
        ) : (
          coverageGroups.map((coverageGroup, index) => (
            <CoverageCard coverageGroup={coverageGroup} key={index} />
          ))
        )}
      </Box>
    )
  }

  function renderUpcoming() {
    _.forEach(
      schedule.upcomingCoverage.onCall,
      (i) => (i.coverageType = 'ON_CALL')
    )
    _.forEach(
      schedule.upcomingCoverage.onDuty,
      (i) => (i.coverageType = 'ON_DUTY')
    )
    const sortedCoverage = _.sortBy(
      _.concat(
        schedule.upcomingCoverage.onCall,
        schedule.upcomingCoverage.onDuty
      ),
      'startsAt'
    )

    const coverageGroups = groupedCoverageItemsForCoverage(sortedCoverage)

    return (
      <Box className="mt-xxl">
        <Typography className={classes.subHeader}>Upcoming Coverage</Typography>
        {sortedCoverage.length === 0 ? (
          <Card className={classes.darkCard}>
            <CardContent className={classes.content}>
              <Typography className={classes.empty}>
                There is no scheduled upcoming coverage.
              </Typography>
            </CardContent>
          </Card>
        ) : (
          coverageGroups.map((coverageGroup, index) => (
            <CoverageCard
              coverageGroup={coverageGroup}
              key={index}
              upcoming={true}
            />
          ))
        )}
      </Box>
    )
  }

  return (
    <Card className={classes.card} key={team.id} variant="outlined">
      <CardHeader
        action={
          <Button
            color="inherit"
            component={RouterLink}
            endIcon={<ChevronRight />}
            size="small"
            sx={{ marginRight: 1, marginTop: 0.5 }}
            to={`/teams/${team.id}/schedules/${schedule.id}`}
            variant="outlined"
          >
            View timeline &amp; details
          </Button>
        }
        classes={{ title: classes.title }}
        title={`${schedule.name} ${
          schedule.isDefault && schedule.name !== 'Default' ? '(default)' : ''
        }`}
      />
      <CardContent className={classes.content}>
        {renderCurrent()}
        {renderUpcoming()}
        <Typography
          className="flex justify-end"
          sx={{
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 600,
            marginRight: 1,
            marginTop: 1,
          }}
        >
          All times in {moment.tz(moment.tz.guess(true)).format('z')}
        </Typography>
      </CardContent>
    </Card>
  )
}

function CoverageCard({ coverageGroup }) {
  const classes = useStyles()
  const { userId } = useCurrents()
  const [othersExpandedView, setOthersExpandedView] = useState(false)

  // All data about the coverage item can be trusted from the first item in the
  // group array, except for the user. Actually, shift data cannot be trusted
  // as consistent either, but we don't currently show it so those get grouped.
  const coverageData = coverageGroup[0]

  // All users in the coverage item, with the current user listed first, if
  // the current user is a participant.
  const coverageUsers = _.sortBy(
    _.map(coverageGroup, (cg) => cg.user),
    (coverageUser) => {
      if (coverageUser.id === userId) {
        return ' ' // whitespace - very early in UTF-8, forces current user first
      }
      return coverageUser.name.toLowerCase()
    }
  )

  let firstUserDisplayName = coverageUsers[0].name
  if (userId === coverageUsers[0].id) {
    firstUserDisplayName = 'You'
  }

  let dropdownText = ''
  if (coverageUsers.length > 1) {
    const othersCount = coverageUsers.length - 1
    let othersWord = 'others'
    if (othersCount === 1) {
      othersWord = 'other'
    }
    dropdownText = `+ ${othersCount} ${othersWord}`
  }

  let coverageTypeText = 'On call'
  if (coverageData.coverageType === 'ON_DUTY') {
    coverageTypeText = 'On duty'
  }
  let rotationEndsText = ''
  if (coverageData.rotationEndsAt) {
    rotationEndsText = `Rotation ends ${coverageDateFormat(
      moment.unix(coverageData.rotationEndsAt)
    )}`
    if (coverageData.rotationEndsAt === coverageData.endsAt) {
      rotationEndsText = 'End of rotation'
    }
  }

  let avatarFauxClass = 'avatarFauxNone'
  if (coverageUsers.length === 2) {
    avatarFauxClass = 'avatarFauxOne'
  } else if (coverageUsers.length > 2) {
    avatarFauxClass = 'avatarFauxTwo'
  }

  return (
    <Card className={classes.darkCard}>
      <CardContent className={classes.content}>
        <Box className="flex flex-col md:flex-row justify-between items-center">
          <Box className="flex flex-row items-center relative md:mb-0 mb-4">
            <Box className={classes[avatarFauxClass]}>
              <Avatar
                className={classes.avatar}
                src={coverageUsers[0].avatarUrl}
              >
                {coverageUsers[0].initials}
              </Avatar>
            </Box>

            <Box className="flex flex-col nowrap ml-3">
              <Box className="flex flex-row items-center">
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600, marginLeft: 1 }}
                >
                  {firstUserDisplayName}
                </Typography>
                {dropdownText !== '' && (
                  <Button
                    onClick={() => setOthersExpandedView((prev) => !prev)}
                    type="button"
                    variant="text"
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: 600,
                        textDecoration: 'underline',
                        color: '#fff',
                      }}
                    >
                      {dropdownText}
                    </Typography>
                  </Button>
                )}
                {coverageData.override && (
                  <>
                    <ChevronLeft
                      sx={{
                        color: styles.primaryLight.color,
                        marginLeft: 1,
                        marginRight: 1,
                      }}
                      alt="covering for"
                    />
                    <Typography
                      sx={{
                        color: styles.primaryLight.color,
                        fontSize: 14,
                        fontWeight: 600,
                        textDecoration: 'line-through',
                      }}
                    >
                      {coverageData.override.fromUser?.name || 'Everyone'}
                    </Typography>
                  </>
                )}
              </Box>

              <Typography
                sx={{
                  color: styles.primaryLight.color,
                  fontSize: 12,
                  fontWeight: 700,
                  marginLeft: 1,
                  textTransform: 'uppercase',
                }}
              >
                {coverageTypeText}
              </Typography>
            </Box>
          </Box>
          <Box className="flex flex-col">
            <Box className="flex flex-row">
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                {coverageDateFormat(moment.unix(coverageData.startsAt))}
              </Typography>
              <ChevronRight
                alt="until"
                sx={{ marginLeft: 1.5, marginRight: 1.5 }}
              />

              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                {coverageDateFormat(moment.unix(coverageData.endsAt))}
              </Typography>
            </Box>

            {coverageData.rotationEndsAt && (
              <Typography
                className="text-right"
                sx={{
                  color: styles.primaryLight.color,
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                <Loop
                  style={{
                    fontSize: 14,
                    verticalAlign: 'middle',
                    display: 'inline-flex',
                  }}
                />{' '}
                {rotationEndsText}
              </Typography>
            )}
          </Box>
        </Box>
        {othersExpandedView && (
          <Box className="flex flex-row items-center flex-wrap">
            {coverageUsers.slice(1).map((coverageUser) => (
              <Box className={classes.avatarPill} key={coverageUser.id}>
                <Avatar
                  className={classes.avatar}
                  src={coverageUser.avatarUrl}
                  key={coverageUser.id}
                >
                  {coverageUser.initials}
                </Avatar>
                <Typography
                  sx={{ fontSize: 14, fontWeight: 600, marginLeft: 1 }}
                >
                  {coverageUser.name}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#fff',
    color: styles.primary.color,
    fontSize: 14,
    fontWeight: 'bold',
    height: 32,
    width: 32,
    zIndex: 4,
    boxShadow: '0px 0px 0px 4px  #3B2FB8',
  },
  avatarFauxNone: {
    position: 'relative',
  },
  avatarFauxOne: {
    position: 'relative',
    '&::before': {
      content: 'no-open-quote',
      position: 'absolute',
      backgroundColor: '#A39BF2',
      border: 'solid 4px #3B2FB8',
      width: '34px',
      height: '34px',
      zIndex: 2,
      display: 'block',
      borderRadius: '100%',
      left: '10px',
    },
  },
  avatarFauxTwo: {
    position: 'relative',
    '&::before': {
      content: 'no-open-quote',
      position: 'absolute',
      backgroundColor: '#A39BF2',
      border: 'solid 4px #3B2FB8',
      width: '34px',
      height: '34px',
      zIndex: 2,
      display: 'block',
      borderRadius: '100%',
      left: '10px',
    },
    '&::after': {
      content: 'no-open-quote',
      position: 'absolute',
      backgroundColor: '#A39BF2',
      border: 'solid 4px #3B2FB8',
      width: '34px',
      height: '34px',
      zIndex: 1,
      display: 'block',
      borderRadius: '100%',
      left: '16px',
      top: '0',
    },
  },
  card: {
    backgroundColor: styles.r5Purple.color,
    borderRadius: 16,
    color: '#fff',
    marginBottom: theme.spacing(3),
  },
  darkCard: {
    backgroundColor: '#392BC8',
    color: '#fff',
    marginTop: theme.spacing(2),
  },
  avatarPill: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '30px',
    alignItems: 'center',
    padding: '8px 10px',
    marginRight: '1em',
    marginTop: '1em',
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  subHeader: {
    color: styles.primaryLight.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '0.18em',
    textTransform: 'uppercase',
  },
  empty: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
}))
