import { R5Error, Loader } from '../shared'
import { ListItem, Typography } from '@mui/material'
import { useCallback, useEffect } from 'react'
import PersonalAccessTokens from './PersonalAccessTokens'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'

export default function Security() {
  const { data, errors, loading, refetch } = useQuery(QUERY)

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return <SecurityLoaded refetch={refetch} viewer={data.viewer} />
}

function SecurityLoaded({ refetch, viewer }) {
  const { accountId, subscribe } = useCurrents()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: PERSONAL_ACCESS_TOKEN_CREATE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: PERSONAL_ACCESS_TOKEN_UPDATE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: PERSONAL_ACCESS_TOKEN_DELETE_SUBSCRIPTION,
      variables: {
        accountId,
        userId: viewer.id,
      },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe, viewer])

  return <PersonalAccessTokens EmptyText={EmptyText} viewer={viewer} />
}

function EmptyText({ text }) {
  const classes = useStyles()

  return (
    <ListItem dense disableGutters>
      <Typography className={classes.cardEmpty} color="textSecondary">
        {text}
      </Typography>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  cardEmpty: {
    fontSize: 14,
  },
}))

const QUERY = gql`
  query Security {
    viewer {
      id
      name
      initials
      accountId
      personalAccessTokens(first: 30) {
        nodes {
          id
          accountId
          userId
          description
          accessToken
          createdAt
          scopes {
            accountRead
            escalationPoliciesRead
            escalationPoliciesWrite
            fileUploadsWrite
            incidentsRead
            incidentsWrite
            incidentEventsRead
            integrationsRead
            integrationsWrite
            integrationAlertsRead
            invitationsRead
            invitationsWrite
            membershipsRead
            membershipsWrite
            overridesRead
            overridesWrite
            schedulesRead
            schedulesWrite
            shiftsRead
            shiftsWrite
            teamsRead
            teamsWrite
            usersRead
            usersWrite
            viewerUserRead
            viewerUserWrite
            viewerContactMethodsRead
            viewerContactMethodsWrite
            viewerIncidentNotificationRulesRead
            viewerIncidentNotificationRulesWrite
            viewerInvitationsRead
          }
          viewerCanAdminister
        }
      }
    }
  }
`

const PERSONAL_ACCESS_TOKEN_CREATE_SUBSCRIPTION = gql`
  subscription onPersonalAccessTokenCreated($accountId: ID!, $userId: ID!) {
    personalAccessTokenCreated(accountId: $accountId, userId: $userId) {
      id
    }
  }
`

const PERSONAL_ACCESS_TOKEN_UPDATE_SUBSCRIPTION = gql`
  subscription onPersonalAccessTokenUpdated($accountId: ID!, $userId: ID!) {
    personalAccessTokenUpdated(accountId: $accountId, userId: $userId) {
      id
      accountId
      userId
      description
      accessToken
      createdAt
      scopes {
        accountRead
        escalationPoliciesRead
        escalationPoliciesWrite
        fileUploadsWrite
        incidentsRead
        incidentsWrite
        incidentEventsRead
        integrationsRead
        integrationsWrite
        integrationAlertsRead
        invitationsRead
        invitationsWrite
        membershipsRead
        membershipsWrite
        overridesRead
        overridesWrite
        schedulesRead
        schedulesWrite
        shiftsRead
        shiftsWrite
        teamsRead
        teamsWrite
        usersRead
        usersWrite
        viewerUserRead
        viewerUserWrite
        viewerContactMethodsRead
        viewerContactMethodsWrite
        viewerIncidentNotificationRulesRead
        viewerIncidentNotificationRulesWrite
        viewerInvitationsRead
      }
      viewerCanAdminister
    }
  }
`

const PERSONAL_ACCESS_TOKEN_DELETE_SUBSCRIPTION = gql`
  subscription onPersonalAccessTokenDeleted($accountId: ID!, $userId: ID!) {
    personalAccessTokenDeleted(accountId: $accountId, userId: $userId) {
      id
    }
  }
`
