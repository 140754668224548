import React from 'react'

export default function Close({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41424 3L3.00003 4.41421L8.65688 10.0711L3 15.7279L4.41421 17.1421L10.0711 11.4853L15.728 17.1421L17.1422 15.7279L11.4853 10.0711L17.1421 4.41423L15.7279 3.00001L10.0711 8.65685L4.41424 3Z"
        fill={color}
      />
    </svg>
  )
}
