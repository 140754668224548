import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'

import { Notifications } from '@mui/icons-material'
import React, { Fragment } from 'react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useNavigate } from '@reach/router'
import moment from 'moment-timezone'

export default function ContactMethodItem({ contactMethods, type }) {
  const classes = useStyles()

  const typeOptions = [
    { label: 'Email', value: 'EMAIL' },
    { label: 'Phone (SMS)', value: 'SMS' },
    { label: 'Phone (voice) ', value: 'VOICE' },
    { label: 'Push', value: 'PUSH' },
    { label: 'Slack', value: 'SLACK' },
  ]

  return (
    <Card className={classes.card} key={type} variant="outlined">
      <CardHeader
        title={_.find(typeOptions, { value: type })?.label || type}
        titleTypographyProps={{
          className: classes.cardTitle,
          color: 'textSecondary',
        }}
      />
      <Divider />
      <CardContent className={classes.content}>
        <List disablePadding>
          {contactMethods.map((contactMethod) => (
            <ContactMethod
              contactMethod={contactMethod}
              key={contactMethod.id}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

function ContactMethod({ contactMethod }) {
  const classes = useStyles()
  const navigate = useNavigate()

  async function handleRequestVerification() {
    navigate(`/profile/contactMethods/${contactMethod.id}/verify`)
  }

  const { onCallEnded, onCallStarted, onDutyEnded, onDutyStarted } =
    contactMethod.coverageChangeNotifications
  const onCallText =
    onCallEnded && onCallStarted
      ? 'On call starts/ends'
      : onCallEnded
      ? 'On call ends'
      : onCallStarted
      ? 'On call starts'
      : null

  const onDutyText =
    onDutyEnded && onDutyStarted
      ? 'On duty starts/ends'
      : onDutyEnded
      ? 'On duty ends'
      : onDutyStarted
      ? 'On duty starts'
      : null

  const coverageNotificationText =
    onCallText && onDutyText
      ? onCallText + ', ' + onDutyText.toLowerCase()
      : onCallText
      ? onCallText
      : onDutyText
      ? onDutyText
      : null

  let secondary = (
    <Typography className={classes.secondary} component="span" key="secondary">
      {`Added ${moment.unix(contactMethod.createdAt).format('MMM D, Y')}`}
    </Typography>
  )

  let secondaryNotifications
  if (coverageNotificationText) {
    secondaryNotifications = (
      <Fragment key="secondaryNotifications">
        <Typography
          className={classes.secondary}
          component="span"
          style={{ marginLeft: 6, marginRight: 4 }}
        >
          ·
        </Typography>
        <Notifications
          color="inherit"
          style={{ fontSize: 18, verticalAlign: 'top', marginTop: 2 }}
        />
        <Typography className={classes.secondary} component="span">
          {coverageNotificationText}
        </Typography>
      </Fragment>
    )
  }

  const needsVerification =
    contactMethod.type !== 'PUSH' && contactMethod.verifiedAt === null

  const formattedTo =
    contactMethod.type === 'SMS' || contactMethod.type === 'VOICE'
      ? formatPhoneNumber(contactMethod.to)
      : contactMethod.to

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (!match) return

    return `${match[1] ? '+1' : ''} (${match[2]}) ${match[3]}-${match[4]}`
  }

  return (
    <ListItem
      className={
        needsVerification || !coverageNotificationText ? classes.height : null
      }
      button={!needsVerification}
      onClick={
        needsVerification
          ? null
          : () => navigate(`/profile/contactMethods/${contactMethod.id}/edit`)
      }
    >
      <ListItemText
        classes={{ primary: classes.primary }}
        primary={`${formattedTo}${
          !!contactMethod.description ? ` (${contactMethod.description})` : ''
        }`}
        secondary={[secondary, secondaryNotifications]}
        secondaryTypographyProps={{ component: 'span' }}
      />
      <Box className="flex items-center">
        {needsVerification ? (
          <Button onClick={handleRequestVerification}>Verify</Button>
        ) : (
          <Icons.ChevronRight color={styles.lightLabel.color} size={20} />
        )}
      </Box>
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 16,
    marginBottom: theme.spacing(3),
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
  header: {
    fontWeight: 'bold',
  },
  height: {
    height: 60,
  },
  primary: {
    fontSize: 14,
    fontWeight: 'bold',
    wordBreak: 'break-all',
  },
  secondary: {
    color: styles.lightLabel.color,
    fontSize: 14,
    wordBreak: 'break-all',
  },
}))
