import React from 'react'

export default function CheckCircle({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM8.11975 9.38021L9.41975 10.6802L12.2665 7.83343L13.1665 8.73339L9.41975 12.4801L7.21979 10.2802L8.11975 9.38021Z"
        fill={color}
      />
    </svg>
  )
}
