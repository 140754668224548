import {
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material'
import { R5Input, R5Title } from '../../components/shared'
import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { Auth } from 'aws-amplify'
import { Logo, SignInBack, SignInFront } from '../../components/illustrations'
import { Link as RouterLink } from '@reach/router'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'

const shortPasswordError = 'Member must have length greater'
const passwordSpacesError = 'Member must satisfy regular expression'
const passwordLowerCaseError = 'Password must have lowercase characters'
const passwordUpperCaseError = 'Password must have uppercase characters'
const passwordNumericError = 'Password must have numeric characters'

export default function ForgotConfirmPage({ location, navigate }) {
  const classes = useStyles()
  const urlParams = new URLSearchParams(location.search)
  const email = urlParams.get('email')

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [code, setCode] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()

    setError(null)
    setLoading(true)

    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      setLoading(false)
      navigate(
        '/?info=Password changed successfully. You can login with it now.'
      )
    } catch (err) {
      setLoading(false)

      if (err.message.includes(shortPasswordError)) {
        setError('Password is too short')
      } else if (err.message.includes(passwordSpacesError)) {
        setError('Password cannot contain spaces')
      } else if (
        err.message.includes(passwordLowerCaseError) ||
        err.message.includes(passwordUpperCaseError) ||
        err.message.includes(passwordNumericError)
      ) {
        setError(
          'Password must have at least one uppercase, lowercase, and numeric character'
        )
      } else {
        setError(err.message)
      }
    }
  }

  async function handleResend() {
    setResendLoading(true)
    try {
      await Auth.forgotPassword(email)
      setResendLoading(false)
      setError(`Reset code resent to ${email}`)
    } catch (err) {
      setResendLoading(false)
      setError(err.message)
    }
  }

  function handlePasswordCompare() {
    if (_.isEqual(password, passwordConfirm)) {
      if (error === 'Passwords do not match') setError(null)
    } else {
      setError('Passwords do not match')
    }
  }

  return (
    <Container className="flex w-full items-center h-full justify-between p-0 max-w-[100%] min-h-[100vh]">
      <R5Title title="Set a new password" />
      <Box className={classes.imgCol}>
        <img
          className={classes.frontImg}
          src={SignInFront}
          alt="Illustration of a phone with R5 UI on the screen."
        />
        <img
          className={classes.backImg}
          src={SignInBack}
          alt="Background illustration of a futuristic city."
        />
      </Box>
      <Box style={{ margin: '0 auto', maxWidth: '400px' }}>
        <CardContent className={classes.content}>
          <Box>
            <Logo
              className={classes.logo}
              color={styles.primary.color}
              size={65}
            />
            <Typography className={classes.title}>Set Password</Typography>
            <Typography className={classes.subtitle}>
              We've emailed you a 6 digit code for you to validate ownership of
              your email address
            </Typography>
            {error && (
              <Typography className={classes.error}>{error}</Typography>
            )}
          </Box>
          <form onSubmit={handleSubmit}>
            <R5Input
              autoFocus
              onChange={setCode}
              error={error?.includes('code')}
              name="code"
              placeholder="Confirmation code"
              value={code}
              fullWidth
            />
            <R5Input
              autoComplete="new-password"
              className="mt-lg"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowPassword((prevShowPassword) => !prevShowPassword)
                    }
                    size="small"
                    tabIndex={-1}
                    title={showPassword ? 'Hide Password' : 'Show Password'}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              error={error?.includes('Password')}
              fullWidth
              name="password"
              onChange={setPassword}
              placeholder="Set a new password"
              type={showPassword ? 'text' : 'password'}
              value={password}
            />
            <R5Input
              autoComplete="new-password"
              className="mt-lg"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowPasswordConfirm(
                        (prevShowPasswordConfirm) => !prevShowPasswordConfirm
                      )
                    }
                    size="small"
                    tabIndex={-1}
                    title={
                      showPasswordConfirm ? 'Hide Password' : 'Show Password'
                    }
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              error={error?.includes('Password')}
              fullWidth
              name="password-confirm"
              onBlur={handlePasswordCompare}
              onChange={setPasswordConfirm}
              placeholder="Confirm new password"
              type={showPasswordConfirm ? 'text' : 'password'}
              value={passwordConfirm}
            />
            <Button
              className={classes.link}
              disabled={resendLoading}
              onClick={handleResend}
            >
              {resendLoading ? (
                <CircularProgress color="inherit" size={21} />
              ) : (
                'Resend Confirmation Code'
              )}
            </Button>
            <Button
              classes={{
                disabled: classes.disabled,
                root: classes.button,
              }}
              disabled={
                loading ||
                _.isEmpty(password) ||
                _.isEmpty(code) ||
                !_.isEqual(password, passwordConfirm)
              }
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {loading ? (
                <CircularProgress color="inherit" size={21} />
              ) : (
                'Set New Password'
              )}
            </Button>
          </form>
          <CardActions className="justify-center">
            <Link
              className={classes.link}
              color="primary"
              component={RouterLink}
              to="/login"
              underline="hover"
            >
              Back to sign in
            </Link>
          </CardActions>
        </CardContent>
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(1),
    fontSize: 12,
    fontWeight: 800,
    marginTop: theme.spacing(3),
    textTransform: 'uppercase',
  },
  card: {
    borderRadius: theme.spacing(1),
    minHeight: 556,
  },
  disabled: {
    backgroundColor: `${styles.primaryLight.color} !important`,
    color: 'white !important',
  },
  error: {
    backgroundColor: styles.error.backgroundColor,
    borderColor: styles.error.color,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    color: 'white',
    marginTop: 20,
    padding: 12,
    width: '100%',
  },
  link: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(1),
    textDecoration: 'underline',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 50,
    fontWeight: 600,
    lineHeight: '107.9%',
    marginBottom: theme.spacing(2),
    textAlign: 'left',
  },
  imgCol: {
    height: '100vh',
    width: '50%',
    backgroundColor: '#4147EB',
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
    '@media (max-width: 925px)': {
      display: 'none',
    },
  },
  backImg: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: '0',
  },
  frontImg: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '-350px',
    top: '50%',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: '60%',
    minWidth: '580px',
    display: 'block',
  },
}))
