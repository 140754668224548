/** @jsxImportSource @emotion/react */

import * as Icons from '../../components/icons'

import { ActivityIndicator, R5Error } from '../../components/shared'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import { useCallback, useEffect } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'
import { css } from '@emotion/react'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useQuery } from '../../hooks'

export default function UserList({
  handleSelect,
  search,
  selectedValues,
  setFetching,
  withNobody = true,
}) {
  const classes = useStyles()
  const { data, errors, fetchMore, fetchingMore, loading } =
    useQuery(USERS_QUERY)

  function handleNext() {
    if (fetchingMore) return
    if (!data.users.pageInfo.hasNextPage) return

    fetchMore({
      variables: {
        cursor: data.users.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult.users.nodes.length
          ? {
              users: {
                __typename: previousResult.users.__typename,
                nodes: [
                  ...previousResult.users.nodes,
                  ...fetchMoreResult.users.nodes,
                ],
                pageInfo: fetchMoreResult.users.pageInfo,
              },
            }
          : previousResult
      },
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleNextDebounced = useCallback(_.debounce(handleNext, 500), [
    data.users?.pageInfo?.hasNextPage,
    fetchingMore,
  ])

  useEffect(() => {
    setFetching(fetchingMore)
  }, [fetchingMore, setFetching])

  useEffect(() => {
    if (!data.users) return
    if (search.length > 0) handleNextDebounced()
  }, [data.users, handleNextDebounced, search.length])

  if (loading) return <ActivityIndicator className="m-md" size={30} />
  if (errors) return <R5Error errors={errors} />

  let _users = withNobody
    ? [{ id: 'nobody', initials: 'NB', name: 'Nobody' }, ...data.users.nodes]
    : data.users.nodes

  const selectedUsers = _users.filter(
    (user) =>
      user.name.toLowerCase().includes(search.toLowerCase()) ||
      selectedValues.find((value) => value.id === user.id)
  )

  return (
    <div className="flex" css={stylesheet}>
      <InfiniteScroll
        className="scroll-shadows"
        dataLength={selectedUsers.length}
        hasChildren={_.some(selectedUsers)}
        hasMore={data.users.pageInfo.hasNextPage}
        height={300}
        next={handleNextDebounced}
      >
        <List dense className={classes.list}>
          {_.some(selectedUsers)
            ? selectedUsers.map((user, index) => (
                <ListItem
                  button
                  className={classes.li}
                  divider={index === selectedUsers.length - 1 ? false : true}
                  key={user.id}
                  onClick={() => handleSelect(user)}
                >
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar className={classes.avatar} src={user.avatarUrl}>
                      {user.initials}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>{user.name}</ListItemText>
                  <ListItemSecondaryAction>
                    {selectedValues.find((value) => value.id === user.id) ? (
                      <Icons.CheckCircle color="#6dd76b" size={32} />
                    ) : null}
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            : !data.users.pageInfo.hasNextPage && (
                <ListItem button className={classes.li}>
                  <ListItemText>No users exist with that name</ListItemText>
                </ListItem>
              )}
        </List>
      </InfiniteScroll>
    </div>
  )
}

const stylesheet = css`
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
  .scroll-shadows {
    background:
      /* Shadow Cover TOP */ linear-gradient(
          hsla(200 1% 98% / 1) 30%,
          rgba(255, 255, 255, 0)
        )
        center top,
      /* Shadow Cover BOTTOM */
        linear-gradient(rgba(255, 255, 255, 0), hsla(200 1% 98% / 1) 70%) center
        bottom,
      /* Shadow TOP */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0)
        )
        center top,
      /* Shadow BOTTOM */
        radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0)
        )
        center bottom;

    background-repeat: no-repeat;
    background-size: 100% 20px, 100% 20px, 100% 8px, 100% 8px;
    background-attachment: local, local, scroll, scroll;
  }
`

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: styles.primary.color,
    fontSize: 12,
    fontWeight: 800,
    height: 32,
    width: 32,
  },
  li: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(82, 89, 255, 0.1)',
      borderRadius: 8,
    },
  },
  list: {
    height: 300,
  },
  listItemAvatar: {
    minWidth: theme.spacing(5),
  },
}))

const USERS_QUERY = gql`
  query UserList($cursor: String) {
    users(after: $cursor, first: 30) {
      nodes {
        id
        name
        initials
        role
        avatarUrl
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
