import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { ConfirmButton, R5TextField } from '../shared'
import React, { useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'

import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useJunkDrawer } from '../../context/junkDrawer'
import { useMutation } from '../../hooks'

export default function PersonalAccessTokenForm({
  personalAccessToken,
  userId,
}) {
  const classes = useStyles()
  const { updateJunkDrawer } = useJunkDrawer()

  const navigate = useNavigate()
  const personalAccessTokenMutation = useMutation(
    personalAccessToken
      ? UPDATE_PERSONAL_ACCESS_TOKEN
      : CREATE_PERSONAL_ACCESS_TOKEN
  )
  const deleteMutation = useMutation(DELETE_PERSONAL_ACCESS_TOKEN)
  const regenerateMutation = useMutation(REGENERATE_PERSONAL_ACCESS_TOKEN)
  const [description, setDescription] = useState(
    personalAccessToken ? personalAccessToken.description : ''
  )
  const [scopes, setScopes] = useState(
    personalAccessToken
      ? personalAccessToken.scopes
      : {
          accountRead: false,
          escalationPoliciesRead: false,
          escalationPoliciesWrite: false,
          fileUploadsWrite: false,
          incidentsRead: false,
          incidentsWrite: false,
          incidentEventsRead: false,
          integrationsRead: false,
          integrationsWrite: false,
          integrationAlertsRead: false,
          invitationsRead: false,
          invitationsWrite: false,
          membershipsRead: false,
          membershipsWrite: false,
          overridesRead: false,
          overridesWrite: false,
          schedulesRead: false,
          schedulesWrite: false,
          shiftsRead: false,
          shiftsWrite: false,
          teamsRead: false,
          teamsWrite: false,
          usersRead: false,
          usersWrite: false,
          viewerUserRead: false,
          viewerUserWrite: false,
          viewerContactMethodsRead: false,
          viewerContactMethodsWrite: false,
          viewerIncidentNotificationRulesRead: false,
          viewerIncidentNotificationRulesWrite: false,
          viewerInvitationsRead: false,
        }
  )

  const [validationErrors, setValidationErrors] = useState([])

  const [loading, setLoading] = useState(false)

  async function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)

    try {
      const response = await personalAccessTokenMutation({
        userId,
        personalAccessTokenId: personalAccessToken?.id,
        description,
        scopes,
      })

      if (response) {
        setLoading(false)
        if (response.data.createPersonalAccessToken) {
          updateJunkDrawer('personalAccessToken', {
            id: response.data.createPersonalAccessToken.personalAccessToken.id,
            accessToken:
              response.data.createPersonalAccessToken.personalAccessToken
                .accessToken,
          })
        }
        navigate('/profile/security')
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)

    try {
      await deleteMutation({
        userId,
        personalAccessTokenId: personalAccessToken.id,
      })
      setLoading(false)
      navigate('/profile/security')
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  async function handleRegenerate() {
    try {
      const response = await regenerateMutation({
        userId,
        personalAccessTokenId: personalAccessToken?.id,
      })

      if (response) {
        setLoading(false)
        if (response.data.regeneratePersonalAccessToken) {
          updateJunkDrawer('personalAccessToken', {
            id: response.data.regeneratePersonalAccessToken.personalAccessToken
              .id,
            accessToken:
              response.data.regeneratePersonalAccessToken.personalAccessToken
                .accessToken,
          })
        }
        navigate('/profile/security')
      }
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  function handleChangeScope(scope, checked) {
    setScopes((prevScopes) => {
      const newScopes = { ...prevScopes }
      newScopes[scope] = checked
      return newScopes
    })
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-xxl ${
          personalAccessToken ? 'justify-between' : 'justify-end'
        }`}
      >
        {personalAccessToken && (
          <ConfirmButton
            confirmContent="Any applications or scripts using this token will no longer be able to access the Ready Five API. You cannot undo this action."
            confirmText="I understand, delete this token"
            error
            onConfirm={handleDelete}
            title="Are you sure you want to delete this token?"
          />
        )}

        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to="/profile/security"
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {personalAccessToken ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  const [readScopes, writeScopes] = _.reduce(
    scopes,
    (accu, value, key) => {
      key.includes('Read') ? (accu[0][key] = value) : (accu[1][key] = value)
      return accu
    },
    [{}, {}]
  )
  const allReadUnChecked = _.every(readScopes, (scope) => !scope)
  const allWriteUnChecked = _.every(writeScopes, (scope) => !scope)
  const readIndeterminate = _.uniq(Object.values(readScopes)).length === 2
  const writeIndeterminate = _.uniq(Object.values(writeScopes)).length === 2

  function handleSelectAllRead() {
    if (readIndeterminate || allReadUnChecked) {
      setScopes((prevScopes) =>
        _.reduce(
          prevScopes,
          (accu, _value, scope) => {
            scope.includes('Read')
              ? (accu[scope] = true)
              : (accu[scope] = prevScopes[scope])
            return accu
          },
          {}
        )
      )
    } else {
      setScopes((prevScopes) =>
        _.reduce(
          prevScopes,
          (accu, _value, scope) => {
            scope.includes('Read')
              ? (accu[scope] = false)
              : (accu[scope] = prevScopes[scope])
            return accu
          },
          {}
        )
      )
    }
  }

  function handleSelectAllWrite() {
    if (writeIndeterminate || allWriteUnChecked) {
      setScopes((prevScopes) =>
        _.reduce(
          prevScopes,
          (accu, _value, scope) => {
            scope.includes('Write')
              ? (accu[scope] = true)
              : (accu[scope] = prevScopes[scope])
            return accu
          },
          {}
        )
      )
    } else {
      setScopes((prevScopes) =>
        _.reduce(
          prevScopes,
          (accu, _value, scope) => {
            scope.includes('Write')
              ? (accu[scope] = false)
              : (accu[scope] = prevScopes[scope])

            return accu
          },
          {}
        )
      )
    }
  }

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      {personalAccessToken && (
        <Box className="flex justify-end">
          <ConfirmButton
            color="primary"
            confirmContent="Any applications or scripts using the current token will no longer be able to access the Ready Five API. You cannot undo this action."
            confirmText="I understand, regenerate this token"
            onConfirm={handleRegenerate}
            title="Are you sure you want to regenerate this token?"
            variant="outlined"
          >
            Regenerate Token
          </ConfirmButton>
        </Box>
      )}
      <R5TextField
        error={_.some(validationErrors['description'])}
        label="Description"
        onChange={setDescription}
        placeholder="What is this token used for?"
        required
        value={description}
      />
      <Card className={classes.card} variant="outlined">
        <CardHeader
          title={
            <Box className="flex flex-1 flex-row justify-between items-center">
              <Typography className={classes.cardTitle} color="textSecondary">
                Select Scopes
              </Typography>
              <Box>
                <FormControl>
                  <FormControlLabel
                    classes={{
                      root: classes.noMargin,
                      label: classes.checkLabel,
                    }}
                    control={
                      <Checkbox
                        checked={_.some(readScopes) && _.every(readScopes)}
                        color="primary"
                        onChange={handleSelectAllRead}
                        name="select-all-read"
                        indeterminate={readIndeterminate}
                      />
                    }
                    label="read"
                    labelPlacement="top"
                  />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    classes={{
                      root: classes.noMargin,
                      label: classes.checkLabel,
                    }}
                    control={
                      <Checkbox
                        checked={_.some(writeScopes) && _.every(writeScopes)}
                        color="primary"
                        onChange={handleSelectAllWrite}
                        name="select-all-write"
                        indeterminate={writeIndeterminate}
                      />
                    }
                    label="write"
                    labelPlacement="top"
                  />
                </FormControl>
              </Box>
            </Box>
          }
        />
        <CardContent className={classes.content}>
          <List disablePadding>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.sectionLabel }}
                primary="Account"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.accountRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('accountRead', checked)
                        }
                        name="account-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="invisible ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={<Checkbox color="primary" name="account-space" />}
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="File Uploads"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.fileUploadsWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('fileUploadsWrite', checked)
                        }
                        name="file-uploads-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.sectionLabel }}
                primary="Teams"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.teamsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('teamsRead', checked)
                        }
                        name="teams-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.teamsWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('teamsWrite', checked)
                        }
                        name="teams-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Escalation Policies"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.escalationPoliciesRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('escalationPoliciesRead', checked)
                        }
                        name="escalation-policies-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.escalationPoliciesWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('escalationPoliciesWrite', checked)
                        }
                        name="escalation-policies-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Integrations"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.integrationsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('integrationsRead', checked)
                        }
                        name="integrations-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.integrationsWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('integrationsWrite', checked)
                        }
                        name="integrations-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Integration Alerts"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.integrationAlertsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('integrationAlertsRead', checked)
                        }
                        name="integration-alerts-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="invisible ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        color="primary"
                        name="integration-alerts-space"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Memberships"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.membershipsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('membershipsRead', checked)
                        }
                        name="memberships-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.membershipsWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('membershipsWrite', checked)
                        }
                        name="memberships-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Overrides"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.overridesRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('overridesRead', checked)
                        }
                        name="overrides-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.overridesWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('overridesWrite', checked)
                        }
                        name="overrides-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Schedules"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.schedulesRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('schedulesRead', checked)
                        }
                        name="schedules-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.schedulesWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('schedulesWrite', checked)
                        }
                        name="schedules-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Shifts"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.shiftsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('shiftsRead', checked)
                        }
                        name="shifts-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.shiftsWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('shiftsWrite', checked)
                        }
                        name="shifts-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.sectionLabel }}
                primary="Incidents"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.incidentsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('incidentsRead', checked)
                        }
                        name="incidents-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.incidentsWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('incidentsWrite', checked)
                        }
                        name="incidents-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Incident Events"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.incidentEventsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('incidentEventsRead', checked)
                        }
                        name="incident-events-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="invisible ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox color="primary" name="incident-events-space" />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.sectionLabel }}
                primary="Invitations"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.invitationsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('invitationsRead', checked)
                        }
                        name="invitations-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.invitationsWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('invitationsWrite', checked)
                        }
                        name="invitations-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.sectionLabel }}
                primary="Users"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.usersRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('usersRead', checked)
                        }
                        name="users-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.usersWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('usersWrite', checked)
                        }
                        name="users-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <Divider className={classes.divider} />
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.sectionLabel }}
                primary="Viewer User (Me)"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.viewerUserRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('viewerUserRead', checked)
                        }
                        name="viewer-user-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.viewerUserWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('viewerUserWrite', checked)
                        }
                        name="viewer-user-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Contact Methods"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.viewerContactMethodsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('viewerContactMethodsRead', checked)
                        }
                        name="viewer-contact-methods-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.viewerContactMethodsWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope(
                            'viewerContactMethodsWrite',
                            checked
                          )
                        }
                        name="viewer-contact-methods-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Notification Rules"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.viewerIncidentNotificationRulesRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope(
                            'viewerIncidentNotificationRulesRead',
                            checked
                          )
                        }
                        name="viewer-incident-notification-rules-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.viewerIncidentNotificationRulesWrite}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope(
                            'viewerIncidentNotificationRulesWrite',
                            checked
                          )
                        }
                        name="viewer-incident-notification-rules-write"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
            <ListItem className={classes.li}>
              <ListItemText
                classes={{ primary: classes.label }}
                className="ml-md"
                primary="Invitations"
              />
              <ListItemIcon className={classes.secondaryAction}>
                <FormControl>
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        checked={scopes.viewerInvitationsRead}
                        color="primary"
                        onChange={({ target: { checked } }) =>
                          handleChangeScope('viewerInvitationsRead', checked)
                        }
                        name="viewer-invitations-read"
                      />
                    }
                    label=""
                  />
                </FormControl>
                <FormControl className="invisible ml-md">
                  <FormControlLabel
                    classes={{ label: classes.checkLabel }}
                    control={
                      <Checkbox
                        color="primary"
                        name="viewer-invitations-space"
                      />
                    }
                    label=""
                  />
                </FormControl>
              </ListItemIcon>
            </ListItem>
          </List>
        </CardContent>
      </Card>

      {renderFooter()}
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  checkLabel: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  content: {
    paddingTop: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  label: {
    fontSize: 14,
    letterSpacing: 1,
  },
  li: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      backgroundColor: 'rgba(82, 89, 255, 0.1)',
      borderRadius: 8,
    },
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  noMargin: {
    marginRight: 0,
  },
  secondaryAction: {
    marginRight: theme.spacing(-3.5),
  },
  sectionLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
  },
}))

const CREATE_PERSONAL_ACCESS_TOKEN = gql`
  mutation CreatePersonalAccessToken($input: CreatePersonalAccessTokenInput!) {
    createPersonalAccessToken(input: $input) {
      personalAccessToken {
        id
        description
        accessToken
      }
    }
  }
`

const REGENERATE_PERSONAL_ACCESS_TOKEN = gql`
  mutation RegeneratePersonalAccessToken(
    $input: RegeneratePersonalAccessTokenInput!
  ) {
    regeneratePersonalAccessToken(input: $input) {
      personalAccessToken {
        id
        accessToken
      }
    }
  }
`

const UPDATE_PERSONAL_ACCESS_TOKEN = gql`
  mutation UpdatePersonalAccessToken($input: UpdatePersonalAccessTokenInput!) {
    updatePersonalAccessToken(input: $input) {
      personalAccessToken {
        id
        accountId
        userId
        description
        accessToken
        createdAt
        scopes {
          accountRead
          escalationPoliciesRead
          escalationPoliciesWrite
          fileUploadsWrite
          incidentsRead
          incidentsWrite
          incidentEventsRead
          integrationsRead
          integrationsWrite
          integrationAlertsRead
          invitationsRead
          invitationsWrite
          membershipsRead
          membershipsWrite
          overridesRead
          overridesWrite
          schedulesRead
          schedulesWrite
          shiftsRead
          shiftsWrite
          teamsRead
          teamsWrite
          usersRead
          usersWrite
          viewerUserRead
          viewerUserWrite
          viewerContactMethodsRead
          viewerContactMethodsWrite
          viewerIncidentNotificationRulesRead
          viewerIncidentNotificationRulesWrite
          viewerInvitationsRead
        }
        viewerCanAdminister
      }
    }
  }
`
const DELETE_PERSONAL_ACCESS_TOKEN = gql`
  mutation DeletePersonalAccessToken($input: DeletePersonalAccessTokenInput!) {
    deletePersonalAccessToken(input: $input) {
      personalAccessTokenId
    }
  }
`
