import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { R5Error, Loader, R5Container, R5Title } from '../../components/shared'
import {
  MembershipItem,
  CoverageItem,
  UserHeader,
} from '../../components/Users'
import { useCallback, useEffect } from 'react'
import { useBack, useQuery } from '../../hooks'
import { Link as RouterLink } from '@reach/router'
import UserOptions from '../../constants/UserOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useCurrents } from '../../context/currents'
import moment from 'moment-timezone'

export default function UserPage({ location, userId }) {
  useBack('/users')

  return location.state?.user ? (
    <UserPageWithLoader userId={location.state.user.id} />
  ) : (
    <UserPageWithLoader userId={userId} />
  )
}

function UserPageWithLoader({ userId }) {
  const { data, errors, loading, refetch } = useQuery(QUERY, {
    userId,
  })

  if (loading) return <Loader />
  if (errors) return <R5Error errors={errors} />

  return (
    <>
      <R5Title title={`User - ${data.user.name}`} />
      <UserPageLoaded refetch={refetch} user={data.user} />
    </>
  )
}

function UserPageLoaded({ refetch, user }) {
  const classes = useStyles()
  const { accountId, subscribe } = useCurrents()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: USER_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_CREATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: MEMBERSHIP_DELETE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  function renderEmpty(text) {
    return (
      <ListItem className={classes.minHeight}>
        <Typography className={classes.cardEmpty} color="textSecondary">
          {text}
        </Typography>
      </ListItem>
    )
  }

  function renderCoverage(coverage, emptyText) {
    return (
      <Card className={classes.card} variant="outlined">
        <CardContent className={classes.content}>
          {coverage.length === 0 ? (
            renderEmpty(emptyText)
          ) : (
            <List disablePadding>
              {coverage.map((item, index) => (
                <CoverageItem coverage={item} key={index} />
              ))}
            </List>
          )}
        </CardContent>
      </Card>
    )
  }

  function renderCurrent() {
    _.forEach(user.currentCoverage.onCall, (i) => (i.coverageType = 'ON_CALL'))
    _.forEach(user.currentCoverage.onDuty, (i) => (i.coverageType = 'ON_DUTY'))
    const sortedCoverage = _.sortBy(
      _.concat(user.currentCoverage.onCall, user.currentCoverage.onDuty),
      'startsAt'
    )

    return (
      <Box className="mt-5">
        <Box className="flex items-center justify-between">
          <Box className="flex items-center">
            <Typography className={classes.header} color="textSecondary">
              Current Coverage
            </Typography>
            {sortedCoverage.length > 0 && (
              <Typography
                className="ml-xs"
                sx={{
                  color: styles.label,
                  fontStyle: 'italic',
                  fontSize: 12,
                }}
              >
                All times {moment.tz(moment.tz.guess(true)).format('z')}
              </Typography>
            )}
          </Box>
        </Box>
        {renderCoverage(sortedCoverage, 'Not currently on call or on duty.')}
      </Box>
    )
  }

  function renderUpcoming() {
    _.forEach(user.upcomingCoverage.onCall, (i) => (i.coverageType = 'ON_CALL'))
    _.forEach(user.upcomingCoverage.onDuty, (i) => (i.coverageType = 'ON_DUTY'))
    const sortedCoverage = _.sortBy(
      _.concat(user.upcomingCoverage.onCall, user.upcomingCoverage.onDuty),
      'startsAt'
    )

    return (
      <Box className="mt-5">
        <Box className="flex items-center justify-between">
          <Box className="flex items-center">
            <Typography className={classes.header} color="textSecondary">
              Upcoming Coverage
            </Typography>
            {sortedCoverage.length > 0 && (
              <Typography
                className="ml-xs"
                sx={{
                  color: styles.label,
                  fontStyle: 'italic',
                  fontSize: 12,
                }}
              >
                All times {moment.tz(moment.tz.guess(true)).format('z')}
              </Typography>
            )}
          </Box>
        </Box>
        {renderCoverage(
          sortedCoverage,
          'No upcoming on call or on duty coverage.'
        )}
      </Box>
    )
  }

  function renderTeams() {
    const hasTeams = user.memberships.nodes.length > 0
    const teams = user.memberships.nodes.map((membership) => (
      <MembershipItem
        key={`membership-${membership.id}`}
        membership={membership}
        user={user}
      />
    ))

    return (
      <Card className={classes.card} variant="outlined">
        <CardHeader
          title="Teams"
          titleTypographyProps={{
            className: classes.cardTitle,
            color: 'textSecondary',
          }}
        />
        <Divider />
        <CardContent className={classes.content}>
          <List disablePadding>
            {hasTeams ? teams : renderEmpty(`${user.name} is not on any teams`)}
          </List>
        </CardContent>
      </Card>
    )
  }

  return (
    <Box>
      <UserHeader
        subtitle={
          user.isDeactivated
            ? 'Deactivated'
            : _.find(UserOptions.roles, { value: user.role }).label
        }
        user={user}
      />
      <R5Container>
        {user.isDeactivated ? (
          <Box className="flex flex-row justify-center">
            <Link
              className={`mr-xs mt-1/10 ${classes.text}`}
              component={RouterLink}
              to={
                user.viewerCanAdminister
                  ? '/profile/edit'
                  : `/users/${user.id}/edit`
              }
              size="large"
            >
              Reactivate
            </Link>
            <Typography
              className={`mt-1/10 ${classes.text}`}
              color="textSecondary"
            >
              user to schedule them
            </Typography>
          </Box>
        ) : (
          <>
            {renderCurrent()}
            {renderUpcoming()}
            {renderTeams()}
          </>
        )}
      </R5Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  cardEmpty: {
    fontSize: 14,
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
  header: {
    fontSize: 14,
    fontWeight: 900,
    letterSpacing: 1,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textTransform: 'uppercase',
  },
  minHeight: {
    height: 48,
  },
  paper: {
    backgroundColor: styles.primary.color,
  },
  text: {
    fontWeight: 'bold',
  },
  title: {
    fontWeight: 'bold',
  },
}))

const QUERY = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      id
      name
      role
      initials
      avatarUrl
      isDeactivated
      viewerCanAdminister
      viewerCanChangeRole
      currentCoverage {
        onCall {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
        onDuty {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
      }
      upcomingCoverage {
        onCall {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
        onDuty {
          team {
            id
            accentColor
            name
          }
          schedule {
            id
            name
          }
          shift {
            id
            name
          }
          startsAt
          endsAt
          rotationStartsAt
          rotationEndsAt
          override {
            fromUser {
              name
            }
          }
        }
      }
      memberships(first: 30) {
        nodes {
          id
          role
          teamId
          team {
            id
            name
            accentColor
          }
          user {
            id
            name
            role
          }
        }
      }
    }
  }
`

const MEMBERSHIP_CREATE_SUBSCRIPTION = gql`
  subscription onMembershipCreated($accountId: ID!) {
    membershipCreated(accountId: $accountId) {
      id
    }
  }
`

const MEMBERSHIP_UPDATE_SUBSCRIPTION = gql`
  subscription onMembershipUpdated($accountId: ID!) {
    membershipUpdated(accountId: $accountId) {
      id
      accountId
      userId
      teamId
      role
      createdAt
    }
  }
`

const MEMBERSHIP_DELETE_SUBSCRIPTION = gql`
  subscription onMembershipDeleted($accountId: ID!) {
    membershipDeleted(accountId: $accountId) {
      id
    }
  }
`

const USER_UPDATE_SUBSCRIPTION = gql`
  subscription onUserUpdated($accountId: ID!) {
    userUpdated(accountId: $accountId) {
      id
      updatedAt
    }
  }
`
