import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Autocomplete } from '@mui/material'
import { R5Error } from '../shared'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useQuery } from '../../hooks'

export default function FromUserSelect({
  fromUser,
  setFromUser,
  shifts,
  teamId,
  toUser,
  validationErrors,
}) {
  const classes = useStyles()
  const { data, errors, fetchMore, fetchingMore, loading } = useQuery(QUERY, {
    teamId,
  })
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (data.team)
      setUsers(
        [
          {
            id: null,
            initials: null,
            name: `Everyone in ${`the selected ${
              shifts.length > 1 ? 'shifts' : 'shift'
            }`}`,
          },
          ...data.team.memberships.nodes.map((membership) => membership.user),
        ].filter((user) => user.id !== toUser?.id)
      )
  }, [data, loading, shifts.length, toUser])

  useEffect(() => {
    if (
      !loading &&
      !fetchingMore &&
      data.team.memberships.pageInfo.hasNextPage
    ) {
      fetchMore({
        variables: {
          cursor: data.team.memberships.pageInfo.endCursor,
          teamId: data.team.id,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.team.memberships.nodes.length
            ? {
                team: {
                  ...previousResult.team,
                  memberships: {
                    __typename: previousResult.team.memberships.__typename,
                    nodes: [
                      ...previousResult.team.memberships.nodes,
                      ...fetchMoreResult.team.memberships.nodes,
                    ],
                    pageInfo: fetchMoreResult.team.memberships.pageInfo,
                  },
                },
              }
            : previousResult
        },
      })
    }
  }, [data, fetchMore, fetchingMore, loading, teamId])

  if (errors) return <R5Error errors={errors} />

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent className={classes.content}>
        <FormControl fullWidth>
          <InputLabel
            className={classes.label}
            error={_.some(validationErrors['fromUser'])}
            htmlFor="from-user"
          >
            Who is being covered?
          </InputLabel>
          <Autocomplete
            blurOnSelect
            classes={{
              clearIndicator: classes.clearIndicator,
              inputRoot: classes.inputRoot,
              popupIndicator: classes.popupIndicator,
              root: classes.root,
            }}
            disableClearable
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.name
            }
            isOptionEqualToValue={(option, value) => option.name === value}
            id="from-user"
            options={users}
            onChange={(_event, option) => setFromUser(option)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            size="small"
            value={
              fromUser
                ? fromUser.name
                : `Everyone in ${`the selected ${
                    shifts.length > 1 ? 'shifts' : 'shift'
                  }`}`
            }
          />
        </FormControl>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
    backgroundColor: styles.primary.color,
    marginBottom: theme.spacing(3),
  },
  clearIndicator: {
    color: theme.palette.common.white,
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  inputRoot: {
    backgroundColor: '#ffffff33',
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
  popupIndicator: {
    color: theme.palette.common.white,
  },
  label: {
    color: theme.palette.common.white,
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.common.white,
      },
    },
  },
}))

const QUERY = gql`
  query FromUserSelect($cursor: String, $teamId: ID!) {
    team(id: $teamId) {
      id
      memberships(after: $cursor, first: 30) {
        nodes {
          id
          user {
            id
            initials
            name
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`
