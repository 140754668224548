import {
  AlertDialog,
  ConfirmButton,
  R5TextField,
} from '../../components/shared'
import { Box, Button, CardContent, CardHeader, Typography } from '@mui/material'
import React, { useState } from 'react'

import { Alert } from '@mui/material'
import { Auth } from 'aws-amplify'
import { QRCodeCanvas } from 'qrcode.react'
import _ from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { useCurrents } from '../../context/currents'

export default function MFAForm() {
  const classes = useStyles()
  const { cognitoUser, load } = useCurrents()

  const [mfaConfigurationKey, setMFAConfigurationKey] = useState('')
  const [mfaChallangeAnswer, setMFAChallangeAnswer] = useState('')
  const [showMFAConfigurationKey, setShowMFAConfigurationKey] = useState(false)
  const [mfaLoading, setMFALoading] = useState(false)
  const [disablingMFA, setDisablingMFA] = useState(false)
  const [enablingMFA, setEnablingMFA] = useState(false)
  const [showMFAEnabledDialog, setShowMFAEnabledDialog] = useState(false)

  const [validationErrors, setValidationErrors] = useState({})

  async function handleEnableMFA(event) {
    event && event.preventDefault()

    setEnablingMFA(true)
    setValidationErrors({})

    try {
      const code = await Auth.setupTOTP(cognitoUser)
      setMFAConfigurationKey(code)
    } catch (error) {
      setEnablingMFA(false)
      if (error.message) setValidationErrors({ mfa: error.message })
    }
  }

  async function handleDisableMFA() {
    setDisablingMFA(true)
    setValidationErrors({})

    try {
      await Auth.setPreferredMFA(cognitoUser, 'NOMFA')
      setDisablingMFA(false)
      load()
    } catch (error) {
      if (error.message === 'User has not verified software token mfa')
        handleEnableMFA()

      setDisablingMFA(false)
      if (error.message) setValidationErrors({ mfa: error.message })
    }
  }

  async function handleConfirmMFA(event) {
    event.preventDefault()

    setMFALoading(true)
    setValidationErrors({})

    try {
      await Auth.verifyTotpToken(cognitoUser, mfaChallangeAnswer)
      await Auth.setPreferredMFA(cognitoUser, 'TOTP')
      setEnablingMFA(false)
      setMFALoading(false)
      setShowMFAEnabledDialog(true)
    } catch (error) {
      setMFALoading(false)
      if (error.message) setValidationErrors({ mfa: error.message })
    }
  }

  return (
    <>
      <CardHeader
        title={
          <Typography className={classes.label} color="textSecondary">
            {cognitoUser.preferredMFA === 'NOMFA'
              ? 'Upgrade your security with 2FA'
              : 'Manage 2FA'}
          </Typography>
        }
      />
      <CardContent
        className={`flex flex-1 flex-row justify-end ${classes.content}`}
      >
        {enablingMFA && mfaConfigurationKey ? (
          <form className="flex flex-1 flex-col" onSubmit={handleConfirmMFA}>
            <Box className="flex flex-col items-center">
              <Typography className={classes.primary}>
                Now, open the authenticator app, tap "Scan QR Code" or "+", and
                when it asks, point your phone's camera at this QR code picture.
              </Typography>
              <QRCodeCanvas
                includeMargin
                size={200}
                value={`otpauth://totp/AWSCognito:${cognitoUser.username}?secret=${mfaConfigurationKey}&issuer=Ready%20Five`}
              />
              <Button
                className="mb-sm"
                onClick={() =>
                  setShowMFAConfigurationKey(
                    (prevShowMFAConfigurationKey) =>
                      !prevShowMFAConfigurationKey
                  )
                }
              >
                {showMFAConfigurationKey ? 'Hide' : 'Show'} configuration key
              </Button>
              {showMFAConfigurationKey && (
                <Alert
                  className="mb-sm break-all"
                  icon={false}
                  severity="warning"
                >
                  {mfaConfigurationKey}
                </Alert>
              )}
              <Typography className={classes.primary}>
                After scanning with your camera, the app will generate a
                six-digit code. Enter it here.
              </Typography>
            </Box>
            <R5TextField
              className="mt-lg"
              error={_.some(validationErrors['mfa'])}
              placeholder="Authentication code"
              onChange={setMFAChallangeAnswer}
              value={mfaChallangeAnswer}
            />
            <Box className="flex flex-1 flex-row justify-end mt-md">
              <Button
                className="mr-sm"
                onClick={() => setEnablingMFA(false)}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={mfaLoading || mfaChallangeAnswer.length === 0}
                type="submit"
                variant="outlined"
              >
                Verify and continue
              </Button>
            </Box>
          </form>
        ) : cognitoUser.preferredMFA === 'NOMFA' ? (
          <form className="flex flex-1 flex-col" onSubmit={handleEnableMFA}>
            <Typography className={classes.primary} paragraph={true}>
              You'll need a 2FA authenticator app on your phone.
            </Typography>
            <Typography className={classes.primary} paragraph={true}>
              <strong>
                If you already have one, click OK below. If you don't have one,
                or you aren't sure, we recommend Microsoft Authenticator.
              </strong>{' '}
              You can download it free on the App Store for iPhone, or Google
              Play Store for Android. Grab your phone, search the store, and
              install it now.
            </Typography>
            <Typography className={classes.primary} paragraph={true}>
              You can also use 1Password, LastPass, Authy, Google Authenticator,
              Duo, or any other key generator that supports TOTP.
            </Typography>
            <Box className="flex flex-1 flex-row justify-end mt-md">
              <Button type="submit" variant="outlined">
                OK, I've got a 2FA app on my phone
              </Button>
            </Box>
          </form>
        ) : (
          <Box className="flex flex-1 flex-col">
            <Typography className={classes.primary} paragraph={true}>
              <strong>2FA is enabled on your account.</strong> When you sign in,
              you'll also be asked to generate a 6-digit code with the
              authenticator app on your phone.
            </Typography>
            <Typography className={classes.primary}>
              Disabling 2FA will remove the security of a second factor from
              your account and is not recommended. Once disabled, you'll need
              only your password to login.
            </Typography>
            <Box className="flex flex-1 flex-row justify-end mt-md">
              <ConfirmButton
                component={Button}
                confirmContent="Are you sure you want to disable 2FA for your account?"
                confirmText="Disable 2FA"
                disabled={disablingMFA}
                error
                onConfirm={handleDisableMFA}
                title="Disable 2FA"
                variant="outlined"
              >
                Disable 2FA
              </ConfirmButton>
            </Box>
          </Box>
        )}
      </CardContent>
      <AlertDialog
        cancelText="Close"
        content="2FA is enabled on your Ready Five account. From now on, when you sign in, you'll also be asked to generate a 6-digit code with the authenticator app on your phone."
        handleClose={() => {
          setShowMFAEnabledDialog(false)
          load()
        }}
        open={showMFAEnabledDialog}
        title="2FA Enabled"
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  primary: {
    fontSize: 14,
  },
}))
