import * as Icons from '../../components/icons'

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import PeriodPill from './PeriodPill'
import { Link as RouterLink } from '@reach/router'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment-timezone'
import { styles } from '../../constants/styles'

export default function OverridePeriod({
  final,
  index,
  period,
  periodEnd,
  periodStart,
  schedule,
  containerDimensions,
}) {
  const classes = useStyles()
  const [detailsVisible, setDetailsVisible] = useState(false)

  function handleClose() {
    setDetailsVisible(false)
  }

  function renderDetails() {
    return (
      <Dialog
        aria-labelledby="period-dialog-title"
        aria-describedby="period-dialog-description"
        classes={{ paper: classes.paper }}
        fullWidth
        onClose={handleClose}
        open={detailsVisible}
        maxWidth="xs"
      >
        <DialogTitle id="period-dialog-title">
          <Box className="flex flex-row justify-between items-start mb-2">
            <Chip className={classes.chip} label={period.target.name} />
            <IconButton onClick={handleClose} size="small">
              <Icons.Close color="#fff" size={20} />
            </IconButton>
          </Box>
          <Box>
            <Typography className={`${classes.label} mb-xs`}>
              Covering for
            </Typography>
            <Chip
              className={classes.chip}
              label={period.override.fromUser?.name || 'Everyone in the shift'}
            />
          </Box>
        </DialogTitle>
        <Divider className={classes.divider} />
        <DialogContent
          className={
            period.override.viewerCanAdminister && !final ? null : 'mb-md'
          }
        >
          <Box className="flex flex-row justify-between my-3">
            <Typography className={classes.label}>Starts</Typography>
            <Typography className={classes.date}>
              {moment
                .unix(period.actualStartsAt)
                .tz(moment.tz.guess(true))
                .format('ddd, MMM D h:mma z')}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between">
            <Typography className={classes.label}>Ends</Typography>
            <Typography className={classes.date}>
              {period.actualEndsAt
                ? moment
                    .unix(period.actualEndsAt)
                    .tz(moment.tz.guess(true))
                    .format('ddd, MMM D h:mma z')
                : 'Never'}
            </Typography>
          </Box>
        </DialogContent>
        {period.override.viewerCanAdminister && !final && (
          <DialogActions>
            <Button
              className={classes.button}
              component={RouterLink}
              fullWidth
              to={`/teams/${schedule.team.id}/schedules/${schedule.id}/overrides/${period.override.id}/edit`}
              variant="outlined"
            >
              Edit Override
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  }

  return (
    <PeriodPill
      index={index}
      period={period}
      periodEnd={periodEnd}
      periodStart={periodStart}
      renderDetails={renderDetails}
      showDetails={() => setDetailsVisible(true)}
      containerDimensions={containerDimensions}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  actions: {
    paddingTop: 0,
  },
  button: {
    borderColor: '#fff',
    color: '#fff',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderColor: '#fff',
    },
  },
  chip: {
    backgroundColor: '#372CB3',
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
  },
  date: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  label: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1,
    opacity: 0.5,
    textTransform: 'uppercase',
  },
  paper: {
    backgroundColor: styles.r5Purple.color,
    borderRadius: 15,
  },
}))
