import React, { useCallback, useEffect } from 'react'

import { R5Error } from '../../components/shared'
import gql from 'graphql-tag'
import { useCurrents } from '../../context/currents'
import { useQuery } from '../../hooks'

export default function AllOpenIncidents({ Incidents }) {
  const { accountId, subscribe } = useCurrents()
  const { data, errors, fetchMore, fetchingMore, loading, refetch } = useQuery(
    ALL_OPEN_INCIDENTS_QUERY
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refetchMemo = useCallback(refetch, [])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INCIDENT_CREATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  useEffect(() => {
    const { unsubscribe } = subscribe({
      query: INCIDENT_UPDATE_SUBSCRIPTION,
      variables: { accountId },
      onData: (_data) => refetchMemo(),
    })
    return unsubscribe
  }, [accountId, refetchMemo, subscribe])

  if (errors) return <R5Error errors={errors} />

  function handleNext() {
    if (fetchingMore) return

    if (data.incidents.pageInfo.hasPreviousPage) {
      fetchMore({
        variables: { cursor: data.incidents.pageInfo.endCursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return fetchMoreResult.incidents.nodes.length
            ? {
                incidents: {
                  __typename: previousResult.incidents.__typename,
                  nodes: [
                    ...previousResult.incidents.nodes,
                    ...fetchMoreResult.incidents.nodes,
                  ],
                  pageInfo: fetchMoreResult.incidents.pageInfo,
                },
              }
            : previousResult
        },
      })
    }
  }

  return (
    <Incidents
      handleNext={handleNext}
      incidents={loading ? { nodes: [] } : data.incidents}
      loading={loading}
    />
  )
}

const FRAGMENTS = {
  incident: gql`
    fragment IncidentsIncident on Incident {
      id
      number
      state
      status
      title
      createdAt
      updatedAt
      triggerAt
      lastTriggeredAt
      viewerCanAcknowledge
      viewerCanSnooze
      viewerCanResolve
      viewerCanReassign
      viewerCanRedact
      createdBy {
        id
        name
        initials
        accentColor
      }
      updatedBy {
        id
        name
        initials
        accentColor
      }
      assignedTo {
        id
        name
        initials
        accentColor
      }
    }
  `,
}

const ALL_OPEN_INCIDENTS_QUERY = gql`
  query AllOpenIncidents($cursor: String) {
    incidents(before: $cursor, last: 30, reverse: true, state: OPEN) {
      pageInfo {
        endCursor
        hasPreviousPage
      }
      nodes {
        ...IncidentsIncident
      }
    }
  }
  ${FRAGMENTS.incident}
`

const INCIDENT_CREATE_SUBSCRIPTION = gql`
  subscription onIncidentCreated($accountId: ID!) {
    incidentCreated(accountId: $accountId) {
      id
    }
  }
`

const INCIDENT_UPDATE_SUBSCRIPTION = gql`
  subscription onIncidentUpdated($accountId: ID!) {
    incidentUpdated(accountId: $accountId) {
      id
      updatedAt
    }
  }
`
