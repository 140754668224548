import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { ConfirmButton, R5Input, R5Tooltip } from '../../components/shared'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from '@reach/router'

import { Alert } from '@mui/material'
import EventRuleCondition from './EventRuleCondition'
import EventRuleOptions from '../../constants/EventRuleOptions'
import _ from 'lodash'
import gql from 'graphql-tag'
import makeStyles from '@mui/styles/makeStyles'
import { styles } from '../../constants/styles'
import { useMutation } from '../../hooks'

export default function EventRuleForm({ eventRuleIndex, integration }) {
  const classes = useStyles()

  const navigate = useNavigate()
  const integrationMutation = useMutation(UPDATE_INTEGRATION)
  const eventRule = eventRuleIndex
    ? integration.eventRules[eventRuleIndex]
    : null

  const [action, setAction] = useState(
    eventRule ? eventRule.action : 'SET_ALERT_ACTION'
  )
  const [value, setValue] = useState(eventRule ? eventRule.value : 'TRIGGER')
  const [valueOptions, setValueOptions] = useState(
    eventRule
      ? eventRule.action === 'SET_ALERT_ACTION'
        ? EventRuleOptions.alertActions
        : action === 'SET_ALERT_SEVERITY'
        ? EventRuleOptions.severityOptions
        : []
      : EventRuleOptions.alertActions
  )
  const [stopEvaluatingOnMatch, setStopEvaluatingOnMatch] = useState(
    eventRule ? eventRule.stopEvaluatingOnMatch : false
  )
  const [criteriaMatch, setCriteriaMatch] = useState(
    eventRule ? eventRule.criteria.match : 'ANY'
  )
  const [conditions, setConditions] = useState(
    eventRule
      ? eventRule.criteria.conditions.map(
          ({
            caseSensitiveValue,
            expectedValue,
            field,
            operation,
            negateOperation,
          }) => ({
            caseSensitiveValue,
            expectedValue,
            field,
            operation,
            negateOperation,
          })
        )
      : [
          {
            caseSensitiveValue: false,
            expectedValue: '',
            field: '',
            operation: 'EQUAL',
            negateOperation: false,
          },
        ]
  )
  const [loading, setLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState([])

  useEffect(() => {
    setValueOptions(
      action === 'SET_ALERT_ACTION'
        ? EventRuleOptions.alertActions
        : action === 'SET_ALERT_SEVERITY'
        ? EventRuleOptions.severityOptions
        : []
    )
    if (action === 'SUPPRESS_ALERT') setStopEvaluatingOnMatch(true)
  }, [action])

  useEffect(() => {
    let newValue
    if (action === 'SET_ALERT_ACTION') {
      newValue = 'TRIGGER'
    } else if (action === 'SET_ALERT_SEVERITY') {
      newValue = 'SEV1'
    } else {
      newValue = ''
    }

    setValue(newValue)
  }, [action, valueOptions])

  function newEventRules() {
    return _.concat(integration.eventRules, {
      action,
      stopEvaluatingOnMatch,
      value,
      criteria: { match: criteriaMatch, conditions },
    }).map(({ action, stopEvaluatingOnMatch, value, criteria }) => ({
      action,
      stopEvaluatingOnMatch,
      value,
      criteria: {
        match: criteria.match,
        conditions: criteria.conditions.map(
          ({
            caseSensitiveValue,
            expectedValue,
            field,
            negateOperation,
            operation,
          }) => ({
            caseSensitiveValue,
            expectedValue,
            field,
            negateOperation,
            operation,
          })
        ),
      },
    }))
  }

  function updatedEventRules() {
    return integration.eventRules.map(
      (
        {
          action: prevAction,
          stopEvaluatingOnMatch: prevStopEvaluatingOnMatch,
          value: prevValue,
          criteria: prevCriteria,
        },
        index
      ) => {
        if (index === parseInt(eventRuleIndex)) {
          return {
            action,
            stopEvaluatingOnMatch,
            value,
            criteria: {
              match: criteriaMatch,
              conditions: conditions.map(
                ({
                  caseSensitiveValue,
                  expectedValue,
                  field,
                  negateOperation,
                  operation,
                }) => ({
                  caseSensitiveValue,
                  expectedValue,
                  field,
                  negateOperation,
                  operation,
                })
              ),
            },
          }
        }
        return {
          action: prevAction,
          stopEvaluatingOnMatch: prevStopEvaluatingOnMatch,
          value: prevValue,
          criteria: {
            match: prevCriteria.match,
            conditions: prevCriteria.conditions.map(
              ({
                caseSensitiveValue,
                expectedValue,
                field,
                negateOperation,
                operation,
              }) => ({
                caseSensitiveValue,
                expectedValue,
                field,
                negateOperation,
                operation,
              })
            ),
          },
        }
      }
    )
  }

  function deletedEventRules() {
    return _.reject(
      integration.eventRules,
      (_eventRule, index) => index === parseInt(eventRuleIndex)
    ).map(({ action, stopEvaluatingOnMatch, value, criteria }) => ({
      action,
      stopEvaluatingOnMatch,
      value,
      criteria: {
        match: criteria.match,
        conditions: criteria.conditions.map(
          ({
            caseSensitiveValue,
            expectedValue,
            field,
            negateOperation,
            operation,
          }) => ({
            caseSensitiveValue,
            expectedValue,
            field,
            negateOperation,
            operation,
          })
        ),
      },
    }))
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setLoading(true)

    try {
      await integrationMutation({
        teamId: integration.teamId,
        integrationId: integration.id,
        eventRules: eventRule ? updatedEventRules() : newEventRules(),
      })
      setLoading(false)
      navigate(`/teams/${integration.teamId}/integrations/${integration.id}`)
    } catch (error) {
      setLoading(false)
      if (error.errors) setValidationErrors(error.errors)
    }
  }

  async function handleDelete() {
    setLoading(true)

    try {
      await integrationMutation({
        teamId: integration.teamId,
        integrationId: integration.id,
        eventRules: deletedEventRules(),
      })
      setLoading(false)
      navigate(`/teams/${integration.teamId}/integrations/${integration.id}`)
    } catch (error) {
      setLoading(false)
      if (process.env.NODE_ENV === 'development') console.log(error)
    }
  }

  function renderHeader() {
    return (
      <CardHeader
        title={
          <>
            <Grid container spacing={1} className="items-center">
              <Grid item>
                <Typography className={classes.label}>If</Typography>
              </Grid>
              <Grid item>
                <Select
                  className="mx-md"
                  classes={{ select: classes.select }}
                  id="criteria-match"
                  input={
                    <R5Input
                      InputProps={{ classes: { input: classes.input } }}
                    />
                  }
                  onChange={setCriteriaMatch}
                  value={criteriaMatch}
                >
                  {EventRuleOptions.criteriaMatch.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <Typography className={classes.label}>
                  of the following conditions are met
                </Typography>
              </Grid>
            </Grid>
          </>
        }
      />
    )
  }

  function renderFooter() {
    return (
      <Box
        className={`flex flex-1 flex-row my-xxl ${
          eventRule ? 'justify-between' : 'justify-end'
        }`}
      >
        {eventRule && (
          <ConfirmButton
            confirmContent="Are you sure you want to delete this event rule?"
            disabled={loading}
            error
            onConfirm={handleDelete}
            title="Delete Event Rule"
          />
        )}

        <Box className="flex flex-row items-center">
          <Button
            classes={{ root: classes.marginRight }}
            component={RouterLink}
            size="large"
            to={`/teams/${integration.teamId}/integrations/${integration.id}`}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
          >
            {eventRule ? 'Update' : 'Create'}
          </Button>
        </Box>
      </Box>
    )
  }

  function renderActions() {
    return (
      <Grid container spacing={1} className="items-center mt-xl">
        <Grid item>
          <Typography className={`mx-sm ${classes.label}`}>Then</Typography>
        </Grid>
        <Grid item>
          <Select
            classes={{ select: classes.select }}
            id="action"
            input={<R5Input />}
            onChange={setAction}
            value={action}
          >
            {EventRuleOptions.actionOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {action !== 'SUPPRESS_ALERT' && (
          <>
            <Grid item>
              <Typography className={`mx-sm ${classes.label}`}>to</Typography>
            </Grid>
            <Grid item>
              <Select
                classes={{ select: classes.select }}
                id="value"
                input={<R5Input />}
                onChange={setValue}
                value={value}
              >
                {valueOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormControl className={classes.margin}>
            <FormControlLabel
              classes={{
                label: classes.checkLabel,
                labelPlacementStart: classes.checkLabelStart,
              }}
              disabled={action === 'SUPPRESS_ALERT'}
              className="flex flex-1 justify-between"
              control={
                action === 'SUPPRESS_ALERT' ? (
                  <R5Tooltip title="Once you suppress an alert, no further action can be taken.">
                    <Box>
                      <Checkbox
                        checked={stopEvaluatingOnMatch}
                        color="primary"
                        disabled={true}
                        name="stop-evaluating-on-match"
                      />
                    </Box>
                  </R5Tooltip>
                ) : (
                  <Checkbox
                    checked={stopEvaluatingOnMatch}
                    color="primary"
                    onChange={({ target: { checked } }) => {
                      setStopEvaluatingOnMatch(checked)
                    }}
                    name="stop-evaluating-on-match"
                  />
                )
              }
              label="Stop evaluating rules if this rule matches"
              labelPlacement="end"
            />
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {_.some(validationErrors) && (
        <Alert className="mb-lg" severity="error" variant="filled">
          One or more errors are present. Please fix them and try again.
        </Alert>
      )}
      <Card className={classes.card} variant="outlined">
        {renderHeader()}
        <CardContent className={classes.content}>
          {conditions.map((condition, index) => (
            <Box
              className="flex flex-col justify-center items-center"
              key={index}
            >
              <EventRuleCondition
                condition={condition}
                conditionIndex={index}
                conditions={conditions}
                index={index}
                setConditions={setConditions}
                validationErrors={validationErrors}
              />
              {index !== conditions.length - 1 && (
                <Chip
                  className={classes.chip}
                  key={index}
                  label={criteriaMatch === 'ANY' ? 'or' : 'and'}
                />
              )}
            </Box>
          ))}
          <Button
            className="mt-md"
            onClick={() => {
              setConditions([
                ...conditions,
                {
                  expectedValue: '',
                  field: '',
                  negateOperation: false,
                  operation: 'EQUAL',
                },
              ])
            }}
            variant="outlined"
          >
            + Add Condition
          </Button>
          {renderActions()}
        </CardContent>
      </Card>
      {renderFooter()}
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
  },
  checkLabel: {
    color: styles.label.color,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  checkLabelStart: {
    marginLeft: 0,
  },
  chip: {
    backgroundColor: styles.primary.color,
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  content: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  input: {
    color: styles.primary.color,
    fontSize: 14,
    padding: theme.spacing(1.5),
    minWidth: 50,
    maxWidth: 50,
  },
  label: {
    color: styles.lightLabel.color,
    fontSize: 14,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  select: { fontWeight: 'bold' },
}))

const UPDATE_INTEGRATION = gql`
  mutation UpdateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      integration {
        id
        name
      }
    }
  }
`
