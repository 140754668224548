import {
  CreatePersonalAccessTokenPage,
  EditPersonalAccessTokenPage,
} from '../../../pages/Profile'
import { Redirect, Router } from '@reach/router'

import React from 'react'

export default function PersonalAccessTokensStack() {
  return (
    <Router>
      <Redirect from="/" to="/profile/security" noThrow />

      <CreatePersonalAccessTokenPage path="new" />
      <EditPersonalAccessTokenPage path=":personalAccessTokenId/edit" />
    </Router>
  )
}
